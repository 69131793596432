<app-menubar></app-menubar>
<div style="display: flex">
  <p-sidebar [(visible)]="display">
    <div style="width: 100%">
      <img class="img-circle" src="{{ team.brasao }}" />
      <div class="name-container">
        <h1 class="name-h1">{{ team.apelido }}</h1>
      </div>
      <div style="max-width: 100%" *ngIf="!inProgress">
        <p-progressSpinner
          [style]="{
            width: '100%',
            height: '70px',
            marginTop: '20%',
            marginBottom: '20%'
          }"
          styleClass="custom-spinner"
        ></p-progressSpinner>
      </div>
    </div>
    <div style="height: 100%">
      <p-slideMenu [model]="items"></p-slideMenu>
      <div style="max-width: 100%" *ngIf="!inProgress">
        <p-progressSpinner
          [style]="{
            width: '100%',
            height: '70px',
            marginTop: '20%',
            marginBottom: '20%'
          }"
          styleClass="custom-spinner"
        ></p-progressSpinner>
      </div>
    </div>
  </p-sidebar>

  <button
    style="float: left"
    type="text"
    (mouseenter)="display = true"
    class="p-button p-button-icon-only"
  >
    <div class="my-icon-left"></div>
    <div class="my-icon-center"></div>
    <div class="my-icon-right"></div>
  </button>
  <div
    class="spin-container"
    style="float: left; max-width: 99%"
    *ngIf="!inProgress"
  >
    <p-progressSpinner
      [style]="{
        width: '100%',
        height: '70px',
        marginTop: '20%',
        marginBottom: '20%'
      }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <div class="timeline-container" *ngIf="option === 'timeline'">
    <app-team-timeline></app-team-timeline>
  </div>
  <div *ngIf="option === 'resumo'">
    <app-resumo></app-resumo>
  </div>
  <div *ngIf="option === 'cartoes'">
    <app-cartoes></app-cartoes>
  </div>
  <div *ngIf="option === 'elenco'">
    <app-elenco-team></app-elenco-team>
  </div>
  <div *ngIf="option === 'caracteristica'">
    <app-caracteristica></app-caracteristica>
  </div>
</div>
