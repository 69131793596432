<app-menubar></app-menubar>
      
    <div id="global-config-area" style="margin-top:5%;">
        <div id="left-config-area">
            <p-fieldset legend="Básico - Configurações genéricas para o sistema">
                <button pButton type="button" label="  - Configure os países, estados e cidades que podem ser usados no sistema" routerLink="/locais"><strong>Locais </strong></button>            
            </p-fieldset>
            <p-fieldset legend="Administrativo - Configurações para a administração" [toggleable]="true" [collapsed]="true">
                <button pButton type="button" label="  - Configure moedas que podem ser usadas em contratos e/ou outros recursos do sistema"  routerLink="/moedas"><strong>Moedas</strong></button>            
                <button pButton type="button" label="  - Configure as multas por atraso"  routerLink="/multas"><strong>Multas</strong></button>            
                <button pButton type="button" label="  - Configure as regras de rateio da premiação em jogos"><strong>Rateio de Premiação</strong></button>            
            </p-fieldset>
            <p-fieldset legend="Campeonatos, Jogos e Equipes - Configurações específicas para o futebol" [toggleable]="true" [collapsed]="true">
                <button pButton type="button" label="  - Configure as posições em que um jogador pode atuar"><strong>Posições</strong></button>            
                <button pButton type="button" label="  - Configure as funções de arbitros e membros da comissão técnica"><strong>Funções de Futebol</strong></button>            
                <button pButton type="button" label="  - Configure as categorias de futebol"  routerLink="/categorias"><strong>Categorias</strong></button>            
                <button pButton type="button" label="  - Configure os motivos para aplicação de cartão em jogo"><strong>Motivos de Cartão</strong></button>            
            </p-fieldset>
            <p-fieldset legend="Análise de desempenho - Configurações específicas para a análise de desempenho">
                <button pButton type="button" label="  - Configure os parâmetros de análise de jogadores"><strong>Parâmetros de jogadores</strong></button>            
            </p-fieldset>
        </div>
        <div id="right-config-area">
            <p-fieldset legend="Física/Fisiologia - Configurações específicas para a fisiologia e preparação física">
                <button pButton type="button" label="  - Configure as referências de carga para os jogadores"><strong>Referências de Carga</strong></button>            
            </p-fieldset>
            <p-fieldset legend="Departamento Médico - Configuraões específicas para os módulos de saúde" [toggleable]="true" [collapsed]="true">
                <button pButton type="button" label="  - Configure os tipos de ocorrencias, procedimentos e avaliações"><strong>Tipos de evento</strong></button>            
                <button pButton type="button" label="  - Configure os mecanismos de lesão/ocorrência"  routerLink="/mecanismos"><strong>Mecanismos</strong></button>            
                <button pButton type="button" label="  - Configure as severidades de lesão/ocorrência"  routerLink="/severidades"><strong>Severidades</strong></button>            
                <button pButton type="button" label="  - Configure os motivos para aplicação de cartão em jogo"><strong>Locais do Corpo</strong></button>
                <button pButton type="button" label="  - Configure as categorias de futebol"  routerLink="/categorias"><strong>Categorias</strong></button>            
                <button pButton type="button" label="  - Configure os motivos para aplicação de cartão em jogo"><strong>Motivos de Cartão</strong></button>                 
            </p-fieldset>
            <p-fieldset legend="Avançado - Configurações avançadas do sistema" [toggleable]="true" [collapsed]="true">
                <button pButton type="button" label="  - Transfere registros de um jogador para outro"><strong>Desduplicador</strong></button>            
                <button pButton type="button" label="  - Configure os usuários do sistema" routerLink="/usuarios"><strong>Usuários</strong></button>            
                <button pButton type="button" label="  - Configure os grupos de usuários do sistema"><strong>Grupos</strong></button>            
                <button pButton type="button" label="  - Configure as regras de acesso para grupos e usuários do sistema" routerLink="/regras"><strong>Regras</strong></button>
            </p-fieldset>
        </div>
    </div>






