import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeIcons } from 'primeng/api';
import { CartoesService } from './cartoes.service';

@Component({
  selector: 'app-cartoes',
  templateUrl: './cartoes.component.html',
  styleUrls: ['./cartoes.component.scss'],
})
export class CartoesComponent implements OnInit {
  response: any;
  data: any;
  basicOptions: any;
  inProgress: boolean;
  selectedCompetition: any;
  competitions: any[];
  selectedSession: any;
  sessions:any[];
  idTeam: any;
  team: any;
  qtd_amarelo: any;
  qtd_vermelho: any;
  qtd_direto: any;
  basicData: any;
  horizontalOptions: any;
  jogo: any;
  jogador: any[]=[];


  constructor(private cartoesService: CartoesService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.idTeam = this.activatedRoute.snapshot.paramMap.get('id');
    this. listSessions();  
  }

  public listSessions() {
    this.inProgress = false;
    
    this.cartoesService.getSessions().subscribe(
      (response) => {
        this.sessions = response;
        
    
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public listCompetitions() {
    if (this.selectedSession) {
      this.cartoesService.getCompetitions(this.selectedSession).subscribe(
        (response) => {
          this.competitions = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }
  public sessionChange() {
    this.listCompetitions()
  }

  public competitionChange() {
    this.getAtividades();
  }

  public getAtividades(){
    this.inProgress = false; 
    this.cartoesService.getAtividades(this.selectedCompetition, this.idTeam).subscribe(
      (response) =>{
        this.response = response;
        this.qtd_amarelo = this.response['qtd_amarelo'],
        this.qtd_vermelho = this.response['qtd_vermelho'],
        this.qtd_direto = this.response['qtd_direto'],
        this.jogo = this.response['jogo']
        for(let n in this.jogo){
          
          if (this.jogo[n]['jogador']){
          this.jogador.push({'jogador':this.jogo[n]['jogador']})
          }}
        console.log(this.jogador)
        this.data = {
          datasets: [{
              data: [this.qtd_amarelo, this.qtd_vermelho, this.qtd_direto],
              backgroundColor: [
                  "#FFFF00",
                  "#FF0000",
                  "#8B0000",
                  
              ],
              label: 'Cartões'
          }],
          labels: [
              "Amarelo",
              "Vermelho",
              "Direto",
          ]
      };
      this.basicData = {
        labels: ['Mandante X Visitante'],
        datasets: [
            {
                label: 'Mandante',
                backgroundColor: '#42A5F5',
                data: [response['mandante']]
            },
            {
                label: 'Visitante',
                backgroundColor: '#FFA726',
                data: [response['visitante']]
            }
        ]
    };
    this.horizontalOptions = {
      indexAxis: 'y',
      plugins: {
          legend: {
              labels: {
                  color: '#495057'
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          },
          y: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          }
      }
  };
  
      }
    )

  }

}