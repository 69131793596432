import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipo-evento',
  templateUrl: './tipo-evento.component.html',
  styleUrls: ['./tipo-evento.component.scss']
})
export class TipoEventoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
