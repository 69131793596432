import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddSoccerPlayerService {

  url = localStorage.host + '/full_cast_all/';

  contractUrl = localStorage.host + '/contract/';

  teamsUrl = localStorage.host + '/grupo/';

  constructor(private http: HttpClient) { }

  public getCast(search: string, pageStart?: number, pageEnd?: number): Observable<any> {
    const start = pageStart ? pageStart : 0;
    const end = pageEnd ? pageEnd : 50;


    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url + 'perfilAdd/' +
      '?search=' +
      search +
      '&pageStart=' +
      pageStart +
      '&pageEnd=' +
      pageEnd,
      { headers: header }
    );
  }

  public getPlayerContracts(playerId: number): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url + 'search_contratcs/' +
      '?player=' +
      playerId,
      { headers: header }
    );
  }

  public addPlayer(contract: any, newInfo: any, team_id: number, type: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url + 'transfer/', {
      'player_id': contract.player_id,
      'team_id': team_id,
      'category_id': localStorage.category_id,
      'modality_id': localStorage.modality_id,
      'contract_id': contract.id,
      'transfer_type': type,
      'start': newInfo.start,
      'end': newInfo.end
    }, {
      headers: header,
    });
  }

  // public getTeams(): Observable<any> {
  //   const header = new HttpHeaders().set(
  //     'Authorization',
  //     'x-csrftoken' + localStorage.token
  //   );
  //   return this.http.get(this.teamsUrl, { headers: header });
  // }

  // public getContract(id: any): Observable<any> {
  //   const header = new HttpHeaders().set(
  //     'Authorization',
  //     'x-csrftoken' + localStorage.token
  //   );
  //   return this.http.get<any>(this.contractUrl + id + '/', {
  //     headers: header,
  //   });
  // }

  // public setContract(contract: any, id: any): Observable<any> {
  //   const header = new HttpHeaders().set(
  //     'Authorization',
  //     'x-csrftoken' + localStorage.token
  //   );
  //   return this.http.patch<any>(this.contractUrl + id + '/', contract, {
  //     headers: header,
  //   });
  // }
}
