<app-menubar></app-menubar>
<p-toast></p-toast>
<div style="max-width: 100%" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{
      width: '100%',
      height: '70px',
      marginTop: '20%',
      marginBottom: '20%'
    }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>

<div class="card crud-container">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
      <button
        pButton
        pRipple
        [label]="Remover"
        icon="pi pi-trash"
        class="p-button-danger"
        (click)="deleteSelectedTeams()"
        [disabled]="!selectedTeams || !selectedTeams.length"
      ></button>
    </ng-template>
  </p-toolbar>

  <p-table
    #dt
    [value]="teams"
    [rows]="10"
    [paginator]="true"
    [globalFilterFields]="['apelido', 'legenda']"
    [(selection)]="selectedTeams"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <h5 class="p-m-0">Times</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem; margin-top: 10%;">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th style="width: 5rem">Brasao</th>
        <th pSortableColumn="apelido">
          Apelido <p-sortIcon field="apelido"></p-sortIcon>
        </th>
        <th pSortableColumn="legenda">
          Sigla <p-sortIcon field="legenda"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-team>
      <tr>
        <td>
          <p-tableCheckbox [value]="team"></p-tableCheckbox>
        </td>
        <td>
          <p-avatar image="{{ team.brasao }}"></p-avatar>
        </td>
        <td>{{ team.apelido }}</td>
        <td>
          {{ team.legenda }}
        </td>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-eye"
            class="p-button-rounded crud-button"
            (click)="showTeam(team)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded crud-button"
            (click)="editTeam(team)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded crud-button"
            (click)="deleteTeam(team)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are
        {{ teams ? teams.length : 0 }} teams.
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="teamDialog"
  [style]="{ width: '50%' }"
  header="Equipe"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-field">
      <label for="nome">Nome</label>
      <input
        type="text"
        pInputText
        id="nome"
        [(ngModel)]="team.nome"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !team.nome"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="apelido">Apelido</label>
      <input
        type="text"
        pInputText
        id="apelido"
        [(ngModel)]="team.apelido"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !team.apelido"
        >Campo Obrigatório.</small
      >
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="brasao">Brasão</label>
      <input id="brasao" type="file" accept="image/*" (change)="selectFile($event)" class="form-control" />
    </div>
    <div class="p-field">
      <label for="legenda">Legenda</label>
      <input
        type="text"
        pInputText
        id="legenda"
        [(ngModel)]="team.legenda"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !team.legenda"
        >Campo Obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="cor">Cor</label>
      <p-colorPicker
        required
        id="cor"
        [disabled]="verb === 'GET'"
        [(ngModel)]="team.cor"
      ></p-colorPicker>

      <small class="p-invalid" *ngIf="submitted && !team.cor"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="pais_id">País</label>
      <p-dropdown
        [options]="nations"
        [(ngModel)]="selectedNation"
        optionLabel="name"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o país"
        appendTo="body"
      >
        <ng-template pTemplate="selectedItem">
          <div class="team-item team-item-value" *ngIf="selectedNation">
            <div>{{ selectedTeam.nome }}</div>
          </div>
        </ng-template>
        <ng-template let-nation pTemplate="item">
          <div class="team-item">
            <div>{{ nation.nome }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small class="p-invalid" *ngIf="submitted && !selectedNation"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="estadio_id">Estádio</label>
      <p-dropdown
        [options]="places"
        [(ngModel)]="selectedPlace"
        optionLabel="apelido"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o estádio"
        appendTo="body"
      >
        <ng-template pTemplate="selectedItem">
          <div class="team-item team-item-value" *ngIf="selectedPlace">
            <div>{{ selectedPlace.apelido }}</div>
          </div>
        </ng-template>
        <ng-template let-place pTemplate="item">
          <div class="team-item">
            <div>{{ place.apelido }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small class="p-invalid" *ngIf="submitted && !selectedPlace"
        >Campo obrigatório.</small
      >
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
