import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PdfCastService } from './pdf-cast.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Contract } from './contract';
import '@angular/compiler'
import jsPDF from "jspdf";
import { HttpHeaders } from '@angular/common/http';
import { image } from 'html2canvas/dist/types/css/types/image';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-cast',
  templateUrl: './pdf-cast.component.html',
  styleUrls: ['./pdf-cast.component.scss'],
  providers: [PdfCastService, MessageService, ConfirmationService],
})
export class PdfCastComponent implements OnInit {

  players: any[];
  playerSearch: string;
  pageCount: number = 0;
  pageFetch: number = 50;
  positions: any[];
  response: any[];
  teamId: any;
  teams: any[];
  selectedTeam: any;
  profileId: any;
  contractId: any;
  contract: Contract;
  modalityName: string;
  inProgress: boolean;
  profileDialog: boolean;
  transferDialog: boolean;
  transferLoanDialog: boolean;
  renewDialog: boolean;
  addSoccerPlayerDialog: boolean;
  playerContracts: any[];
  playerContractInfo: any;
  activeContract: any;
  hideAddButton: boolean;
  formpage: boolean;
  base64textString: any ;
  testImage: any;

  @ViewChild('dt',{static: false}) el: ElementRef

  constructor(
    private pdfCastService: PdfCastService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer
  ) {}
  

  ngOnInit(): void {
    this.formpage = true
    this.teamId = this.activatedRoute.snapshot.paramMap.get('id') != 'null' ? this.activatedRoute.snapshot.paramMap.get('id') : localStorage.getItem('activeTeam');
    this.modalityName = localStorage.modality_name;
    this.listCast();
    this.getTeam();

    if (this.teamId !== 'null') {
      this.inProgress = true;
    }
  }
  
  public getTeam() {
    this.pdfCastService.getTeam(this.teamId).subscribe(
      (response) => {
        this.teamId = response;
        
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
  public listCast(pageStart?: number, pageEnd?: number) {
    this.teamId = localStorage.getItem('activeTeam');

    this.pdfCastService.getCast(this.teamId).subscribe(
      (response) => {
        
        this.inProgress = false;
        this.response = response;
        this.pageCount = response[0].lastItemNumber
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }
  
  public printPdf(){
    const doc= new jsPDF('l', 'pt' )
    doc.html(this.el.nativeElement, {callback:(doc) =>
    doc.save("Elenco-"+ this.teamId.apelido +".pdf")});
  }

}
