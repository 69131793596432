import { Component, Input, OnInit } from '@angular/core';
import { NewSoccerPlayerService } from './new-soccer-player.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Contract } from './contract';
import { NewPlayer } from './newplayer';
import '@angular/compiler'
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-soccer-player',
  templateUrl: './new-soccer-player.component.html',
  styleUrls: ['./new-soccer-player.component.scss'],
  providers: [NewSoccerPlayerService, MessageService, ConfirmationService],
})
export class NewSoccerPlayerComponent implements OnInit {
  @Input() teamId: any;
  players: any[];
  playerSearch: string;
  pageCount: number = 0;
  pageFetch: number = 50;
  positions: any[];
  response: any[];
  teams: any[];
  selectedTeam: any;
  profileId: any;
  contractId: any;
  contract: Contract;
  modalityName: string;
  inProgress: boolean;
  profileDialog: boolean;
  transferDialog: boolean;
  transferLoanDialog: boolean;
  renewDialog: boolean;
  addSoccerPlayerDialog: boolean;
  playerContracts: any[];
  playerContractInfo: any;
  activeContract: any;
  hideAddButton: boolean;
  formpage: boolean;
  selectPais: any;
  selectPosicao: any;
  selectTeam: any;
  side: any;
  selectCategoria: any;
  selectEstado: any;
  date1: any;
  player: FormGroup;
  selecao: any;
  sexo: any;


  constructor(
    private newSoccerPlayerService: NewSoccerPlayerService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private fb: FormBuilder,

  ) { }


  ngOnInit(): void {
    this.http.get('https://api.csm.corinthians.com.br/./pais/')
      .subscribe(pais => { this.selectPais = pais; });
    this.http.get('https://api.csm.corinthians.com.br/posicao/?modalidade_id=' + localStorage.modality_id + '&categoria_id=' + localStorage.categoty_id)
      .subscribe(posicao => { this.selectPosicao = posicao; });
    this.http.get(`https://api.csm.corinthians.com.br/estado/`)
      .subscribe(estado => { this.selectEstado = estado });
    this.http.get('https://api.csm.corinthians.com.br/categoria/?modality_id=' + localStorage.modality_id + '&category_id=' + localStorage.categoty_id)
      .subscribe(categoria => { this.selectCategoria = categoria });


    this.player = this.fb.group({
      nome: [null,], apelido: [null,], foto: [null,], sexo: [null,], nascimento: [null,], side: [null],
      altura: [null,], ativo: [null], categoria: [null], equipe: [null], posicao: [null], nacionalidade: [null],
      estado: [null], pais: [null], federacao: [null], federacao_regional: [null], jogador_selecao: [null],
      id_ogol: [null], id_soccerway: [null], id_transfermarket: [null], inicio: [null], fim: [null], camisa: [null]

    })
    this.sexo = [{ name: "Masculino", id: "M" }, { name: "Feminino", id: "F" }]
    this.selecao = [{ name: "Sim Principal", id: 'S' }, { name: "Sim Base", id: 'B' }, { name: "não", id: 'N' }]
    this.formpage = true;
    this.modalityName = localStorage.modality_name;
    this.side = [{ name: "Destro", id: "D" }, { name: "Canhoto", id: "C" }, { name: "Ambidestro", id: "A" }]

  }



  transferInfo(contract: any) {
    this.activeContract = contract;

    this.playerContractInfo = {
      'start': new Date(),
      'end': new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    };
  }

  addNewPlayer() {
    console.log(this.player.value['estado'])
    if (!this.player.value['estado']) {
      this.player.value['estado'] = null

    }
    if (!this.player.value['id_ogol']) {
      this.player.value['id_ogol'] = null

    }
    if (!this.player.value['id_soccerway']) {
      this.player.value['id_soccerway'] = null

    }
    if (!this.player.value['federacao']) {
      this.player.value['federacao'] = null

    }
    if (!this.player.value['federacao_regional']) {
      this.player.value['federacao_regional'] = null

    }
    if (!this.player.value['id_transfermarket']) {
      this.player.value['id_transfermarket'] = null

    }

    this.newSoccerPlayerService.newPlayer(this.player).subscribe(
      (response) => {
        this.sendFoto(response)
        alert('Operacao realizada com sucesso')
        window.location.reload();
        this.inProgress = false;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );

  }



  public listCast(pageStart?: number, pageEnd?: number) {
    this.teamId = localStorage.getItem('activeTeam');



    this.newSoccerPlayerService.getCast(this.playerSearch, pageStart, pageEnd).subscribe(
      (response) => {

        this.inProgress = false;
        this.response = response;
        this.pageCount = response[0].lastItemNumber
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }



  public getSideString(side: string): string {

    if (side === 'C' || side === 'E') {
      return 'Canhoto';
    }
    if (side === 'D') {
      return 'Destro';
    }
    if (side === 'A') {
      return 'Ambidestro';
    }
    return null;
  }

  public newPlayerPage() {
    this.formpage = false;
    this.selectedTeam = ''
    this.http.get(`https://api.csm.corinthians.com.br/teams/`)
      .subscribe(team => {
        this.selectTeam = team;
        let index = 0
        for (let n in this.selectTeam) {
          if (this.selectTeam[n].id == this.teamId) {

            break
          }
          index = index + 1
        }
        this.selectedTeam = this.selectTeam[index]

      });
  }

  public showPlayerContracts(player: any) {
    this.newSoccerPlayerService.getPlayerContracts(player.id_people).subscribe(
      (response) => {
        console.log(response)

        if (response[0].contracts.length > 0) {
          this.playerContracts = response
        }
        else {
          alert('Esse jogador nao tem nenhum contrato ativo, criando novo')
          this.transferInfo({ id: 0, player_id: player.id })
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar os contratos.',
          life: 3000,
        });
      }
    );
  }

  addPlayer(contract: any, newInfo: any, type: string) {
    this.hideAddButton = true;

    this.newSoccerPlayerService.addPlayer(contract, newInfo, this.teamId, type).subscribe(
      (response) => {
        alert('Operacao realizada com sucesso')
        window.location.reload();
        this.inProgress = false;
        window.location.reload()
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  selectFile(event: any) {

    if (event.target.files && event.target.files[0]) {
      const foto = event.target.files[0];
      const formData = new FormData();
      formData.append('foto', foto);
      this.player['foto'] = formData['foto']
    }
  }

  sendFoto(pessoa) {
    try {
      this.http.patch('https://api.csm.corinthians.com.br/pessoa-foto/' + pessoa + '/', this.player['foto'])
        .subscribe(resposta => console.log(resposta));

    } catch (error) {
      alert('Erro ao carregar arquivo');
    }
  }


}