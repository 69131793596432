import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GamesCRUDService {
  url = localStorage.host + '/evento/';
  allGameUrl = localStorage.host + '/all-games/';
  teamsUrl = localStorage.host + '/teams/';
  eventUrl = localStorage.host + '/evento/';
  placesUrl = localStorage.host + '/estadios/';
  sessionUrl = localStorage.host + '/sessions/';
  competitionsUrl = localStorage.host + '/competitions/';
  phasesUrl = localStorage.host + '/phases/';
  turnsUrl = localStorage.host + '/turns/';
  modality_id = localStorage.modality_id;
  category_id = localStorage.category_id;

  constructor(private http: HttpClient) { }

  public getGames(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.allGameUrl +
      '?modality_id=' +
      this.modality_id +
      '&category_id=' +
      this.category_id,
      {
        headers: header,
      }
    );
  }

  public getTeams(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl, {
      headers: header,
    });
  }

  public getTeam(id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl + id + '/', {
      headers: header,
    });
  }

  public getEstadios(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.placesUrl, {
      headers: header,
    });
  }

  public getSessions(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.sessionUrl, { headers: header });
  }

  public getCompetitions(session): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.competitionsUrl + '?session=' + session +
      '&modalidade_id=' + localStorage.modality_id +
      '&categoria_id=' + localStorage.category_id, {
      headers: header,
    });
  }

  public getPhases(competition_id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.phasesUrl + '?competition_id=' + competition_id, {
      headers: header,
    });
  }

  public getTurns(phase_id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.turnsUrl + '?phase_id=' + phase_id, {
      headers: header,
    });
  }

  public getGame(gameId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.eventUrl + gameId + '/', {
      headers: header,
    });
  }

  public getGamesSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <any[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public getGamesWithOrdersSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <any[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public postGame(game: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url, game, { headers: header });
  }

  public putGame(game: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.put<any>(this.url + game.id + '/', game, {
      headers: header,
    });
  }

  public deleteGame(game: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.delete<any>(this.url + game.id + '/', {
      headers: header,
    });
  }
}
