import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-severidades',
  templateUrl: './severidades.component.html',
  styleUrls: ['./severidades.component.scss']
})
export class SeveridadesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
