import { Component, OnInit } from '@angular/core';
import { ElencoService } from './elenco.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Profile } from './elenco';
import { Contract } from './contract';
import {Location} from '@angular/common'; 
import '@angular/compiler';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { ProfileComponent } from '../profile/profile.component';
import { ConditionalExpr } from '@angular/compiler';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-elenco',
  templateUrl: './elenco.component.html',
  styleUrls: ['./elenco.component.scss'],
  providers: [ElencoService, MessageService, ConfirmationService],
})
export class ElencoComponent implements OnInit {

  cast: any[];
  contrato:any[];
  id: any;
  teamId: any;
  inProgress: boolean;




  constructor(
    private elencoService: ElencoService,
    private activatedRoute: ActivatedRoute,

  ) {}

  ngOnInit(): void {
    this.inProgress = true
    this.teamId =  this.activatedRoute.snapshot.paramMap.get('id');
    this.getCast()
  }

  getCast(){
    this.elencoService.getCast(this.teamId).subscribe(
      cast => {this.cast = cast; 
        this.inProgress = false;
      }
    )
  }

  calcContract(data){
    const dateC = new Date(data);
    const anoC = dateC.getFullYear();
    const today = new Date();
    const todayAno = today.getFullYear()
    var teste = anoC - todayAno
    if(teste < 1 && teste > -1){
      return 'teste'
    }else if(teste >= 1 && teste < 3){
      return 'teste2'
    }else if(teste >= 3){
      return 'teste3'
    }else{
      return 'teste'
    }

  }
}
      