import { Injectable } from '@angular/core';
import { Post } from './post';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DepartmentMenuService {
  url = localStorage.host + '/posts/';

  constructor(private http: HttpClient) { }

  public getPosts(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url, { headers: header });
  }

  public getPostsSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <Post[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public getPostsWithOrdersSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <Post[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public postPost(department: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url, department, { headers: header });
  }

  public putPost(department: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.put<any>(this.url + department.id + '/', department, {
      headers: header,
    });
  }

  public deletePost(department: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.delete<any>(this.url + department.id + '/', {
      headers: header,
    });
  }
}
