import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CastReportSimpleService {
  url = localStorage.host + '/full_cast/';
  teamsUrl = localStorage.host + '/teams/';

  constructor(private http: HttpClient) { }

  public getCast(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id +
      '&team_id=' +
      teamId,
      { headers: header }
    );
  }

  public getTeam(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl + teamId + '/', { headers: header });
  }

  public getTeams(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl, { headers: header });
  }
}
