import { Component, OnInit } from '@angular/core';
import { DpmedicoService } from './dpmedico.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { StepsModule } from 'primeng/steps';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-dpmedico',
  templateUrl: './dpmedico.component.html',
  styleUrls: ['./dpmedico.component.scss']
})
export class DpmedicoComponent implements OnInit {

  ocorrencias: any;
  avaliacoes: any;
  ocorrenciaDialog: boolean;
  tipOcorrencia: any;
  mecanismos: any;
  severidade: any;
  corpo: any;
  lesao: any;
  form : FormGroup;
  form2 : FormGroup;
  form3 : FormGroup;
  form4: FormGroup;
  position_x: any;
  position_y: any;
  elenco: any;
  estrutura: any
  UpdateOcorrenciaDialog: boolean;
  editOcorrencia: any;
  id_ocorrencia: any;
  tipo_saude:any;
  status: any;
  events: any[];
  check: any;
  selectedStatus:any;
  today: number = Date.now();
  formData: any;
  fileName: any;
  exames: any;
  tipoProcedimento: any;
  tipoAvaliacao: any;
  periodo: any;
  procedimentos:any;
  indice: any
  situacao: any;
  pessoa_id: any;
 

  constructor(private dpmedicoService: DpmedicoService, private fb: FormBuilder, private confirmationService: ConfirmationService,) { 
    this.form = this.fb.group({atleta:[null], tipo_ocorrencia: [null], data_ocorrencia: [null], retorno:[null], queixa:[null], 
      histotico:[null], diagnostico: [null], conduta: [null], observacao: [null], impacto: [null], severidade:[null], mecanismo: [null], estrutura:[null], reicidente:[null], modalidade: localStorage.modality_id, categoria:localStorage.category_id}) 
    
    this.form2 = this.fb.group({observacao:[null], ocorrencia_id:[null], status:[null], data:[null]}),

    this.form3 = this.fb.group({tipo_saude_id:[null], data:[null], periodo:[null], descricao:[null], evento_id:[null], identity:'atividade_procedimento', ordem:0})
    
    this.form4 = this.fb.group({inicio: [null], tipo_saude_id: [null], indice_id:[null], valor_indice:[null], situacao:[null], identity:'atividade_avaliacao', descricao:[null], evento_pai:[null], pessoa_id:[null] })
  }

  ngOnInit(): void {
    this.getOcorrencias()
    this.corpo = 'frente'
    this.lesao = 0
    this.periodo = [{id: 0, periodo: 'Manhã'}, {id: 1, periodo: 'Tarde'}, {id: 2, periodo: 'Noite'}] 
    this.situacao = [{id: 0, situacao: 'Péssimo'},{id: 1, situacao: 'Ruim'},{id: 2, situacao: 'Mediano'},{id: 3, situacao: 'Bom'},{id: 4, situacao: 'Execelente'}]
   
  }

  getOcorrencias(){
    this.dpmedicoService.getOcorrencias().subscribe(
      data => { this.ocorrencias = data;}
    )

  }

  getStatus(ocorencia_id){
    this.dpmedicoService.getStatus(ocorencia_id).subscribe(
      data => { this.status = data;
        
        return this.status.status}
    )
   
  }

  getIndice(id){
    this.dpmedicoService.getIndice(id).subscribe(
      data => {this.indice =  data}
    )
  }

  updateStatus(){
   this.status[0].status = this.selectedStatus
   this.status[0].data = new Date();
    this.dpmedicoService.patchStatus(this.status[0]).subscribe(
      data => { console.log(data)}
      
    );
    window.location.reload()
  }

  getAvaliacao(evento_pai){
    this.dpmedicoService.getAvaliacao(evento_pai).subscribe(
      data => { this.avaliacoes = data; }
    )

  }

  getEstrutura(){
    this.dpmedicoService.getEstrutura().subscribe(
      data => { this.estrutura = data; })
  }

  listCast2() {
    
    this.dpmedicoService.getCast2(1).subscribe(
      (response) => {
        
       
        this.elenco = response;

      },
  
    );
  }
  

  getTipoOcorrencia(){
    this.dpmedicoService.getTipoOcorrencia().subscribe(
      data => { 
        console.log(data)
        var ocorrencia = new Array()
        var procedimento = new Array()
        var avaliacao = new Array()
        for(let i in data){
          if(data[i].evento == 'ocorrencia'){
            ocorrencia.push(data[i])
          }else if(data[i].evento =='procedimento'){
            procedimento.push(data[i])
          }else if(data[i].evento =='avaliacao'){
            avaliacao.push(data[i])
          }
        }
        this.tipOcorrencia = ocorrencia
        this.tipoProcedimento = procedimento
        this.tipoAvaliacao = avaliacao
        console.log(this.tipoProcedimento)
        
      }
    );
  }
  addLocalCorpo(event){
    const x = event.clientX;
    const y = event.clientY;
    const div = document.getElementById('click');
    const ponto = document.createElement("span");
    const lesao =  document.getElementById('lesao');
    const drop = document.createElement('div')
    

    lesao.appendChild(drop)
    ponto.innerHTML =' <img style="margin-left: 40px; margin-top: 20px;" src="/assets/imgs/corpo_frente.png">'
    ponto.innerHTML ='<div  style="top: '+(parseInt(y)-5)+'px; left: '+(parseInt(x)-75)+'px;; width: 20px; height: 20px; border-radius: 50%; background-color: rgb(255, 0, 0); position: absolute !important; float: none">1</div>'
    div.appendChild(ponto); // crio o ponto
    
 }
 

 selectFile(event: any) {
     
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    this.fileName = file.name;
    this.formData = new FormData();
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    this.formData.append('creation_time', today.toISOString())
    this.formData.append('evento', this.id_ocorrencia );
    this.formData.append('exame', file);
    console.log(this.formData)
    
  }
}

  postExame(){
    this.dpmedicoService.postExames(this.formData).subscribe(
      data => {console.log(data); window.location.reload()}
    )

  }

  getExames(){
    this.dpmedicoService.getExames(this.id_ocorrencia).subscribe(
      data => {this.exames = data; this.exames = data}
    )
  }

  getMecanismo(){
    this.dpmedicoService.getMecanismo().subscribe(
      data =>{this.mecanismos = data;  }
    )
  }

  getSeveridade(){
    this.dpmedicoService.getSeveridade().subscribe(
      data =>{this.severidade = data; }
    )
  }

  changeBody(body){
    this.corpo = body
  }

  newDialog(){
    this.ocorrenciaDialog = true
    this.getTipoOcorrencia()
    this.getMecanismo()
    this.getSeveridade()
    this.listCast2()
    this.getEstrutura()
 
    
  }

  getColor(status){
    if(status == "I"){
      return "#FF0000"
    }else if(status =='F'){
      return "#FF8C00"
    }else if(status =='P'){
      return "#FFA500"
    }else if(status =='T'){
      return "#FFFF00"
    }else if(status =='L'){
      return "#00BFFF"
    }else{
      return "whithe"
    }
  }
  
  getPasso(status){
    if(status == "I"){
      return "Intensivo"
    }else if(status =='F'){
      return "Fisioterapia"
    }else if(status =='P'){
      return "Proprioceptivo"
    }else if(status =='T'){
      return "Transição"
    }else if(status =='L'){
      return "Liberado"
    }else{
      return "Erro"
    }
  }

  getProcedimento(id){
    this.dpmedicoService.getProcedimento(id).subscribe(
      data =>{this.procedimentos = data; console.log(data) }
    )
  }

  updateOcorrencia(ocorrencia){
    this.pessoa_id = ocorrencia.pessoa_id
    this.id_ocorrencia = ocorrencia.occorencia_id
    this.UpdateOcorrenciaDialog = true
    this.editOcorrencia = ocorrencia
    this.tipo_saude = ocorrencia.tipo_saude
    this.getStatus(this.id_ocorrencia)
    this.getProcedimento(this.id_ocorrencia)
    this.getExames()
    this.getTipoOcorrencia()
    this.getMecanismo()
    this.getSeveridade()
    this.listCast2()
    this.getAvaliacao(this.id_ocorrencia)
    this.getEstrutura()
   
  }

  postOcorrencia(){
  
    this.dpmedicoService.postOcorrencia(this.form.value).subscribe(
      data=>{console.log(data)}
    )
  }
  
  postAvaliacao(){
    this.form4.value.pessoa_id = this.pessoa_id
    this.form4.value.evento_pai = this.id_ocorrencia
    this.form4.value.indice_id = this.form4.value.indice_id.id
    this.form4.value.situacao = this.form4.value.situacao.id
    this.form4.value.tipo_saude = this.form4.value.tipo_saude.id
    this.dpmedicoService.postAvaliacao(this.form4.value).subscribe(
      data => {console.log(data)}
    )
  }
  addProcedimento(){
    this.form3.value.tipo_saude_id = this.form3.value.tipo_saude_id.id
    this.form3.value.periodo = this.form3.value.periodo.id
    this.form3.value.evento_id = this.id_ocorrencia
    this.dpmedicoService.postProcedimento(this.form3.value).subscribe(
      data => {console.log(data)}
    )
  }

  deleteAtividade(atividade){
     
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja deletar a atividade: ' + atividade.atividade +' referente a atleta ' + atividade.atleta +' ?',
      acceptLabel: "Sim",
      rejectLabel: "Não",

      accept: () => {
        this.dpmedicoService.deleteOcorrencia(atividade.id).subscribe( data => {console.log(data)} ) 
        window.location.reload()}
    })
    
   
  }



}
