import { Component, OnInit, Input } from '@angular/core';
import { CastReportService } from './cast-report.service';
import { MessageService } from 'primeng/api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-cast-report',
  templateUrl: './cast-report.component.html',
  styleUrls: ['./cast-report.component.scss'],
  providers: [CastReportService, MessageService],
})
export class CastReportComponent implements OnInit {
  inProgress: boolean;
  response: any;
  team: any;
  teams: any[];
  selectedTeam: any;

  constructor(
    private castReportService: CastReportService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listTeams();
  }

  public listCast() {
    this.getTeam();
    this.castReportService.getCast(this.selectedTeam.id).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
       
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  public listTeams() {
    this.castReportService.getTeams().subscribe(
      (response) => {
        this.inProgress = false;
        this.teams = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipes.',
          life: 3000,
        });
      }
    );
  }

  public getTeam() {
    this.castReportService.getTeam(this.selectedTeam.id).subscribe(
      (response) => {
        this.team = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipe.',
          life: 3000,
        });
      }
    );
  }

  public getSideString(side: string): string {
    if (side === 'E') {
      return 'Canhoto';
    }
    if (side === 'D') {
      return 'Destro';
    }
    return null;
  }

  exportPdf() {
    let pdf = new jsPDF('l', 'pt', 'a4');
    let element = document.getElementById('pdf');
    pdf.html(element, {
      callback: (pdf) => {
        pdf.save('CastReport.pdf');
      },
    });
  }
}
