import { Component, OnInit } from '@angular/core';
import { PostCRUDService } from './post-crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Post } from './post';
import '@angular/compiler';

@Component({
  selector: 'app-post-crud',
  templateUrl: './post-crud.component.html',
  styleUrls: ['./post-crud.component.scss'],
  providers: [PostCRUDService, MessageService, ConfirmationService],
})
export class PostCRUDComponent implements OnInit {
  postDialog: boolean;

  posts: Post[];

  post: Post;

  selectedPosts: Post[];

  selectedPost: any;

  submitted: boolean;

  verb: string;

  departmentOptions: any[];

  departments: any[];

  isRestrictedOptions: any[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  constructor(
    private postCRUDService: PostCRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listDepartments();
    this.listPost();
  }

  public listDepartments() {
    this.postCRUDService.getDepartments().subscribe(
      (response) => {
        this.departmentOptions = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar departamentos.',
          life: 3000,
        });
      }
    );
  }

  public listPost() {
    this.postCRUDService.getPosts().subscribe(
      (response) => {
        this.posts = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar post.',
          life: 3000,
        });
      }
    );
  }

  openNew() {
    this.post = {};
    this.submitted = false;
    this.postDialog = true;
    this.verb = 'POST';
  }

  public deleteSelectedPosts() {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir todos os posts selecionados?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedPosts.forEach((post) => this.deletePost(post));
        this.selectedPosts = null;
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          icon: 'success',
          detail: 'Posts excluídos.',
          life: 3000,
        });
      },
    });
  }

  editPost(post: Post) {
    this.post = { ...post };
    this.postDialog = true;
    this.verb = 'PUT';
  }

  showPost(post: Post) {
    this.post = { ...post };
    this.postDialog = true;
    this.verb = 'GET';
  }

  // deletePost(post: Post) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete ' + post.name + '?',
  //     header: 'Confirm',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.posts = this.posts.filter(
  //         (val) => val.id !== post.id
  //       );
  //       this.post = {};
  //       this.messageService.add({
  //         severity: 'success',
  //         summary: 'Successful',
  //         detail: 'Post Deleted',
  //         life: 3000,
  //       });
  //     },
  //   });
  // }

  hideDialog() {
    this.postDialog = false;
    this.submitted = false;
  }

  submitForm() {
    if (this.verb === 'POST') {
      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    this.post.content = this.post.content
      .split('<img src=')
      .join('<img class="image-post" src=');
    this.postCRUDService.postPost(this.post).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Post foi criado com sucesso.',
          life: 3000,
        });
        this.posts = [...this.posts];
        this.postDialog = false;
        this.post = {};
        this.listPost();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar post.',
          life: 3000,
        });
      }
    );
  }

  putForm() {
    this.post.content = this.post.content
      .split('<img src=')
      .join('<img class="image-post" src=');
    this.postCRUDService.putPost(this.post).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Post editado com sucesso.',
          life: 3000,
        });
        this.posts = [...this.posts];
        this.postDialog = false;
        this.post = {};
        this.listPost();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar post.',
          life: 3000,
        });
      }
    );
  }

  deletePost(post) {
    this.postCRUDService.deletePost(post).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Excluído',
          detail: 'O post ' + post.title + ' foi excluído com sucesso.',
          life: 3000,
        });
        this.post = {};
        this.listPost();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao excluir o post ' + post.title + '.',
          life: 3000,
        });
      }
    );
  }
}
