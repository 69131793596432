import { Component, OnInit } from '@angular/core';
import { SearchFieldService } from './search-field.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent implements OnInit {
  searchValue: string;
  apps: any[];
  dataForm = this.formBuilder.group({
    searchValue: '',
  });

  constructor(
    private searchFieldService: SearchFieldService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.listIndex();
  }

  public listIndex() {
    this.searchFieldService.getAppIndex().subscribe(
      (response) => {
        this.apps = response;
       
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public search() {
    
    // this.router.navigate(['/search-result/' + this.searchValue]);
    this.redirectTo('/search-result/' + this.searchValue);
  }

  public redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  addHit(index) {
    this.searchFieldService.addHit(index).subscribe(
      (response) => {
        this.listIndex();
        if (index.url.includes('#')) {
          this.redirectTo(index.url.replace('#', ''));
        } else {
          window.open(index.url);
        }
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
