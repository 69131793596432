import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class FemininoService {
  urlDp = localStorage.host + '/dp-atividade-fem/';
  urlAtividadeDp = localStorage.host + '/tipo-atividade-fem/?dpId=';
  urlFullCast = localStorage.host + '/full_cast/'
  atividadeUrl = localStorage.host + '/atividade-fem2/'
  checkatividadeUrl = localStorage.host + '/check-atividade-fem/'
  constructor(private http: HttpClient) { }

  public getDpFem(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.urlDp
    );
  }


  public getDpAtividade(dpId): Observable<any> {
    console.log(this.urlDp)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.urlAtividadeDp + dpId, { headers: header }
    );
  }

  public getCast() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.urlFullCast
      + "?category_id=" + localStorage.category_id
      + "&modality_id=" + localStorage.modality_id
      + "&team_id=1", { headers: header })
  }
  getAtividade() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.atividadeUrl, { headers: header })

  }

  saveAtividade(form) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post(
      this.atividadeUrl, {
      id_dp: form.dp.id,
      atividade: form.atividade,
      atleta: form.atleta,
      data_atividade: form.data_atividade,
      user_criacao: localStorage.username,
      local: form.local,
      tempo: form.tempo,
      observacao: form.observacao
    }, { headers: header })

  }

  checkAtividade(form) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post(
      this.checkatividadeUrl, {
      id_dp: form.dp.id,
      atividade: form.atividade,
      atleta: form.atleta,
      data_atividade: form.data_atividade,
      user_criacao: localStorage.username,
      local: form.local,
      tempo: form.tempo,
      observacao: form.observacao
    }, { headers: header })

  }

  updateAtividade(form) {
    console.log(form.tempo)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch(

      'https://api.csm.corinthians.com.br/atividade-fem/' + form.id + '/', {
      id_dp: form.dp,
      atividade_id: form.atividade_id,
      atleta_id: form.atleta_id,
      data_atividade: form.data_atividade,
      //user_alteracao: localStorage.username,
      local: form.local,
      tempo: form.tempo,
      observacao: form.observacao,

    }, { headers: header })

  }


  deleteAtividade(id) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch(

      'https://api.csm.corinthians.com.br/atividade-fem/' + id + '/', {
      is_deleted: 1,
    }, { headers: header })

  }

}
