<div style="float: left; width: 100%">
  <div>
    Casa
    <div class="spin-container" style="max-width: 25%" *ngIf="inProgress">
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px',
          marginTop: '20%',
          marginBottom: '20%'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>
    <p-chart
      type="pie"
      class="p-d-inline"
      [data]="atHomeData"
      [style]="{ width: '25%' }"
      *ngIf="!inProgress"
    ></p-chart>
  </div>
  <div>
    Fora
    <div class="spin-container" style="max-width: 25%" *ngIf="inProgress">
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px',
          marginTop: '20%',
          marginBottom: '20%'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>
    <p-chart
      type="pie"
      class="p-d-inline"
      [data]="outsideData"
      [style]="{ width: '25%' }"
      *ngIf="!inProgress"
    ></p-chart>
  </div>
  <div>
    Total
    <div class="spin-container" style="max-width: 25%" *ngIf="inProgress">
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px',
          marginTop: '20%',
          marginBottom: '20%'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>
    <p-chart
      type="pie"
      class="p-d-inline"
      [data]="totalData"
      [style]="{ width: '25%' }"
      *ngIf="!inProgress"
    ></p-chart>
  </div>
</div>
