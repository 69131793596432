<app-menubar></app-menubar>
<p-toast></p-toast>

<div class="card crud-container">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
      <button
        pButton
        pRipple
        [label]="Remover"
        icon="pi pi-trash"
        class="p-button-danger"
        (click)="deleteSelectedPosts()"
        [disabled]="!selectedPosts || !selectedPosts.length"
      ></button>
    </ng-template>

    <!--
    <ng-template pTemplate="right">
      <p-fileUpload
        mode="basic"
        accept="image/*"
        [maxFileSize]="1000000"
        label="Importar"
        chooseLabel="Import"
        class="p-mr-2 p-d-inline-block"
      ></p-fileUpload>
      <button
        pButton
        pRipple
        label="Exportar"
        icon="pi pi-upload"
        class="p-button-help"
      ></button>
    </ng-template>
    -->
  </p-toolbar>

  <p-table
    #dt
    [value]="posts"
    [rows]="10"
    [paginator]="true"
    [globalFilterFields]="['title', 'type', 'created_by', 'updated_at']"
    [(selection)]="selectedPosts"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <h5 class="p-m-0">Postagens</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="title">
          Titulo <p-sortIcon field="title"></p-sortIcon>
        </th>
        <th pSortableColumn="type">
          Tipo <p-sortIcon field="type"></p-sortIcon>
        </th>
        <th pSortableColumn="created_by">
          Criador por <p-sortIcon field="created_by"></p-sortIcon>
        </th>
        <th pSortableColumn="updated_at">
          Atualizado em <p-sortIcon field="updated_at"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-post>
      <tr>
        <td>
          <p-tableCheckbox [value]="post"></p-tableCheckbox>
        </td>
        <td>{{ post.title }}</td>
        <td>{{ post.type }}</td>
        <td>
          {{ post.created_by }}
        </td>
        <td>{{ post.updated_at }}</td>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-eye"
            class="p-button-rounded crud-button"
            (click)="showPost(post)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded crud-button"
            (click)="editPost(post)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded crud-button"
            (click)="deletePost(post)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are
        {{ posts ? posts.length : 0 }} posts.
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="postDialog"
  [style]="{ width: '50%' }"
  header="Post Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-field">
      <label for="title">Titulo</label>
      <input
        type="text"
        pInputText
        id="title"
        [(ngModel)]="post.title"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !post.title"
        >Title is required.</small
      >
    </div>
    <div class="p-field">
      <label for="subtitle">SubTitulo</label>
      <input
        type="text"
        pInputText
        id="subtitle"
        [(ngModel)]="post.subtitle"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !post.subtitle"
        >subtitle is required.</small
      >
    </div>
    <div class="p-field">
      <label for="type">Tipo de postagem</label>
      <div class="input-radio">
        <p-radioButton
          name="groupname"
          value="department"
          [(ngModel)]="post.type"
        ></p-radioButton>
        <label>Normal</label>
      </div>
      <div class="input-radio">
        <p-radioButton
          name="groupname"
          value="main"
          [(ngModel)]="post.type"
        ></p-radioButton>
        <label>Banner</label>
      </div>
      <small class="p-invalid" *ngIf="submitted && !post.type"
        >Type is required.</small
      >
    </div>
    <div class="p-field" *ngIf="post.type == 'main'">
      <label for="image">URL da imagem</label>
      <input
        type="text"
        pInputText
        id="image"
        [(ngModel)]="post.image"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !post.image"
        >image is required.</small
      >
    </div>
    <div class="p-field" *ngIf="post.type == 'department'">
      <label for="departments">Departamentos que receberam a publicação</label>
      <p-multiSelect
        [options]="departmentOptions"
        [showToggleAll]="false"
        [(ngModel)]="post.departments"
        optionLabel="name"
        [virtualScroll]="true"
        [filter]="true"
        [disabled]="verb === 'GET'"
      >
        <ng-template let-department pTemplate="item">
          <div>
            <div>{{ department.name }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
      <small class="p-invalid" *ngIf="submitted && !post.departments"
        >DP is required.</small
      >
    </div>
    <div class="p-field" *ngIf="post.type == 'department'">
      <label for="content">Conteúdo</label>
      <p-editor
        type="text"
        [(ngModel)]="post.content"
        required
        autofocus
        [style]="{ minHeight: '120px' }"
      ></p-editor>

      <small class="p-invalid" *ngIf="submitted && !post.content"
        >Content is required.</small
      >
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
