import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indices-avaliacao',
  templateUrl: './indices-avaliacao.component.html',
  styleUrls: ['./indices-avaliacao.component.scss']
})
export class IndicesAvaliacaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
