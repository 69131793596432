import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TeamsCRUDService {
  CRUDUrl = localStorage.host + '/teams/';
  urlNations = localStorage.host + '/pais/';
  urlPlaces = localStorage.host + '/estadios/';
  url = localStorage.host + '/teams2/';

  constructor(private http: HttpClient) { }

  public getTeams(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url, { headers: header });
  }

  public getNations(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlNations, { headers: header });
  }

  public getPlaces(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlPlaces, { headers: header });
  }

  public postTeam(team: any): Observable<any> {
    console.log(team);

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.CRUDUrl, team, { headers: header });
  }

  public putTeam(team: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch(this.CRUDUrl + team.id + '/', team, {
      headers: header,
    });
  }

  public deleteTeam(team: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.patch<any>(this.CRUDUrl + team.id + '/', team, {
      headers: header,
    });
  }
}
