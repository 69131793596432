import { Component, OnInit } from '@angular/core';
import { ElencoService } from './elenco.service'; 
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-elenco-team',
  templateUrl: './elenco.component.html',
  styleUrls: ['./elenco.component.scss']
})
export class ElencoTeamComponent implements OnInit {
  messageService: any;
  response: any;
  teamId: any;
  inProgress: boolean;
  cols: { field: string; header: string; width: string; fontweight:string, borderright:string }[];
  caracteristica: any;

  constructor( 
    private elencoService: ElencoService, 
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.inProgress = true;
    this.listCast();
    
    this.cols = [
      {field: 'first', header: 'Jogador', width:'5%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'second', header: 'Tempo de contrato', width: '10%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'third', header: 'Ocorências', width: '10%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'fourth', header: 'Avaliações', width: '10%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'fourth', header: 'Situação', width: '10%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
    ]

  }

  public listCast() {
    this.teamId =  this.activatedRoute.snapshot.paramMap.get('id');
    this.elencoService.getCast(this.teamId).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
        console.log(this.response)
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }


}
