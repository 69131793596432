import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ClipboardService } from './clipboard.service';
import { PlayerInGame } from './player_in_game';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ArenaComponent } from '../../arena/arena.component';
@Component({
  selector: 'app-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss'],
  providers: [ClipboardService, MessageService, ConfirmationService],
})
export class ClipboardComponent implements OnInit {
  @Input() teamId: any;
  @Input() gameId: any;
  @Input() isCRUD: boolean;
  @Input() inHome: any;
  buttonInProgress: boolean;
  inProgress: boolean;
  playerDialog: boolean;
  dragPlayerDialog: boolean;
  activitiesDialog: boolean;
  teamData: any;
  players: any;
  player: any;
  playerInGame: PlayerInGame;
  playersInGame: PlayerInGame[];
  submitted: boolean;
  verb: string;
  toDo: boolean = true;
  changeDialog: boolean;
  position: any;
  positions: any[];
  selectedPosition: any;
  arena: ArenaComponent;
  activities: any;

  constructor(
    private clipboardService: ClipboardService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    
    
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listCast();
    this.listPositions();
    this.getTeam();
    
  

  }

  public listActivities(player) {
 
    //
    this.clipboardService.getActivities(this.gameId).subscribe(
      (response) => {
        this.inProgress = false;
        this.activities = response;
        console.log()
       
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar atividades.',
          life: 3000,
        });
      }
    );
  }

  public listCast() {
    
    
    this.inProgress = true;
    this.clipboardService.getJNJCast(this.teamId, this.gameId).subscribe(
      (response) => {
        this.inProgress = false;
        this.players = response;
        this.listActivities(response);
        
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar Elenco.',
          life: 3000,
        });
      }
    );
  }

  public listPositions() {
    this.clipboardService.getPositions().subscribe(
      (response) => {
        this.positions = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar Posições.',
          life: 3000,
        });
      }
    );
  }

  public getTeam() {
    this.clipboardService.getTeam(this.teamId).subscribe(
      (response) => {
        this.teamData = response; 
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipe.',
          life: 3000,
        });
      }
    );
  }

  addInMainCast() {
    this.buttonInProgress = true;
    this.playerInGame = {};
    this.playerInGame.editor = localStorage.iduser;
    this.playerInGame.situacao = 'TIT';
    this.clipboardService
      .addOrRemoveToMainCast(this.playerInGame, this.player.id)
      .subscribe(
        (response) => {
          this.playerInGame = {};
          this.listCast();
          this.selectedPosition = null;
          this.hideChangeDialog();
          this.arena.listCast();
          // this.messageService.add({
          //   severity: 'info',
          //   summary: 'Relacionado',
          //   detail:
          //     'O jogador ' + player.surname + ' foi relacionado com sucesso.',
          //   life: 3000,
          // });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve algum erro ao relacionar jogador.',
            life: 3000,
          });
        }
      );
  }

  removeToMainCast(player) {
    this.buttonInProgress = true;
    this.playerInGame = {};
    this.playerInGame.editor = localStorage.iduser;
    this.playerInGame.situacao = 'BAN';

    this.clipboardService
      .addOrRemoveToMainCast(this.playerInGame, player.id)
      .subscribe(
        (response) => {
          this.playerInGame = {};
          this.listCast();
          
          this.messageService.add({
            severity: 'info',
            summary: 'Relacionado',
            detail:
              'O jogador ' + player.surname + ' foi relacionado com sucesso.',
            life: 3000,
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve algum erro ao relacionar jogador.',
            life: 3000,
          });
        }
      );
  }

  openNew() {
    this.player = {};
    this.submitted = false;
    this.playerDialog = true;
    this.verb = 'POST';
  }

  openNewDrag() {
    this.player = {};
    this.submitted = false;
    this.dragPlayerDialog = true;
    this.verb = 'POST';
  }

  editPlayer(player) {
    this.playerInGame = player;
    this.playerDialog = true;
    this.verb = 'PUT';
  }

  showPlayer(player) {
    // this.getPlayer(player.id);
    this.playerDialog = true;
    this.verb = 'GET';
  }

  hideDialog() {
    this.listCast();
    this.playerDialog = false;
    this.submitted = false;
  }

  submitForm() {
    if (this.verb === 'POST') {
      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    this.playerInGame.criador = localStorage.iduser;
    this.clipboardService.postPlayer(this.playerInGame).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Jogo foi criado com sucesso.',
          life: 3000,
        });
        this.playersInGame = [...this.playersInGame];
        this.playerDialog = false;
        this.playerInGame = {};
        this.listCast();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar jogo.',
          life: 3000,
        });
      }
    );
  }

  putForm() {
    this.playerInGame.editor = localStorage.iduser;
    this.clipboardService.putPlayer(this.playerInGame).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Jogo editado com sucesso.',
          life: 3000,
        });
        this.playersInGame = [...this.playersInGame];
        this.playerDialog = false;
        this.playerInGame = {};
        this.listCast();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar jogo.',
          life: 3000,
        });
      }
    );
  }

  deleteGame(playerInGame) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir este jogador do jogo?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.clipboardService.deletePlayer(playerInGame).subscribe(
          (response) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Excluído',
              detail:
                'O jogo ' + playerInGame.name + ' foi excluído com sucesso.',
              life: 3000,
            });
            this.playerInGame = {};
            this.listCast();
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail:
                'Houve algum erro ao excluir o jogo ' + playerInGame.name + '.',
              life: 3000,
            });
          }
        );
      },
    });
  }

  setPosition(player) {
    this.player = player;
    this.listPositions();
    this.changeDialog = true;
  }

  hideChangeDialog() {
    this.changeDialog = false;
  }
  openActivities() {
    // this.player = {};
    this.submitted = false;
    this.activitiesDialog = true;
    this.verb = 'POST';
  }
}
