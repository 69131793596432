import { Component, OnInit, Input } from '@angular/core';
import { ActivitiesCRUDService } from './activities-crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Activity } from './activity';
import '@angular/compiler';

@Component({
  selector: 'app-activities-crud',
  templateUrl: './activities-crud.component.html',
  styleUrls: ['./activities-crud.component.scss'],
  providers: [ActivitiesCRUDService, MessageService, ConfirmationService],
})
export class ActivitiesCrudComponent implements OnInit {
  @Input() gameId: any;
  @Input() visitingTeamId: any;
  @Input() commandingTeamId: any;

  inProgress: boolean;

  activityDialog: boolean;

  castDialog: boolean;

  activities: any[];

  activity: Activity;

  teams: any[];

  places: any[];

  selectedActivities: Activity[];

  selectedActivity: Activity;

  submitted: boolean;

  verb: string;

  selectedActivityType: any;

  activityTypes: any[];

  selectedTeam: any;

  periods: any[];

  durations: any[];

  commandingCast: any[];

  visitingCast: any[];

  arbitrationCast: any[];

  commandingCommission: any[];

  visitingCommission: any[];

  pass: boolean;

  origins: any[];

  bodyParts: any[];

  moves: any[];

  types: any[];

  cardTypes: any[];

  reasons: any[];

  kickOptions: any[];

  footOptions: any[];

  resultOptions: any[];

  constructor(
    private activitiesCRUDService: ActivitiesCRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.activity = {};
    this.activity.justo = true;
    this.periods = [
      { label: '1º Tempo', value: '1' },
      { label: '2º Tempo', value: '1' },
      { label: 'Penaltis', value: '1' },
      { label: 'Ext. 1º Tempo', value: '1' },
      { label: 'Ext. 2º Tempo', value: '1' },
    ];

    this.durations = [
      { label: '<10s', value: '1' },
      { label: '10~20s', value: '1' },
      { label: '>20s', value: '1' },
    ];

    this.origins = [
      { label: 'Bola Rolando', value: 'BR' },
      { label: 'Bola Parada', value: 'BP' },
    ];

    this.bodyParts = [
      { label: 'Pé Direito', value: 'PED' },
      { label: 'Pé Esquerdo', value: 'PEE' },
      { label: 'Cabeça', value: 'CAB' },
      { label: 'Outro', value: 'OTR' },
    ];

    this.moves = [
      { label: 'Erro do Adversario', value: 'Erro do Adversario' },
      { label: 'Cruzamento Baixo', value: 'Cruzamento Baixo' },
      { label: 'Cruzamento Alto', value: 'Cruzamento Alto' },
      { label: 'Cobrança Direta', value: 'Cobrança Direta' },
      { label: 'Enfiada', value: 'Enfiada' },
      { label: 'Troca de Passes', value: 'Troca de Passes' },
      { label: 'Rebote', value: 'Rebote' },
      { label: '2º Lance', value: '2º Lance' },
      { label: 'Lançamento', value: 'Lançamento' },
      { label: 'Individual', value: 'Individual' },
      { label: 'Lançamento Alto', value: 'Lançamento Alto' },
      { label: 'Outros', value: 'Outros' },
    ];

    this.types = [
      {
        label: 'Bola Recuperada no Ataque',
        value: 'Bola Recuperada no Ataque',
      },
      {
        label: 'Jogada Construída Apoiada',
        value: 'Jogada Construída Apoiada',
      },
      { label: 'Escanteio Curto', value: 'Escanteio Curto' },
      { label: 'Falta', value: 'Falta' },
      { label: 'Contra Ataque', value: 'Contra Ataque' },
      { label: 'Penalti', value: 'Penalti' },
      { label: 'Escanteio', value: 'Escanteio' },
      { label: 'Lateral', value: 'Lateral' },
      { label: 'Falta Curta', value: 'Falta Curta' },
      {
        label: 'Jogada Construída Vertical',
        value: 'Jogada Construída Vertical',
      },
    ];

    this.kickOptions = [
      {
        label: 'De Chapa',
        value: 'CHA',
      },
      {
        label: 'Com Peito do Pé',
        value: 'PDP',
      },
      { label: 'De Trivela', value: 'TRI' },
    ];

    this.footOptions = [
      {
        label: 'Direito',
        value: 'D',
      },
      {
        label: 'Esquerdo',
        value: 'E',
      },
    ];

    this.resultOptions = [
      {
        label: 'Gol Feito',
        value: 'G',
      },
      {
        label: 'Defesa',
        value: 'D',
      },
      {
        label: 'Na Trave',
        value: 'T',
      },
      {
        label: 'Pra Fora',
        value: 'F',
      },
    ];

    this.activityTypes = [
      { name: 'GOL', code: 'atividade_gol', img: 'assets/imgs/fi_ball.png' },
      {
        name: 'CARTÃO',
        code: 'atividade_cartao',
        img: 'assets/imgs/fi_card-yellow.png',
      },
      {
        name: 'SUBSTITUIÇÃO',
        code: 'atividade_substituicao',
        img: 'assets/imgs/fi_tactic.png',
      },
      {
        name: 'PENALTI',
        code: 'atividade_penalti',
        img: 'assets/imgs/fi_shoe.png',
      },
      {
        name: 'ERRO DE ARBITRAGEM',
        code: 'atividade_arbitro',
        img: 'assets/imgs/fi_whistle.png',
      },
      // {
      //   name: 'PASSE',
      //   code: 'atividade_passe',
      //   img: 'assets/imgs/fi_player3.png',
      // },
      {
        name: 'CARTÂO COMISSÂO',
        code: 'atividade_cartao_comissao',
        img: 'assets/imgs/fi_card-gray.png',
      },
      {
        name: 'CONSULTA AO VAR',
        code: 'atividade_arbitro',
        img: 'assets/imgs/fi_var.png',
      },
      {
        name: 'OUTROS',
        code: 'atividade_procedimento',
        img: 'assets/imgs/fi_player2.png',
      },
    ];

    this.cardTypes = [
      { name: 'Amarelo', code: 'A', img: 'assets/imgs/fi_card-yellow.png' },
      {
        name: 'Vermelho',
        code: 'V',
        img: 'assets/imgs/fi_card-red.png',
      },
      {
        name: 'Vermelho Direto',
        code: 'D',
        img: 'assets/imgs/fi_card-red.png',
      },
    ];
  }

  ngOnInit(): void {
    this.inProgress = true;
    this.listActivities(this.gameId);
    this.getTeam(this.commandingTeamId);
    this.listCast(this.commandingTeamId, true);
    this.listCommission(1, true);
    this.getTeam(this.visitingTeamId);
    this.listCast(this.visitingTeamId, false);
    this.listCommission(0, false);
    this.listArbitration();

  }

  public listActivities(gameId) {
    this.activitiesCRUDService.getActivities(gameId).subscribe(
      (response) => {
        this.activities = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar atividades.',
          life: 3000,
        });
      }
    );
  }

  public listCast(teamId, is_commanding) {
    this.inProgress = true;
    this.activitiesCRUDService.getJNJCast(teamId, this.gameId).subscribe(
      (response) => {
        this.inProgress = false;
        if (is_commanding) {
          this.commandingCast = response;
        } else {
          this.visitingCast = response;
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar Elenco.',
          life: 3000,
        });
      }
    );
  }

  public listArbitration() {
    this.inProgress = true;
    this.activitiesCRUDService.getANJCast(this.gameId).subscribe(
      (response) => {
        this.inProgress = false;
        this.arbitrationCast = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar Arbitragem.',
          life: 3000,
        });
      }
    );
  }

  public listCommission(commanding, is_commanding) {
    this.inProgress = true;
    this.activitiesCRUDService.getCNJCast(commanding, this.gameId).subscribe(
      (response) => {
        this.inProgress = false;
        if (is_commanding) {
          this.commandingCommission = response;
        } else {
          this.visitingCommission = response;
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar Comissão.',
          life: 3000,
        });
      }
    );
  }

  public getTeam(teamId) {
    this.teams = [];
    this.activitiesCRUDService.getTeam(teamId).subscribe(
      (response) => {
        this.inProgress = false;
        this.teams.push(response);
       
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipe.',
          life: 3000,
        });
      }
    );
  }

  openNew() {
    this.activity = {};
    this.submitted = false;
    this.activityDialog = true;
    this.verb = 'POST';
  }

  public deleteSelectedActivities() {
    this.confirmationService.confirm({
      message:
        'Tem certeza que deseja excluir todos os atividades selecionados?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedActivities.forEach((activity) =>
          this.deleteActivity(activity)
        );
        this.selectedActivities = null;
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          icon: 'success',
          detail: 'Atividades excluídas.',
          life: 3000,
        });
      },
    });
  }

  editActivity(activity) {
    this.selectedActivity = activity;
    this.activityDialog = true;
    this.verb = 'PUT';
  }

  showActivity(activity) {
    this.selectedActivity = activity;
    this.activityDialog = true;
    this.verb = 'GET';
  }

  hideDialog() {
    this.selectedActivityType = null;
    this.selectedTeam = null;
    this.activityDialog = false;
    this.submitted = false;
  }

  submitForm() {
    if (this.verb === 'POST') {
      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    this.activity.criador = localStorage.iduser;
    this.activity.ordem = 0;
    this.activity.evento = this.gameId;
    this.activity.field_is_deleted = 0;
    this.activity.identity = this.selectedActivityType.code;

    if (this.activity.cor) {
      this.activity.cor = this.activity.cor.code;
    }

    if (this.activity.justo) {
      this.activity.justo = 1;
    } else {
      this.activity.justo = 0;
    }

    if (this.selectedTeam.id == this.commandingTeamId) {
      this.activity.equipe_mandante = 1;
    } else {
      this.activity.equipe_mandante = 0;
    }

    this.activitiesCRUDService.postActivity(this.activity).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu atividade foi criada com sucesso.',
          life: 3000,
        });
        this.activities = [...this.activities];
        this.activityDialog = false;
        this.activity = {};
        this.selectedActivityType = null;
        this.selectedTeam = null;
        this.listActivities(this.gameId);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar atividade.',
          life: 3000,
        });
      }
    );
  }

  putForm() {
    this.activity.editor = localStorage.iduser;
    this.activity.ordem = 0;
    this.activity.evento = this.gameId;
    this.activity.field_is_deleted = 0;
    this.activity.identity = this.selectedActivityType.code;

    if (this.activity.cor) {
      this.activity.cor = this.activity.cor.code;
    }

    if (this.activity.justo) {
      this.activity.justo = 1;
    } else {
      this.activity.justo = 0;
    }

    if (this.selectedTeam.id == this.commandingTeamId) {
      this.activity.equipe_mandante = 1;
    } else {
      this.activity.equipe_mandante = 0;
    }
    this.activitiesCRUDService.putActivity(this.activity).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu atividade editada com sucesso.',
          life: 3000,
        });
        this.activities = [...this.activities];
        this.activityDialog = false;
        this.activity = {};
        this.listActivities(this.gameId);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar atividade.',
          life: 3000,
        });
      }
    );
  }

  deleteActivity(activity) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir esta atividade?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        activity.field_is_deleted = 1;
        this.activitiesCRUDService.putActivity(activity).subscribe(
          (response) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Excluído',
              detail:
                'O atividade ' + activity.id + ' foi excluída com sucesso.',
              life: 3000,
            });
            this.activity = {};
            this.listActivities(this.gameId);
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail:
                'Houve algum erro ao excluir a atividade ' + activity.id + '.',
              life: 3000,
            });
          }
        );
      },
    });
  }
}
