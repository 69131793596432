import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SelectModalityService {
  url = 'https://api.csm.corinthians.com.br/user_role_by_user/?email=';
  userLogin = 'https://api.csm.corinthians.com.br/token_details';
  constructor(private http: HttpClient) {}

  public getUserRoles(user): Observable<any> {
    console.log('service:',user)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + user, {
      headers: header,
    });
  }

}
