import { Component, OnInit, Input } from '@angular/core';
import { GamesService } from './games.service';
import { SideMenuService } from '../../components/side-menu/side-menu.service';
import { TeamTimelineService } from '../../components/team/team-timeline/team-timeline.service'; 
@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
})
export class GamesComponent implements OnInit {
 
  selectedGame: any;
  selectedSession: any;
  selectedCompetition: any;
  selectedPhase: any;
  selectedTurn: any;
  games: any[];
  sessions: any[];
  competitions: any[];
  phases: any[];
  turns: any[];
  submitted: boolean;
  activitiesDialog: boolean;
  verb: string;
  pdfLineUpDialog: boolean;

  constructor(private gamesService: GamesService, private sideMenuService: SideMenuService, private teamTimelineService: TeamTimelineService) {}

  ngOnInit(): void {
    this.menuGame()
   
    }

  public menuGame(){
    if(!this.selectedGame){
      this.listSessions();
      const response = this.sideMenuService.getGames()
      try{
        const response = this.sideMenuService.getGames()
        this.selectedGame= response['item']['id']
        
      }
      catch{ 
        const response = this.teamTimelineService.getGames()
        this.selectedGame = response; 
      }
      
    }else{
    this.listSessions();
    }
  }
  public listSessions() {
    
    this.gamesService.getSessions().subscribe(
      (response) => {
        
        this.sessions = response;
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public listCompetitions() {
    if (this.selectedSession) {
      this.gamesService.getCompetitions(this.selectedSession).subscribe(
        (response) => {
          this.competitions = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }

  public listPhases() {
    console.log(this.selectedCompetition)
    if (this.selectedCompetition) {
      this.gamesService.getPhases(this.selectedCompetition.id_campeonato).subscribe(
        (response) => {
          this.phases = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }

  public listTurns() {
    if (this.selectedPhase) {
      this.gamesService.getTurns(this.selectedPhase.id).subscribe(
        (response) => {
          this.turns = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }

  public listTurnGames() {
    if (this.selectedTurn) {
      this.gamesService
        .getTurnGames(
          this.selectedCompetition.id_campeonato,
          this.selectedPhase.id,
          this.selectedTurn
        )
        .subscribe(
          (response) => {
            
            this.games = response;
          },
          (error) => {
            alert('Houve algum erro ao carregar a lista.');
          }
        );
    }
  }

  public sessionChange() {
    this.listCompetitions();
  }

  public competitionChange() {
    this.listPhases();
  }

  public phaseChange() {
    this.listTurns();
  }

  public turnChange() {
    this.listTurnGames();
  }

  openActivities() {
    // this.player = {};
    this.submitted = false;
    this.activitiesDialog = true;
    this.verb = 'POST';
  }

  public pdfLineUp() {
    this.pdfLineUpDialog = true;
  }


}
