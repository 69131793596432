<div class="menu-container">
  <p-menu [model]="items"></p-menu>
</div>
<div class="menu-mobile-container">
  <button
    type="button"
    pButton
    icon="pi pi-bars"
    (click)="menu.toggle($event)"
  ></button>
  <p-menu id="menu-mobile" #menu [popup]="true" [model]="items"></p-menu>
</div>
<p-dialog
  [(visible)]="postDialog"
  [style]="{ width: '50%' }"
  header="Post Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-field">
      <label for="title">Titulo</label>
      <input
        type="text"
        pInputText
        id="title"
        [(ngModel)]="post.title"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !post.title"
        >Title is required.</small
      >
    </div>
    <div class="p-field">
      <label for="subtitle">SubTitulo</label>
      <input
        type="text"
        pInputText
        id="subtitle"
        [(ngModel)]="post.subtitle"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !post.subtitle"
        >subtitle is required.</small
      >
    </div>
    <div class="p-field">
      <label for="content">Conteúdo</label>
      <p-editor
        type="text"
        [(ngModel)]="post.content"
        required
        autofocus
        [style]="{ minHeight: '120px' }"
      ></p-editor>

      <small class="p-invalid" *ngIf="submitted && !post.content"
        >Content is required.</small
      >
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </ng-template>
</p-dialog>
