<div class="card flex-layout">
  <div class="posts-container">
    <div class="empt-msg" *ngIf="isEmpt">
      <p class="text">Nenhuma postagem por enquanto :/</p>
    </div>
    <p-timeline [value]="posts" align="left" style="width: 90%">
      <ng-template pTemplate="marker" let-post>
        <span class="custom-marker p-shadow-2"> 21/11/1994 10:30 </span>
      </ng-template>
      <ng-template pTemplate="content" let-post>
        <p-card [header]="post.title" [subheader]="post.subtitle">
          <div [innerHTML]="post.content"></div>
          <div style="margin-left: 90%; display: flex">
            <button
              pButton
              pRipple
              icon="pi pi-pencil"
              class="p-button-rounded crud-button"
              (click)="editPost(post)"
            ></button>
            <button
              pButton
              pRipple
              icon="pi pi-trash"
              class="p-button-rounded crud-button"
              (click)="deletePost(post)"
            ></button>
          </div>
        </p-card>
      </ng-template>
    </p-timeline>
  </div>
</div>
<p-dialog
  [(visible)]="postDialog"
  [style]="{ width: '50%' }"
  header="Post Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-field">
      <label for="title">Titulo</label>
      <input
        type="text"
        pInputText
        id="title"
        [(ngModel)]="post.title"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !post.title"
        >Title is required.</small
      >
    </div>
    <div class="p-field">
      <label for="subtitle">SubTitulo</label>
      <input
        type="text"
        pInputText
        id="subtitle"
        [(ngModel)]="post.subtitle"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !post.subtitle"
        >subtitle is required.</small
      >
    </div>
    <div class="p-field">
      <label for="content">Conteúdo</label>
      <p-editor
        type="text"
        [(ngModel)]="post.content"
        required
        autofocus
        [style]="{ minHeight: '120px' }"
        [disabled]="verb === 'GET'"
      ></p-editor>

      <small class="p-invalid" *ngIf="submitted && !post.content"
        >Content is required.</small
      >
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </ng-template>
</p-dialog>
