import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchField',
})
export class SearchFieldPipe implements PipeTransform {
  transform(value: any, input: any): any {
    if (input) {
      return value.filter(
        (val) => val.terms.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    } else {
      return null;
    }
  }
}
