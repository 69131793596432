import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { TeamService } from './teams.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})
export class TeamComponent implements OnInit {
  idTeam: any;
  team: any;
  inProgress: boolean;
  display: boolean;
  items: MenuItem[];
  option: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private teamService: TeamService
  ) {}

  ngOnInit(): void {
    this.idTeam = this.activatedRoute.snapshot.paramMap.get('id');
    this.getTeam();
    this.option = 'timeline';
    this.inProgress = true;
    this.display = false;
    this.items = [
      {
        label: 'Linha do tempo',
        command: () => this.setOption('timeline'),
      },
      {
        label: 'Resumo',
        command: () => this.setOption('resumo'),
      },
      {
        label: 'Cartões',
        command: () => this.setOption('cartoes'),
      },
      {
        label: 'Elenco',
        command: () => this.setOption('elenco')
        
      },
      {
        label: 'Características',
        command: () => this.setOption('caracteristica')
  
      },
      {
        label: 'Indisponibilidades',
  
      },
      {
        label: 'Posicionamentos',
  
      },
    ];
  }

  public getTeam() {
    this.teamService.getTeam(this.idTeam).subscribe(
      (response) => {
        this.team = response;
        
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public setOption(optionName) {
    this.option = optionName;
    this.display = false;
  }
}
