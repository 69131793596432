<div class="spin-container" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{ width: '100%', height: '70px', marginTop: '5%' }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div class="flex-layout" *ngIf="!inProgress">
  <div class="timeline-container">
    <p-timeline [value]="events" align="right" styleClass="customized-timeline">
      <ng-template pTemplate="marker" let-event>
        <span
          class="custom-marker p-shadow-2"
          [style.backgroundColor]="event.color"
          
        >
          <i [style.color]="event.font_color" class="event.icon"></i>
        </span>
      </ng-template>
      <ng-template pTemplate="content" let-event>
        <p-card
          [header]="event.game.name"
          [subheader]="event.game.date"
          styleClass="p-timeline-event-opposite"
        >
          <p>
            {{event.game_info}}
          </p>
        </p-card>
      </ng-template>
    </p-timeline>
    <button (click)="appendEvents()" style="border: none; background-color: transparent; width100%;" *ngIf="!inProgress">
    <div class="extend-button">
      <p class="centralized">
        Mais eventos
      </p>
    </div>
    </button>
  </div>
  <div class="options-container">
    <h4 class="options-name">{{player.name}}</h4>
    <br />
    <h5>{{player.position}}</h5>
    <h5>{{player.sex_surname}} nascido(a) em {{player.since_date}} ({{player.age}} anos) .</h5>
    <hr />
    <p>Adicione conteúdo na linha do tempo.</p>
    <div class="option">
      <a href="#"><i class="pi pi-comment"></i> Escrever um texto</a>
    </div>
    <div class="option">
      <a href="#"><i class="pi pi-video"></i> Publicar um vídeo</a>
    </div>
    <div class="option">
      <a href="#"><i class="pi pi-link"></i> Adicionar um link</a>
    </div>
    <div class="option">
      <a href="#"><i class="pi pi-star-o"></i> Fazer uma análise</a>
    </div>
    <div class="option">
      <a href="#"
        ><i class="pi pi-eye"></i> Incluir em uma lista de observação</a
      >
    </div>
    <div class="option">
      <a href="#"><i class="pi pi-list"></i> Abrir ficha individual</a>
    </div>

    <div class="p-col-12 p-md-4 comment-input">
      <label>Comentários e anexos</label>
      <div class="p-inputgroup">
        <button type="button" pButton pRipple icon="pi pi-paperclip"></button>
        <input type="text" pInputText placeholder="Digite os seu comentário" />
        <button type="button" pButton pRipple icon="pi pi-comment"></button>
      </div>
    </div>
  </div>
</div>
