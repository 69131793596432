import { Component, OnInit, Input } from '@angular/core';
import { TransferService } from './transfer.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
  providers: [TransferService, MessageService, ConfirmationService],
})
export class TransferComponent implements OnInit {
  @Input() profileId: any;
  @Input() contractId: any;
  date1: Date;
  date2: Date;
  submitted: any;
  teams: any[];
  selectedTeam: any;

  constructor(
    private transferService: TransferService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listTeams();
  }

  public listTeams() {
    this.transferService.getTeams().subscribe(
      (response) => {
        this.teams = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar times.',
          life: 3000,
        });
      }
    );
  }

  submitForm() {
    this.submitted = true;
    let data = {};
    data['player_id'] = this.profileId;
    data['team_id'] = this.selectedTeam.id;
    data['category_id'] = localStorage.category_id;
    data['modality_id'] = localStorage.modality_id;
    data['contract_id'] = this.contractId;
    data['transfer_type'] = 'sell';
    data['start'] = this.date1;
    data['end'] = this.date2;
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja Transferir o jogador?',
      header: 'Transferir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.transferService.transferPlayer(data).subscribe(
          (response) => {
            this.listTeams();
            this.messageService.add({
              severity: 'info',
              summary: 'Transferido',
              detail: 'O jogador foi transferido com sucesso.',
              life: 3000,
            });
            window.location.reload()
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Houve algum erro ao transferir jogador.',
              life: 3000,
            });
          }
        );
      },
    });
  }
}
