<div style="width: 150%; box-shadow: 2px 2px 2px #b3b3b3">
  <div style="width: 150%; background-color: black; color: white">
    Classificação
  </div>
  <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
    <p-progressSpinner
      [style]="{
        width: '100%',
        height: '70px',
        marginTop: '20%',
        marginBottom: '20%'
      }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <div *ngIf="!inProgress" style="max-width: 500px">
    <div style="width: 150%;">
      <p-table [value]="response" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th
              id="{{ column.replace(' ', '') }}"
              style="font-size: smaller; padding: 1px; font-weight: 800"
              *ngFor="let column of columns"
            >
              {{ column }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td
              class="centralize"
              style="padding: 0px; font-size: x-small; background-color: {{
                item.colocation_color
              }}"
            >
              {{ item.colocation }}
            </td>
            <td class="left" style="padding: 0px; font-size: x-small">
              <img src="{{ item.brasao }}" style="height: 14px; width: 14px" />
              {{ item.name }}
            </td>
            <td  class="centralize"style="padding: 0px; font-size: x-small">
              {{ item.pts }}
            </td>
            <td class="centralize" style="padding: 0px; font-size: x-small">
              {{ item.pj }}
            </td>
            <td class="centralize" style="padding: 0px; font-size: x-small">
              {{ item.v }}
            </td>
            <td class="centralize" style="padding: 0px; font-size: x-small">
              {{ item.e }}
            </td>
            <td class="centralize" style="padding: 0px; font-size: x-small">
              {{ item.d }}
            </td>
            <td class="centralize" style="padding: 0px; font-size: x-small">
              {{ item.gp }}
            </td>
            <td class="centralize" style="padding: 0px; font-size: x-small">
              {{ item.gc }}
            </td>
            <td class="centralize" style="padding: 0px; font-size: x-small">
              {{ item.sg }}
            </td>
            <td  class="centralize" style="padding: 0px; font-size: x-small">
              {{ score(item.pts, item.pj).toFixed() }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              <div id="sequence-item" *ngFor="let info of item.sequence">
                <div id="circleBlue" *ngIf="info == 'V'"></div>
                <div id="circleRed" *ngIf="info == 'D'"></div>
                <div id="circleGray" *ngIf="info == 'E'"></div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
