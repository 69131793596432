import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MainPostsService {
  url = localStorage.host + '/posts-by-type/?type=main';

  constructor(private http: HttpClient) { }

  public getPosts(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url, { headers: header });
  }
}
