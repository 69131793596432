<p-menubar style=" height: 50px; width: 100%; position: fixed !important; z-index: 999;" [model]="items">
  
  <ng-template pTemplate="start">
    <div style="display: flex; float: left">
      <div style="width: 10%;">
        <img  src="/assets/imgs/CSM_branco_h.ico"  style="height: 100px; margin-bottom: -35px; margin-top: -35px;" *ngIf="!modalityName">
      </div>
        <a href="/home" class="font-logo" *ngIf="modalityName">
        <span class="font-logo-modality">{{ modalityLegend }}|</span
        >{{ categoryLegend }}
      </a>
      <app-side-menu *ngIf="modalityName"></app-side-menu>
    </div>
  </ng-template>
  <ng-template pTemplate="end" >
    <p style="float: left; margin: 5px 30px 0 0; padding: 0" routerLink="/configuracoes">Configurações</p>
    <p
      class="mobile-hide"
      style="float: left; margin: 5px 50px 0 0; padding: 0">
      Bem-vindo <strong>{{ username }}</strong>
    </p>
    <button #btn type="button" class="p-menubar btn-side-menu" pButton icon="pi pi-power-off" (click)="sairIAS()"></button>
  </ng-template>
</p-menubar>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>