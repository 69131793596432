import { Component, OnInit } from '@angular/core';
import { TeamsCRUDService } from './teams-crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Team } from './team';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-teams-crud',
  templateUrl: './teams-crud.component.html',
  styleUrls: ['./teams-crud.component.scss'],
  providers: [TeamsCRUDService, MessageService, ConfirmationService],
})
export class TeamsCRUDComponent implements OnInit {
  teamDialog: any;

  teams: any[];

  team: Team;

  selectedTeams: Team[];

  selectedTeam: any;

  submitted: boolean;

  verb: string;

  inProgress: boolean;

  places: any[];

  nations: any[];

  selectedPlace: any;

  selectedNation: any;

  brasao: FormGroup;

  constructor(
    private teamsCRUDService: TeamsCRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.inProgress = true;
    this.listTeams();
    this.listPlaces();
    this.listNations();
    this.brasao = this.fb.group({ brasao: [null,] })

  }

  public listTeams() {
    this.teamsCRUDService.getTeams().subscribe(
      (response) => {
        this.inProgress = false;
        this.teams = response;
        console.log(response)
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipes.',
          life: 3000,
        });
      }
    );
  }

  public listPlaces() {
    this.teamsCRUDService.getPlaces().subscribe(
      (response) => {
        this.inProgress = false;
        this.places = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar estádios.',
          life: 3000,
        });
      }
    );
  }

  public listNations() {
    this.teamsCRUDService.getNations().subscribe(
      (response) => {
        this.inProgress = false;
        this.nations = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar Países.',
          life: 3000,
        });
      }
    );
  }

  openNew() {
    this.team = {};
    this.submitted = false;
    this.teamDialog = true;
    this.verb = 'POST';
  }

  public deleteSelectedTeams() {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir todas as equipes selecionadas?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedTeams.forEach((team) => this.deleteTeam(team));
        this.selectedTeams = null;
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          icon: 'success',
          detail: 'Equipes excluídas.',
          life: 3000,
        });
      },
    });
  }

  editTeam(team: Team) {
    this.team = { ...team };
    this.teamDialog = true;
    this.verb = 'PUT';
  }

  showTeam(team: Team) {
    this.team = { ...team };
    this.teamDialog = true;
    this.verb = 'GET';
  }

  hideDialog() {
    this.teamDialog = false;
    this.submitted = false;
  }

  submitForm() {
    if (this.verb === 'POST') {
      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    this.team.identity = 'grupo_equipe';
    this.team.field_is_deleted = 0;
    this.team.criador = localStorage.iduser;
    this.team.estado_id = this.selectedPlace.id;
    this.team.pais_id = this.selectedNation.id;
    this.teamsCRUDService.postTeam(this.team).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Sua equipe foi criada com sucesso.',
          life: 3000,
        });
        this.teams = [...this.teams];
        this.teamDialog = false;
        this.team = {};
        this.listTeams();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar equipe.',
          life: 3000,
        });
      }
    );
  }

  putForm() {
    console.log(this.team)
    this.sendFoto(this.team)
    delete (this.team.brasao)
    this.teamsCRUDService.putTeam(this.team).subscribe(
      (response) => {

        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Equipe editada com sucesso.',
          life: 3000,
        });
        this.teams = [...this.teams];
        this.teamDialog = false;
        this.team = {};
        this.listTeams();
      },
      (error) => {
        console.log(error)
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar equipe.',
          life: 3000,
        });
      }
    );
  }

  deleteTeam(team) {
    team.field_is_deleted = 1;
    this.teamsCRUDService.deleteTeam(team).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Excluído',
          detail: 'A equipe ' + team.apelido + ' foi excluída com sucesso.',
          life: 3000,
        });
        this.team = {};
        this.listTeams();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao excluir a equipe ' + team.apelido + '.',
          life: 3000,
        });
      }
    );
  }

  selectFile(event: any) {

    if (event.target.files && event.target.files[0]) {
      const foto = event.target.files[0];
      const formData = new FormData();
      console.log(foto)
      formData.append('brasao', foto);
      this.brasao['brasao'] = formData
    }
  }

  sendFoto(team) {
    console.log(this.brasao)
    try {
      this.http.patch(localStorage.host + '/teams/' + team.id + '/', this.brasao['brasao'])
        .subscribe(resposta => console.log(resposta));

    } catch (error) {
      alert('Erro ao carregar arquivo');
    }
  }


}
