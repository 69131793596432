import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Login } from './login';
import { LoginService } from './login.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  loginform: any = [];
  form: FormGroup;
  currentCollection: any = [];
  authusergroup: any = [];
  display: any;
  username: string;
  password: string;
  msgs: any = [];
  inProgress: boolean;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  // -----
  loginForm: FormGroup;

  submitted = false;

  ngOnInit() {
    this.inProgress = false;
    localStorage.modality_name = '';
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });

    if (localStorage.token) {
      this.router.navigate(['select-modality/']);
    }
  }

  public onSubmit() {
    this.inProgress = true;
    this.submitted = true;
    localStorage.username = this.loginform.username;
    localStorage.host = 'https://api.csm.corinthians.com.br/';
    localStorage.inicio = 0;
    localStorage.display = false;
    console.log(localStorage.host)
    this.loginService
      .logar(this.loginform.username, this.loginform.password)
      .subscribe(
        (data) => {
          console.log(data);
          localStorage.token = data.token;
          localStorage.user = JSON.stringify(data.user);
          if (data.non_field_errors) {
            localStorage.clear();
            this.inProgress = false;
            this.show();
            this.messageService.add({
              key: 'error',
              severity: 'error',
              summary: 'Credenciais incorretas!',
              detail: 'Username ou senha inválidas.',
            });
          } else {
            localStorage.iduser = data.user.id;
            this.loginService.postUser(data).subscribe(
              data => console.log()
            )
            this.router.navigate(['select-modality/']);

            localStorage.wlcbox = true;
          }
        },
        (err) => {
          console.log(err)
          localStorage.clear();
          this.inProgress = false;
          if(err.error == "'user'"){
            this.msgs.push({
              key: 'error',
              severity: 'error',
              summary: 'Credenciais incorretas!',
              detail: 'Usuário não identificado, ou senha não confere!',
            });
          }else{
            this.msgs.push({
              key: 'error',
              severity: 'error',
              summary: 'Credenciais incorretas!',
              detail: 'Usuário sem permissão de utilizar o sistema!',
            });
          }
        }
      );
  }
  public show() {
    this.msgs.push({
      severity: 'error',
      summary: 'Credenciais incorretas!',
      detail: '',
    });
  }

  public hide() {
    this.msgs = [];
  }
  public esqueciSenha(){
    console.log('aqui')
    this.confirmationService.confirm({
      message:
        'Entre em contato com o departamento de TI para resetar sua senha',
      header: 'CSM',
      acceptLabel: 'Sim',
      accept: () => { console.log('ok')
      },
    });
  }

}
