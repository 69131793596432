import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CompetitionTableService {
  url = localStorage.host + '/classificacao/';

  constructor(private http: HttpClient) { }

  public getClassification(fase): Observable<any> {

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url + '?fase=' + fase,
      { headers: header }
    );
  }
}
