<div class="spin-container" style="max-width: 80%" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{
      width: '100%',
      height: '70px',
      marginTop: '20%',
      marginBottom: '20%'
    }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div clas="cont" >
  <p-tabView [scrollable]="true"   *ngIf="!inProgress">
    <p-tabPanel header="{{ item.name }}" *ngFor="let item of response">
      <div class="competition-container">
        <div style="margin-left: 0%; margin-right: auto">
          <div>
            <app-competition-table fase="{{item.fase_id }}"></app-competition-table>
          </div>
          <div style="width: 100%">
            <app-usage-charts competition_id="{{item.id}}"></app-usage-charts>
          </div>
          <div style="margin-left: auto;  margin-right: auto">
            <app-round-games competition_id="{{item.id}}"></app-round-games>
          </div>
        </div>
        <div style="margin-left: auto; margin-top: auto; margin-right: auto">
          <app-cast-table></app-cast-table>
        </div>
      </div>
      
    </p-tabPanel>
  </p-tabView>
</div>
