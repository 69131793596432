import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referencia-carga',
  templateUrl: './referencia-carga.component.html',
  styleUrls: ['./referencia-carga.component.scss']
})
export class ReferenciaCargaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
