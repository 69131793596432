<p-galleria
  [(value)]="posts"
  [responsiveOptions]="responsiveOptions"
  [numVisible]="5"
  [circular]="true"
  [showItemNavigators]="true"
  [showThumbnails]="false"
  [showIndicators]="true"
  [showItemNavigatorsOnHover]="true"
  [showIndicatorsOnItem]="true"
  [autoPlay]="true"
  [transitionInterval]="5000"
  style="width: 100%"
>
  <ng-template pTemplate="item" let-item>
    <img [src]="item.image" style="width: 100%; display: block" />
  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div class="p-grid p-nogutter p-justify-center">
      <img [src]="item.image" style="display: block" />
    </div>
  </ng-template>
</p-galleria>
