import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { SideMenuService } from './side-menu.service'
import { nextTick } from 'process';
import * as moment from 'moment';
import { compareNumbers } from '@fullcalendar/angular';
import { identifierModuleUrl } from '@angular/compiler';
import { Router } from '@angular/router'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  constructor(private sideMenuService: SideMenuService, private router: Router) {}

  items: MenuItem[];
  game: any;
  lastGame:any;
  nextGame:any;
  nextPlayer:any[];
  ngOnInit() {
   
    this.menuItems()
  }
 
  
  goTo(game) {    
    
    this.sideMenuService.goToService(game)
  }
 

  menuItems(){
    console.log(localStorage.category_id )
    if(localStorage.category_id == 1 ){
    console.log(localStorage.modality_name)
    this.sideMenuService.getGame().subscribe(
      game =>{this.game = game; this.lastGame = this.game[0]['commanding_team'] + ' ' + this.game[0]['commanding_score'] +' X ' + ' ' + this.game[0]['visiting_score']+  ' ' + this.game[0]['visiting_team']; 
        this.sideMenuService.getNextGame().subscribe( next =>{
        this.nextGame = next
        this.sideMenuService.getNextTems().subscribe(team =>{
        this.nextPlayer = team 
        
        
      
      this.items = [
      {
    
        label: 'Próximos Jogos',
        items: [{
          id: this.nextGame[0],
          label: this.nextGame[0]['commanding_team'] + ' X ' + this.nextGame[0]['visiting_team'] + ' ' + moment(this.nextGame[0]['start']).format('DD-MMM-YYYY'),
          command: (event: Event) => {this.goTo(event) },
        },
        {
          id: this.nextGame[1],
          label: this.nextGame[1]['commanding_team'] + ' X ' + this.nextGame[1]['visiting_team'] + ' '+ moment(this.nextGame[1]['start']).format('DD-MMM-YYYY'),
          command: (event: Event) => {this.goTo(event) },
        },
        
        ],
      }, 
      {
        label: 'Próximos Adversários',
        items:[
          
          {
            label: this.nextPlayer[0]['time'] + ' - ' +  moment(this.nextPlayer[0]['start']).format('DD-MM-YYYY')
          },
          {
            label: this.nextPlayer[1]['time'] + ' - ' +  moment(this.nextPlayer[1]['start']).format('DD-MM-YYYY') 
          },
        
      ],
      },
      {
        label: 'Campeonatos Ativos',
        items: [],
      },
      {
        label:
          'Treino',
        url: '#',
      },
      {
        
        label:'Último jogo: ' ,
        command: (event: Event) => {this.goTo(event) },
      },
      {
        label: 'Elenco Corinthians',
        routerLink: ['/elenco'],
      },
      {
        label: 'Gerenciar',
        items: [
          {
            label: 'Pessoas',
            routerLink: ['/people-crud'],
          },
          {
            label: 'Equipes',
            routerLink: ['/teams-crud'],
          },
        ],
      },
      {
        label: 'Básico',
        items: [
          {
            label: 'Dashboard',
            url: '#dashboard/PRO',
          },
          {
            label: 'Pessoas',
            url: '#profile/',
          },
          {
            label: 'Lugares',
            url: '#',
          },
          {
            label: 'Relatórios',
            url: '#reports',
          },
        ],
      },
      {
        label: 'Campeonatos, Jogos e Equipes',
        items: [
          {
            label: 'Editor de Elencos',
            url: '#set-cast/1',
          },
          {
            label: 'Campeonatos',
            url: '#/campeonatos',
          },
          {
            label: 'Equipes',
            routerLink: ['/teams-crud'],
          },
          {
            label: 'Jogos',
            url: '#games-crud',
          },
        ],
      },
      {
        label: 'Análise de Desempenho',
        items: [
          {
            label: 'Pesquisa de Observações',
            url: '#',
          },
          {
            label: 'Listas de Observações',
            url: '#',
          },
          {
            label: 'Referências para Observações',
            url: '#',
          },
          {
            label: 'Gols',
            url: '#',
          },
          {
            label: 'Classificação',
            url: '#',
          },
          {
            label: 'Penaltis',
            url: '#',
          },
          {
            label: 'Observações',
            url: '#',
          },
        ],
      },
      {
        label: 'Física/Fisiologia',
        items: [
          {
            label: 'Treinos',
            url: '#',
          },
          {
            label: 'Auto-avaliações',
            url: '#',
          },
        ],
      },
      {
        label: 'Departamento Médico',
        routerLink: ['/dp-medico'],
      },
      {
        label: 'Administração',
        items: [
          {
            label: 'Programação',
            url: '#',
          },
          {
            label: 'Convocações',
            url: '#',
          },
          {
            label: 'Avisos',
            url: '#',
          },
          {
            label: 'Premiação',
            url: '#',
          },
        ],
      },
      {
        label: 'Opções',
        items: [
          {
            label: 'Meu perfil',
            url: '#',
          },
          {
            label: 'Configurações',
            url: '#',
          },
        ],
      },
    ]
  })})}
    )}
    else{
      this.items = [
        {
          label: 'Atividades',
          url: '#/atividades',
        },
        {
          label: 'Campeonatos, Jogos e Equipes',
          items: [
            {
              label: 'Editor de Elencos',
              url: '#set-cast/1',
            },
            {
              label: 'Campeonatos',
              url: '#/campeonatos',
            },
            {
              label: 'Equipes',
              routerLink: ['/teams-crud'],
            },
            {
              label: 'Jogos',
              url: '#games-crud',
            },
          ],
        },
        {
          label: 'Gerenciar',
          items: [
            {
              label: 'Pessoas',
              routerLink: ['/people-crud'],
            },
            {
              label: 'Equipes',
              routerLink: ['/teams-crud'],
            },
          ],
        },
        {
          label: 'Opções',
          items: [
            {
              label: 'Meu perfil',
              url: '#',
            },
            {
              label: 'Configurações',
              url: '#',
            },
          
          ],
        },
      ]
     
    }
  }
}
