import { Component, OnInit } from '@angular/core';
import { AddSoccerPlayerService } from './add-soccer-player.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Contract } from './contract';
import '@angular/compiler'

@Component({
  selector: 'app-add-soccer-player',
  templateUrl: './add-soccer-player.component.html',
  styleUrls: ['./add-soccer-player.component.scss'],
  providers: [AddSoccerPlayerService, MessageService, ConfirmationService],
})
export class AddSoccerPlayerComponent implements OnInit {

  players: any[];
  playerSearch: string;
  pageCount: number = 0;
  pageFetch: number = 50;
  positions: any[];
  response: any[];
  teamId: any;
  teams: any[];
  selectedTeam: any;
  profileId: any;
  contractId: any;
  contract: Contract;
  modalityName: string;
  inProgress: boolean;
  profileDialog: boolean;
  transferDialog: boolean;
  transferLoanDialog: boolean;
  renewDialog: boolean;
  addSoccerPlayerDialog: boolean;
  playerContracts: any[];
  playerContractInfo: any;
  activeContract: any;
  hideAddButton: boolean;

  constructor(
    private addSoccerPlayerService: AddSoccerPlayerService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  

  ngOnInit(): void {
    this.teamId = this.activatedRoute.snapshot.paramMap.get('id') != 'null' ? this.activatedRoute.snapshot.paramMap.get('id') : localStorage.getItem('activeTeam');
    this.modalityName = localStorage.modality_name;

    if (this.teamId !== 'null') {
      this.inProgress = true;
    }
  }

  transferInfo(contract: any){
    this.activeContract = contract;

    this.playerContractInfo = {
      'start': new Date(),
      'end': new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    };
  }

  addPlayer(contract: any, newInfo: any, type: string){
    this.hideAddButton = true;

    this.addSoccerPlayerService.addPlayer(contract, newInfo, this.teamId, type).subscribe(
      (response) => {
        alert('Operacao realizada com sucesso')
        window.location.reload();
        this.inProgress = false;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );  }

  public listCast(pageStart?: number, pageEnd?: number) {
    this.teamId = localStorage.getItem('activeTeam');

    

    this.addSoccerPlayerService.getCast(this.playerSearch, pageStart, pageEnd).subscribe(
      (response) => {
        
        this.inProgress = false;
        this.response = response;
        this.pageCount = response[0].lastItemNumber
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  public showPlayerContracts(player: any) {
    this.addSoccerPlayerService.getPlayerContracts(player.id_people).subscribe(
      (response) => {
        

        if(response[0].contracts.length > 0){
          this.playerContracts = response
        }
        else {
          alert('Esse jogador nao tem nenhum contrato ativo, criando novo')
          this.transferInfo({id: 0, player_id: player.id})
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar os contratos.',
          life: 3000,
        });
      }
    );
  }

}
