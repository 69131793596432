import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class PlayersSelectService {
  url = localStorage.host + '/full-cast-game/';
  urlCRUD = localStorage.host + '/jogador_no_jogo/';
  constructor(private http: HttpClient) { }

  public getFullCast(idTeam, idGame): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id +
      '&team_id=' +
      idTeam +
      '&game_id=' +
      idGame,
      { headers: header }
    );
  }

  public addOrRemoveToMainCast(player: any, id: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.urlCRUD + id + '/', player, {
      headers: header,
    });
  }

  public deleteJNJ(playerId: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.delete<any>(this.urlCRUD + playerId + '/', {
      headers: header,
    });
  }
}
