import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentFixtureAutoDetect } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class NewSoccerPlayerService {


  url = localStorage.host + '/';
  contractUrl = localStorage.host + '/contract/';

  teamsUrl = localStorage.host + '/grupo/';

  constructor(private http: HttpClient) { }

  public getCast(search: string, pageStart?: number, pageEnd?: number): Observable<any> {
    const start = pageStart ? pageStart : 0;
    const end = pageEnd ? pageEnd : 50;


    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      'https://api.csm.corinthians.com.br/perfilAdd/' +
      '?search=' +
      search +
      '&pageStart=' +
      pageStart +
      '&pageEnd=' +
      pageEnd,
      { headers: header }
    );
  }


  public newPlayer(player: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );



    return this.http.post<any>('https://api.csm.corinthians.com.br/' + 'pessoa/', {

      "nome": player.value['nome'],
      "apelido": player.value['apelido'],
      "sexo": player.value['sexo']['id'],
      "nascimento": player.value['nascimento'],
      "altura": parseFloat(player.value['altura']),
      "camisa": player.value['camisa'],
      "ativo:": 1,
      "side": player.value['side'],
      "equipe": player.value['equipe'],
      "categoria": player.value['categoria'],
      "posicao": player.value['posicao'],
      "nacionalidade": player.value['nacionalidade']['sigla'],
      "estado": player.value['estado'],
      "pais": player.value['pais'],
      "federacao": player.value['federacao'],
      "federacao_regional": player.value['federacao_regional'],
      "jogador_selecao": player.value['jogador_selecao']['id'],
      "id_ogol": player.value['id_ogol'],
      "id_soccerway": player.value['id_soccerway'],
      "id_transfermarkt": player.value['id_transfermarket'],
      "inicio": player.value['inicio'],
      "fim": player.value['fim'],
      "modalidade": localStorage.modality_id
    }, {
      headers: header,
    });

  }

  public getPlayerContracts(playerId: number): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url + 'search_contratcs/' +
      '?player=' +
      playerId,
      { headers: header }
    );
  }

  public addPlayer(contract: any, newInfo: any, team_id: number, type: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url + 'transfer/', {
      'player_id': contract.player_id,
      'team_id': team_id,
      'category_id': localStorage.category_id,
      'modality_id': localStorage.modality_id,
      'contract_id': contract.id,
      'transfer_type': type,
      'start': newInfo.start,
      'end': newInfo.end
    }, {
      headers: header,
    });
  }


}
