import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class PlayerTimelineService {
  url = localStorage.host + '/player-timeline/';
  constructor(private http: HttpClient) { }

  public getTimeline(idTeam): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + '?people_id=' + idTeam, {
      headers: header,
    });
  }

  public appendToTimeline(idTeam, lastRegistry): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url + '?people_id=' + idTeam + '&last_registry=' + lastRegistry,
      {
        headers: header,
      }
    );
  }
}
