import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  teamsUrl = localStorage.host + '/teams/';
  transferUrl = localStorage.host + '/transfer/';

  constructor(private http: HttpClient) { }

  public getTeams(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl, {
      headers: header,
    });
  }

  public getTeam(id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl + id + '/', {
      headers: header,
    });
  }

  public transferPlayer(data: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.transferUrl, data, {
      headers: header,
    });
  }
}
