import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashCalendarService {
  url = localStorage.host + '/calendar-events/';

  constructor(private http: HttpClient) { }

  public getEvents(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id,
      { headers: header }
    );
  }
}
