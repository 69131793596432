
<div class="spin-container" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{ width: '100%', height: '70px', marginTop: '5%' }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div class="flex-layout" *ngIf="!inProgress">   
<div style="float: left; margin-left: 5%; margin-top: 10%; width: 100%">
  <h5>Temporada</h5>
  <p-dropdown
    [options]="sessions"
    [(ngModel)]="selectedSession"
    [showClear]="true"
    placeholder="Selecione a temporada"
    (click)="sessionChange()"
  >
    <ng-template pTemplate="selectedItem">
      <div class="session-item session-item-value" *ngIf="selectedSession">
        <div>{{ selectedSession }}</div>
      </div>
    </ng-template>
    <ng-template let-session pTemplate="item">
      <div class="session-item">
        <div>{{ session }}</div>
      </div>
    </ng-template>
  </p-dropdown>


  <h5>Campeonato</h5>
      <p-dropdown
        [options]="competitions"
        [(ngModel)]="selectedCompetition"
        optionLabel="descricao"
        [filter]="true"
        filterBy="descricao"
        [showClear]="true"
        placeholder="Selecione Campeonato"
        (click)="competitionChange()"
      >
        <ng-template pTemplate="selectedItem">
          <div
            class="competition-item competition-item-value"
            *ngIf="selectedCompetition"
          >
            <div>{{ selectedCompetition.descricao }}</div>
          </div>
        </ng-template>
        <ng-template let-competition pTemplate="item">
          <div class="competition-item">
            <div>{{ competition.descricao }}</div>
          </div>
        </ng-template>
      </p-dropdown>
</div>
<div style="margin-top: 5%; margin-left: -20%;">
  <h1 style="width: 300%;">Resumo de Cartões {{team}} </h1>
</div>
   
<div class="spin-container" style="max-width: 25%" *ngIf="inProgress">
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px',
          marginTop: '20%',
          marginBottom: '20%'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
</div>

  <div style="margin-top: 15%; margin-left: -15%; width: 100%">
    <p-chart
      type="doughnut"
      [data]="data"
      [options]="chartOptions"
      [style]="{ width: '100%' }"
      *ngIf="!inProgress"
    ></p-chart>
  </div>
  <div style="margin-left: 5%; margin-top: 15%;" > 
    <p-card header="Amarelo" [style]="{'width': '110%', 'height':'90%', 'margin-bottom': '1em', 'background-color': 'rgb(255, 255, 77)', 'color': 'rgb(255, 118, 80)', 'text-align': 'center', 'font-size': '200%'}">
      {{ qtd_amarelo }}
    </p-card>
  </div>
  <div style="margin-left: 5%; margin-top: 15%; color: rgb(200, 43, 0);"> 
    <p-card header="Vermelho" [style]="{'width': '100%', 'height':'90%', 'margin-bottom': '1em', 'background-color': 'rgb(255, 55, 0)', 'color': 'white', 'text-align': 'center', 'font-size': '200%'}">
      {{ qtd_vermelho }}
    </p-card>
  </div>
  <div style="margin-left: 5%; margin-top: 15%;"> 
    <p-card header="Direto" [style]="{'width': '135%', 'height':'90%', 'margin-bottom': '1em', 'background-color': '#8B0000', 'color': 'white', 'text-align': 'center', 'font-size': '200%'}">
      {{ qtd_direto }}
    </p-card>
  </div>
</div>

<div> 
  <div style="width: 30%; margin-left: 2%;">
    <h2>Motivos de Cartões</h2>
    <div class="card">
      <p-chart type="bar" [data]="basicData" [options]="horizontalOptions"></p-chart>
    </div>
  </div>
  <div style="width: 30%; margin-left: 35%; margin-top: -17%;"> 
    <h2>Cartões como mandante/visitante</h2>
    <div class="card">
      <p-chart type="bar" [data]="basicData" [options]="horizontalOptions"></p-chart>
    </div>
  </div>
</div>
<div>  
  <div style="margin-left:1%; margin-top: 5%;">
    <p-card header="Pendurado" [style]="{'width': '20%'}">
    </p-card>
  </div>
  <div style="margin-left: 22%; margin-top: -8.5%;">
    <p-card header="Suspensos" [style]="{'width': '25%'}">
    </p-card>
  </div>
  <div style="margin-left: 44%; margin-top: -8.5%;">
    <p-card header="DM/ Indisponiveis  " [style]="{'width': '30%'}">
    </p-card>
  </div>
  <div style="margin-left: 65%; margin-top: -12%;">
    <p-card header="Seleção" [style]="{'width': '50%'}">
    </p-card>
  </div>

  <div style="margin-left: 5%; margin-top: 10%; width: 100%;">
    <p-card [style]="{'width': '80%'}">
      <p-table [value]="jogo" responsiveLayout="scroll" [style]="{'width': '100%'}">
        <ng-template pTemplate="header">
          <tr>
            <th style="writing-mode: vertical-rl">teste</th>
            <th *ngFor="let n of jogo" style="writing-mode: vertical-rl">{{ n.jogo }}</th>
          </tr>
        
        </ng-template>
        <ng-template pTemplate="body" let-jogo>
          <tr>
            <td>{{ jogo.jogador}}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
</div>

