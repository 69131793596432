<div class="spin-container" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{ width: '100%', height: '70px', marginTop: '5%' }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div class="flex-layout" *ngIf="!inProgress">   
<div style="float: left; margin-left: 5%; margin-top: 5%; width: 100%">
  <h5>Temporada</h5>
  <p-dropdown
    [options]="sessions"
    [(ngModel)]="selectedSession"
    [showClear]="true"
    placeholder="Selecione a temporada"
    (click)="sessionChange()"
  >
    <ng-template pTemplate="selectedItem">
      <div class="session-item session-item-value" *ngIf="selectedSession">
        <div>{{ selectedSession }}</div>
      </div>
    </ng-template>
    <ng-template let-session pTemplate="item">
      <div class="session-item">
        <div>{{ session }}</div>
      </div>
    </ng-template>
  </p-dropdown>

  <h5>Campeonato</h5>
      <p-dropdown
        [options]="competitions"
        [(ngModel)]="selectedCompetition"
        optionLabel="descricao"
        [filter]="true"
        filterBy="descricao"
        [showClear]="true"
        placeholder="Selecione Campeonato"
        (click)="competitionChange()"
      >
        <ng-template pTemplate="selectedItem">
          <div
            class="competition-item competition-item-value"
            *ngIf="selectedCompetition"
          >
            <div>{{ selectedCompetition }}</div>
          </div>
        </ng-template>
        <ng-template let-competition pTemplate="item">
          <div class="competition-item">
            <div>{{ competition }}</div>
          </div>
        </ng-template>
      </p-dropdown>

<div style="width: 300%;">
  <p style=" margin-top: -4%; margin-left: 8%; font-size: 200%;">Resumo de {{team}} </p>
</div>
   
<div class="spin-container" style="max-width: 25%" *ngIf="inProgress">
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px',
          marginTop: '20%',
          marginBottom: '20%'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>

<div style="margin-top: 2%; margin-left: 20%; width: '35%'">
    <p-chart
      type="pie"
      class="p-d-inline"
      [data]="totalData"
      [style]="{ width: '35%' }"
      *ngIf="!inProgress"
    ></p-chart>
</div>
  <div style="margin-left:25%; margin-top: 2%; font-size: 500%; font: arial-black; color: blue;">
    {{ response.total.victories }}
  </div>
  <div style="margin-left:25%; font-size: 150%; color: blue;">
    <label for="nome">Vitórias</label> 
  </div>
    <div style="margin-left:35%; margin-top: -10.3%; font-size: 500%; font: arial-black; color: red;">
    {{ response.total.defeats }}
  </div> 
  <div style="margin-left:35%; font-size: 150%; color: red;">
    <label for="nome">Derrotas</label>
  </div>
    <div style="margin-left:45%; margin-top: -10.3%; font-size: 500%; font: arial-black; color: gray;">
    {{ response.total.draws }}
  </div>
  <div style="margin-left: 45%; font-size: 150%; color: gray;">
    <label for="nome">Empates</label> 
  </div>
  <div style="width: 40%; margin-left: 55%; margin-top: -30%;">  
  <p-card >
    <div style="width: 90%;">
      <h1 style="text-align: center; margin-top: -5%;" >Histórico</h1>  
      <h3 style="text-align: center; margin-top: 3%;" >Mandante X  Visitante</h3>
        <p-table [value]="response" responsiveLayout="scroll" >
          <ng-template pTemplate="header">
              <tr>
                  <th></th>
                  <th>Mandante</th>
                  <th>Visitante</th>
              </tr>
              <tr>
                  <td style="text-align: center; color: blue;">Vitórias</td>
                  <td style="text-align: center; color: blue;">{{response.mandante.victories}}</td>
                  <td style="text-align: center; color: blue;">{{response.visitante.victories}}</td>
              </tr>
              <tr>
                  <td style="text-align: center; color: gray;">Empates</td>
                  <td style="text-align: center; color: gray;">{{response.mandante.draws}}</td>
                  <td style="text-align: center; color: gray;">{{response.visitante.draws}}</td>
              </tr>
              <tr>
                  <td style="text-align: center; color: red;">Derrotas</td>
                  <td style="text-align: center; color: red;">{{response.mandante.defeats}}</td>
                  <td style="text-align: center; color: red;">{{response.visitante.defeats}}</td>
              </tr>
          </ng-template>
      </p-table>
      </div>
  </p-card>
