import { Component, OnInit, Input } from '@angular/core';
import { ArenaService } from './arena.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-arena',
  templateUrl: './arena.component.html',
  styleUrls: ['./arena.component.scss'],
  providers: [ArenaService, MessageService, ConfirmationService],
})
export class ArenaComponent implements OnInit {
  @Input() teamId: any;
  @Input() gameId: any;
  players: any;
  inProgress: boolean;
  constructor(
    private arenaService: ArenaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listCast();
  }

  public listCast() {
    this.inProgress = true;
    this.arenaService.getJNJCast(this.teamId, this.gameId).subscribe(
      (response) => {
        this.inProgress = false;
        this.players = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar Elenco.',
          life: 3000,
        });
      }
    );
  }
}
