import { Injectable } from '@angular/core';
import { Campeonatos } from './campeonatos';
import { Router } from '@angular/router'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompetitionTableComponent } from '../../components/dashboard/competition-table/competition-table.component';


@Injectable({
  providedIn: 'root',
})
export class CampeonatosService {
  url = localStorage.host + '/competitions/';
  phaseUrl = localStorage.host + '/phases/'
  modality_id = localStorage.modality_id;
  category_id = localStorage.category_id;
  gameUrl = localStorage.host + '/turn-games/'
  campeonatoUrl = 'https://api.csm.corinthians.com.br/add-campeonato/';
  faseUrl = 'https://api.csm.corinthians.com.br/phases/';
  game: any;

  constructor(private http: HttpClient, private router: Router) { }

  public getCampeonatos(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + '?session=2023&categoria_id=' + localStorage.category_id + '&modalidade_id=' + localStorage.modality_id, { headers: header });
  }

  public getCampeonatosTemporada(temp: any): Observable<any> {
    let temporada = temp.value['ano']
    console.log(temp)
    console.log(temporada)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + '?session=' + temporada + '&categoria_id=' + localStorage.category_id + '&modalidade_id=' + localStorage.modality_id, { headers: header });
  }

  public getPhase(phase_id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.phaseUrl +
      '?competition_id=' +
      phase_id,
      { headers: header });
  }



  public getGames(campeonato_id, phase_id): Observable<any> {
    console.log(phase_id)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.gameUrl +
      '?modality_id=' +
      this.modality_id +
      '&category_id=' +
      this.category_id +
      '&competition_id=' +
      campeonato_id +
      '&phase_id=' +
      phase_id,
      {
        headers: header,
      }
    );
  }

  getParticipante(phase_id) {
    console.log(phase_id)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      'https://api.csm.corinthians.com.br/participante_fase/?phase_id=' + phase_id,
      {
        headers: header,
      }
    );
  }


  public goToService(game) {

    this.setGame(game)
    this.router.navigateByUrl('/games', {
      state: { selectedGame: game }
    })
  }

  setGame(game) {
    this.game = game;
  }

  public postCampeonato(campeonato: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    console.log(campeonato.value['nome'])
    return this.http.post<any>(this.campeonatoUrl, {

      "nome": campeonato.value['nome'],
      "temporada": campeonato.value['temporada'],
      "abrangencia": campeonato.value['abrangencia'],
      "inicio": campeonato.value['inicio'],
      "termino": campeonato.value['termino'],
      "observacao": campeonato.value['observacao'],
      "oficial": campeonato.value['oficial'],
      "modalidade_id": localStorage.modality_id,
      "categoria_id": localStorage.category_id,
      "user": localStorage.username + '@sccorinthians.com.br',
      "field_is_deleted": 0
    }, {
      headers: header,
    });

  }

  public postFase(id: any, fase: any) {
    console.log(id)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    console.log(fase.value)
    return this.http.post<any>(this.faseUrl +
      '?competition_id=' + id,
      {
        "evento_pai": id,
        "nome": fase.value['nome'],
        "apelido": fase.value['apelido'],
        "inicio": fase.value['inicio'],
        "sistema": fase.value['sistema'],
        "classifica": fase.value['classifica'],
        "desclassifica": fase.value['desclassifica'],
        "vitoria": fase.value['vitoria'],
        "diferente_vitoria": fase.value['diferente_visitante'],
        "empate": fase.value['empate'],
        "diferente_empate": fase.value['diferente_visitante'],
        "derrota": fase.value['derrota'],
        "diferente_derrota": fase.value['empate'],
        "iamaralo": fase.value['iamarelo'],
        "iiamaralo": fase.value['iiamarelo'],
        "vermelho": fase.value['vermelho'],
        "criterio": fase.value['criterio'],
        "user": localStorage.username + '@sccorinthians.com.br',
      }, {
      headers: header,
    });

  }

  getEquipes() {

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get('https://api.csm.corinthians.com.br/teams/',
      {
        headers: header,
      }
    );

  }

  addParticipantes(form, fase) {
    console.log(form)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post('https://api.csm.corinthians.com.br/participante-fase/', {
      'equipes': form,
      'fase_id': fase,
    },
      {
        headers: header,
      }
    );

  }

  deleteCampeonato(campeonato) {
    console.log(campeonato)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch('https://api.csm.corinthians.com.br/evento/' + campeonato.id_campeonato + '/', {
      'field_is_deleted': 1,

    },
      {
        headers: header,
      }
    );


  }

  updateCampeonato(campeonato) {
    console.log(campeonato)
    if (campeonato.oficial == true) {
      campeonato.oficial = 1
    }
    console.log(campeonato.oficial)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch('https://api.csm.corinthians.com.br/evento/' + campeonato.id + '/', {
      'descricao': campeonato.descricao,
      'abrangencia': campeonato.abrangencia,
      'inicio': campeonato.inicio,
      'termino': campeonato.termino,
      'observacao': campeonato.observacao,
      'temporada': campeonato.temporada,
      'oficial': campeonato.oficial
    },
      {
        headers: header,
      }
    );


  }

  getCampeonato(id) {

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get('https://api.csm.corinthians.com.br/evento/' + id + '/',
      {
        headers: header,
      }
    );


  }

}
