import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multas',
  templateUrl: './multas.component.html',
  styleUrls: ['./multas.component.scss']
})
export class MultasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
