import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ProvavelEscalacaoService } from './provavel-escalacao.service';
import { MessageService } from 'primeng/api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'provavel-escalacao',
  templateUrl: './provavel-escalacao.component.html',
  styleUrls: ['./provavel-escalacao.component.scss'],
  providers: [ProvavelEscalacaoService, MessageService],
})


export class ProvavelEscalacaoComponent implements OnInit {
  inProgress: boolean;
  response: any;
  team: any;
  teams: any[];
  selectedTeam: any;

  @ViewChild('pdf',{static: false}) el: ElementRef
  constructor(
    private provavelEscalacaoService: ProvavelEscalacaoService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listTeams();
  }

  public listCast() {
   
    this.provavelEscalacaoService.getCast(this.selectedTeam.id).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  public listTeams() {
    this.provavelEscalacaoService.getTeams().subscribe(
      (response) => {
        this.inProgress = false;
        this.teams = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipes.',
          life: 3000,
        });
      }
    );
 

  }
  public getSideString(side: string): string {
    if (side === 'E') {
      return 'Canhoto';
    }
    if (side === 'D') {
      return 'Destro';
    }
    return null;
  }

  exportPdf() {
    
    let pdf = new jsPDF('l', 'pt', 'a4');
    let element = document.getElementById('pdf');
    pdf.html(element, {
      callback: (pdf) => {
        pdf.save('CastReport.pdf');
      },
    });
  }

  public printPdf(){
    const doc= new jsPDF('l', 'pt' )
    doc.html(this.el.nativeElement, {callback:(doc) =>
    doc.save("Elenco.pdf")});
  }

}
