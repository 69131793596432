import { Component, OnInit, Input } from '@angular/core';
import { RounsGamesService } from './round-games.service';

@Component({
  selector: 'app-round-games',
  templateUrl: './round-games.component.html',
  styleUrls: ['./round-games.component.scss'],
})
export class RoundGamesComponent implements OnInit {
  @Input() competition_id: any;
  games: any;
  first: number;
  rows = 1;
  inProgress: boolean;

  constructor(private rounsGamesService: RounsGamesService) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listGames();
  }

  public listGames() {
    this.first = 1;
    this.rounsGamesService.getGames(this.competition_id).subscribe(
      (response) => {
        this.inProgress = false;
        this.games = response;
        console.log(response)
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  isLastPage(): boolean {
    return this.games ? this.first === this.games.length - this.rows : true;
  }

  isFirstPage(): boolean {
    return this.games ? this.first === 0 : true;
  }
}
