<div style="margin-top: 5%;margin-left: 85%;">
    <button pButton type="button" label="Save" icon="pi pi-check" (click)="addCaracteristica()"></button>
    <button style="margin-left: 10px;"  pButton pRipple  type="button"  icon="pi pi-file-o" (click)="reportAble()"></button>
</div>
<div
style="width: 90%; margin-top: 1%; margin-left: 5%;  box-shadow: 2px 2px 2px #b3b3b3"
*ngIf="response"
>
    <div 
      class="p-d-flex"
      style="
        float: right;
      "
      >
<div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{
      width: '100%',
      height: '70px',
      marginTop: '20%',
      marginBottom: '20%'
    }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div *ngIf="!inProgress" style="width: 100%" >
  <div *ngFor="let item of response">
    <div style="background-color: black; color: white">
      {{ item.position }}
    </div>
    <p-table
      #dt
      [columns]="[item.position]"
      [value]="item.players"
      [ngStyle]="{'width': '100%'}"
      responsiveLayout="scroll"
    >
      
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.field" colspan="col.colspan" 
          [ngStyle]="{'width': col.width, 'font-weight': col.fontweight, 'border-right': col.borderright}">
                   {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="padding: 0px; border-right: 1px solid #f8f8f8; align-items: center;">
            <a style="text-decoration:none; color:black" href="#/profile/{{ item.id_people }}/">
                <div>
                    <img style="width: 50%" src="{{ item.image }}">
                </div>
                    {{ item.surname }}
            </a>
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;border-right: 1px solid #f8f8f8;">
            <textarea  [rows]="5" [cols]="40" [autoResize]="true" style="font-size: small;"     [(ngModel)]="item.positive_points" pInputTextarea>{{ item.positive_points }}</textarea>  
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;border-right: 1px solid #f8f8f8;">
            <textarea [rows]="5" [cols]="40" [autoResize]="true" style="font-size: small; " 
            [(ngModel)]="item.negative_points"  pInputTextarea></textarea>  
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
</div>

<p-dialog
  [(visible)]="reportDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Caracteristicas"
  [modal]="true"
  styleClass="p-fluid"
>
<button pButton type="button" label="Gerar PDF" icon="pi pi-pdf" (click)="exportPdf()"></button>
<div id="pdf" >
  <div  *ngFor="let item of elenco" style="background-color: black; width: 3508px; height: 2480px; ">
    <div>
      <img style="margin-left: 30px;margin-top: 10px; width: 100px;" src="{{ item.image }}" /><a style="margin-left:20px; font-size: 60px; color: rgb(255, 255, 255); font-family: Arial ;">{{item.number}} - {{ item.surname }}</a>
    </div>
    <img style="margin-left: 750px; margin-top: -110px; width: 120px;" src="{{ brasao}}" /><br>
    <div id="div_lado">
      <div>
        <a  style="margin-left: 30px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;"> {{ item.position_name }}</a><br>
        <a  style="margin-left: 30px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">{{ item.age }} anos</a><br>
        <a  style="margin-left: 30px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">{{ getSideString(item.side) }}</a><br>
        <a  style="margin-left: 30px; font-size: 20px; color: white; font-family: Arial;">{{ item.height }}</a><br><br>
        <img src="assets/imgs/campo_report.png"  style="margin-left: 30px; width: 200px; height: 300px;">
        <div  class="{{selctPosition(item.position)}}" style="width: 30px;"></div>
      </div>
      <div style="margin-top: 100px; width: 500px;">
        <a  style="margin-left: 10px; font-size: 40px; color: rgb(255, 255, 255); font-family: Arial;">Características Ofensivas</a><br>
        <a  style="margin-left: 20px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">{{item.positive_points}}</a><br><br>
        <a  style="margin-left: 10px; font-size: 40px; color: rgb(255, 255, 255); font-family: Arial;">Características Defensivas</a><br>
        <a  style="margin-left: 20px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">{{item.negative_points}}</a><br><br>
        <a  style="margin-left: 10px; font-size: 40px; color: rgb(255, 255, 255); font-family: Arial;">Clubes Anteriores</a><br>
        <a  style="margin-top:10px; margin-left: 10px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">- {{cut(item.history)}}</a><br>
        <a  style="margin-left: 10px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">- {{cut2(item.history)}}</a><br>
        <a  style="margin-left: 10px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">- {{cut3(item.history)}}</a><br>
      </div>
      <a style="margin-left: 60px; margin-top: 450px; font-size: 20px; color: rgb(255, 255, 255); font-family: Arial;">CIFUT</a>
    </div>
  </div>
</div>
</p-dialog>