import { PessoasComponent } from './pessoas/pessoas.component';
import { EquipesComponent } from './equipes/equipes.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



export const routes: Routes = [
  {
    path: 'criar-pessoa',
    component: PessoasComponent,
  },
  {
    path: 'criar-equipe',
    component: EquipesComponent,
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntidadesRoutingModule { }
