import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class DpmedicoService {
  urlOcorrencia = localStorage.host + '/ocorrencias/'
  urlAvaliacao = localStorage.host + '/avaliacoes/'
  urlTipoSaude = localStorage.host + '/tipo_saude/'
  urlMecanismo = localStorage.host + '/mecanismo/'
  urlSeveridade = localStorage.host + '/severidade/'
  url2 = localStorage.host + '/elenco_completo/';
  urlEstrutura = localStorage.host + '/estrutura/';
  statusUrl = localStorage.host + '/status_ocorrencia/?ocorrencia='
  statusDpMedicoUrl = localStorage.host + '/status_dpmedico/'
  procedimentoUrl = localStorage.host + '/procedimento/'
  indiceUrl = localStorage.host + '/indice_saude/?tipo='
  avaliacaoUrl = localStorage.host + '/evento/'
  constructor(private http: HttpClient) { }

  getOcorrencias() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlOcorrencia + '?modality_id=' + localStorage.modality_id + '&category_id=' + localStorage.category_id, { headers: header })

  }

  getAvaliacao(evento_pai) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlAvaliacao + '?modality_id=' + localStorage.modality_id + '&category_id=' + localStorage.category_id + '&evento_pai=' + evento_pai, { headers: header })

  }

  getIndice(id) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.indiceUrl + id, { headers: header })
  }

  getTipoOcorrencia() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlTipoSaude, { headers: header })

  }

  getMecanismo() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlMecanismo, { headers: header })
  }


  getSeveridade() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlSeveridade, { headers: header })
  }

  public getCast2(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url2 +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id +
      '&team_id=' +
      teamId,
      { headers: header }
    );
  }

  getEstrutura() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlEstrutura, { headers: header })
  }

  getStatus(ocorrencia) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.statusUrl + ocorrencia, { headers: header })
  }

  getProcedimento(evento_id) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.procedimentoUrl + '?evento_id=' + evento_id, { headers: header })

  }

  patchStatus(status) {
    console.log(status.id)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    delete (status.uuid)
    return this.http.post(this.statusDpMedicoUrl, status, { headers: header })

  }

  postOcorrencia(ocorrencia) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post(this.urlOcorrencia, ocorrencia, { headers: header })
  }

  postAvaliacao(avaliacao) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post(this.urlAvaliacao, avaliacao, { headers: header })
  }

  postProcedimento(formData) {
    console.log(formData)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post(this.procedimentoUrl, formData, { headers: header })

  }


  postExames(formData) {
    console.log(formData)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post('https://api.csm.corinthians.com.br/exames/', formData, { headers: header })

  }

  getExames(evento) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get('https://api.csm.corinthians.com.br/exames/?evento=' + evento,)
  }

  deleteOcorrencia(id) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch(

      'https://api.csm.corinthians.com.br/atividade-fem/' + id + '/', {
      is_deleted: 1,
    }, { headers: header })

  }

}
