<app-menubar></app-menubar>
<div style="margin-top: 10%;">
    <div class="card">
       
        <p-table #dt1 [value]="atividades_atletas" dataKey="id"
            [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[50, 100, 150]" 
            [paginator]="true" currentPageReportTemplate="Showing {last} to {first} of {totalRecords} entries"
            [globalFilterFields]="['atleta','departamento','atividade','data', 'user_criacao']">
            <ng-template pTemplate="caption">
            
             
                <div class="flex">
                    <p-button label="Adicionar atividade"  (click)="newAtividade()"></p-button>
                    <p-button  style="margin-left: 10px;"  label="Relatório"  (click)="reportDialogOpen()"></p-button>
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width:15rem">
                        <div class="flex justify-content-center align-items-center">
                            Atleta
                            <p-columnFilter type="text" field="atleta" display="menu"></p-columnFilter>
                        </div>
                    </th>
                    <th style="min-width:15rem">
                        <div class="flex justify-content-center align-items-center">
                            Área Responsavel 
                            <p-columnFilter type="text" field="departamento" display="menu"></p-columnFilter>
                        </div>
                    </th>
                    <th style="min-width:15rem">
                        <div class="flex justify-content-center align-items-center">
                            Atividade
                        </div>
                    </th>
                    <th style="min-width:10rem" >
                        <div class="flex justify-content-center align-items-center">
                           Data Atividade 
                            <p-columnFilter lang="pt" locale="pt" type="date"  field="data_atividade" display="menu">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-calendar #calendar [ngModel]="value" (onSelect)="filter(calendar.value)" (onInput)="setup(calendar.value, col.field)" dateFormat="dd/mm/yy"></p-calendar>
                               </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>
                    <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                            Usuário Criação
                        </div>
                    </th>
                    <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                            
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-atividade>
                <tr>
                    <td style="text-align: center;">
                        {{atividade.atleta}}
                    </td>
                    <td style="text-align: center;">
                       {{atividade.departamento}}
                    </td>
                    <td style="text-align: center;">
                       {{atividade.atividade}}
                    </td>
                    <td style="text-align: center;">
                        {{atividade.data_atividade |  date: 'dd/MM/yyyy'}}
                    </td>
                    <td style="text-align: center;">
                        {{atividade.user_criacao}}
                    </td>
                    <td style="text-align: center;">
                        <button pButton pRipple (click)="updateAtividade(atividade)" icon="pi pi-pencil"></button>
                        <button style="margin-left: 2%;" pButton pRipple (click)="deleteAtividade(atividade)" icon="pi pi-trash"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

<p-dialog
  [(visible)]="newAtividadeDialog"
  [style]="{ width: '60%', minHeight: '50%' }"
  header="Nova atividade"
  [modal]="true"
  styleClass="p-fluid"
>
    <ng-template pTemplate="content">
        <div class="card">
            <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="form" method="post">
                <h5>Departamento Responsável</h5>
                <p-dropdown [options]="departamento" appendTo="body" [(ngModel)]="selectedDepartamento"  optionLabel="nome_dp" formControlName="dp" placeholder="Selecione um departamento " (click)="departamentoChange()"></p-dropdown>
                
                <div style="margin-top: 1%;">
                    <h5>Atividade</h5>
                    <p-multiSelect [options]="atividades" appendTo="body" formControlName="atividade"  (click)="departamentoChange()" defaultLabel="Selecione uma atividade" optionLabel="atividade_nome" selectedItemsLabel="{0} items selected"></p-multiSelect>
                   
                </div>
                <div style="margin-top: 1%;">
                    <h5>Atletas</h5>
                    <p-multiSelect [options]="cast" appendTo="body" placeholder="Selecione um aleta" formControlName="atleta" optionLabel="surname"  selectedItemsLabel="{0} items selected"></p-multiSelect>
                </div>
                <div style="margin-top: 1%;">
                    <h5>Data Atividade</h5>
                    <p-calendar  formControlName="data_atividade" dateFormat="dd/mm/yy" appendTo="body" required></p-calendar>
                </div>
                <div style="margin-top: 1%;">
                    <h5>local</h5>
                    <input formControlName="local" pInputText/>
                </div>
                <div style="margin-top: 1%;">
                    <h5>Tempo de Atividade</h5>
                    <p-inputNumber formControlName="tempo" [showButtons]="true"></p-inputNumber>
                </div>
                <div style="margin-top: 1%;">
                    <h5>Observações</h5>
                    <textarea formControlName="observacao" pInputTextarea rows="3" cols="20"></textarea>
                </div>
            </form>
        </div>
        <div style="margin-top: 1%;">
            <p-button label="Salvar" (click)="checkAtividade()"></p-button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="updateAtividadeDialog"
  [style]="{ width: '60%', minHeight: '50%' }"
  header="Editar atividade"
  [modal]="true"
  styleClass="p-fluid"
>
    <ng-template pTemplate="content">
        <div class="card">
            
                <h5>Departamento Responsável</h5>
                <input type="text" pInputText [(ngModel)]="atividade.departamento" placeholder="{{atividade.departamento}}"  readonly/>
                
                <div style="margin-top: 1%;">
                    <h5>Atividade</h5>
                    <input type="text" pInputText [(ngModel)]="atividade.atividade" readonly />
                   
                </div>
                <div style="margin-top: 1%;">
                    <h5>Atletas</h5>
                    <input type="text" pInputText [(ngModel)]="atividade.atleta" readonly />
                </div>
                <div style="margin-top: 1%;">
                    <h5>Data Atividade</h5>
                    <p-calendar [(ngModel)]="atividade.data_atividade" placeholder="{{atividade.data_atividade | date: 'dd/MM/yyyy'}}"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
                <div style="margin-top: 1%;">
                    <h5>local</h5>
                    <input [(ngModel)]="atividade.local" pInputText/>
                </div>
                <div style="margin-top: 1%;">
                    <h5>Tempo de Atividade</h5>
                    <p-inputNumber [(ngModel)]="atividade.tempo"  [showButtons]="true"></p-inputNumber>
                </div>
                <div style="margin-top: 1%;">
                    <h5>Observações</h5>
                    <textarea  [(ngModel)]="atividade.observacao"  pInputTextarea rows="3" cols="20"></textarea>
                </div>
          
        </div>
        <div style="margin-top: 1%;">
            <p-button label="Salvar" (click)="changeAtividade(atividade)"></p-button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="reportDialog"
  [style]="{ width: '50%'}"
  header="Relatório de Atividades"
  [modal]="true"
  styleClass="p-fluid"
>
<app-report-atividades></app-report-atividades>
</p-dialog>