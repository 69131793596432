<input type="text" [(ngModel)]="playerSearch">
<button (click)="listCast(0, pageFetch)">Procurar</button>

<label style="margin-left: 65%;" for="newplayer">Novo Jogador</label>
<button pButton pRipple icon="pi pi-plus" class="p-button-rounded crud-button" (click)="newPlayerPage()" style="
  color: white !important;
  background-color: black !important;
  float: right;"></button>

<ng-container *ngIf="formpage; then showformpage; else showcreatepage"></ng-container>
<ng-template #showformpage>
  <div *ngFor="let item of response">
    <p-table [columns]="[item.position]" [value]="item.players" responsiveLayout="scroll">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Foto
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Apelido
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Altura
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Predominancia
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            País de origem
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            CBF
          </th>

          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Ogol
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Soccerway
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Transfermarkt
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            <p-avatar image="{{ item.image }}"></p-avatar>
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            <a href="#/profile/{{ item.id_people }}/">
              {{ item.surname }}
            </a>
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            {{ item.height }}
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            {{ getSideString(item.side) }}
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            {{ item.origin }}
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            {{ item.cbf }}
          </td>

          <td style="padding: 0px; font-size: x-small; text-align: center;">
            {{ item.id_ogol }}
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            {{ item.id_soccerway }}
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            {{ item.id_transfermarkt }}
          </td>
          <td style="padding: 0px; font-size: x-small; text-align: center;">
            <button
              pButton
              pRipple
              icon="pi pi-plus"
              class="p-button-rounded crud-button"
              style="
                color: white !important;
                background-color: rgb(0, 179, 255) !important;
              "
              (click)="showPlayerContracts(item)"
            ></button>
          </td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>


<button *ngIf="pageCount > pageFetch" pButton pRipple icon="pi pi-arrow-left" class="p-button-rounded crud-button"
  style="
    color: white !important;
    background-color: black !important;
    float: left;
  " (click)="listCast(pageCount-(pageFetch *2), pageCount-pageFetch)"></button>

<button *ngIf="pageCount" pButton pRipple icon="pi pi-arrow-right" class="p-button-rounded crud-button" style="
    color: white !important;
    background-color: black !important;
    float: right;
  " (click)="listCast(pageCount, pageCount+pageFetch)"></button>

<p-dialog [(visible)]="playerContracts" [style]="{ width: '90%', minHeight: '100%' }"
  header="Contratos ativos do jogador" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div *ngFor="let item of playerContracts">
      <p-table [columns]="[item.position]" [value]="item.contracts" responsiveLayout="scroll">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Brasao
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Clube
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Camisa
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Ativo
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Situaçao
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Inicio
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Termino
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Transferir/Emprestar
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td style="padding: 0px; font-size: x-small">
              <p-avatar image="{{ item.image }}"></p-avatar>
            </td>
            <td style="padding: 0px; font-size: x-small">
              <a href="#/profile/{{ item.id_people }}/">
                {{ item.club }}
              </a>
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.shirt }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.active ? 'SIM' : 'NAO' }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.situation }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.start }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.end }}
            </td>

            <td style="padding: 0px; font-size: x-small; text-align: center;" *ngIf="item.club_id != teamId">
              <button pButton pRipple icon="pi pi-ellipsis-h" class="p-button-rounded crud-button" style="
                  color: white !important;
                  background-color: blue !important;
                " (click)="transferInfo(item)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="activeContract" [style]="{ width: '60%', minHeight: '100%' }"
  header="Adicione as informacoes do novo contrato" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div style="height: 400px; display: flex; float: left" class="p-field p-col-12 p-md-4">
      <div style="width: 40%; margin-right:50%" class="p-calendar p-datepicker">
        <label for="inicio">Novo Inicio</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="playerContractInfo.start" view="date" dateFormat="dd/mm/yy"
          [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" inputId="inicio"></p-calendar>
        <!-- <small class="p-invalid" *ngIf="submitted && !contract.inicio"
            >Campo obrigatório.</small
          > -->
      </div>
      <div style="width: 40%; margin-left:50%" class="p-calendar p-datepicker">
        <label for="termino">Novo Termino</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="playerContractInfo.end" view="date" dateFormat="dd/mm/yy"
          [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" inputId="termino"></p-calendar>
        <!-- <small class="p-invalid" *ngIf="submitted && !contract.termino"
            >Campo obrigatório.</small
          > -->
      </div>
    </div>

    <div [style]="!playerContracts ? 'justify-content: center;' : 'justify-content: space-between;'"
      *ngIf="!hideAddButton">
      <button pButton pRipple label="Transferir" class="p-button-text"
        (click)="addPlayer(activeContract, playerContractInfo, 'transferir')" style="width: 40%; margin-top: 20%;"></button>
      <button *ngIf="playerContracts" pButton pRipple label="Emprestar" class="p-button-text"
        (click)="addPlayer(activeContract, playerContractInfo, 'loan')" style="width: 40%; margin-left: 20%;"></button>
    </div>

  </ng-template>
</p-dialog>

<ng-template #showcreatepage >
  <form action="/pagina-processa-dados-do-form" [formGroup]="player" method="post">
    <div> 
      <div style="margin-top: 5%;">
        <label >Foto</label>
        <input style="margin-left: 1%;" type="file" accept="image/*" (change)="selectFile($event)" class="form-control">
      </div>
      <div style="margin-top: 2%;">
        <span class="p-float-label" style="display: inline;">
          <input type="text" formControlName="nome" pInputText [(ngModel)]="nome" required/>
          <label for="nome">Nome</label>
        </span>
        <span class="p-float-label " style="margin-left: 1%; display: inline;">
          <input type="text" style="width: 30%;" formControlName="apelido" pInputText [(ngModel)]="apelido" required/>
          <label for="apelido">Apelido</label>
        </span>
        <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
          <p-dropdown [style]="{'width':'110%'}" [options]="sexo" formControlName="sexo" [autoDisplayFirst]="false" optionLabel="name" appendTo="body" required></p-dropdown>
          <label for="apelido">Sexo</label>
        </span>
      </div>
      <div style="margin-top: 2%;">
        <span class="p-float-label" style="width: 10%; display: inline-flex;">
          <p-dropdown   [style]="{'width':'120%'}" [options]="side" formControlName="side" [autoDisplayFirst]="false" optionLabel="name" appendTo="body" required></p-dropdown>
          <label for="apelido">Perna Dominante</label>
        </span>
        <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
          <p-calendar inputId="basic" formControlName="nascimento" [(ngModel)]="nascimento" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="1980:3030" [readonlyInput]="true" appendTo="body" required></p-calendar>
          <label for="calendar">Nascimento</label>
        </span>
        <span class="p-float-label" style="width: 20%; margin-left: 1%; display: inline;">
          <p-inputNumber [showButtons]="true" mode="decimal"  [step]="0.01" [style]="{'width':'10%'}" formControlName="altura" pInputNumber [(ngModel)]="altura" required></p-inputNumber>
          <label for="altura">Altura</label>
        </span>
        <span class="p-float-label" style="width: 20%; margin-left: 1%; display: inline;">
          <p-inputNumber [showButtons]="true"  [style]="{'width':'10%'}" formControlName="camisa" pInputNumber [(ngModel)]="camisa" required></p-inputNumber>
          <label for="altura">Camisa</label>
        </span>
        <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
          <p-dropdown inputId="id"  formControlName="equipe" appendTo="body" [(ngModel)]="selectedTeam" [autoDisplayFirst]="false" [options]="selectTeam" optionLabel="apelido" required></p-dropdown>
          <label for="posicao">Equipe</label>
        </span>
        <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
          <p-dropdown inputId="id" formControlName="categoria" appendTo="body"  [autoDisplayFirst]="false" [options]="selectCategoria" [(ngModel)]="categoria"optionLabel="nome" required></p-dropdown>
          <label for="posicao">Categoria</label>
        </span>
        <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
          <p-dropdown inputId="posicao" formControlName="posicao" appendTo="body" [autoDisplayFirst]="false" [options]="selectPosicao" [(ngModel)]="posicao" optionLabel="nome" required></p-dropdown>
          <label for="posicao">Posição</label>
        </span>
        <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
          <p-dropdown inputId="estado" formControlName="estado" appendTo="body" [autoDisplayFirst]="false" [options]="selectEstado" [(ngModel)]="estado" optionLabel="nome"></p-dropdown>
          <label for="dropdown">Estado</label>
        </span>
      </div>
    </div>
    <div style="margin-top: 2%;">
      <span class="p-float-label" style=" width: 11%; display: inline-flex;">
        <p-dropdown inputId="nacionalidade" formControlName="nacionalidade" [style]="{'width':'130%'}" appendTo="body"  [autoDisplayFirst]="false" [options]="selectPais" [(ngModel)]="pais" optionLabel="sigla" required></p-dropdown>
        <label for="nacionalidade">Nacionalidade</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-dropdown inputId="pais" formControlName="pais" [autoDisplayFirst]="false" appendTo="body" [options]="selectPais" [(ngModel)]="pais" optionLabel="nome" required></p-dropdown>
        <label for="dropdown">País</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-dropdown inputId="selecao" formControlName="jogador_selecao" appendTo="body"  [autoDisplayFirst]="false" [options]="selecao" optionLabel="name" required></p-dropdown>
        <label for="jogador_selecao">Seleção</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-inputNumber inputId="integeronly" [min]="0" formControlName="federacao"   [(ngModel)]="federacao"></p-inputNumber>
        <label for="federacao" required>Número BID/CBF</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-inputNumber inputId="integeronly" [min]="0" formControlName="federacao_regional" [(ngModel)]="federacao_regional"></p-inputNumber>
        <label for="federacao_regional">Número FPF</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-inputNumber inputId="integeronly" [min]="0" formControlName="id_ogol"  [(ngModel)]="id_ogol" ></p-inputNumber>
        <label for="id_ogol">ID no OGol</label>
      </span>
    </div>
    <div style="margin-top: 2%;">
      <span class="p-float-label" style="display: inline-flex;">
        <p-inputNumber inputId="integeronly" [min]="0" formControlName="id_soccerway"  [(ngModel)]="id_soccerway" ></p-inputNumber>
        <label for="id_soccerway">ID no Soccerway</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-inputNumber inputId="integeronly" [min]="0" formControlName="id_transfermarket" [(ngModel)]="id_transfermarket" ></p-inputNumber>
        <label for="id_transfermarket">ID no Transfermarkt</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-calendar inputId="basic" formControlName="inicio" appendTo="body" [(ngModel)]="inicio" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" appendTo="body" required></p-calendar>
        <label for="inicio">Inicio do Contrato</label>
      </span>
      <span class="p-float-label" style="margin-left: 1%; display: inline-flex;">
        <p-calendar inputId="basic" formControlName="fim" appendTo="body" [(ngModel)]="fim" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" appendTo="body" required></p-calendar>
              <label for="fim">Fim do Contrato</label>
      </span>
    </div>
    <div>
      <button pButton type="button" label="Save" icon="pi pi-check" pTooltip="Click to proceed" (click)="addNewPlayer()" style="    width: 8%;
        margin-left: 80.5%; margin-top: 8%;"></button>
    </div>
    
  </form>
</ng-template>