import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class GamesService {
  sessionUrl = localStorage.host + '/sessions/';
  competitionsUrl = localStorage.host + '/competitions/';
  phasesUrl = localStorage.host + '/phases/';
  turnsUrl = localStorage.host + '/turns/';
  turnGamesUrl = localStorage.host + '/turn-games/';

  modality_id = localStorage.modality_id;
  category_id = localStorage.category_id;
  constructor(private http: HttpClient) { }

  public getSessions(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.sessionUrl, { headers: header });
  }

  public getCompetitions(session): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.competitionsUrl +
      '?session=' + session +
      '&categoria_id=' + localStorage.category_id +
      '&modalidade_id=' + localStorage.modality_id, {
      headers: header,
    });
  }

  public getPhases(competition_id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token,

    );
    return this.http.get(this.phasesUrl + '?competition_id=' + competition_id, {
      headers: header,
    });
  }

  public getTurns(phase_id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.turnsUrl + '?phase_id=' + phase_id, {
      headers: header,
    });
  }

  public getTurnGames(competition_id, phase_id, turn): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.turnGamesUrl +
      '?phase_id=' +
      phase_id +
      '&competition_id=' +
      competition_id +
      '&modality_id=' +
      this.modality_id +
      '&category_id=' +
      this.category_id +
      '&turn=' +
      turn,
      {
        headers: header,
      }
    );
  }
}
