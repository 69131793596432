import { Component, OnInit } from '@angular/core';
import { GamesCRUDService } from './games-crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Game } from './game';
import '@angular/compiler';

@Component({
  selector: 'app-games-crud',
  templateUrl: './games-crud.component.html',
  styleUrls: ['./games-crud.component.scss'],
  providers: [GamesCRUDService, MessageService, ConfirmationService],
})
export class GamesCRUDComponent implements OnInit {
  inProgress: boolean;

  gameDialog: boolean;

  castDialog: boolean;

  games: Game[];

  game: Game;

  teams: any[];

  places: any[];

  selectedMTeam: any;

  selectedVTeam: any;

  selectedPlace: any;

  selectedGames: Game[];

  selectedGame: Game;

  selectedSession: any;
  selectedCompetition: any;
  selectedPhase: any;
  selectedTurn: any;
  sessions: any[];
  competitions: any[];
  phases: any[];
  turns: any[];

  submitted: boolean;

  verb: string;

  isRestrictedOption = true;
  isRestrictedOptions: any[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  activitiesDialog: boolean;

  constructor(
    private gameCRUDService: GamesCRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listGames();
    this.listSessions();
    this.listTeams();
    this.listPlaces();
  }

  public listSessions() {
    this.gameCRUDService.getSessions().subscribe(
      (response) => {
        this.sessions = response;
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public listCompetitions() {
    if (this.selectedSession) {
      this.gameCRUDService.getCompetitions(this.selectedSession).subscribe(
        (response) => {
          this.competitions = response; console.log(response)
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }

  public listPhases() {
    console.log(this.selectedCompetition.id_campeonato)
    if (this.selectedCompetition) {
      this.gameCRUDService.getPhases(this.selectedCompetition.id_campeonato).subscribe(
        (response) => {
          this.phases = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }

  public listTurns() {
    if (this.selectedPhase) {
      this.gameCRUDService.getTurns(this.selectedPhase.id).subscribe(
        (response) => {
          this.turns = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }

  public listGames() {
    this.gameCRUDService.getGames().subscribe(
      (response) => {
        this.inProgress = false;
        this.games = response;
        console.log(this.games);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar jogos.',
          life: 3000,
        });
      }
    );
  }

  public listTeams() {
    this.gameCRUDService.getTeams().subscribe(
      (response) => {
        this.teams = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar times.',
          life: 3000,
        });
      }
    );
  }

  public listPlaces() {
    this.gameCRUDService.getEstadios().subscribe(
      (response) => {
        this.places = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar estadios.',
          life: 3000,
        });
      }
    );
  }

  public getTeam(id) {
    this.gameCRUDService.getTeam(id).subscribe(
      (response) => {
        return response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro requisicionar time.',
          life: 3000,
        });
      }
    );
  }

  public getGame(gameId) {
    this.gameCRUDService.getGame(gameId).subscribe(
      (response) => {
        this.game = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar times.',
          life: 3000,
        });
      }
    );
  }

  public sessionChange() {
    this.listCompetitions();
  }

  public competitionChange() {
    this.listPhases();
  }

  public phaseChange() {
    this.listTurns();
  }

  openNew() {
    this.game = {};
    this.submitted = false;
    this.gameDialog = true;
    this.verb = 'POST';
  }

  public deleteSelectedGames() {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir todos os jogos selecionados?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedGames.forEach((game) => this.deleteGame(game));
        this.selectedGames = null;
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          icon: 'success',
          detail: 'Jogos excluídos.',
          life: 3000,
        });
      },
    });
  }

  editGame(game) {
    this.getGame(game.id);
    this.selectedMTeam = {
      id: game.commanding_team_id,
      apelido: game.commanding_team,
    };
    this.selectedVTeam = {
      id: game.visiting_team_id,
      apelido: game.visiting_team,
    };
    this.gameDialog = true;
    this.verb = 'PUT';
  }

  editCast(game) {
    this.selectedGame = game;
    console.log(this.selectedGame);

    this.castDialog = true;
    this.verb = 'PUT';
  }

  showGame(game) {
    this.getGame(game.id);
    this.selectedMTeam = {
      id: game.commanding_team_id,
      apelido: game.commanding_team,
    };
    this.selectedVTeam = {
      id: game.visiting_team_id,
      apelido: game.visiting_team,
    };
    this.gameDialog = true;
    this.verb = 'GET';
  }

  // deleteGame(game: Game) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete ' + game.name + '?',
  //     header: 'Confirm',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.games = this.games.filter(
  //         (val) => val.id !== game.id
  //       );
  //       this.game = {};
  //       this.messageService.add({
  //         severity: 'success',
  //         summary: 'Successful',
  //         detail: 'Game Deleted',
  //         life: 3000,
  //       });
  //     },
  //   });
  // }

  openActivities() {
    // this.player = {};
    this.submitted = false;
    this.activitiesDialog = true;
    this.verb = 'POST';
  }

  hideDialog() {
    this.gameDialog = false;
    this.submitted = false;
  }

  submitForm() {
    if (this.verb === 'POST') {
      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    console.log(this.selectedPlace.id, this.selectedPhase.id)
    this.game.criador = localStorage.iduser;
    this.game.equipe_mandante_id = this.selectedMTeam.id;
    this.game.equipe_visitante_id = this.selectedVTeam.id;
    this.game.identity = 'evento_jogo';
    this.game.modalidade_id = localStorage.modality_id;
    this.game.categoria_id = localStorage.category_id;
    this.game.evento_pai = this.selectedCompetition.id_campeonato;
    this.game.fase_id = this.selectedPhase.id;
    this.game.lugar = this.selectedPlace.id;
    this.game.temporada = this.selectedSession;
    this.game.anulado = 0;
    this.game.avulso = 0;
    this.game.enviada = 0;
    this.game.has_viagem = 0;
    this.game.oficial = 1;
    this.game.oculto = 0;
    this.game.com_contato = 0;
    this.game.cronica = 0;
    this.game.pre_temporada = 0;
    this.game.valor_indice = 0;
    this.game.field_is_deleted = 0;
    this.game.impacto = 0;
    this.game.pre_evento = 0;
    this.gameCRUDService.postGame(this.game).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Jogo foi criado com sucesso.',
          life: 3000,
        });
        this.games = [...this.games];
        this.gameDialog = false;
        this.game = {};
        this.listGames();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar jogo.',
          life: 3000,
        });
      }
    );
  }

  putForm() {
    this.game.editor = localStorage.iduser;
    this.gameCRUDService.putGame(this.game).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Jogo editado com sucesso.',
          life: 3000,
        });
        this.games = [...this.games];
        this.gameDialog = false;
        this.game = {};
        this.listGames();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar jogo.',
          life: 3000,
        });
      }
    );
  }

  deleteGame(game) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir este jogo?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.gameCRUDService.deleteGame(game).subscribe(
          (response) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Excluído',
              detail: 'O jogo ' + game.name + ' foi excluído com sucesso.',
              life: 3000,
            });
            this.game = {};
            this.listGames();
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Houve algum erro ao excluir o jogo ' + game.name + '.',
              life: 3000,
            });
          }
        );
      },
    });
  }
}
