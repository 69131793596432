
<div class="filters-container" style="margin: 5%">
  <h2>Relatório de Elenco</h2>
  <div style="display: flex; float: left; width: 100%">
    <div>
      <h5>Equipe</h5>
      <p-dropdown
        [options]="teams"
        [(ngModel)]="selectedTeam"
        optionLabel="apelido"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o equipe"
      >
        <ng-template pTemplate="selectedItem">
          <div class="team-item team-item-value" *ngIf="selectedTeam">
            <div>{{ selectedTeam.apelido }}</div>
          </div>
        </ng-template>
        <ng-template let-team pTemplate="item">
          <div class="team-item">
            <div>{{ team.apelido }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <button
        pButton
        pRipple
        icon="pi pi-search"
        class="p-button-text"
        (click)="listCast()"
      ></button>
    </div>
  </div>
  <hr />
</div>

<div style="margin: 5%">
  <h1 *ngIf="!response">Nenhuma equipe selecionada</h1>
  <div
    style="width: 100%; margin: auto; box-shadow: 2px 2px 2px #b3b3b3"
    
  >
   
  </div>
</div>

<button
type="button"
pButton
pRipple
icon="pi pi-file-pdf"
(click)="exportPdf()"
pTooltip="PDF"
tooltipPosition="bottom"
style="
      color: white !important;
      background-color: #000000 !important;
      margin-left: 5%;
      width: 37px;
      height: auto;
    "
></button>
<div style="margin: 5%;"> 
  <div style="width: 842px; height: 595px;" id="pdf">
    
      <div  *ngIf="response" >
        <table style="width: 100%; margin: 1%;" border="1">
          <caption style="text-align: left; background-color: #000000; color: white;">

              <img *ngIf="modalidade == 'FUTE' && categoria == 'PRO'"  style="width: 70px; margin-left: 10px;" src="{{cutUrl(team.brasao)}}" />
              <img *ngIf="modalidade != 'FUTE' && categoria == 'PRO'"  style="width: 70px;" src="/assets/imgs/CSM_branco_simbolo.png" />
              <img *ngIf="modalidade == 'FUTE' && categoria != 'PRO'"  style="width: 70px;" src="/assets/imgs/CSM_branco_simbolo.png" />
              <h3 style="margin-left: 35%; margin-top: -5%; font-family: Arial;text-transform: uppercase;">{{ team.apelido }}</h3>
              <h3 *ngIf="modalidade == 'FUTE' && categoria == 'PRO'" style="margin-left: 85%;margin-top: -2.3%; font-family: Arial;text-transform: uppercase;">CIFUT</h3>
              <h3 *ngIf="modalidade != 'FUTE' && categoria == 'PRO'" style="margin-left: 85%;margin-top: -2.3%; font-family: Arial;text-transform: uppercase;">{{modalidade}}|{{categoria}} </h3>
              <h3 *ngIf="modalidade == 'FUTE' && categoria != 'PRO'" style="margin-left: 85%;margin-top: -2.3%; font-family: Arial;text-transform: uppercase;">{{modalidade}}|{{categoria}} </h3>
              <h3 style="color: #000000;">-</h3>
              
        
          </caption>
          
        <div *ngFor="let item of response" style="display: inline-table;"  >
          <table width="88px" border="1" margin-top="1px" id="{{ item.sigla }}" >
            <caption  style="background-color:#000000; color: white; text-align: left; font-size: 10px;">{{ item.position }}</caption>
            <tr *ngFor="let n of item.players"  >
              <td style="font-size: 6px; text-align: left; font-family:'Arial';">
                <div>
                  <table>
                    <td><div style="width:10px; height:10px; text-align: center; font-size: 10px;"><b>{{ n.number }}</b></div></td>
                      <td ><p-avatar image="{{ n.image }}"></p-avatar></td>
                  </table>
                    Nome: {{ n.surname }}<br>
                    Idade: {{ n.age }}<br>
                    Pé: {{ getSideString(n.side) }} /Altura: {{ n.height }}<br>
                    {{ n.last_club }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </table>
    </div>
  </div>
</div>