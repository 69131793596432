import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeIcons } from 'primeng/api';
import { PlayerTimelineService } from './player-timeline.service';

@Component({
  selector: 'app-player-timeline',
  templateUrl: './player-timeline.component.html',
  styleUrls: ['./player-timeline.component.scss'],
})
export class PlayerTimelineComponent implements OnInit {
  idPeople: any;
  inProgress: boolean;
  player: any;
  events: any[];
  lastRegistry: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private playerTimelineService: PlayerTimelineService
  ) {}

  ngOnInit(): void {
    this.idPeople = this.activatedRoute.snapshot.paramMap.get('id');
    this.inProgress = true;
    this.listEvents();
  }

  public listEvents() {
    this.playerTimelineService.getTimeline(this.idPeople).subscribe(
      (response) => {
        this.inProgress = false;
        this.player = response;
        this.events = this.player.updates;
        this.lastRegistry = this.player.last_registry;
        
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public appendEvents() {
    this.inProgress = true;
    this.playerTimelineService
      .appendToTimeline(this.idPeople, this.lastRegistry)
      .subscribe(
        (response) => {
          this.inProgress = false;
          let data = this.events;
          response.updates.forEach((update) => {
            data.push(update);
          });
          this.events = data;
          

          this.lastRegistry = response.last_registry;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
  }
}
