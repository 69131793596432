import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeIcons } from 'primeng/api';
import { TeamTimelineService } from './team-timeline.service';

@Component({
  selector: 'app-team-timeline',
  templateUrl: './team-timeline.component.html',
  styleUrls: ['./team-timeline.component.scss'],
})
export class TeamTimelineComponent implements OnInit {
  idTeam: any;
  inProgress: boolean;
  team: any;
  events: any[]=[];
  lastRegistry: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private teamTimelineService: TeamTimelineService
  ) {}

  ngOnInit(): void {
    this.idTeam = this.activatedRoute.snapshot.paramMap.get('id');
    this.inProgress = true;
    this.listEvents();
  }

  public listEvents() {
    this.teamTimelineService.getTimeline(this.idTeam).subscribe(
      (response) => {
        
        this.inProgress = false;
        this.team = response;
        this.events = this.team['updates']
        this.lastRegistry = this.events[this.events.length - 1].game.date;
       
        
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public appendEvents() {
    this.inProgress = true;
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.lastRegistry = this.events[this.events.length - 1].game.date;
    console.log(this.lastRegistry)
    this.teamTimelineService
      .appendToTimeline(id, this.lastRegistry)
      .subscribe(
        (response) => {
          this.inProgress = false;
          let data = this.events;
          response.updates.forEach((update) => {
            data.push(update);
          });
          this.events = data;
          console.log(this.events);

          this.lastRegistry = response.last_registry;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
  }
  public pageGame(game){
    
    this.teamTimelineService.goToService(game)
  }
  public pageActive(game){
    console.log(game)
  }
}
