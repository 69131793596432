
<div class="spin-container" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{ width: '100%', height: '70px', marginTop: '50%' }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>

<div *ngIf="!inProgress" style="width: 90%; margin-top: 5%; margin-left: 5%;  box-shadow: 2px 2px 2px #b3b3b3">
  <div *ngFor="let item of response">
    <div style="background-color: black; color: white">
      {{ item.position }}
    </div>
    <p-table
      #dt
      [columns]="[item.position]"
      [value]="item.players"
      [ngStyle]="{'width': '100%'}"
      responsiveLayout="scroll"
    >
      
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.field" colspan="col.colspan" 
          [ngStyle]="{'width': col.width, 'font-weight': col.fontweight, 'border-right': col.borderright}">
                   {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="padding: 0px; border-right: 1px solid #f8f8f8; align-items: center;">
            <a style="text-decoration:none; color:black" href="#/profile/{{ item.id_people }}/">
                <div>
                    <img style="width: 50%" src="{{ item.image }}">
                </div>
                    {{ item.surname }}
            </a>
          </td>
          <td style="padding: 0px;border-right: 1px solid #f8f8f8; text-align: center;">
            {{ item.end_contract | date}}
          </td>
          <td  style="padding: 0px; border-right: 1px solid #f8f8f8; text-align: center;">
            <a *ngIf="item.ocorencia == 'DMI'" href="" class="pi pi-briefcase" > Ver</a>
            <div *ngIf="item.ocorencia != 'DMI'" class="pi pi-thumbs-up">OK</div>
          </td>
          <td style="padding: 0px; border-right: 1px solid #f8f8f8; text-align: center;">
            <i style="width: 100%" class="pi pi-chart-line"></i>
            Ver
          </td>
          <td style="padding: 0px; border-right: 1px solid #f8f8f8; text-align: center;">
            {{ item.ocorencia }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
  <div style="width: 100%;">
    <app-elenco></app-elenco>
  </div>
  

