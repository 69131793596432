<app-menubar></app-menubar>
<br />
<br />
<p-toast></p-toast>

<div class="card" style="margin-top: 5%;">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
    </ng-template>

  </p-toolbar>

  <p-table
    #dt
    [value]="campeonatos"
    [rows]="10"
    [paginator]="true"
    [globalFilterFields]="['name', 'created_by', 'updated_at']"
    [(selection)]="selectedCampeonatos"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <h5 class="p-m-0">Campeonatos</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
       
        
          <form action="/pagina-processa-dados-do-form" [formGroup]="temp" method="post">
            <p-dropdown [options]="years"  formControlName="ano" placeholder="Temporada"></p-dropdown>
          </form>
       
          <button
            pButton
            pRipple
            icon="pi pi-check"
            class="p-button-danger"
            (click)="getCampeonatos()"
            
          ></button>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
       
        <th pSortableColumn="name">
          Nome <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="name">
          Modalidade <p-sortIcon field="modalidade"></p-sortIcon>
        </th>
        <th pSortableColumn="name">
          Categoria <p-sortIcon field="categoria"></p-sortIcon>
        </th>
        <th pSortableColumn="created_by">
          Criador por <p-sortIcon field="created_by"></p-sortIcon>
        </th>
        <th pSortableColumn="updated_at">
          Atualizado em <p-sortIcon field="updated_at"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-campeonato>
      <tr>
        <td style="text-align: center;" >
          {{ campeonato.campeonato }}
        </td>
        <td style="text-align: center;" >
          {{ campeonato.modalidade }}
        </td>
        <td style="text-align: center;" >
          {{ campeonato.categoria }}
        </td>
        <td style="text-align: center;">
          {{ campeonato.created_by }}
        </td>
        <td style="text-align: center;">
          {{ campeonato.update_time  | date }}
        </td>
        <td>
          <abbr title="Adicionar Fases">
          <button
            pButton
            pRipple
            icon="pi pi-plus"
            class="p-button-rounded p-button-success p-mr-2"
            (click)="getPhase(campeonato.id_campeonato, campeonato.campeonato)"
          ></button>
          </abbr>
          <abbr title="Editar Campeonato">
            <button
              pButton
              pRipple
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"
              (click)="editCampeonato(campeonato)"
            ></button>
          </abbr>
          <abbr title="Excluir Campeonato">
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            (click)="deleteCampeonato(campeonato)"
          ></button>
          </abbr>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        Total de 
        {{ campeonatos ? campeonatos.length : 0 }} campeonatos.
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="campeonatoDialog"
  [style]="{ width: '90%' }"
  header="{{ selectedCampeonato }}"
  appendTo="body"
  >

  <div *ngFor="let fase of fases" style="margin: 1%">
    <p-button [style]="{ width: '25%' }" (click)="openParticipante(fase.campeonato, fase.id)" >{{ fase.apelido }}</p-button>
  </div>
  <p-button [style]="{ width: '25%', margin: '1%' }" (click)="novaFase()">
    + Nova Fase 
  </p-button>

  
</p-dialog>

<p-dialog
  [(visible)]="novoCampeonatoDialog"
  [style]="{ width: '90%' }"
  header="Novo Campeonato"
  appendTo="body">
  <form action="/pagina-processa-dados-do-form" [formGroup]="campeonato" method="post">
    <div>
      <div class="p-field p-col-12 p-md-4">
        <span class="p-float-label" id="nome" >
          <input type="text" formControlName="nome" id="nome"  pInputText style="width: 40%;"/>
          <label for="nome">Nome</label>
        </span>
      </div>
    </div>
    
    <div>
      <div class="p-field p-col-12 p-md-4">
        <span class="p-float-label"   style="margin-left: 41%; margin-top: -3.3%;">
          <p-dropdown [options]="years"  formControlName="temporada" placeholder="Temporada" ></p-dropdown>
        
        </span>
      </div>
    </div>

    <div>
      <div class="p-field p-col-12 p-md-4">
        <span class="p-float-label"  style="margin-left: 59%; margin-top: -3.3%;">
          <input type="text" id="abrangencia" formControlName="abrangencia" pInputText />
          <label for="nome">Abrangencia</label>
        </span>
      </div>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="inicio" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="width: 29.5%; margin-top: 1%;">
          <p-calendar inputId="basic" view="date" appendTo="body" formControlName="inicio" id="inicio" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" ></p-calendar>
          <label for="inicio">Início</label>
        </span>
      </div>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="width: 29.5%; margin-left: 30%; margin-top: -3.3%;">
          <p-calendar inputId="basic" view="date" appendTo="body"  formControlName="termino" id="termino" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030"></p-calendar>
          <label for="fim">Término</label>
        </span>
      </div>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="obs" class="p-field p-col-12 p-md-4">
        <span class="p-float-label"   style="width: 29.5%; margin-top: 1%;">
        <textarea formControlName="observacao" id="observacao" pInputTextarea></textarea>
          <label for="obs" >Observação</label>
        </span>
      </div>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="oficial" class="p-field p-col-12 p-md-4">
        <span class="p-float-label"  style="margin-left: 32%; margin-top: -5%;">
          <p-radioButton value="1" formControlName="oficial" id="oficial" ></p-radioButton>
          <label style="margin-left: 2%;" >Oficial</label>
        </span>
      </div>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="margin-left: 40%; margin-top: -1.7%;">
          <p-radioButton  value="0" formControlName="oficial"></p-radioButton>
          <label style="margin-left: 2%;" >Não Listar</label>
        </span>
      </div>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="width: 30%; margin-top: 5%;">
          <p-button label="Salvar" icon="pi pi-check" iconPos="left" (click)="addCampeonato()"></p-button>
        </span>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog
  [(visible)]="novoFaseDialog"
  [style]="{ width: '90%' }"
  header="Nova Fase">
  <form action="/pagina-processa-dados-do-form" [formGroup]="fase" method="post">

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="nome" >
        <input type="text" formControlName="nome" pInputText style="width: 20%;"/>
        <label for="nome">Nome da fase</label>
      </span>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="apelido" style="width: 20%; margin-left: 22%; margin-top: -3.1%;" >
        <input type="text" formControlName="apelido" pInputText />
        <label for="apelido">Apelido</label>
      </span>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="inicio" class="p-field p-col-12 p-md-4" style="width: 20%; margin-left: 40%; margin-top: -3.1%;">
        <span class="p-float-label" >
          <p-calendar inputId="basic" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" formControlName="inicio"></p-calendar>
          <label for="inicio">Início</label>
        </span>
      </div>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="sistema" style="width: 20%; margin-top: 1%;" >
        <input type="text" formControlName="sistema" pInputText />
        <label for="sistema">Sistema</label>
      </span>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="classifica" style="width: 20%; margin-left: 20%; margin-top: -3.1%;" >
        <input type="text" formControlName="classifica" pInputText />
        <label for="classifica">Classifica</label>
      </span>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="desclassifica" style="width: 20%; margin-left: 40%; margin-top: -3.1%;" >
        <input type="text" formControlName="desclassifica" pInputText />
        <label for="desclassifica">Desclassifica</label>
      </span>
    </div>
    
    <!-- <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="margin-left: 60%; margin-top: -3%;">
          <p-radioButton ></p-radioButton>
          <label style="margin-left: 3%;" >Zera Cartões</label>
        </span>
      </div>
    </div> -->

    <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label">
          <label style="margin-top: 3%;" >Pontuação</label>
        </span>
      </div>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="vitoria" style="width: 20%; margin-top: 5%;" >
        <input type="text" formControlName="vitoria" pInputText />
        <label for="vitoria">Vitória</label>
      </span>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="empate" style="width: 20%; margin-top: -3.1%; margin-left: 20%;" >
        <input type="text" formControlName="empate" pInputText />
        <label for="empate">Empate</label>
      </span>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="derrota" style="width: 20%; margin-top: -3.1%; margin-left:40%;" >
        <input type="text" formControlName="derrota" pInputText />
        <label for="derrota">Derrota</label>
      </span>
    </div>

    <!-- <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="margin-top: 3%; margin-top: 1%;">
          <p-radioButton ></p-radioButton>
          <label style="margin-left: 3%;" >Diferente p/ visitante</label>
        </span>
      </div>
    </div> -->

    <!-- <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="margin-left: 20%; margin-top: -1.5%;">
          <p-radioButton ></p-radioButton>
          <label style="margin-left: 3%;" >Diferente p/ visitante</label>
        </span>
      </div>
    </div>

    <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label" style="margin-left: 40%; margin-top: -1.5%;">
          <p-radioButton ></p-radioButton>
          <label style="margin-left: 3%;" >Diferente p/ visitante</label>
        </span>
      </div>
    </div> -->
    
    <div class="p-fluid p-grid p-formgrid">
      <div id="fim" class="p-field p-col-12 p-md-4">
        <span class="p-float-label">
          <label style="margin-top: 2%;" >Multa Cartões</label>
        </span>
      </div>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="iamarelo" style="width: 20%; margin-top: 5%;" >
        <input type="text" formControlName="iamarelo" pInputText />
        <label for="iamarelo">1 Amarelo</label>
      </span>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="iiamarelo" style="width: 20%; margin-top: -3.1%; margin-left: 20%;" >
        <input type="text" formControlName="iiamarelo" pInputText />
        <label for="iiamarelo">2 Amarelos</label>
      </span>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <span class="p-float-label" id="vermelho" style="width: 20%; margin-top: -3.1%; margin-left:40%;" >
        <input type="text" formControlName="vermelho" pInputText />
        <label for="vermelho">Vermelho</label>
      </span>
    </div>
    
    <div class="p-fluid p-grid p-formgrid">
      <div id="criterio" class="p-field p-col-12 p-md-4">
        <span class="p-float-label">
          <label style="margin-top: 2%;" >Critérios de desempate</label>
        </span>
      </div>
    </div>

        <div id="criterio {{ criterios }}" class="p-field p-col-12 p-md-4" >
          <span class="p-float-label" style="margin-top: 5%;  width: 10%;">
            <label>1º</label>
          </span>
        </div>
      
        <div id="criterio" class="p-field p-col-12 p-md-4" >
          <span class="p-float-label" style="margin-top: 2%; margin-left: 5%; width: 20%;">
            <p-dropdown formControlName="criterio1"></p-dropdown>
          </span><br>
        </div>

        <div class="p-field p-col-12 p-md-4" >
          <span class="p-float-label" style="margin-top: 2%;  width: 10%;">
            <label>1º</label>
          </span>
        </div>
      
        <div id="criterio" class="p-field p-col-12 p-md-4" >
          <span class="p-float-label" style="margin-top: 2%; margin-left: 5%; width: 20%;">
            <p-dropdown formControlName="criterio2"></p-dropdown>
          </span><br>
        </div>

        <div class="p-field p-col-12 p-md-4" >
          <span class="p-float-label" style="margin-top: 2%;  width: 10%;">
            <label>2º</label>
          </span>
        </div>
      
        <div  class="p-field p-col-12 p-md-4" >
          <span class="p-float-label" style="margin-top: 2%; margin-left: 5%; width: 20%;">
            <p-dropdown formControlName="criterio3"></p-dropdown>
          </span><br>
        </div>
      

    <div id="fim" class="p-field p-col-12 p-md-4">
      <span class="p-float-label" style="width: 30%; margin-top: 5%;">
        <p-button label="Salvar" icon="pi pi-check" iconPos="left" (click)="addFase()"></p-button>
      </span>
    </div>
  </form> 
</p-dialog>

<p-dialog
  [(visible)]="participanteFaseDialog"
  [style]="{ width: '90%' }"
  header="Adicionar Equipes"
  appendTo="body">

  <div>
    <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="teams" method="post">
    <p-multiSelect
    [options]="equipes"
    [(ngModel)]="selectedTeam"
    optionLabel="apelido"
    [filter]="true"
    filterBy="nome"
    [showClear]="true"
    placeholder="Selecione a equipe"
    appendTo="body"
    formControlName="equipes"
  >
      <ng-template pTemplate="selectedItem">
        <div class="team-item team-item-value" *ngIf="selectedTeam">
          <div>{{ selectedTeam.apelido }}</div>
        </div>
      </ng-template>
      <ng-template let-team pTemplate="item">
        <div class="team-item">
          <div>{{ team.apelido }}</div>
        </div>
      </ng-template>
    </p-multiSelect>
  </form>
  </div><br>
  <div>
    <p-button  (click)="addParticipantes()">
      Adicionar Participantes
    </p-button>
  </div>
  
  <div>  
    <div style="margin-top: 2%; margin-left: 30%; height: 90%;">
      
      <p-table [value]="participantes" >
        <ng-template pTemplate="body" let-participante>
            <tr>
                <td>{{ participante.equipe.nome }}</td>
            </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</p-dialog>



<p-dialog
  [(visible)]="editCampeonatoDialog"
  [style]="{ width: '60%', minHeight: '50%' }"
  header="Editar Campeonato"
  [modal]="true"
  styleClass="p-fluid"
>
    <ng-template pTemplate="content">
        <div class="card">
                <h5>Campeonato</h5>
                <input type="text" pInputText [(ngModel)]="selectedCampeonato.descricao"/>
                
                <div style="margin-top: 1%;">
                    <h5>Temporada</h5>
                    <p-dropdown [options]="years" [(ngModel)]="selectedCampeonato.temporada"  ></p-dropdown>
                   
                </div>
                <div style="margin-top: 1%;">
                    <h5>Abrangencia</h5>
                    <input type="text" pInputText [(ngModel)]="selectedCampeonato.abrangencia"/>
                </div>
                <div style="margin-top: 1%;">
                    <h5>Inicio</h5>
                    <p-calendar placeholder="{{selectedCampeonato.inicio | date: 'dd/MM/yyyy'}}"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
                <div style="margin-top: 1%;">
                  <h5>Termino</h5>
                  <p-calendar placeholder="{{selectedCampeonato.termino | date: 'dd/MM/yyyy'}}"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
                <div style="margin-top: 1%;">
                  <h5>Oficial</h5>
                  <p-toggleButton [(ngModel)]="selectedCampeonato.oficial" onLabel="Yes" offLabel="No"></p-toggleButton>
                </div>
                <div style="margin-top: 1%;">
                    <h5>Observações</h5>
                    <textarea  [(ngModel)]="selectedCampeonato.observacao"  pInputTextarea rows="3" cols="20"></textarea>
                </div>
          
        </div>
        <div style="margin-top: 1%;">
            <p-button label="Salvar" (click)="updateCampeonato(selectedCampeonato)" ></p-button>
        </div>
      
    </ng-template>
</p-dialog>
