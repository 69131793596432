
<div class="spin-container" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{ width: '100%', height: '70px', marginTop: '5%' }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div *ngIf="!inProgress"  style="margin-left: 5%">
  <img style="width: 60%; margin-top: 5% ;" src="assets/imgs/four-lines.png">
  <div class="card text-white bg-danger mb-3" style="padding-bottom: 200%; margin-top: -100%;">
    <div  class="example-box"  *ngFor="let item of cast" cdkDrag >
      <p-card header="{{ item.position }}" [style]="{'width': '15rem', 'margin-bottom': '1em', 'background-color': 'rgb(30, 30, 30)', 'color': 'white'}">
        <div *ngFor="let n of item.players" class="{{ calcContract(n.end_contract) }}">
          {{ n.number }}-{{ n.surname }}-{{ n.end_contract | date : 'dd/MM/yyyy'}} 
        </div>
      </p-card>
    </div>
  </div>
</div>
    