<app-menubar></app-menubar>
<br />
<br />
<app-search-field></app-search-field>
<br />
<br />
<p-card class="result-container">
  <h4>Pesquisa: {{ searchKeys }}</h4>
  <app-search-result-list></app-search-result-list>
</p-card>
