import { Injectable, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  url = localStorage.host + '/cast-game/';
  url2 = localStorage.host + '/full_cast/';
  teamUrl = localStorage.host + '/teams/';
  urlCRUD = localStorage.host + '/jogador_no_jogo/';
  positionsUrl = localStorage.host + '/posicao/?modalidade_id=1';
  activitiesUrl = localStorage.host + '/activities-in-game/';


  constructor(private http: HttpClient) { }

  public getJNJCast(teamId, gameId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url + '?team_id=' + teamId + '&game_id=' + gameId,
      {
        headers: header,
      }
    );
  }

  public getTeam(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamUrl + teamId + '/', {
      headers: header,
    });
  }

  public postPlayer(player: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url, player, { headers: header });
  }

  public putPlayer(player: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.put<any>(this.url + player.id + '/', player, {
      headers: header,
    });
  }

  public deletePlayer(player: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.delete<any>(this.url + player.id + '/', {
      headers: header,
    });
  }

  public addOrRemoveToMainCast(player: any, id: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.urlCRUD + id + '/', player, {
      headers: header,
    });
  }

  public getPositions(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.positionsUrl, { headers: header });
  }

  public getActivities(gameId): Observable<any> {

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.activitiesUrl + '?game_id=' + gameId, {
      headers: header,
    });
  }
}
