import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class ResumoService {
  url = localStorage.host + '/score-resumo/';
  competitionsUrl = localStorage.host + '/competitions/';
  sessionUrl = localStorage.host + '/sessions/';
  scoreFilterUrl = localStorage.host + '/score-resumo/'

  constructor(private http: HttpClient) { }

  public getScore(idTeam): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url +
      '?team_id=' + idTeam +
      '&modalidade_id=' + localStorage.modality_id +
      '&categoria_id=' + localStorage.category_id,

      { headers: header }
    );
  }


  public getSessions(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.sessionUrl, { headers: header });
  }



  public getCompetitions(session): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.competitionsUrl +
      '?session=' + session +
      '&modalidade_id=' + localStorage.modality_id +
      '&categoria_id=' + localStorage.category_id, {
      headers: header,
    });
  }
  public mudaScore(competition_id, idTeam) {
    console.log(competition_id)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.scoreFilterUrl + '?team_id=' + idTeam + '&competition_id=' + competition_id, {
      headers: header,
    });
  }
}
