import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DepartmentMenuService } from './department-menu.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Post } from './post';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import '@angular/compiler';

@Component({
  selector: 'app-department-menu',
  templateUrl: './department-menu.component.html',
  styleUrls: ['./department-menu.component.scss'],
  providers: [DepartmentMenuService, MessageService, ConfirmationService],
})
export class DepartmentMenuComponent implements OnInit {
  id: String;

  items: MenuItem[];

  postDialog: boolean;

  posts: Post[];

  post: Post;

  submitted: boolean;

  verb: string;

  constructor(
    private departmentMenuService: DepartmentMenuService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.items = [
      {
        label: 'Criar Postagem',
        icon: 'pi pi-fw pi-plus',
        command: () => this.openNew(),
      },
      { label: 'Voltar', icon: 'pi pi-fw pi-refresh', url: '#home' },
    ];
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  openNew() {
    this.post = {};
    this.submitted = false;
    this.postDialog = true;
    this.verb = 'POST';
  }

  hideDialog() {
    this.postDialog = false;
    this.submitted = false;
  }

  submitForm() {
    this.post.departments = [{ id: this.id }];
    this.post.type = 'department';
    this.post.content = this.post.content
      .split('<img src=')
      .join('<img class="image-post" src=');
    this.departmentMenuService.postPost(this.post).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Departamento foi criado com sucesso.',
          life: 3000,
        });
        this.postDialog = false;
        this.post = {};
        this.router
          .navigateByUrl('/home', { skipLocationChange: true })
          .then(() => this.router.navigate(['department/' + this.id]));
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar departamento.',
          life: 3000,
        });
      }
    );
  }
}
