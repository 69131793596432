<div class="spin-container" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{ width: '100%', height: '70px', marginTop: '5%' }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<p-table [value]="players" responsiveLayout="scroll" *ngIf="!inProgress">
  <ng-template pTemplate="body" let-player>
    <tr>
      <td style="width: 10%">{{ player.number }}</td>
      <td style="width: 18%">
        <div class="pos-{{ player.position }}">{{ player.position }}</div>
      </td>
      <td>{{ player.surname }}</td>
      <td style="display: flex; float: left">
        <div class="spin-container" *ngIf="buttonInProgress">
          <p-progressSpinner
            [style]="{ width: '30px', height: '30px' }"
            styleClass="custom-spinner"
          ></p-progressSpinner>
        </div>
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          class="p-button-rounded crud-button"
          style="color: white !important; background-color: blue !important"
          (click)="addInMainCast(player)"
          *ngIf="player.status === 'NR' && !buttonInProgress"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-minus"
          class="p-button-rounded crud-button"
          style="color: white !important; background-color: red !important"
          (click)="removeToMainCast(player)"
          *ngIf="player.status !== 'NR' && !buttonInProgress"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
