<div id="arena-area" style="margin: 5%; opacity: none">
  <div>
    <div
      class="posicao-campo po"
      style="display: flex; float: left; width: 50%"
    >
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '1G' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '2G' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '3G' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '4G' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '5G' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
    </div>
    <div style="display: flex; float: left; width: 100%">
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '1F' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '2F' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '3F' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '4F' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '5F' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
    </div>
    <div style="display: flex; float: left; width: 100%">
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '1E' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '2E' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '3E' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '4E' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '5E' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
    </div>
    <div style="display: flex; float: left; width: 100%">
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '1D' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '2D' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '3D' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '4D' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '5D' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
    </div>
    <div style="display: flex; float: left; width: 100%">
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '1C' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '2C' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '3C' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '4C' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '5C' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
    </div>
    <div style="display: flex; float: left; width: 100%">
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '1B' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '2B' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '3B' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '4B' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '5B' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
    </div>
    <div style="display: flex; float: left; width: 100%">
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '1A' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '2A' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '3A' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone" >
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '4A' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
      <div class="zone">
        <b *ngFor="let player of players"
          ><div cdkDrag
            class="circle-div position-{{player.zone}}"
            *ngIf="player.zone == '5A' && player.status == 'TIT'"
          >
            {{ player.number }}
          </div></b
        >
      </div>
    </div>
  </div>
</div>
<br />
