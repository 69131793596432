
import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PlayerCrudService } from './player-crud.service';
import { Profile, Posicao } from './profile';
import { Contract } from '../contract';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-player-crud',
  templateUrl: './player-crud.component.html',
  styleUrls: ['./player-crud.component.scss'],
  providers: [PlayerCrudService, MessageService],
})
export class PlayerCrudComponent implements OnInit {
  @Input() profileId: any;
  @Input() contractId: any;
  date1: Date;
  date2: Date;
  submitted: boolean;
  profile: Profile;
  contract: Contract;
  selectPosicao:any;
  posicao: Posicao
  selectPerna:any;
  perna: any;
  position: any
  uploadedFiles: any[] = [];
  pessoa: any

  constructor(
    private playerCrudService: PlayerCrudService,
    private messageService: MessageService,
    private http: HttpClient,

    
    
  ) {}

  ngOnInit(): void {
    this.getProfile();
    this.getContract();
    this.getPosicoes();
  
    this.perna = [{id: 'D', nome:'Destro'}, 
                  {id: 'E',  nome: 'Canhoto'},
                  {id: 'A', nome: 'Ambidestro'}      ]
    
  }


  public getProfile() {
    this.playerCrudService.getProfile(this.profileId).subscribe(
      (response) => {
        
        this.profile = response;

        this.getPessoa(this.profile)
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar perfil.',
          life: 3000,
        });
      }
    );
  }
  
  public getPosicoes() {
    this.playerCrudService.getPosicoes().subscribe(
      (response) => {
        
        this.posicao = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar perfil.',
          life: 3000,
        });
      }
    );
  }

  getPessoa(pessoa_id){
    this.playerCrudService.getPessoa(this.profile.pessoa).subscribe(
      data => {this.pessoa = data}
    ) 

  }

  selectPosition(position){
    let selected
    let response 
    for(let pos in this.posicao){
      selected = this.posicao[pos]
      if(position == selected.id){
        response = selected
        break
      }     
    }
    
    return response.nome
  }

  public getContract() {
    this.playerCrudService.getContract(this.contractId).subscribe(
      (response) => {
        this.contract = response;
        this.date1 = new Date(response.inicio);
        this.date2 = new Date(response.termino);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar contrato.',
          life: 3000,
        });
      }
    );
  }

  submitForm() {
   
    if(this.selectPerna){
      this.profile.predominancia = this.selectPerna['id']  
    }
    if(this.selectPosicao){this.profile.posicao_id = this.selectPosicao['id'];
    }
    this.playerCrudService.editProfile(this.profile, this.profileId).subscribe(
      (response) => {
        this.getProfile();
        this.messageService.add({
          severity: 'info',
          summary: 'Salvo',
          detail: 'O perfil foi editado com sucesso.',
          life: 3000,
          
        }); 
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar perfil.',
          life: 3000,
        });
      }
    );
    this.profile.posicao_id = this.selectPosicao;
    this.contract.inicio = this.date1;
    this.contract.termino = this.date2;
    if(!this.contract.camisa){
      this.contract.camisa = null;
    }
    this.playerCrudService
      .editContract(this.contract, this.contractId)
      .subscribe(
        (response) => {
          
          this.getContract();
          this.messageService.add({
            severity: 'info',
            summary: 'Salvo',
            detail: 'O contrato foi editado com sucesso.',
            life: 3000,
          })
         
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve algum erro ao editar contrato.',
            life: 3000,
          });
        }
      );window.location.reload()
  }
  public getSideString(side: string): string {
   
    if (side === 'C' || side === 'E') {
      return 'Canhoto';
    }
    if (side === 'D') {
      return 'Destro';
    }
    if (side === 'A') {
      return 'Ambidestro';
    }

    return 'Perna Predominante';

  }

  selectFile(event: any) {
     
    if (event.target.files && event.target.files[0]) {
      const foto = event.target.files[0];
      const formData = new FormData();
      formData.append('foto', foto);
      
      try {
        this.http.patch('https://api.csm.corinthians.com.br/pessoa-foto/'+this.profile.pessoa+'/', formData)
        .subscribe(resposta => console.log(resposta));

      } catch (error) {
        alert('Erro ao carregar arquivo');
      }
    }
  }

}
