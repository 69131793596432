
<div class="filters-container" style="margin: 5%">
  <h2>Relatório de Elenco</h2>
  <div style="display: flex; float: left; width: 100%">
    <div>
      <h5>Equipe</h5>
      <p-dropdown
        [options]="teams"
        [(ngModel)]="selectedTeam"
        optionLabel="apelido"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o equipe"
      >
        <ng-template pTemplate="selectedItem">
          <div class="team-item team-item-value" *ngIf="selectedTeam">
            <div>{{ selectedTeam.apelido }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <button
        pButton
        pRipple
        icon="pi pi-search"
        class="p-button-text"
        (click)="listCast()"
      ></button>
    </div>
  </div>
  <hr />
</div>
<div *ngIf="!response"  style="margin-left: 5%; height: 70%;">
  <h1 >Nenhuma equipe selecionada</h1>
</div>
<button
pButton
pRipple
icon="pi pi-download"
(click)="exportPdf()"
class="p-button-rounded crud-button"
style="
  color: white !important;
  background-color: #000000 !important;
  margin: 2px 10px 0 0;
  width: 37px;
  height: auto;
"
></button>


<div *ngIf="response"  style="margin-left: 5%" id="pdf">
  <img style="width: 60%; margin-top: 5% ;" src="assets/imgs/four-lines.png">
  <div class="card text-white bg-danger mb-3" style="padding-bottom: 200%; margin-top: -100%;">
    <div *ngFor="let item of response">
        <div class="example-box" *ngFor="let n of item.players" cdkDrag>
          <img src="{{n.image}}" style="width: 70%; margin-left: 15%;">
          <h2 style="color: white ; font-size: x-small; background-color: black; text-align: center;"> 
            <b>{{ n.number }} - {{ n.surname }}</b> 
          </h2>
          <h2 style="color: black ; font-size: x-small; text-align: center;"> 
            <b>{{ n.height }} - {{ getSideString(n.side) }}</b> 
          </h2>
          <h2 style="color: black ; font-size: x-small; text-align: center;"> 
            <b><input type="text" style="width: 70%; height: 20%; font-size: x-small; font-weight: bold; color: red; text-align: center; outline: 0"></b> 
          </h2>
        </div>
    </div>
  </div>

    