<app-menubar></app-menubar>
<div  style="margin-top: 5%; margin-left: 1%; margin-right: 1%;" >
    <p-tabView>
        <p-tabPanel header="Ocorrências">
            <div style="margin: 0.8%">
                <p-table #dt1 [value]="ocorrencias" dataKey="id"
                [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[50, 100, 150]" 
                [paginator]="true" currentPageReportTemplate="Showing {last} to {first} of {totalRecords} entries"
                [globalFilterFields]="['atleta','tipo']">
                <ng-template pTemplate="caption">
                
                 
                    <div class="flex">
                        <p-button label="Adicionar ocorrência"  (click)="newDialog()"></p-button>
                        
                        <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width:15rem">
                            
                            <div class="flex justify-content-center align-items-center">
                                id
                                
                            </div>
                        </th>
                        <th style="min-width:15rem">
                            
                            <div class="flex justify-content-center align-items-center">
                                Atleta
                               
                            </div>
                        </th>
                        <th style="min-width:15rem">
                            
                            <div class="flex justify-content-center align-items-center">
                                Tipo
                               
                            </div>
                        </th>
                        <th style="min-width:15rem">
                            <div class="flex justify-content-center align-items-center">
                                Mecanismo 
                                
                            </div>
                        </th>
                        <th style="min-width:15rem">
                            <div class="flex justify-content-center align-items-center">
                                Severidade
                            </div>
                        </th>
                        <th style="min-width:10rem">
                            <div class="flex justify-content-center align-items-center">
                                Descrição
                            </div>
                        </th>
                        <th style="min-width:30rem">
                            <div class="flex justify-content-center align-items-center">
                                Status
                            </div>
                        </th>
                        <th style="min-width:10rem">
                            <div class="flex justify-content-center align-items-center">
                                Inicio
                            </div>
                        </th>
                        <th style="min-width:15rem">
                            
                            <div class="flex justify-content-center align-items-center">
                                Termino
                                
                            </div>
                        </th>
                        <th style="min-width:15rem">
                            
                            <div class="flex justify-content-center align-items-center">
                                
                                
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ocorrencia>
                    <tr>
                        <td style="text-align: center;">
                            {{ocorrencia.occorencia_id}}
                        </td>
                        <td style="text-align: center;"> 
                            {{ocorrencia.atleta}}
                        </td>
                        <td  style="text-align: center;"> 
                            {{ocorrencia.tipo_saude}}
                        </td>
                        <td style="text-align: center;">
                           {{ocorrencia.mecanismo}}
                        </td>
                        <td style="text-align: center;">
                           {{ocorrencia.severidade}}
                        </td>
                        <td style="text-align: center;">
                            {{ocorrencia.descricao}}
                        </td>
                        <td style="text-align: center;">
                            {{ocorrencia.status}}
                        </td>
                        <td style="text-align: center;">
                            {{ocorrencia.inicio |  date: 'dd/MM/yyyy'}}
                        </td>
                        <td style="text-align: center;">
                            {{ocorrencia.termino |  date: 'dd/MM/yyyy'}}
                        </td>
                        <td style="text-align: center;">
                            <button pButton pRipple (click)="updateOcorrencia(ocorrencia)" icon="pi pi-pencil"></button>
                            <button style="margin-left: 2%;" pButton pRipple (click)="deleteAtividade(atividade)" icon="pi pi-trash"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No customers found.</td>
                    </tr>
                </ng-template>
            </p-table>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>

<p-dialog
  [(visible)]="ocorrenciaDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Nova Ocorrência"
  [modal]="true"
  styleClass="p-fluid"
>


    <div style="display: inline-block;">
        <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="form" method="post">
            <td style="width: 650px;">
                <div style="display: inline-flex;">
                    <p-dropdown [options]="elenco"  formControlName="atleta" appendTo="body"  optionLabel="name" placeholder="Atleta"></p-dropdown>
                    <p-dropdown style="margin-left: 5px;" formControlName="tipo_ocorrencia" [options]="tipOcorrencia" appendTo="body"  optionLabel="nome" placeholder="Tipo Ocorrencia"></p-dropdown>
                    <p-calendar style="margin-left: 5px;" formControlName="data_ocorrencia" placeholder="Data Ocorrência"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                    <p-calendar style="  margin-left: 5px;" formControlName="retorno" placeholder="Previsão Retorno"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
                <div style="margin-top: 10px;">
                    <textarea style="width: 650px;" formControlName="queixa" placeholder="Queixa Principal"  pInputTextarea rows="3" cols="20"></textarea>
                    <textarea style="width: 650px;" formControlName="histotico"  placeholder="Histórico da Doença Atual"  pInputTextarea rows="3" cols="20"></textarea>
                    <textarea style="width: 650px;" formControlName="diagnostico"  placeholder="Diagnóstico"  pInputTextarea rows="3" cols="20"></textarea>
                    <textarea style="margin-top: 5px; width: 650px;" formControlName="conduta" placeholder="Conduta"  pInputTextarea rows="3" cols="20"></textarea>
                    <textarea style="margin-top: 5px; width: 650px;" formControlName="observacao" placeholder="Observações Adicionais"  pInputTextarea rows="3" cols="20"></textarea>
                </div>
                <div id="lesao">
                  
                </div>
            </td>
            <td >              
               
                <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                    <p-dropdown style="width: 300px;" formControlName="severidade" [options]="severidade" optionLabel="nome" appendTo="body"   placeholder="Severidade"></p-dropdown>
                </div>
                <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                    <p-dropdown style="width: 300px;" formControlName="mecanismo" [options]="mecanismos" optionLabel="nome" appendTo="body"   placeholder="Mecanismo"></p-dropdown>
                </div>
                <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                    <p-dropdown style="width: 300px;" formControlName="estrutura" [options]="estrutura" optionLabel="nome" appendTo="body"   placeholder="Estrutura"></p-dropdown>
                </div>
                <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                    <p-dropdown style="width: 300px;" formControlName="reicidente" appendTo="body"   placeholder="Reincidente"></p-dropdown>
                </div>
               
                <!-- <div style="margin-left: 10px; margin-top: 300px;">           
                    <span class="p-buttonset">
                        <button pButton pRipple label="Anterior" (click)="changeBody('frente')"></button>
                        <button pButton pRipple label="Posterior" (click)="changeBody('costa')"></button>
                    </span>
                    <p-card>  
                        <div id="click" (mousedown)="addLocalCorpo($event)" > 
                        
                            <img  *ngIf="corpo=='frente'" style="margin-left: 40px; margin-top: 20px;" src="/assets/imgs/corpo_frente.png">
                            <img  *ngIf="corpo=='costa'" style="margin-left: 40px; margin-top: 20px;" src="/assets/imgs/corpo_costas.png"> 
                        
                        </div>
                    </p-card>
                </div>   -->
            </td>
            <footer class="sticky-modal-footer">
                <button style="margin-top: 10px; width: 100px;" pButton pRipple label="Salvar" (click)="postOcorrencia()"></button>
            </footer>
        </form>
    </div>
</p-dialog> 

<p-dialog
  [(visible)]="UpdateOcorrenciaDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Ocorrência {{tipo_saude}} - {{id_ocorrencia}}"
  [modal]="true"
  styleClass="p-fluid"
>

    <ng-template pTemplate="content">
        <div class="card" style="margin-top: 50px;">
            <p-accordion [activeIndex]="4">
                <p-accordionTab header="Atualizar status da ocorrência">
                    <p-timeline [value]="status" align="top" layout="horizontal" styleClass="customized-timeline">
                        <ng-template pTemplate="marker" let-status>
                            <span class="custom-marker shadow-2" style=" background: {{getColor(status.status)}}; border-radius: 50%; width: 20px; height: 20px;" >
                                <h3 style=" text-align: center;"><b>{{status.status}}</b></h3>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-status>
                            {{getPasso(status.status)}}<br>
                            {{status.data  | date: 'dd/MM/yyyy'}}
                        </ng-template>
                    </p-timeline>
                    <tr>
                        <div class="flex flex-wrap gap-3">
                            <div style="width: 100px;" *ngIf="status[status.length - 1].status == 'I'">
                                <input type="radio" id="status" name="status" value="I"  checked>
                                <label for="ingredient1" class="ml-2"><b>Intensivo</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento intensivo da ocorrência, possivelmente em cirurgia ou repouso.</small></p>
                            </div>
                            <div style="width: 100px;" *ngIf="status[status.length - 1].status != 'I'">
                                <input type="radio" id="status" name="status" value="I" [(ngModel)]="selectedStatus" >
                                <label for="ingredient1" class="ml-2"><b>Intensivo</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento intensivo da ocorrência, possivelmente em cirurgia ou repouso.</small></p>
                            </div>

                            <div style="margin-left: 60px; width: 120px;"  *ngIf="status[status.length - 1].status == 'F'" >
                                <input type="radio" id="status" name="status" value="F" checked>
                                <label for="ingredient2" class="ml-2"><b>Fisioterapia</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento funcional e fisioterápico da ocorrência.</small></p>
                            </div>
                            <div style="margin-left: 60px; width: 120px;"  *ngIf="status[status.length - 1].status != 'F'" >
                                <input type="radio" id="status" name="status" [(ngModel)]="selectedStatus"  value="F" >
                                <label for="ingredient2" class="ml-2"><b>Fisioterapia</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento funcional e fisioterápico da ocorrência.</small></p>
                            </div>
                            <div  style="margin-left: 60px; width: 145px;" *ngIf="status[status.length - 1].status == 'P'">
                                <input type="radio" id="status" name="status" value="P" checked>
                                <label for="ingredient3" class="ml-2" ><b>Proprioceptivo</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento de força e proprieceptivo da ocorrência.</small></p>
                            </div>
                            <div  style="margin-left: 60px; width: 145px;" *ngIf="status[status.length - 1].status != 'P'">
                                <input type="radio" id="status" name="status" [(ngModel)]="selectedStatus"  value="P" >
                                <label for="ingredient3" class="ml-2" ><b>Proprioceptivo</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento de força e proprieceptivo da ocorrência.</small></p>
                            </div>
                        
                            <div style="margin-left: 60px; width: 120px;" *ngIf="status[status.length - 1].status == 'T'">
                                <input type="radio" id="status" name="status" value="T"  checked>
                                <label for="ingredient4" class="ml-2"><b>Transição</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento funcional, técnico e físico da ocorrência.</small></p>
                            </div>
                            <div style="margin-left: 60px; width: 120px;" *ngIf="status[status.length - 1].status != 'T'">
                                <input type="radio" id="status" name="status" [(ngModel)]="selectedStatus"  value="T" >
                                <label for="ingredient4" class="ml-2"><b>Transição</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento funcional, técnico e físico da ocorrência.</small></p>
                            </div>
                            <div style="margin-left: 60px; width: 120px;" *ngIf="status[status.length - 1].status == 'L'">
                                <input type="radio" id="status" name="status" [(ngModel)]="selectedStatus"  value="L" checked>
                                <label for="ingredient4" class="ml-2"><b>Liberado</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento encerrado, atleta liberado para atividades normais.</small></p>
                            </div>
                            <div style="margin-left: 60px; width: 120px;" *ngIf="status[status.length - 1].status != 'L'">
                                <input type="radio" id="status" name="status" [(ngModel)]="selectedStatus"  value="L" >
                                <label for="ingredient4" class="ml-2"><b>Liberado</b></label>
                                <p style="width: 80px; margin-left: 20px;"><small>Tratamento encerrado, atleta liberado para atividades normais.</small></p>
                            </div>
                        </div>
                    </tr>
                    <h5>Nova previsão de retorno</h5>
                    <p-calendar  formControlName="retorno" placeholder="{{editOcorrencia.termino | date: 'dd/MM/yyyy'}}"   dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                    <textarea style=" margin-top: 10px;" placeholder="Observações sobre a alteração do status"  pInputTextarea rows="3" cols="20"></textarea>
                    <button style="margin-top: 10px; width: 150px;" pButton pRipple label="Atualizar Status" (click)="updateStatus()"></button>
                </p-accordionTab>
                <p-accordionTab header="Procedimentos">
                    <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="form3" method="post">
                        <tr><td>
                        
                            <p-dropdown formControlName="tipo_saude_id" [options]="tipoProcedimento" optionLabel="nome" value="id" placeholder="Tipo" style="width: 300px;" appendTo="body"></p-dropdown>
                        </td>
                        <td>
                            
                            <p-calendar formControlName="data" placeholder="Data" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                        </td>
                        <td>
                            
                            <p-dropdown formControlName="periodo" [options]="periodo" optionLabel="periodo" [value]="id" style="width: 300px;" placeholder="Período" appendTo="body"></p-dropdown>
                        </td></tr>
                        <h5>Descrição</h5>
                        <textarea formControlName="descricao" style="margin-top: 5px; width: 100%;"  pInputTextarea rows="3" cols="20"></textarea>
                        <button style="margin-top: 10px; width: 150px;" pButton pRipple label="Adicionar Procedimento" (click)="addProcedimento()" ></button>
                    </form>
                    <p-divider></p-divider>
                    <div style="margin-top: 30px; margin-left: 1%;">                 
                        <p-table *ngIf="procedimentos.length > 0" [value]="procedimentos" [tableStyle]="{ 'min-width': '30rem' }">
                            <ng-template pTemplate="header">
                                <tr >
                                    <th><b>Tipo:</b></th>
                                    <th><b>Periodo:</b></th>
                                    <th><b>Descricao:</b></th>
                                    <th><b>Data:</b></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-procedimento>
                                <tr>
                                    <td style="text-align: center;">{{ procedimento.tipo }}</td>
                                    <td style="text-align: center;">{{ procedimento.periodo }}</td>
                                    <td style="text-align: center;">{{ procedimento.descricao }}</td>
                                    <td style="text-align: center;">{{ procedimento.data  |  date: 'dd/MM/yyyy'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-accordionTab>
                    
                <p-accordionTab header="Avaliações">
                    <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="form4" method="post">
                        <div style="width: 100%;">
                            <td>
                                <p-calendar formControlName="inicio" placeholder="Data" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                            </td>
                            <td>
                                <p-dropdown formControlName="tipo_saude"  placeholder="Tipo" [options]="tipoAvaliacao" optionLabel="nome"    (onChange)="getIndice($event.value.id)" appendTo="body"></p-dropdown>
                            </td>
                            <td>
                                <p-dropdown formControlName="indice_id" placeholder="índice" [options]="indice" optionLabel="nome" appendTo="body"></p-dropdown>
                            </td>
                            <td style="width: 150px;">
                                <p-inputNumber formControlName="valor_indice"  [showButtons]="true" placeholder="Valor Índice" appendTo="body"></p-inputNumber>
                            </td>
                            <td>
                                <p-dropdown formControlName="situacao" placeholder="Situação"  [options]="situacao" optionLabel="situacao" appendTo="body"></p-dropdown>
                            </td>
                        </div>
                        <div style="width: 100%;">
                            <textarea formControlName="descricao" placeholder="Descrição da avaliação" style="margin-top: 5px; width: 100%;"  pInputTextarea rows="3" cols="20"></textarea>
                        
                        </div>
                    </form>
                    <button style="margin-top: 10px; width: 150px;" (click)="postAvaliacao()" pButton pRipple label="Adicionar Avaliação" ></button>
                    <p-divider></p-divider>
                    <p-table *ngIf="avaliacoes.length > 0" [value]="avaliacoes" [tableStyle]="{ 'min-width': '30rem' }">
                        <ng-template pTemplate="header">
                            <tr >
                                <th><b>Tipo:</b></th>
                                <th><b>Índice:</b></th>
                                <th><b>Valor índice:</b></th>
                                <th><b>Situação:</b></th>
                                <th><b>Descrição:</b></th>
                                <th><b>Data:</b></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-avaliacao>
                            <tr>
                                <td style="text-align: center;">{{ avaliacao.tipo_saude }}</td>
                                <td style="text-align: center;">{{ avaliacao.indice }}</td>
                                <td style="text-align: center;">{{ avaliacao.valor_indice }}</td>
                                <td style="text-align: center;">{{ avaliacao.situacao}}</td>
                                <td style="text-align: center;">{{ avaliacao.descricao }}</td>
                                <td style="text-align: center;">{{ avaliacao.inicio  |  date: 'dd/MM/yyyy'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-accordionTab>
                <p-accordionTab header="Exames">
                    <div style="display: inline-flex; width: 100%;">
                        <label class="upload" for="file">Escolha um arquivo</label>
                        <input id="file" style="margin-left: 1%;" type="file" accept="image/*, .pdf*" (change)="selectFile($event)" class="form-control"> 
                        <b style="margin-left: 10px; margin-top: 10px;">{{fileName}}</b>
                    </div>
                    <div style="margin-top: 10px; margin-left: 1%; width: 120px;">
                        <p-button label="Salvar" icon="pi pi-check" (click)="postExame(id_ocorrencia)"></p-button>
                    </div>
                    <div style="margin-top: 30px; margin-left: 1%;">
                                            
                        <p-table *ngIf="exames.length > 0" [value]="exames" [tableStyle]="{ 'min-width': '30rem' }">
                            <ng-template pTemplate="header">
                                <tr >
                                    <th><b>arquivos:</b></th>
                                    <th><b>Data:</b></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-exame>
                                <tr>
                                    <td style="text-align: center;"><a style="text-decoration: none;" href="https://api.csm.corinthians.com.br/media/{{exame.exame}}" target="_blank">{{ exame.exame }}</a></td>
                                    <td style="text-align: center;">{{ exame.data  |  date: 'dd/MM/yyyy'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-accordionTab>
            </p-accordion>
            
        </div>
        <p-divider></p-divider>
        <td style="width: 650px;">
            <div style="display: inline-flex;">
                <div>
                    <h5>Atleta</h5>
                    <input type="text" pInputText [(ngModel)]="editOcorrencia.atleta" placeholder="{{editOcorrencia.atleta}}"  readonly/>
                </div>
                <div style="margin-left: 5px;">
                    <h5>Tipo Ocorrência</h5>
                    <input  type="text" pInputText [(ngModel)]="editOcorrencia.tipo_saude" placeholder="{{editOcorrencia.tipo_saude}}"  readonly/>
                </div>
                <div style="margin-left: 5px;" >
                    <h5>Inicio Ocorrência</h5>
                    <p-calendar  placeholder="{{editOcorrencia.inicio | date: 'dd/MM/yyyy'}}"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
                <div style="  margin-left: 5px;">
                    <h5>Térmio</h5>
                    <p-calendar  formControlName="retorno" placeholder="{{editOcorrencia.termino | date: 'dd/MM/yyyy'}}"   dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
                </div>
            </div>
            <div style="margin-top: 10px;">
                <div>
                    <h5>Queixa Principal</h5>
                    <textarea style="width: 650px;" [(ngModel)]="editOcorrencia.queixa"  pInputTextarea rows="3" cols="20"></textarea>
                </div>
                <div>   
                    <h5>Histórico da Doença Atual</h5>
                    <textarea style="width: 650px;" [(ngModel)]="editOcorrencia.historico"  pInputTextarea rows="3" cols="20"></textarea>
                </div>
                <div>
                    <h5>Diagnóstico</h5>
                    <textarea style="width: 650px;" [(ngModel)]="editOcorrencia.diagnostico"  pInputTextarea rows="3" cols="20"></textarea>
                </div>
                <div>
                    <h5>Conduta</h5>
                    <textarea style="margin-top: 5px; width: 650px;" [(ngModel)]="editOcorrencia.descricao"  pInputTextarea rows="3" cols="20"></textarea>
                </div>
                <div>
                    <h5>Observacao</h5>
                    <textarea style="margin-top: 5px; width: 650px;" [(ngModel)]="editOcorrencia.observacao" pInputTextarea rows="3" cols="20"></textarea>
                </div>
            </div>
        </td>
        <td >              
            <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                <h5 style="margin-left: 10px;">Severidade</h5>
                <p-dropdown style="width: 300px;"  [options]="severidade" optionLabel="nome" appendTo="body"   placeholder="{{editOcorrencia.severidade}}"></p-dropdown>
            </div>
            <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                <h5 style="margin-left: 10px;">Mecanismo</h5>
                <p-dropdown style="width: 300px;" [options]="mecanismos" optionLabel="nome" appendTo="body"   placeholder="{{editOcorrencia.mecanismo}}"></p-dropdown>
            </div>
            <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                <h5 style="margin-left: 10px;">Estrutura</h5> 
                <p-dropdown style="width: 300px;"  [options]="estrutura" optionLabel="nome" appendTo="body"   placeholder="{{editOcorrencia.estrutura}}"></p-dropdown>
            </div>
            <div style="margin-left: 10px; margin-top: 5px; width: 300px;">
                <h5 style="margin-left: 10px;">Reincidente</h5>
                <p-dropdown style="width: 300px;"  appendTo="body"   placeholder="Reincidente"></p-dropdown>
            </div>
        </td>
        <footer class="sticky-modal-footer">
            <button style="margin-top: 10px; width: 100px;" pButton pRipple label="Salvar"></button>
        </footer>
    </ng-template>
</p-dialog> 


