import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { Login } from './login';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly PATH: string = '';

  constructor(private http: HttpClient) { }

  getCollection(id: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      `${env.baseApiUrl}accounts/sessioncollection/?key=${id}/`,
      { headers: header }
    );
  }

  getUserAuthGroups(id: string): Observable<any[]> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get<any[]>(`${env.baseApiUrl}queryusergroup/${id}/`, {
      headers: header,
    });
  }

  logar(username: string, password: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    console.log(env.baseApiUrl);
    const loginobj = { username: username, password: password };
    return this.http.post<any>('http://15.228.58.208/ad-auth/', loginobj, {
      headers: header,
    });
  }

  postUser(data) {
    console.log(data)
    const header = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post<any>('http://15.228.58.208/novo_user/', {
      'nome': data.user.first_name + ' ' + data.user.last_name,
      'token': data.token,
      'email': data.user.email
    }, {
      headers: header,
    })
  }
}