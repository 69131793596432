import { UsuariosService } from './usuarios.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { FormGroup, FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  user: FormGroup;
  rolaps: any = [];
  uprolaps: any = [];
  selectNivel: any;
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  nivel: any;
  regrasDialog: boolean;
  novoUsuarioDialog: boolean;
  atualizaUsuarioDialog: boolean;
  regras: any;
  modalidade: any;
  categoria: any;
  modulo: any;
  tipo: any;
  rules: FormGroup;
  user_id: any;
  selectedModalidade: any;
  selectedCategoria: any;
  selectedModulo: any;

  // tslint:disable-next-line: max-line-length
  constructor(
    private rolapsService: UsuariosService,  
    private router: Router, private http: HttpClient,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.regrasDialog = false;
    
    this.listaRolAps();
    this.rules = this.fb.group({modalidade: [null,], categoria: [null,], modulo: [null,], })
    this.user = this.fb.group({
      nome: [null,], sobrenome: [null,], email: [null,], user: [null,], tipo: [null,],
      nivel: [null,], categoria: [null,], senha: [null,], re_senha: [null,],});

    this.nivel = [{name: 'Leitor', id: 0},
                  {name: 'Comum', id: 1},
                  {name: 'Administrador', id: 2},
                  {name: 'Super', id: 3}];
    
    this.modulo = [{name: 'TODOS', value: 'TODOS'},
                   {name: 'Administrativo', value: 'admin'},
                   {name: 'Competição', value: 'competicao'},
                   {name: 'Físico', value: 'fisica'},
                   {name: 'Gestão/Diretoria', value: 'gestao'},
                   {name: 'Médico', value: 'medico'},
                   {name: 'Análise de Desempenho', value: 'scout'}];
    
    this.tipo = [{name: 'Administrador do Sistema'},
                 {name: 'Analista de Desempenho'},
                 {name: 'Membo da Comissão Técnica'},
                 {name: 'Membro da Diretoria'},
                 {name: 'Membro do Dpto. Médico'},
                 {name: 'Membro do Dpto. Físico'},
                 {name: 'Membro da Administração do Futebol'},
                 {name: 'Membro da Gerência'},
                 {name: 'Jogador do Clube'}];
   
  }

  // tslint:disable-next-line: typedef
  public listaRolAps(){
    this.rolapsService.getRolAps().subscribe(
      response => {
        this.rolaps = response;
        console.log(localStorage.host)
      },
      error => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public listModalidade(){
    this.rolapsService.getModalidade().subscribe(
      response => {
        
        this.modalidade = response;
       
      },
      error => {
        alert('Houve algum erro ao carregar a lista.');
      }
    ); 
  }

  public listCategoria(){
    
    this.rolapsService.getCategoria(this.selectedModalidade).subscribe(
      response => {

        this.categoria = response;
      },
      error => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }



  public getRegras(id) {
    this.rolapsService.getRegras(id).subscribe(
      response => {
        this.user_id = id
        this.regras = response       
        console.log(id)
      },
      error => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
    this.listModalidade();
    
    this.regrasDialog = true;
  }

  public saveUsuario(){
    console.log(this.user.value)
  }

  public novoUsuario(){
    this.novoUsuarioDialog = true;
  }

  public atualizaUsuario(){
    this.atualizaUsuarioDialog = true;
  }

  // tslint:disable-next-line: typedef
  public upRolAps(id: string){
    localStorage.idd = id;
  }

  // tslint:disable-next-line: typedef
  cancelarDeletarBox(){
    document.getElementById("deletarbox").classList.remove("hidden");
    document.getElementById("deletarbox").classList.add("hidden");
  }

    // tslint:disable-next-line: typedef
  deletar(){
    document.getElementById("deletarbox").classList.remove("hidden");
    document.getElementById("deletarbox").classList.add("hidden");
    // $('#deletarbox').removeClass('hidden'); // To Remove
    // $('#deletarbox').addClass('hidden'); // To Remove
    this.rolapsService.delete(localStorage.idds).subscribe(
      r => {
        this.listaRolAps();
      }
    );
  }

  // tslint:disable-next-line: typedef
  deletarBox(id: string){
    localStorage.idds = id;
    // $('#deletarbox').removeClass('hidden'); // To Remove
    document.getElementById("deletarbox").classList.remove("hidden");

  }

  saveRegra(){
  
    let form = [{usuario_id: this.user_id, 
                 modalidade_id: this.rules.value['modalidade'].id,
                 categoria_id: this.rules.value['categoria'].id,
                 modulo: this.rules.value['modulo'].value,
                 nivel: 0
                },]
    this.rolapsService.saveUsreRule(form).subscribe(
      data => console.log(data)
    );
    this.getRegras(this.user_id)
  }

  saveAllCatgoriaRegra(){

    console.log(this.categoria)

    let form = [{usuario_id: this.user_id, 
                 modalidade_id: this.rules.value['modalidade'].id,
                 categoria_id: this.categoria,
                 modulo: this.rules.value['modulo'].value,
                 nivel: 0},
              ]
    this.rolapsService.saveUsreRule(form).subscribe(
      data => console.log(data)
    );
    this.getRegras(this.user_id)
  }


}
