import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesCRUDService {
  url = localStorage.host + '/activities-in-game/';
  urlCRUD = localStorage.host + '/atividade/';
  teamUrl = localStorage.host + '/teams/';
  JNJUrl = localStorage.host + '/cast-game/';
  CNJUrl = localStorage.host + '/game-commission/';
  ANJUrl = localStorage.host + '/game-arbitration/';

  constructor(private http: HttpClient) { }

  public getActivities(gameId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + '?game_id=' + gameId, {
      headers: header,
    });
  }

  public getTeam(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamUrl + teamId + '/', {
      headers: header,
    });
  }

  public getJNJCast(teamId, gameId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.JNJUrl + '?team_id=' + teamId + '&game_id=' + gameId,
      {
        headers: header,
      }
    );
  }

  public getCNJCast(commanding, gameId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.CNJUrl + '?commanding=' + commanding + '&game_id=' + gameId,
      {
        headers: header,
      }
    );
  }

  public getANJCast(gameId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.ANJUrl + '?game_id=' + gameId, {
      headers: header,
    });
  }

  public postActivity(activity: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.urlCRUD, activity, { headers: header });
  }

  public putActivity(activity: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.urlCRUD + activity.id + '/', activity, {
      headers: header,
    });
  }

  public deleteActivity(activity: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.delete<any>(this.urlCRUD + activity.id + '/', {
      headers: header,
    });
  }
}
