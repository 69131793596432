<div style="width: 100%; margin-left: -30% ; box-shadow: 2px 2px 2px #b3b3b3">
  <div style="width: 200px; background-color: black; color: white">
    Elenco {{ modalityName }}
  </div>
  <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
    <p-progressSpinner
      [style]="{
        width: '100%',
        height: '70px',
        marginTop: '20%',
        marginBottom: '20%'
      }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <div *ngIf="!inProgress" style="max-width: 200px">
    <div *ngFor="let item of response">
      <p-table
        [columns]="[item.position]"
        [value]="item.players"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              {{ item.position }}
            </th>
            <th>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td style="padding: 0px; font-size: small; ">
              <a href="#/profile/{{ item.id_people }}/" style="text-decoration: none; color: black;">
                {{ item.surname }}
              </a>
            </td>
            <td style="padding: 0px; font-size: x-small">
                <p-avatar image="{{ item.image }}"></p-avatar>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
