import { Component, OnInit } from '@angular/core';
import { DepartmentCRUDService } from './department-crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Department } from './department';
import '@angular/compiler';

@Component({
  selector: 'app-department-crud',
  templateUrl: './department-crud.component.html',
  styleUrls: ['./department-crud.component.scss'],
  providers: [DepartmentCRUDService, MessageService, ConfirmationService],
})
export class DepartmentCRUDComponent implements OnInit {
  departmentDialog: boolean;

  departments: Department[];

  department: Department;

  selectedDepartments: Department[];

  selectedDepartment: any;

  submitted: boolean;

  verb: string;

  isRestrictedOption = true;
  isRestrictedOptions: any[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  constructor(
    private departmentCRUDService: DepartmentCRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listDepartment();
  }

  public listDepartment() {
    this.departmentCRUDService.getDepartments().subscribe(
      (response) => {
        this.departments = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar departamentos.',
          life: 3000,
        });
      }
    );
  }

  openNew() {
    this.department = {};
    this.submitted = false;
    this.departmentDialog = true;
    this.verb = 'POST';
  }

  public deleteSelectedDepartments() {
    this.confirmationService.confirm({
      message:
        'Tem certeza que deseja excluir todos os departamentos selecionados?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedDepartments.forEach((department) =>
          this.deleteDepartment(department)
        );
        this.selectedDepartments = null;
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          icon: 'success',
          detail: 'Departamentos excluídos.',
          life: 3000,
        });
      },
    });
  }

  editDepartment(department: Department) {
    this.department = { ...department };
    this.departmentDialog = true;
    this.verb = 'PUT';
  }

  showDepartment(department: Department) {
    this.department = { ...department };
    this.departmentDialog = true;
    this.verb = 'GET';
  }

  // deleteDepartment(department: Department) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete ' + department.name + '?',
  //     header: 'Confirm',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.departments = this.departments.filter(
  //         (val) => val.id !== department.id
  //       );
  //       this.department = {};
  //       this.messageService.add({
  //         severity: 'success',
  //         summary: 'Successful',
  //         detail: 'Department Deleted',
  //         life: 3000,
  //       });
  //     },
  //   });
  // }

  hideDialog() {
    this.departmentDialog = false;
    this.submitted = false;
  }

  submitForm() {
    if (this.verb === 'POST') {
      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    this.departmentCRUDService.postDepartment(this.department).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Departamento foi criado com sucesso.',
          life: 3000,
        });
        this.departments = [...this.departments];
        this.departmentDialog = false;
        this.department = {};
        this.listDepartment();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar departamento.',
          life: 3000,
        });
      }
    );
  }

  putForm() {
    this.departmentCRUDService.putDepartment(this.department).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Departamento editado com sucesso.',
          life: 3000,
        });
        this.departments = [...this.departments];
        this.departmentDialog = false;
        this.department = {};
        this.listDepartment();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar departamento.',
          life: 3000,
        });
      }
    );
  }

  deleteDepartment(department) {
    this.departmentCRUDService.deleteDepartment(department).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Excluído',
          detail:
            'O departamento ' + department.name + ' foi excluído com sucesso.',
          life: 3000,
        });
        this.department = {};
        this.listDepartment();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail:
            'Houve algum erro ao excluir o departamento ' +
            department.name +
            '.',
          life: 3000,
        });
      }
    );
  }
}
