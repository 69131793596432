<p-toast></p-toast>
<div class="filters-container" style="margin: 5%">
  <h2>Relatório de Performance</h2>
  <div style="display: flex; float: left; width: 100%">
    <div style="margin-top: 1%;">
      <p-dropdown
        
        [options]="sessions"
        [(ngModel)]="selectedSession"
        [showClear]="true"
        placeholder="Selecione a temporada"
        (click)="sessionChange()"
      >
        <ng-template pTemplate="selectedItem">
          <div class="session-item session-item-value" *ngIf="selectedSession">
            <div>{{ selectedSession }}</div>
          </div>
        </ng-template>
        <ng-template let-session pTemplate="item">
          <div class="session-item">
            <div>{{ session }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div style="margin-top: 1%;">
      <p-dropdown
        style="margin-top: 1%;"
        [options]="competitions"
        [(ngModel)]="selectedCompetition"
        optionLabel="descricao"
        [filter]="true"
        filterBy="descricao"
        [showClear]="true"
        placeholder="Selecione Campeonato"
        (click)="competitionChange()"
      >
        <ng-template pTemplate="selectedItem">
          <div
            class="competition-item competition-item-value"
            *ngIf="selectedCompetition"
          >
            <div>{{ selectedCompetition.descricao }}</div>
          </div>
        </ng-template>
        <ng-template let-competition pTemplate="item">
          <div class="competition-item">
            <div>{{ competition.descricao }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div style="margin-top: 1%;">
      <p-dropdown
        style="margin-top: 1%;"
        [options]="teams"
        [(ngModel)]="selectedTeam"
        optionLabel="apelido"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o equipe"
      >
        <ng-template pTemplate="selectedItem">
          <div class="team-item team-item-value" *ngIf="selectedTeam">
            <div>{{ selectedTeam.apelido }}</div>
          </div>
        </ng-template>
        <ng-template let-team pTemplate="item">
          <div class="team-item">
            <div>{{ team.apelido }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div style="margin-top: 1%;">
      <button
        pButton
        pRipple
        icon="pi pi-search"
        class="p-button-text"
        (click)="getData()"
      ></button>
    </div>
  </div>
  <hr />
</div>

<div style="margin: 14.2%">
  <h1 *ngIf="!response">Nenhuma equipe selecionada</h1>
  <div
    style="width: 100%; margin: auto; box-shadow: 2px 2px 2px #b3b3b3"
    *ngIf="response"
  >

  <button
    pButton
    pRipple
    icon="pi pi-download"
    (click)="  printPdf()"
    class="p-button-rounded crud-button"
    style="
      color: white !important;
      background-color: #000000 !important;
      margin: 2px 10px 0 0;
      width: 37px;
      height: auto;
    "
  ></button>
  <div id="dt" #dt>
    <div
    
      style="
        width: 100%;
        background-color: black;
        color: white;
        display: flex;
        float: left;
        justify-content: space-between;
        border-bottom: solid 1px white;
        align-items: center;
      "
      
    >
      <div ><img style="width: 80px" src="{{ selectedTeam.brasao }}" /></div>
      <div style="text-align: center">DESEMPENHO</div>
      <div class="font-logo"></div>
    </div>
    <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px',
          marginTop: '20%',
          marginBottom: '20%'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>
    <div *ngIf="!inProgress" style="width: 100%">
      <div style="display: flex; float: left; width: 100%">
        <div style="width: 50%; padding: 1%; background-color: white">
          <div><h3>MANDANTE</h3></div>
          <p-table>
            <ng-template pTemplate="header">
              <tr style="border: solid 1px">
                <th style="border: solid 1px">J</th>
                <th style="border: solid 1px">P</th>
                <th style="border: solid 1px">V</th>
                <th style="border: solid 1px">E</th>
                <th style="border: solid 1px">D</th>
                <th style="border: solid 1px">GP</th>
                <th style="border: solid 1px">GC</th>
                <th style="border: solid 1px">SG</th>
                <th style="border: solid 1px">%</th>
              </tr>
              <tr style="border: solid 1px">
                <td style="border: solid 1px">{{ response.in_home.data.J }}</td>
                <td style="border: solid 1px">{{ response.in_home.data.P }}</td>
                <td style="border: solid 1px">{{ response.in_home.data.V }}</td>
                <td style="border: solid 1px">{{ response.in_home.data.E }}</td>
                <td style="border: solid 1px">{{ response.in_home.data.D }}</td>
                <td style="border: solid 1px">
                  {{ response.in_home.data.GP }}
                </td>
                <td style="border: solid 1px">
                  {{ response.in_home.data.GC }}
                </td>
                <td style="border: solid 1px">
                  {{ response.in_home.data.SG }}
                </td>
                <td style="border: solid 1px">
                  {{ response.in_home.data["%"] }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-table [value]="response.in_home.games">
            <ng-template pTemplate="body" let-game>
              <tr style="border: solid 1px">
                <td style="border: solid 1px">{{ game.competition_name }}</td>
                <td style="border: solid 1px">{{ game.commanding_team }}</td>
                <td
                  *ngIf="game.commanding_score > game.visiting_score"
                  style="
                    border: solid 1px;
                    color: white;
                    background-color: blue;
                    text-align: center;
                    width: 15%;
                  "
                >
                  {{ game.commanding_score }} X {{ game.visiting_score }}
                </td>
                <td
                  *ngIf="game.commanding_score < game.visiting_score"
                  style="
                    border: solid 1px;
                    color: white;
                    background-color: red;
                    text-align: center;
                    width: 15%;
                  "
                >
                  {{ game.commanding_score }} X {{ game.visiting_score }}
                </td>
                <td
                  *ngIf="game.commanding_score == game.visiting_score"
                  style="
                    border: solid 1px;
                    color: white;
                    background-color: gray;
                    text-align: center;
                    width: 15%;
                  "
                >
                  {{ game.commanding_score }} X {{ game.visiting_score }}
                </td>
                <td style="border: solid 1px">{{ game.visiting_team }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div style="width: 50%; padding: 1%; background-color: white">
          <div><h3>VISITANTE</h3></div>

          <p-table>
            <ng-template pTemplate="header">
              <tr style="border: solid 1px">
                <th style="border: solid 1px">J</th>
                <th style="border: solid 1px">P</th>
                <th style="border: solid 1px">V</th>
                <th style="border: solid 1px">E</th>
                <th style="border: solid 1px">D</th>
                <th style="border: solid 1px">GP</th>
                <th style="border: solid 1px">GC</th>
                <th style="border: solid 1px">SG</th>
                <th style="border: solid 1px">%</th>
              </tr>
              <tr style="border: solid 1px">
                <td style="border: solid 1px">
                  {{ response.out_home.data.J }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data.P }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data.V }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data.E }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data.D }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data.GP }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data.GC }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data.SG }}
                </td>
                <td style="border: solid 1px">
                  {{ response.out_home.data["%"] }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-table [value]="response.out_home.games">
            <ng-template pTemplate="body" let-game>
              <tr style="border: solid 1px">
                <td style="border: solid 1px">{{ game.competition_name }}</td>
                <td style="border: solid 1px">{{ game.commanding_team }}</td>
                <td
                  *ngIf="game.commanding_score < game.visiting_score"
                  style="
                    border: solid 1px;
                    color: white;
                    background-color: blue;
                    text-align: center;
                    width: 15%;
                  "
                >
                  {{ game.commanding_score }} X {{ game.visiting_score }}
                </td>
                <td
                  *ngIf="game.commanding_score > game.visiting_score"
                  style="
                    border: solid 1px;
                    color: white;
                    background-color: red;
                    text-align: center;
                    width: 15%;
                  "
                >
                  {{ game.commanding_score }} X {{ game.visiting_score }}
                </td>
                <td
                  *ngIf="game.commanding_score == game.visiting_score"
                  style="
                    border: solid 1px;
                    color: white;
                    background-color: gray;
                    text-align: center;
                    width: 15%;
                  "
                >
                  {{ game.commanding_score }} X {{ game.visiting_score }}
                </td>
                <td style="border: solid 1px">{{ game.visiting_team }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div></div>
      </div>
      <br />
      <p-table>
        <ng-template pTemplate="header">
          <tr style="border: solid 1px">
            <th style="border: solid 1px">J</th>
            <th style="border: solid 1px">P</th>
            <th style="border: solid 1px">V</th>
            <th style="border: solid 1px">E</th>
            <th style="border: solid 1px">D</th>
            <th style="border: solid 1px">GP</th>
            <th style="border: solid 1px">GC</th>
            <th style="border: solid 1px">SG</th>
            <th style="border: solid 1px">%</th>
          </tr>
          <tr>
            <td style="border: solid 1px">{{ response.J }}</td>
            <td style="border: solid 1px">{{ response.P }}</td>
            <td style="border: solid 1px">{{ response.V }}</td>
            <td style="border: solid 1px">{{ response.E }}</td>
            <td style="border: solid 1px">{{ response.D }}</td>
            <td style="border: solid 1px">{{ response.GP }}</td>
            <td style="border: solid 1px">{{ response.GC }}</td>
            <td style="border: solid 1px">{{ response.SG }}</td>
            <td style="border: solid 1px">{{ response["%"] }}</td>
          </tr>
        </ng-template>
      </p-table>
   

