import { Injectable } from '@angular/core';
import { Post } from './post';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PostCRUDService {
  url = localStorage.host + '/posts/';
  departmentsUrl = localStorage.host + '/departments/';

  constructor(private http: HttpClient) { }

  public getPosts(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url, { headers: header });
  }

  public getPostsSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <Post[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public getPostsWithOrdersSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <Post[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public postPost(post: any): Observable<any> {
    console.log(post);

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url, post, { headers: header });
  }

  public putPost(post: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.put<any>(this.url + post.id + '/', post, {
      headers: header,
    });
  }

  public deletePost(post: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.delete<any>(this.url + post.id + '/', {
      headers: header,
    });
  }

  public getDepartments(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.departmentsUrl, { headers: header });
  }
}
