import { Component, OnInit, Input } from '@angular/core';
import { TransferByLoanService } from './transfer-by-loan.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-transfer-by-loan',
  templateUrl: './transfer-by-loan.component.html',
  styleUrls: ['./transfer-by-loan.component.scss'],
  providers: [TransferByLoanService, MessageService, ConfirmationService],
})
export class TransferByLoanComponent implements OnInit {
  @Input() profileId: any;
  @Input() contractId: any;
  date1: Date;
  date2: Date;
  submitted: any;
  contract: any;
  selectedTeam: any;
  teams: any[];

  constructor(
    private transferByLoanService: TransferByLoanService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listTeams();
  }

  public listTeams() {
    this.transferByLoanService.getTeams().subscribe(
      (response) => {
        this.teams = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar times.',
          life: 3000,
        });
      }
    );
  }

  submitForm() {
    this.submitted = true;
    let data = {};
    data['player_id'] = this.profileId;
    data['team_id'] = this.selectedTeam.id;
    data['category_id'] = localStorage.category_id;
    data['modality_id'] = localStorage.modality_id;
    data['contract_id'] = this.contractId;
    data['transfer_type'] = 'loan';
    data['start'] = this.date1;
    data['end'] = this.date2;
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja Emprestar o jogador?',
      header: 'Transferir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.transferByLoanService.transferPlayer(data).subscribe(
          (response) => {
            this.listTeams();
            this.messageService.add({
              severity: 'info',
              summary: 'Transferido',
              detail: 'O jogador foi emprestado com sucesso.',
              life: 3000,
            });
            window.location.reload()
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Houve algum erro ao emprestar jogador.',
              life: 3000,
            });
          }
        );
      },
    });
  }
}
