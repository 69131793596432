import { Component, OnInit, Input } from '@angular/core';
import { ContractRenewalService } from './contract-renewal.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-contract-renewal',
  templateUrl: './contract-renewal.component.html',
  styleUrls: ['./contract-renewal.component.scss'],
  providers: [ContractRenewalService, MessageService, ConfirmationService],
})
export class ContractRenewalComponent implements OnInit {
  @Input() profileId: any;
  @Input() contractId: any;
  submitted: any;
  contract: any;
  date1: Date;
  date2: Date;

  constructor(
    private contractRenewalService: ContractRenewalService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {}

  submitForm() {
    this.submitted = true;
    let data = {};
    data['player_id'] = this.profileId;
    data['category_id'] = localStorage.category_id;
    data['modality_id'] = localStorage.modality_id;
    data['contract_id'] = this.contractId;
    data['transfer_type'] = 'sell';
    data['start'] = this.date1;
    data['end'] = this.date2;
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja renovar contrato?',
      header: 'Renovar?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.contractRenewalService.renewContract(data).subscribe(
          (response) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Renovado',
              detail: 'O contrato foi renovado com sucesso.',
              life: 3000,
            });
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Houve algum erro ao renovar contrato.',
              life: 3000,
            });
          }
        );
      },
    });
  }
}
