import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ReportAtividadesService {
  urlDp = localStorage.host + '/dp-atividade-fem/';
  urlAtividadeDp = localStorage.host + '/tipo-atividade-fem/?dpId=';
  urlFullCast = localStorage.host + '/full_cast/'
  urlReport = localStorage.host + '/report-atividade/'
  constructor(private http: HttpClient) { }

  public getDpFem(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.urlDp
    );
  }

  public getCast() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.urlFullCast
      + "?category_id=" + localStorage.category_id
      + "&modality_id=" + localStorage.modality_id
      + "&team_id=1", { headers: header })
  }

  public getDpAtividade(dpId): Observable<any> {
    console.log(this.urlDp)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.urlAtividadeDp + dpId, { headers: header }
    );
  }

  getRport(form) {

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post(
      this.urlReport, form, { headers: header });
  }

}
