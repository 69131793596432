<p-toast></p-toast>
<div class="filters-container" style="margin: 5%">
  <h2>Relatório de Escalação</h2>
  <div style="display: flex; float: left; width: 100%">
    <div>
      <h5>Equipe</h5>
      <p-dropdown
        [options]="teams"
        [(ngModel)]="selectedTeam"
        optionLabel="apelido"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o equipe"
      >
        <ng-template pTemplate="selectedItem">
          <div class="team-item team-item-value" *ngIf="selectedTeam">
            <div>{{ selectedTeam.apelido }}</div>
          </div>
        </ng-template>
        <ng-template let-team pTemplate="item">
          <div class="team-item">
            <div>{{ team.apelido }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <div style="margin-top: -19%; margin-left: 100%;">
        <button
          pButton
          pRipple
          icon="pi pi-search"
          class="p-button-text"
          (click)="listCast()"
        ></button>
    </div>
    </div>
  </div>
  <hr />
</div>

<div
  style="
    margin-top: 5%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 10% !important;
  "
>
  <h1 *ngIf="!response">Nenhuma equipe selecionada</h1>
  <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
    <p-progressSpinner
      [style]="{
        width: '100%',
        height: '70px',
        marginTop: '20%',
        marginBottom: '20%'
      }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <div
  *ngIf="response" 
>
    <button
        pButton
        pRipple
        icon="pi pi-download"
        (click)="  printPdf()"
        class="p-button-rounded crud-button"
        style="
          color: white !important;
          background-color: #000000 !important;
          margin: 2px 10px 0 0;
          width: 37px;
          height: auto;    
        "
    ></button>
  </div>

  <div
    style="width: 100%; margin-left:2%; margin-top: 0%"
    *ngIf="response" #dt
  >
    <div
      style="
        width: 100%;
        background-color: black;
        color: white;
        display: flex;
        float: left;
        justify-content: space-between;
        border-bottom: solid 1px white;
        align-items: center;
        margin-top: 1%;
        
      "
    >
      <div><img style="width: 80px" src="{{ selectedTeam.brasao }}" /></div>
      <div style="text-align: center">
        <div style="font-weight: bold">{{ selectedTeam.apelido }}</div>
        <div>{{ selectedTeam.nome }}</div>
      </div>
      <div class="font-logo"></div>
    </div>
    <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px',
          marginTop: '20%',
          marginBottom: '20%'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>
    <div *ngIf="!inProgress" style="width: 100%; display: flex; float: left">
      <div
        style="width: 20%; border: solid; background-color: white"
        *ngFor="let game of response; let i = index"
      >
        <div class="game-{{ i }}">
          <h6 style="width: 100%; text-align: center">{{ game.start | date }}</h6>
          <h6 style="width: 100%; text-align: center">
            <b>{{ game.game_name }}</b>
          </h6>
          <h6 style="width: 100%; text-align: center">
            {{ game.competition_name }}
          </h6>
          <div style="margin-left: -19%;">
            <app-arena
              teamId="{{ selectedTeam.id }}"
              gameId="{{ game.id }}"
            ></app-arena>
          </div>
          <div>
            <h4
              style="
                width: 100%;
                text-align: center;
                margin-top: auto;
                margin-bottom: auto;
              "
              *ngIf="game.cast.length == 0"
            >
              Não Escalado
            </h4>
            <div style="display: flex; float: left; width: 100%">
              <p-table
                *ngIf="game.cast.length > 0"
                [value]="game.cast"
                responsiveLayout="scroll"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th style="padding: 0px">TITULARES</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-player>
                  <tr>
                    <td
                      style="
                        width: 40% !important;
                        padding: 0px;
                        font-size: x-small;
                        background-color: #939fc9;
                      "
                      *ngIf="player.status === 'TIT'"
                    >
                      {{ player.number }} {{ player.surname }}
                      <b *ngFor="let goal of player.goals"
                        ><img
                          class="activit-icon"
                          src="/assets/imgs/fi_ball.png"
                      /></b>
                      <b *ngFor="let card of player.cards"
                        ><img
                          *ngIf="card.color === 'A'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-yellow.png" /><img
                          *ngIf="card.color === 'V'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-red.png" /><img
                          *ngIf="card.color === 'D'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-red.png" /><img
                          *ngIf="card.color === 'B'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-black.png" /><img
                          *ngIf="card.color === 'G'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-grey.png"
                      /></b>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <p-table
                *ngIf="game.cast.length > 0"
                [value]="game.cast"
                responsiveLayout="scroll"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th style="padding: 0px">RESERVAS</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-player>
                  <tr>
                    <td
                      style="
                        width: 40% !important;
                        padding: 0px;
                        font-size: x-small;
                        background-color: #d7dee5;
                      "
                      *ngIf="
                        !(player.status == 'TIT') &&
                        !(player.status == 'DM') &&
                        !(player.status == 'SCA')
                      "
                    >
                      {{ player.number }} {{ player.surname }}
                      <b *ngFor="let goal of player.goals"
                        ><img
                          class="activit-icon"
                          src="/assets/imgs/fi_ball.png"
                      /></b>
                      <b *ngFor="let card of player.cards"
                        ><img
                          *ngIf="card.color === 'A'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-yellow.png" /><img
                          *ngIf="card.color === 'V'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-red.png" /><img
                          *ngIf="card.color === 'D'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-red.png" /><img
                          *ngIf="card.color === 'B'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-black.png" /><img
                          *ngIf="card.color === 'G'"
                          class="activit-icon"
                          src="/assets/imgs/fi_card-grey.png"
                      /></b>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <h4
              *ngIf="game.cast.length > 0"
              style="width: 100%; text-align: center; padding: 0px"
            >
              <b *ngIf="game.cast.length > 0">SUBSTITUIÇÕES</b>
            </h4>
            <p-table
              [value]="game.changes"
              responsiveLayout="scroll"
              *ngIf="game.cast.length > 0"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th></th>
                  <th>
                    <i
                      class="pi pi-arrow-down"
                      style="padding: 0px; color: red; font-size: 1rem"
                    ></i>
                  </th>
                  <th>
                    <i
                      class="pi pi-arrow-up"
                      style="padding: 0px; color: blue; font-size: 1rem"
                    ></i>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-change>
                <tr>
                  <td
                    style="
                      width: 40% !important;
                      padding: 0px;
                      font-size: x-small;
                    "
                  >
                    {{ change.time }}'
                  </td>
                  <td
                    style="
                      width: 40% !important;
                      padding: 0px;
                      font-size: x-small;
                    "
                  >
                    {{ change.output_player }}
                  </td>
                  <td
                    style="
                      width: 40% !important;
                      padding: 0px;
                      font-size: x-small;
                    "
                  >
                    {{ change.input_player }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <h4
              *ngIf="game.cast.length > 0"
              style="width: 50%; text-align: center; padding: 0px;  font-size: x-small"
            >
              <b style="padding: 0px; font-size: x-small">TREINADOR:</b> 
              <b>{{ [game.coach] }}</b>
            </h4>
            <b style="padding: 0px; font-size: x-small">Indisponiveis:</b>
            <span *ngFor="let player of game.cast">
              <span
                *ngIf="player.status === 'DM'"
                style="
                  width: 50%;
                  margin-left: 5%;
                  text-align: center;
                  padding: 0px;
                  font-size: x-small;
                "
              >
               <b> {{ player.surname }}; </b>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
