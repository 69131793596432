/**
 * Core Modules nd Components
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';

/**
 *  Login
 */
import { LoginModule } from './app-template/pages/login/login.module';
import { LoginRoutingModule } from './app-template/pages/login/login.routing.module';

/**
 *  Prime NG
 */

import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TableModule } from 'primeng/table';
import { MenubarModule } from 'primeng/menubar';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { LoginComponent } from './app-template/pages/login/login.component';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { CardModule } from 'primeng/card';
import { SlideMenuModule } from 'primeng/slidemenu';
import { GalleriaModule } from 'primeng/galleria';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TimelineModule } from 'primeng/timeline';
import { EditorModule } from 'primeng/editor';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { SliderModule } from 'primeng/slider';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { ChartModule } from 'primeng/chart';
import { SplitterModule } from 'primeng/splitter';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TabMenuModule } from 'primeng/tabmenu';
import { TagModule } from 'primeng/tag';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmationService } from 'primeng/api';
import {ToggleButtonModule} from 'primeng/togglebutton';

// fullcalendar
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
]);

/**
 *   Extra Angular modules
 */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { HomeComponent } from './app-template/pages/home/home.component';
import { HomeRoutingModule } from './app-template/pages/home/home.routing.module';
import { UsuariosRountingModule } from './app-template/usuarios/usuarios.routing.module';
import { UsuariosComponent } from './app-template/usuarios/usuarios.component';
import { PostsComponent } from './app-template/components/posts/posts.component';
import { SideMenuComponent } from './app-template/components/side-menu/side-menu.component';
import { MainPostsComponent } from './app-template/components/main-posts/main-posts.component';
import { DepartmentRoutingModule } from './app-template/pages/department/department.routing.module';
import { DepartmentComponent } from './app-template/pages/department/department.component';
import { DepartmentMenuComponent } from './app-template/components/department-menu/department-menu.component';
import { MenubarComponent } from './app-template/components/menubar/menubar.component';
import { SearchFieldComponent } from './app-template/components/search-field/search-field.component';
import { SearchFieldPipe } from './app-template/components/search-field/search-field.pipe';
import { SearchResultComponent } from './app-template/pages/search-result/search-result.component';
import { SearchResultRoutingModule } from './app-template/pages/search-result/search-result.routing.module';
import { SearchResultModule } from './app-template/pages/search-result/search-result.module';
import { SearchResultListComponent } from './app-template/components/search-result-list/search-result-list.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { GenericCRUDComponent } from './app-template/pages/generic-crud/generic-crud.component';
import { GenericCRUDRoutingModule } from './app-template/pages/generic-crud/generic-crud.routing.module';
import { GenericCRUDModule } from './app-template/pages/generic-crud/generic-crud.module';
import { DepartmentCRUDComponent } from './app-template/pages/department-crud/department-crud.component';
import { DepartmentCRUDRoutingModule } from './app-template/pages/department-crud/department-crud.routing.module';
import { DepartmentCRUDModule } from './app-template/pages/department-crud/department-crud.module';
import { PostCRUDComponent } from './app-template/pages/post-crud/post-crud.component';
import { PostCRUDRoutingModule } from './app-template/pages/post-crud/post-crud.routing.module';
import { PostCRUDModule } from './app-template/pages/post-crud/post-crud.module';
import { FavoriteAppsComponent } from './app-template/components/favorite-apps/favorite-apps.component';
import { SelectModalityComponent } from './app-template/pages/select-modality/select-modality.component';
import { SelectModalityModule } from './app-template/pages/select-modality/select-modality.module';
import { SelectModalityRoutingModule } from './app-template/pages/select-modality/select-modality.routing.module';
import { ConfiguracoesComponent } from './app-template/pages/configuracoes/configuracoes.component';
import { DashboardComponent } from './app-template/pages/dashboard/dashboard.component';
import { DashboardModule } from './app-template/pages/dashboard/dashboard.module';
import { DashboardRoutingModule } from './app-template/pages/dashboard/dashboard.routing.module';
import { CompetitionTableComponent } from './app-template/components/dashboard/competition-table/competition-table.component';
import { CastTableComponent } from './app-template/components/dashboard/cast-table/cast-table.component';
import { NextPreviousGameComponent } from './app-template/components/dashboard/next-previous-game/next-previous-game.component';
import { RoundGamesComponent } from './app-template/components/dashboard/round-games/round-games.component';
import { UsageChartsComponent } from './app-template/components/dashboard/usage-charts/usage-charts.component';
import { DashCalendarComponent } from './app-template/components/dashboard/dash-calendar/dash-calendar.component';
import { CompetitionsComponent } from './app-template/components/dashboard/competitions/competitions.component';
import { ConfiguracoesRoutingModule } from './app-template/pages/configuracoes/configuracoes-routing.module';
import { ProfileModule } from './app-template/pages/profile/profile.module';
import { TeamModule } from './app-template/pages/teams/teams.module';
import { TeamRoutingModule } from './app-template/pages/teams/teams.routing.module';
import { ProfileRoutingModule } from './app-template/pages/profile/profile.routing.module';
import { ExplorerRoutingModule } from './app-template/pages/explorer/explorer.routing.module';
import { ExplorerModule } from './app-template/pages/explorer/explorer.module';
import { GamesRoutingModule } from './app-template/pages/games/games.routing.module';
import { GamesModule } from './app-template/pages/games/games.module';
import { GamesCRUDModule } from './app-template/pages/games-crud/games-crud.module';
import { GamesCRUDRoutingModule } from './app-template/pages/games-crud/games-crud.routing.module';
import { SetCastModule } from './app-template/pages/set-cast/set-cast.module';
import { SetCastRoutingModule } from './app-template/pages/set-cast/set-cast.routing.module';
import { ElencoModule } from './app-template/pages/elenco/elenco.module';
import { ElencoRoutingModule } from './app-template/pages/elenco/elenco.routing.module';
import { ReportsModule } from './app-template/pages/reports/reports.module';
import { ReportsRoutingModule } from './app-template/pages/reports/reports.routing.module';
import { PeopleCRUDModule } from './app-template/pages/people-crud/people-crud.module';
import { PeopleCRUDRoutingModule } from './app-template/pages/people-crud/people-crud.routing.module';
import { TeamsCRUDRoutingModule } from './app-template/pages/teams-crud/teams-crud.routing.module';
import { FemininoModule } from './app-template/pages/feminino/feminino.module';
import { FemininoRoutingModule } from './app-template/pages/feminino/feminino.routing.module';
import { DpmedicoRoutingModule } from './app-template/pages/dpmedico/dpmedico.routing.module';
import { DpmedicoModule } from './app-template/pages/dpmedico/dpmedico.module';
// import { ConfiguracoesComponent } from './app-template/pages/configuracoes/configuracoes.component';

/***
 * APPs de  Configurações
 */

import { CategoriaComponent } from './app-template/pages/configuracoes/categoria/categoria.component';
import { DespublicadorComponent } from './app-template/pages/configuracoes/despublicador/despublicador.component';
import { EstuturasCorpoComponent } from './app-template/pages/configuracoes/estruturas-corpo/estruturas-corpo.component';
import { FuncaoFutebolComponent } from './app-template/pages/configuracoes/funcao-futebol/funcao-futebol.component';
import { GruposComponent } from './app-template/pages/configuracoes/grupos/grupos.component';
import { IndicesAvaliacaoComponent } from './app-template/pages/configuracoes/indices-avaliacao/indices-avaliacao.component';
import { LocaisComponent } from './app-template/pages/configuracoes/locais/locais.component';
import { LocaisCorpoComponent } from './app-template/pages/configuracoes/locais-corpo/locais-corpo.component';
import { MecanismosComponent } from './app-template/pages/configuracoes/mecanismos/mecanismos.component';
import { MoedasComponent } from './app-template/pages/configuracoes/moedas/moedas.component';
import { MotivoCartaoComponent } from './app-template/pages/configuracoes/motivo-cartao/motivo-cartao.component';
import { MultasComponent } from './app-template/pages/configuracoes/multas/multas.component';
import { ParametroJogadorComponent } from './app-template/pages/configuracoes/parametro-jogador/parametro-jogador.component';
import { PosicoesComponent } from './app-template/pages/configuracoes/posicoes/posicoes.component';
import { RateioPremiacaoComponent } from './app-template/pages/configuracoes/rateio-premiacao/rateio-premiacao.component';

import { ReferenciaCargaComponent } from './app-template/pages/configuracoes/referencia-carga/referencia-carga.component';
import { SeveridadesComponent } from './app-template/pages/configuracoes/severidades/severidades.component';
import { TipoEventoComponent } from './app-template/pages/configuracoes/tipo-evento/tipo-evento.component';
import { ProfileComponent } from './app-template/pages/profile/profile.component';
import { PessoasComponent } from './app-template/pages/entidades/pessoas/pessoas.component';
import { EntidadesRoutingModule } from './app-template/pages/entidades/entidades-routing.module';
import { PlayerTimelineComponent } from './app-template/components/profile/player-timeline/player-timeline.component';
import { TeamTimelineComponent } from './app-template/components/team/team-timeline/team-timeline.component';
import { ResumoComponent } from './app-template/components/team/Resumo/resumo.component';
import { PlayerSummaryComponent } from './app-template/components/profile/player-summary/player-summary.component';
import { ContractsComponent } from './app-template/components/profile/contracts/contracts.component';
import { PlayerRemarksComponent } from './app-template/components/profile/player-remarks/player-remarks.component';
import { OccurrencesComponent } from './app-template/components/profile/occurrences/occurrences.component';
import { AssessmentsComponent } from './app-template/components/profile/assessments/assessments.component';
import { PhysicalPlayerSummaryComponent } from './app-template/components/profile/physical-player-summary/physical-player-summary.component';
import { ProfileCrudComponent } from './app-template/components/profile/profile-crud/profile-crud.component';
import { ExplorerComponent } from './app-template/pages/explorer/explorer.component';
import { EquipesComponent } from './app-template/pages/entidades/equipes/equipes.component';
import { GamesComponent } from './app-template/pages/games/games.component';
import { ClipboardComponent } from './app-template/components/games/clipboard/clipboard.component';
import { GamesCRUDComponent } from './app-template/pages/games-crud/games-crud.component';
import { PlayersSelectComponent } from './app-template/components/games/players-select/players-select.component';
import { DragAndDropMainCastComponent } from './app-template/components/games/drag-and-drop-main-cast/drag-and-drop-main-cast.component';
import { SetCastComponent } from './app-template/pages/set-cast/set-cast.component';
import { PlayerCrudComponent } from './app-template/components/set-cast/player-crud/player-crud.component';
import { ElencoComponent } from './app-template/pages/elenco/elenco.component';
import { ContractRenewalComponent } from './app-template/components/set-cast/contract-renewal/contract-renewal.component';
import { TransferComponent } from './app-template/components/set-cast/transfer/transfer.component';
import { TransferByLoanComponent } from './app-template/components/set-cast/transfer-by-loan/transfer-by-loan.component';
import { ReportsComponent } from './app-template/pages/reports/reports.component';
import { CastReportComponent } from './app-template/components/reports/cast-report/cast-report.component';
import { LatestLineupsReportComponent } from './app-template/components/reports/latest-lineups-report/latest-lineups-report.component';
import { PerformanceReportComponent } from './app-template/components/reports/performance-report/performance-report.component';
import { PeopleCRUDComponent } from './app-template/pages/people-crud/people-crud.component';
import { ArenaComponent } from './app-template/components/arena/arena.component';
import { ProvavelEscalacaoComponent } from './app-template/components/reports/provavel-escalacao/provavel-escalacao.component';
import { ActivitiesCrudComponent } from './app-template/components/activities-crud/activities-crud.component';
import { TeamsCRUDComponent } from './app-template/pages/teams-crud/teams-crud.component';
import { AddSoccerPlayerComponent } from './app-template/components/set-cast/add-soccer-player/add-soccer-player.component';
import { NewSoccerPlayerComponent } from './app-template/components/set-cast/new-soccer-player/new-soccer-player.component';
import { DragDropModule }  from '@angular/cdk/drag-drop';
import { TeamComponent } from './app-template/pages/teams/teams.component';
import { PdfCastComponent } from './app-template/components/reports/pdf-elenco/pdf-cast.component';
import { CaracteristicaComponent } from './app-template/components/team/caracteristica/caracteristica.component';  
import { CartoesComponent } from './app-template/components/team/cartoes/cartoes.component';
import { ElencoTeamComponent } from './app-template/components/team/elenco/elenco.component';
import { CastReportSimpleComponent } from './app-template/components/reports/pdf-elenco-simples/cast-report-simple.component';
import { CampeonatosComponent } from './app-template/pages/campeonatos-crud/campeonatos.component';
import { CampeonatosModule } from './app-template/pages/campeonatos-crud/campeonatosrouting.module';
import { FemininoComponent } from './app-template/pages/feminino/feminino.component';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { DpmedicoComponent } from './app-template/pages/dpmedico/dpmedico.component';
import { ReportAtividadesComponent } from './app-template/components/reports/report-atividades/report-atividades.component';
registerLocaleData(ptBr)

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    UsuariosComponent,
    PostsComponent,
    SideMenuComponent,
    MainPostsComponent,
    DepartmentComponent,
    DepartmentMenuComponent,
    MenubarComponent,
    SearchFieldComponent,
    SearchFieldPipe,
    SearchResultComponent,
    SearchResultListComponent,
    GenericCRUDComponent,
    DepartmentCRUDComponent,
    PostCRUDComponent,
    FavoriteAppsComponent,
    SelectModalityComponent,
    ConfiguracoesComponent,
    DashboardComponent,
    CompetitionTableComponent,
    CastTableComponent,
    NextPreviousGameComponent,
    RoundGamesComponent,
    UsageChartsComponent,
    DashCalendarComponent,
    CompetitionsComponent,
    CategoriaComponent,
    DespublicadorComponent,
    EstuturasCorpoComponent,
    FuncaoFutebolComponent,
    GruposComponent,
    IndicesAvaliacaoComponent,
    LocaisComponent,
    LocaisCorpoComponent,
    MecanismosComponent,
    MoedasComponent,
    MotivoCartaoComponent,
    MultasComponent,
    ParametroJogadorComponent,
    PosicoesComponent,
    RateioPremiacaoComponent,
    ReferenciaCargaComponent,
    SeveridadesComponent,
    TipoEventoComponent,
    ProfileComponent,
    PessoasComponent,
    PlayerTimelineComponent,
    PlayerSummaryComponent,
    ContractsComponent,
    PlayerRemarksComponent,
    OccurrencesComponent,
    AssessmentsComponent,
    PhysicalPlayerSummaryComponent,
    ProfileCrudComponent,
    ExplorerComponent,
    EquipesComponent,
    GamesComponent,
    ClipboardComponent,
    GamesCRUDComponent,
    PlayersSelectComponent,
    DragAndDropMainCastComponent,
    SetCastComponent,
    ElencoComponent,
    PlayerCrudComponent,
    ContractRenewalComponent,
    TransferComponent,
    TransferByLoanComponent,
    ReportsComponent,
    CastReportComponent,
    LatestLineupsReportComponent,
    PerformanceReportComponent,
    PeopleCRUDComponent,
    ArenaComponent,
    ActivitiesCrudComponent,
    TeamsCRUDComponent,
    AddSoccerPlayerComponent,
    NewSoccerPlayerComponent,
    TeamComponent,
    TeamTimelineComponent,
    ResumoComponent,
    PdfCastComponent,
    CaracteristicaComponent,
    CartoesComponent,
    ElencoTeamComponent,
    ProvavelEscalacaoComponent,
    CastReportSimpleComponent,
    CampeonatosComponent,
    FemininoComponent,
    DpmedicoComponent,
    ReportAtividadesComponent,

 
    

  ],
  imports: [
    ToggleButtonModule,
    CommonModule,
    BrowserModule,
    ButtonModule,
    PasswordModule,
    AccordionModule,
    TableModule,
    TagModule,
    PanelModule,
    PanelMenuModule,
    MenubarModule,
    ColorPickerModule,
    LoginModule,
    LoginRoutingModule,
    HomeRoutingModule,
    DepartmentRoutingModule,
    GenericCRUDRoutingModule,
    GenericCRUDModule,
    DepartmentCRUDRoutingModule,
    DepartmentCRUDModule,
    PostCRUDRoutingModule,
    PostCRUDModule,
    ProfileModule,
    ProfileRoutingModule,
    TeamModule,
    SearchResultRoutingModule,
    SearchResultModule,
    SelectModalityModule,
    SelectModalityRoutingModule,
    DashboardModule,
    DashboardRoutingModule,
    DialogModule,
    AppRoutingModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    BrowserAnimationsModule,
    UsuariosRountingModule,
    ReactiveFormsModule,
    FormsModule,
    DividerModule,
    MenuModule,
    CalendarModule,
    CardModule,
    FieldsetModule,
    HttpClientModule,
    SlideMenuModule,
    GalleriaModule,
    DividerModule,
    VirtualScrollerModule,
    SelectButtonModule,
    TimelineModule,
    EditorModule,
    RadioButtonModule,
    MultiSelectModule,
    ToastModule,
    MessagesModule,
    TeamRoutingModule,
    MessageModule,
    ProgressSpinnerModule,
    SliderModule,
    ContextMenuModule,
    DropdownModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ListboxModule,
    ChartModule,
    TabViewModule,
    ConfiguracoesRoutingModule,
    EntidadesRoutingModule,
    SplitterModule,
    FullCalendarModule,
    CheckboxModule,
    SidebarModule,
    AvatarModule,
    AvatarGroupModule,
    AccordionModule,
    ExplorerRoutingModule,
    ExplorerModule,
    GamesRoutingModule,
    GamesModule,
    GamesCRUDModule,
    GamesCRUDRoutingModule,
    SetCastModule,
    SetCastRoutingModule,
    ElencoModule,
    ElencoRoutingModule,
    ReportsModule,
    ReportsRoutingModule,
    TabMenuModule,
    PeopleCRUDModule,
    PeopleCRUDRoutingModule,
    TeamsCRUDRoutingModule,
    AppRoutingModule,
    DragDropModule,
    CampeonatosModule,
    FemininoModule,
    FemininoRoutingModule,
    DpmedicoRoutingModule,
    DpmedicoModule
    
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AppComponent, ConfirmationService,  {provide: LOCALE_ID, useValue: 'pt'}],
  bootstrap: [AppComponent],
})
export class AppModule {}
