<input type="text" [(ngModel)]="playerSearch">
<button (click)="listCast(0, pageFetch)" >Procurar</button>

<div *ngFor="let item of response">
    <p-table
      [columns]="[item.position]"
      [value]="item.players"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Foto
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Nome
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Altura
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Predominancia
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            País de origem
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            CBF
          </th>

          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Ogol
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Soccerway
          </th>
          <th style="font-size: smaller; padding: 1px; font-weight: 800">
            Transfermarkt
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="padding: 0px; font-size: x-small">
            <p-avatar image="{{ item.image }}"></p-avatar>
          </td>
          <td style="padding: 0px; font-size: x-small">
            <a href="#/profile/{{ item.id_people }}/">
              {{ item.surname }}
            </a>
          </td>
          <td style="padding: 0px; font-size: x-small">
            {{ item.height }}
          </td>
          <td style="padding: 0px; font-size: x-small">
            {{ item.side }}
          </td>
          <td style="padding: 0px; font-size: x-small">
            {{ item.origin }}
          </td>
          <td style="padding: 0px; font-size: x-small">
            {{ item.cbf }}
          </td>

          <td style="padding: 0px; font-size: x-small">
            {{ item.id_ogol }}
          </td>
          <td style="padding: 0px; font-size: x-small">
            {{ item.id_soccerway }}
          </td>
          <td style="padding: 0px; font-size: x-small">
            {{ item.id_transfermarkt }}
          </td>
          <td style="padding: 0px; font-size: x-small">
            <button
              pButton
              pRipple
              icon="pi pi-plus"
              class="p-button-rounded crud-button"
              style="
                color: white !important;
                background-color: rgb(0, 179, 255) !important;
              "
              (click)="showPlayerContracts(item)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <button
  *ngIf="pageCount > pageFetch"
  pButton
  pRipple
  icon="pi pi-arrow-left"
  class="p-button-rounded crud-button"
  style="
    color: white !important;
    background-color: black !important;
    float: left;
  "
  (click)="listCast(pageCount-(pageFetch *2), pageCount-pageFetch)"
></button>

  <button
  *ngIf="pageCount"
  pButton
  pRipple
  icon="pi pi-arrow-right"
  class="p-button-rounded crud-button"
  style="
    color: white !important;
    background-color: black !important;
    float: right;
  "
  (click)="listCast(pageCount, pageCount+pageFetch)"
></button>

<p-dialog
  [(visible)]="playerContracts"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Contratos ativos do jogador"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div *ngFor="let item of playerContracts">
      <p-table
        [columns]="[item.position]"
        [value]="item.contracts"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Brasao
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Clube
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Camisa
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Ativo
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Situaçao
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Inicio
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Termino
            </th>
            <th style="font-size: smaller; padding: 1px; font-weight: 800">
              Transferir/Emprestar
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td style="padding: 0px; font-size: x-small">
              <p-avatar image="{{ item.image }}"></p-avatar>
            </td>
            <td style="padding: 0px; font-size: x-small">
              <a href="#/profile/{{ item.id_people }}/">
                {{ item.club }}
              </a>
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.shirt }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.active ? 'SIM' : 'NAO' }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.situation }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.start }}
            </td>
            <td style="padding: 0px; font-size: x-small">
              {{ item.end }}
            </td>

            <td style="padding: 0px; font-size: x-small; text-align: center;"
            *ngIf="item.club_id != teamId">
              <button
                pButton
                pRipple
                icon="pi pi-ellipsis-h"
                class="p-button-rounded crud-button"
                style="
                  color: white !important;
                  background-color: blue !important;
                "
                (click)="transferInfo(item)"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="activeContract"
  [style]="{ width: '60%', minHeight: '100%' }"
  header="Adicione as informacoes do novo contrato"
  [modal]="true"
  styleClass="p-fluid"
>
<ng-template pTemplate="content">  
  <div style="height: 400px; display: flex; float: left" class="p-field p-col-12 p-md-4">
      <div style="width: 40%; margin-right:50%" class="p-calendar p-datepicker">
          <label for="inicio">Novo Inicio</label>
          <p-calendar style="top: 0 !important;" 
          [(ngModel)]="playerContractInfo.start" 
          view="date" dateFormat="dd/mm/yy" 
          [showIcon]="true" 
          [yearNavigator]="true" 
          yearRange="2000:3030" 
          inputId="inicio"></p-calendar>
          <!-- <small class="p-invalid" *ngIf="submitted && !contract.inicio"
            >Campo obrigatório.</small
          > -->
      </div>
      <div style="width: 40%; margin-left:50%"  class="p-calendar p-datepicker">
          <label for="termino">Novo Termino</label>
          <p-calendar style="top: 0 !important;" 
          [(ngModel)]="playerContractInfo.end" 
          view="date" dateFormat="dd/mm/yy" 
          [showIcon]="true" 
          [yearNavigator]="true" 
          yearRange="2000:3030" 
          inputId="termino"></p-calendar>
          <!-- <small class="p-invalid" *ngIf="submitted && !contract.termino"
            >Campo obrigatório.</small
          > -->
      </div>
  </div>
  
  <div 
  style="margin-top: 20%; display: flex" 
  [style]="!playerContracts ? 'justify-content: center;' : 'justify-content: space-between;'"
  *ngIf="!hideAddButton">
    <button
      pButton
      pRipple
      label="Transferir"
      class="p-button-text"
      (click)="addPlayer(activeContract, playerContractInfo, 'transferir')"
      style="width: 40%;"
    ></button>
    <button
    *ngIf="playerContracts"
    pButton
    pRipple
    label="Emprestar"
    class="p-button-text"
    (click)="addPlayer(activeContract, playerContractInfo, 'loan')"
    style="width: 40%;"
    ></button>
  </div>
</ng-template>

</p-dialog>