import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  items: any[];
  columns: any[]
  pdfLineUpDialog: boolean;
  desempenhoEquipeDialog: boolean;
  elencoCompletoDialog: boolean;
  provavelEscalacaoDialog: boolean;
  elencoSimplesDialog: boolean;
  
  constructor() {}
  ngOnInit(): void {
    
  }
  public pdfLineUp() {
    this.pdfLineUpDialog = true;
  }

  public pdfDesempenhoEquipe() {
    this.desempenhoEquipeDialog = true;
  }

  public pdfElencoCompleto() {
    this.elencoCompletoDialog = true;
  }

  public provavelEscalacao() {
    this.provavelEscalacaoDialog = true;
  }

  public elencoSimples() {
    this.elencoSimplesDialog = true;
  }


}

