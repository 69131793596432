<div>
    <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="form" method="post">   
        <b>Periodo:</b> 
        <p-calendar style="margin-left: 5px;" formControlName="inicio"   placeholder="Data Inicial"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
        <p-calendar style="  margin-left: 5px;" formControlName="final" placeholder="Data Final"  dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
        <p-divider></p-divider>   
        <b>Dados de Atividades:</b> 
        <p-dropdown style="margin-left: 5px;" formControlName="departamento" [options]="departamento" appendTo="body" [(ngModel)]="selectedDepartamento"  optionLabel="nome_dp" placeholder="Departamento(s)"></p-dropdown>
        <p-multiSelect style="margin-left: 5px;" formControlName="atividade" [options]="atividades" appendTo="body"  (click)="departamentoChange()" defaultLabel="Atividade(s)" optionLabel="atividade_nome" selectedItemsLabel="{0} items selected"></p-multiSelect>
        <p-multiSelect style="margin-left: 5px;" formControlName="atleta" [options]="cast" appendTo="body" placeholder="Aleta(s)" optionLabel="surname"  selectedItemsLabel="{0} items selected"></p-multiSelect>
        <p-divider></p-divider>   
    </form>
        <div>
            <b>Formato:</b> 
            <div>
                <input style="margin-left: 80px;" type="radio" id="formato" name="formato" value="pdf" [(ngModel)]="selectedTypeRport">
                <b>PDF</b>
            </div>
            <div>
                <input style="margin-left: 80px;" type="radio" id="formato" name="formato" value="xls"  [(ngModel)]="selectedTypeRport">
                <b>Excel</b>
            </div>
        </div>
    
    <footer><button style="margin-top: 2%; width: 100px; margin-left: 80%;" (click)="getReport()" pButton pRipple label="Gerar"> <i class="pi pi-spin pi-cog" style="font-size: 2rem"></i></button></footer>
</div>