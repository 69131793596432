import { Component, OnInit } from '@angular/core';
import { People } from './people';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { PeopleCRUDService } from './people-crud.service';

@Component({
  selector: 'app-people-crud',
  templateUrl: './people-crud.component.html',
  styleUrls: ['./people-crud.component.scss'],
  providers: [PeopleCRUDService, MessageService, ConfirmationService],
})
export class PeopleCRUDComponent implements OnInit {
  inProgress: boolean;

  peopleDialog: boolean;

  peoples: People[];

  people: People;

  selectedPeoples: People[];

  selectedPeople: any;

  submitted: boolean;

  verb: string;

  departmentOptions: any[];

  departments: any[];
  image: any;

  isRestrictedOptions: any[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];
  constructor(
    private peopleCRUDService: PeopleCRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listPeople();
  }

  public listPeople() {
    this.peopleCRUDService.getPeoples().subscribe(
      (response) => {
        this.peoples = response;
        console.log(response)
        
        this.inProgress = false;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar people.',
          life: 3000,
        });
      }
    );
  }

  cutUrl(url){
    if(url == null){
      this.image = null;
    }else{
    
      this.image = url.split('/')
      if(this.image[5]== "s3.sa-east-1.amazonaws.com"){
        return 'https://'+this.image[5]+'/'+this.image[6]+'/'+this.image[7]+'/'+this.image[8]
      }else if(this.image[4] == "https%3A" || this.image[4] == "http%3A"){
        return 'http://'+this.image[5]+'/'+this.image[6]+'/'+this.image[7]+'/'+this.image[8]
      }else{
        return url
      }
    }
  }
  
  openNew() {
    this.people = {};
    this.submitted = false;
    this.peopleDialog = true;
    this.verb = 'POST';
  }

  public deleteSelectedPeoples() {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir todas as pessoas selecionadas?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedPeoples.forEach((people) => this.deletePeople(people));
        this.selectedPeoples = null;
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          icon: 'success',
          detail: 'Pessoas excluídos.',
          life: 3000,
        });
      },
    });
  }

  editPeople(people: People) {
    this.people = { ...people };
    this.peopleDialog = true;
    this.verb = 'PUT';
  }

  showPeople(people: People) {
    this.people = { ...people };
    this.peopleDialog = true;
    this.verb = 'GET';
  }

  hideDialog() {
    this.peopleDialog = false;
    this.submitted = false;
  }

  submitForm() {
    if (this.verb === 'POST') {
      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    this.peopleCRUDService.postPeople(this.people).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Pessoa foi criado com sucesso.',
          life: 3000,
        });
        this.peoples = [...this.peoples];
        this.peopleDialog = false;
        this.people = {};
        this.listPeople();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar pessoa.',
          life: 3000,
        });
      }
    );
  }

  putForm() {
    this.peopleCRUDService.putPeople(this.people).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Pessoa editada com sucesso.',
          life: 3000,
        });
        this.peoples = [...this.peoples];
        this.peopleDialog = false;
        this.people = {};
        this.listPeople();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar pessoa.',
          life: 3000,
        });
      }
    );
  }

  deletePeople(people) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja excluir' + people.apelido + '?',
      header: 'Excluir?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        people.field_is_deleted = 1;
        this.peopleCRUDService.deletePeople(people).subscribe(
          (response) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Excluído',
              detail:
                'A pessoa ' + people.apelido + ' foi excluído com sucesso.',
              life: 3000,
            });
            this.people = {};
            this.listPeople();
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail:
                'Houve algum erro ao excluir a pessoa ' + people.apelido + '.',
              life: 3000,
            });
          }
        );
      },
    });
  }
}
