import { Injectable } from '@angular/core';

import { observable, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SetCastService {

  url = localStorage.host + '/full_cast/';
  url2 = localStorage.host + '/elenco_completo/';
  contractUrl = localStorage.host + '/contrato/';
  urlOgol = localStorage.host + '/ogol/';

  teamsUrl = localStorage.host + '/teams/';
  urlperfil = localStorage.host + '/perfil/';


  constructor(private http: HttpClient) { }

  public getCast(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id +
      '&team_id=' +
      teamId,
      { headers: header }
    );
  }

  public getCast2(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url2 +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id +
      '&team_id=' +
      teamId,
      { headers: header }
    );
  }

  public getTeams(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl, { headers: header });
  }

  public getContract(id: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get<any>(this.contractUrl + id + '/', {
      headers: header,
    });
  }

  public getProfile(profileId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get<any>(this.urlperfil + profileId + '/', {
      headers: header,
    });
  }

  public setContract(contract: any, id: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.contractUrl + id + '/', contract, {
      headers: header,
    });
  }

  public editProfile(profile: any, id: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.urlperfil + id + '/', profile, {
      headers: header,
    });
  }


  //Colocar tudo dentro de components de acordo com o padrao do projeto....
  public setUpdateHistory(id: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token,
    );
    return this.http.post(
      this.urlOgol + id,
      { headers: header },
      { responseType: 'text' }
    );
  }

}

