<app-menubar></app-menubar>
<div id="content_area" style="margin-top: 2%;">
    <div id="box_content">
        <div id="header_table">
            <h3>Lista de usuários</h3>
            <button class='btn btn-warning' (click)=" novoUsuario()">Novo</button>
        </div>
        <table class="row-border hover">
          <thead>
            <tr>
              <th>ID Usuário</th>
              <th>Usuário</th>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Tipo</th>
              <th>Nivel</th>
              <th>Modalidade</th>
              <th>Categoria</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let apsrol of rolaps;">
              <td style="text-align: left; font-size: small;">
                  {{apsrol.id}}
              </td>
              <td style="text-align: left; font-size: small">
                  {{apsrol.usuario}}
              </td>
              <td style="text-align: left; font-size: small">
                {{apsrol.nome}}
              </td>
              <td style="text-align: left; font-size: small">
                {{apsrol.email}}
              </td>
              <td>
                <p-dropdown [options]="tipo" optionLabel="name" filterBy="name" [showClear]="true" placeholder="{{apsrol.tipo}}"></p-dropdown>
              </td>
              <td>
                <p-dropdown [options]="nivel" optionLabel="name" filterBy="name" [showClear]="true" placeholder="{{apsrol.nivel}}"></p-dropdown>
              </td>
              <td>
                <p-dropdown  [options]="modalidade" optionLabel="nome" filterBy="nome" [showClear]="true" placeholder="{{apsrol.modalidade}}"></p-dropdown>
              </td>
              <td>
                <p-dropdown  [options]="categoria" optionLabel="nome" filterBy="nome" [showClear]="true" placeholder="{{apsrol.categoria}}"></p-dropdown>
              </td>
              <td>
                <button pButton  class="btn btn-primary"  (click)='getRegras(apsrol.id)' label="Regras"></button>
              </td>
              <td>
                  <button pButton (click)='atualizaUsuario()' class="btn btn-primary" label="Ver"></button>
              <td>
                  <button pButton (click)='deletarBox(apsrol.id)' class="btn btn-primary" label="Deletar"></button>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>
<div id='deletarbox' class='hidden'>
  <div id='deletarcontent'>
    <h1>Deseja mesmo cancelar?</h1>
    <div id='deletaroptions'>
      <button (click)='deletar()' class="btn btn-primary">Sim</button>
      <button (click)='cancelarDeletarBox();'  class="btn btn-danger" >Cancelar</button>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="regrasDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Regras de Usuario"
  [modal]="true"
  styleClass="p-fluid"
>
   
<div style="margin-left: 1%">
<div style="padding-bottom: 100%; margin-top: 5%;">
      <table >
        <thead>
          <tr>
            <th>Modalidade</th>
            <th>Categoria</th>
            <th>Módulo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of regras;">
            <td style="text-align: left; font-size: small;">
              {{item.modalidade}}
            </td>
            <td style="text-align: left; font-size: small">
              {{item.categoria}}
            </td>
            <td style="text-align: left; font-size: small">
              {{item.modulo}}
            </td>
            <td>
              <button pButton (click)='deletarBox(apsrol.id)' class="btn btn-primary" label="Deletar"></button>
            </td>
          </tr>
        </tbody>
      </table>
      <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="rules" method="post">
      <table>
        <thead>
          <tr>
            <th>Modalidade</th>
            <th>Categoria</th>
            <th>Módulo</th>
          </tr>
        </thead>
        
        <tbody>
          <tr>
            <td>
              <p-dropdown 
                inputId="modalidade"
                formControlName="modalidade" 
                [options]="modalidade" 
                [(ngModel)]="selectedModalidade"
                optionLabel="nome"
                placeholder=" ">
                
              </p-dropdown>
            </td>
            <td>
              <p-dropdown 
              *ngIf="selectedModalidade"
              (click)="listCategoria()"
              inputId="categoria" 
              formControlName ="categoria"
              [options]="categoria" 
              [(ngModel)]="selectedCategoria"
              placeholder=" "
              optionLabel="nome">
            </p-dropdown>
            </td>
            <td>
              <p-dropdown 
                *ngIf="selectedCategoria"
                inputId="modulo" 
                formControlName="modulo"
                [options]="modulo" 
                [(ngModel)]="selectedModulo"
                placeholder=" "
                optionLabel="name">
              </p-dropdown>
            </td>
            <td><button pButton class="btn btn-primary" (click)="saveAllCatgoriaRegra()" label="Adicionar p/ todas categorias"></button></td>
            <td><button pButton class="btn btn-primary" (click)="saveRegra()" label="Adicionar"></button></td>
          </tr>
        </tbody>
      </table>       
      </form>
    </div>
  </div>
 </p-dialog>

 <p-dialog
  [(visible)]="novoUsuarioDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Novo Usuario"
  [modal]="true"
  styleClass="p-fluid"
>

<div style="margin-left: 1%">
  <div style="padding-bottom: 100%; margin-top: 5%;">
    <div class="row">
      <div class="col-xl-9 col-lg-4">
          <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Criar novo usuário</h5>
          </div>
      </div>
    </div>
      <form [formGroup]="user">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input formControlName="nome" id="float-input" type="text" pInputText> 
                <label for="float-input">Nome</label>
              </span>
            </div>
            <div class="form-group" style="margin-top: 1%" >
              <span class="p-float-label">
                <input formControlName="sobrenome" id="float-input" type="text" pInputText> 
                <label for="float-input">Sobrenome</label>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input id="float-input"  formControlName="email" type="text" pInputText> 
                <label for="float-input">Email</label>
              </span>
            </div>
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input id="float-input" type="text"  formControlName="user" pInputText> 
                <label for="float-input">Nome de usuário</label>
            </span>
            </div>
          </div>
          <div class="form-group"style="margin-top: 1%">
            <p-dropdown 
              formControlName="tipo"
              [options]="tipo" 
              optionLabel="name" 
              filterBy="name" 
              [showClear]="true"
              placeholder="Selecione um Tipo">
            </p-dropdown>
          </div>
          <div class="form-group"style="margin-top: 1%">
            <p-dropdown 
              formControlName="nivel"
              [options]="nivel" 
              optionLabel="name" 
              filterBy="name" 
              [showClear]="true"
              placeholder="Selecione um Nível">
           </p-dropdown>
          </div>
          <div class="form-group"style="margin-top: 1%">
            <p-dropdown
              formControlName="categoria"  
              [options]="categoria" 
              optionLabel="nome" filterBy="nome" 
              [showClear]="true"
              placeholder="Selecione uma Categoria">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input formControlName="senha" id="float-input" type="text" pInputText> 
                <label for="float-input">Senha</label>
              </span>
            </div>
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label" >
                <input formControlName="re_senha" id="float-input" type="text" pInputText> 
                <label for="float-input">Repetir senha</label>
              </span>
            </div>
          </div>
        </div>
        <button pButton (click)="saveUsuario()" class="btn btn-primary" label="Salvar" style="margin-top: 1%"></button>
      </form>
  </div>
</div>
<div id='deletarbox' class='hidden'>
<div id='deletarcontent'>
  <h1>Deseja salvar?</h1>
  <div id='deletaroptions'>
    <button  class="btn btn-primary">Sim</button>
    <button  class="btn btn-danger" >Cancelar</button>
  </div>
</div>
</div>
</p-dialog>


<p-dialog
[(visible)]="atualizaUsuarioDialog"
[style]="{ width: '90%', minHeight: '100%' }"
header="Alteração de Usuario"
[modal]="true"
styleClass="p-fluid"
>
<div style="margin-left: 1%">
  <div style="padding-bottom: 100%; margin-top: 5%;">
    <div class="row">
      <div class="col-xl-9 col-lg-4">
          <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Atualizar usuário</h5>
          </div>
      </div>
    </div>
      <form>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input id="float-input" type="text" pInputText> 
                <label for="float-input">Nome</label>
              </span>
            </div>
            <div class="form-group" style="margin-top: 1%" >
              <span class="p-float-label">
                <input id="float-input" type="text" pInputText> 
                <label for="float-input">Sobrenome</label>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input id="float-input" type="text" pInputText> 
                <label for="float-input">Email</label>
              </span>
            </div>
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input id="float-input" type="text" pInputText> 
                <label for="float-input">Nome de usuário</label>
            </span>
            </div>
          </div>
          <div class="form-group"style="margin-top: 1%">
            <p-dropdown   
              name="usergroup" 
              id="usergroup" 
              class="form-control" 
              placeholder="Selecione um grupo">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label">
                <input id="float-input" type="text" pInputText> 
                <label for="float-input">Senha</label>
              </span>
            </div>
            <div class="form-group" style="margin-top: 1%">
              <span class="p-float-label" >
                <input id="float-input" type="text" pInputText> 
                <label for="float-input">Repetir senha</label>
              </span>
            </div>
          </div>
        </div>
        <button pButton  (click)="saveUsuario()" class="btn btn-primary" label="Salvar" style="margin-top: 1%">
        </button>
      </form>
  </div>
</div>
<div id='deletarbox' class='hidden'>
<div id='deletarcontent'>
  <h1>Deseja atualizar?</h1>
  <div id='deletaroptions'>
    <button class="btn btn-primary">Sim</button>
    <button   class="btn btn-danger" >Cancelar</button>
  </div>
</div>
</div>
</p-dialog>