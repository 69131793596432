<p>Você está prestes a mover esse jogador para uma nova equipe. O contrato atual será desativado.</p>

<p-toast></p-toast>

<div class="p-field" style="width: 95%; padding: 5px">
  <label>Equipe</label>
  <p-dropdown
    [options]="teams"
    [(ngModel)]="selectedTeam"
    optionLabel="apelido"
    [filter]="true"
    filterBy="nome"
    [showClear]="true"
    placeholder="Selecione a equipe"
  >
    <ng-template pTemplate="selectedItem">
      <div class="team-item team-item-value" *ngIf="selectedTeam">
        <div>{{ selectedTeam.apelido }}</div>
      </div>
    </ng-template>
    <ng-template let-team pTemplate="item">
      <div class="team-item">
        <div>{{ team.apelido }}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div>

<div style="height: 300px; display: flex; float: left" class="p-field p-col-12 p-md-4">
    <div style="width: 40%; margin-right:50%" class="p-calendar p-datepicker">
        <label for="inicio">Inicio</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="date1" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" inputId="inicio"></p-calendar>
        <small class="p-invalid" *ngIf="submitted && !date1"
          >Campo obrigatório.</small
        >
    </div>
    <div style="width: 40%; margin-left:50%"  class="p-calendar p-datepicker">
        <label for="termino">Termino</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="date2" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" inputId="termino"></p-calendar>
        <small class="p-invalid" *ngIf="submitted && !date2"
          >Campo obrigatório.</small
        >
    </div>
</div>
<button
  pButton
  pRipple
  label="Salvar"
  icon="pi pi-check"
  class="p-button-text"
  (click)="submitForm()"
  style="margin-top: 40%"
></button>


<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
