<p-menubar style="width: 100%; position: fixed !important; z-index: 999;" >
  
  <ng-template pTemplate="start">
    <div style="display: flex; float: left">
      <div style="width: 10%;">
        <img  src="/assets/imgs/CSM_branco_h.ico"  height="40" *ngIf="!modalityName">
      </div>
        <a href="/home" class="font-logo" *ngIf="modalityName">
        <span class="font-logo-modality">{{ modalityLegend }}|</span
        >{{ categoryLegend }}
      </a>
      <app-side-menu *ngIf="modalityName"></app-side-menu>|
      <abbr title="Editar">
        <button
          pButton
          pRipple
          [disabled]="!selectedAtleta"
          icon="pi pi-pencil"
          class="p-button-rounded crud-button"
          style="
            color: white !important;
            background-color: gray !important;
            
          "
          (click)="editProfile()"
        ></button></abbr>

        <abbr title="Transferir">
        <button
          pButton
          pRipple
          [disabled]="!selectedAtleta"
          icon="pi pi-send"
          class="p-button-rounded crud-button"
          style="
            color: white !important;
            background-color: gray !important;
            margin-left: 20%;
          "
          (click)="transfer()"
        ></button></abbr>
  
        <abbr title="Empréstimo">
        <button
          pButton
          pRipple
          [disabled]="!selectedAtleta"
          icon="pi pi-share-alt"
          class="p-button-rounded crud-button"
          style="
            color: white !important;
            background-color: gray !important;
            margin-left: 40%;
          "
          (click)="transferByLoan()"
        ></button></abbr>
     
        <abbr title="Renovar Contrato">
        <button
          pButton
          pRipple
          [disabled]="!selectedAtleta"
          icon="pi pi-refresh"
          class="p-button-rounded crud-button"
          style="
            color: white !important;
            background-color: gray !important;
            margin-left: 60%;
          "
          (click)="renewContract()"
        ></button></abbr>
        <abbr title="Atualizar Carreira">
        <button
        pButton
        pRipple
        [disabled]="!selectedAtleta"
        icon="pi pi-id-card"
        class="p-button-rounded crud-button"
        style="
          color: white !important;
          background-color: rgb(78, 55, 182) !important;
          margin-left: 80%;
          "
        (click)="updateHistory()"
        
      ></button></abbr>
        <abbr title="Desativar">
          <button
            pButton
            pRipple
            [disabled]="!selectedAtleta"
            icon="pi pi-user-minus"
            class="p-button-rounded crud-button"
            style="
              color: white !important;
              background-color: red !important;
              margin-left: 100%;
            "
            (click)="getForDeactivateContractContract()"
          ></button></abbr>
          
      <div class="filters-container" style="margin-top: 0%; margin-left: 10%;">
        <div style="display: flex; float: left; width: 100%">
            <p-dropdown
              [options]="teams"
              [(ngModel)]="selectedTeam"
              optionLabel="apelido"
              [filter]="true"
              filterBy="nome"
              [showClear]="true"
              placeholder="Corinthians-SP"
            >
              <ng-template pTemplate="selectedItem">
                <div class="team-item team-item-value" *ngIf="selectedTeam">
                  <div>{{ selectedTeam.apelido }}</div>
                </div>
              </ng-template>
              <ng-template let-team pTemplate="item">
                <div class="team-item">
                  <div>{{ team.apelido }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <button
              pButton
              pRipple
              icon="pi pi-search"
              class="p-button-text"
              (click)="searchCast()"
            ></button>
          </div>
        </div>
    </div>
  </ng-template>
  <ng-template pTemplate="end" >
    <p style="float: left; margin: 5px 30px 0 0; padding: 0" routerLink="/configuracoes">Configurações</p>
    <p
      class="mobile-hide"
      style="float: left; margin: 5px 50px 0 0; padding: 0">
      Bem-vindo <strong>{{ username }}</strong>
    </p>
    <button #btn type="button" class="p-menubar btn-side-menu" pButton icon="pi pi-power-off" (click)="sairIAS()"></button>
    
  </ng-template>
</p-menubar>

<p-toast></p-toast>


<div style="margin-top: 5%; margin-left: 1%; margin-right: 1%;" >
  <p-tabView>
      
    <p-tabPanel header="Elenco / Posições">
      <div style="margin: 0.8%">
        
        <div
          class="spin-container"
          style="max-width: 100%"
          *ngIf="inProgress && !response"
        >
          <p-progressSpinner
            [style]="{
              width: '100%',
              height: '70px',
              marginTop: '20%',
              marginBottom: '20%'
            }"
            styleClass="custom-spinner"
          ></p-progressSpinner>
        </div>
        <div
          style="width: 100%; margin: auto; box-shadow: 2px 2px 2px #b3b3b3"
          *ngIf="response"
        >
          <div
            style="
              width: 100%;
              background-color: black;
              color: white;
              border-bottom: solid 1px white;
              text-align: center;
            "
          >
            Elenco {{ modalityName }}

              <div 
                class="p-d-flex"
                style="
                  float: right;
                "
                >
                <abbr title="Elenco Completo">
                <button 
                  type="button" 
                  pButton pRipple 
                  icon="pi pi-file-o" 
                  (click)="pdfCast()" 
                  class="p-button-rounded crud-button" 
                  pTooltip="CSV" 
                  tooltipPosition="bottom"
                  style="
                  color: white !important;
                  background-color: gray !important;
                  margin: 2px 10px 0 0;
                  width: 37px;
                  height: auto;
                "
                >
              </button></abbr>
              
              <abbr title="Elenco Simples">
              <button 
                type="button" 
                pButton 
                pRipple 
                icon="pi pi-file-excel" 
                (click)="pdfSimpleCast()" 
                class="p-button-rounded crud-button"  
                pTooltip="XLS" 
                tooltipPosition="bottom"
                style="
                color: white !important;
                background-color: gray !important;
                margin: 2px 10px 0 0;
                width: 37px;
                height: auto;
              "
                >
              </button>
              </abbr>
              <abbr title="Inserir Novo Jogador">
              <button
                pButton
                pRipple
                icon="pi pi-user-plus"
                class="p-button-rounded crud-button"
                style="
                  color: white !important;
                  background-color: gray !important;
                  margin: 2px 10px 0 0;
                  width: 37px;
                  height: auto;
                "
                (click)="newSoccerPlayer()"
              ></button></abbr>
              </div>

          </div>
          <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
            <p-progressSpinner
              [style]="{
                width: '100%',
                height: '70px',
                marginTop: '20%',
                marginBottom: '20%'
              }"
              styleClass="custom-spinner"
            ></p-progressSpinner>
          </div>
          <div *ngIf="!inProgress" style="width: 100%" >
            <div style="background-color: black; color: white">
              {{ total_players }} Atletas
            </div>
          
            <div *ngFor="let item of response">
              <div style="background-color: black; color: white; margin-top: 1%;">
                {{ item.position }} 
              </div>
              <p-table
              
                [columns]="[item.position]"
                [value]="item.players"
                [ngStyle]="{'width': '100%'}"
                [(selection)]="selectedAtleta"
                responsiveLayout="scroll"
                bordercolor="black"
                (onRowSelect)="onRowSelect($event)" 
              >
                
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 0.5%;"></th>
                    <th *ngFor="let col of cols" [pSortableColumn]="col.field" colspan="col.colspan" 
                    [ngStyle]="{'width': col.width, 'font-weight': col.fontweight, 'border-right': col.borderright}">
                            {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                    <td>
                      <p-tableRadioButton  name="uniqueName" [value]="item" [binary]="true"></p-tableRadioButton >
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center; border-right: 1px solid #f8f8f8;">
                      {{ item.number }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      <p-avatar image="{{ item.image }}"></p-avatar>
                    </td>
                    <td style="padding: 0px; font-size: small; border-right: 1px solid #f8f8f8;">
                      <a href="#/profile/{{ item.id_people }}/" style="text-decoration: none; color: black;">
                        <br>{{ item.surname }} <br><br>
                      <h5>{{ item.name }} </h5><br>
                      </a>
                    </td>
                    <td  style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;" step=".01">
                      {{decimal(item.height)}}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ getSideString(item.side) }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.origin }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.cbf }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.history }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                  
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.id_ogol }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.id_soccerway }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.id_transfermarkt }}
                    </td>
                    <td style="padding: 0px; font-size: x-small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.start_contract | date: 'dd/MM/yyyy' }}
                    </td>
                    <td style="padding: 0px; font-size: x-small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.end_contract | date: 'dd/MM/yyyy' }}
                    </td>
                    <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                      {{ item.status }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header=" Elenco / Filtros">
    <div style="margin: 0.8%">
      <div
          class="spin-container"
          style=""
          *ngIf="inProgress && !response"
        >
          <p-progressSpinner
            [style]="{
              width: '100%',
              height: '70px',
              marginTop: '20%',
              marginBottom: '20%'
            }"
            styleClass="custom-spinner"
          ></p-progressSpinner>
        </div>
        <div
          style="width: 100%; margin: auto; box-shadow: 2px 2px 2px #b3b3b3"
          *ngIf="response"
        >
          <div
            style="
              width: 100%;
              background-color: black;
              color: white;
              border-bottom: solid 1px white;
              text-align: center;
            "
          >
            Elenco {{ modalityName }}

              <div 
                class="p-d-flex"
                style="
                  float: right;
                "
                >
                <abbr title="Elenco Completo">
                <button 
                  type="button" 
                  pButton pRipple 
                  icon="pi pi-file-o" 
                  (click)="pdfCast()" 
                  class="p-button-rounded crud-button" 
                  pTooltip="CSV" 
                  tooltipPosition="bottom"
                  style="
                  color: white !important;
                  background-color: #5484ff !important;
                  margin: 2px 10px 0 0;
                  width: 37px;
                  height: auto;
                "
                >
              </button></abbr>
              
              <abbr title="Elenco Simples">
              <button 
                type="button" 
                pButton 
                pRipple 
                icon="pi pi-file-excel" 
                (click)="pdfSimpleCast()" 
                class="p-button-rounded crud-button"  
                pTooltip="XLS" 
                tooltipPosition="bottom"
                style="
                color: white !important;
                background-color: #5484ff !important;
                margin: 2px 10px 0 0;
                width: 37px;
                height: auto;
              "
                >
              </button>
              </abbr>
              <button
                pButton
                pRipple
                icon="pi pi-print"
                
                class="p-button-rounded crud-button"
                style="
                  color: white !important;
                  background-color: #5484ff !important;
                  margin: 2px 10px 0 0;
                  width: 37px;
                  height: auto;
                "
              ></button>
              <abbr title="Inserir Novo Jogador">
              <button
                pButton
                pRipple
                icon="pi pi-user-plus"
                class="p-button-rounded crud-button"
                style="
                  color: white !important;
                  background-color: #5484ff !important;
                  margin: 2px 10px 0 0;
                  width: 37px;
                  height: auto;
                "
                (click)="newSoccerPlayer()"
              ></button></abbr>
              </div>

          </div>
          <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
            <p-progressSpinner
              [style]="{
                width: '100%',
                height: '70px',
                marginTop: '20%',
                marginBottom: '20%'
              }"
              styleClass="custom-spinner"
            ></p-progressSpinner>
          </div>
          <div *ngIf="!inProgress" style="width: 100%" >
            <div style="background-color: black; color: white">
              {{ total_players }} Atletas
            </div>
          
            
          </div>
        </div>
      <p-table 
          #dt1 [value]="elenco" dataKey="id"
          [(selection)]="selectedAtleta"
          [rows]="50" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" 
          [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['surname', 'number', 'height', 'position_name']"  (onRowSelect)="onRowSelect($event)" >
        <ng-template pTemplate="caption">
                <div class="flex">
                  <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar" />
                  </span>
                </div>
            </ng-template>
              <ng-template pTemplate="header">
                  <tr>
                      <th style="width:2rem"></th>
                      <th style="width:2rem">
                          <div class="flex justify-content-center align-items-center">
                              Foto 
                          </div>
                      </th>
                      <th pSortableColumn="surname" style="width:5rem">
                          <div class="flex justify-content-center align-items-center">
                            <p-sortIcon field="surname"></p-sortIcon>Nome 
                          </div>
                      </th>
                      <th pSortableColumn="position_name" style="min-width:10rem">
                          <div class="flex justify-content-center align-items-center">
                            <p-sortIcon field="position_name"></p-sortIcon>Posicao 
                          </div>
                      </th>
                      <th pSortableColumn="number" style="width:2.5rem">
                        <div class="flex justify-content-center align-items-center">
                          <p-sortIcon field="number"></p-sortIcon>camisa
                        </div>
                      <th pSortableColumn="height" style="width:5rem">
                        <div class="flex justify-content-center align-items-center">
                          <p-sortIcon field="height"></p-sortIcon>Altura
                        </div>
                      </th>
                      <th pSortableColumn="side" style="width:7rem">
                        <div class="flex justify-content-center align-items-center">
                          <p-sortIcon field="side"></p-sortIcon> predominancia    
                        </div>
                    </th>
                      <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          País de Origem
                        </div>
                      </th>
                      <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          CBF    
                        </div>
                    </th>
                    <th [ngStyle]="{'width': '30%'}">
                      <div class="flex justify-content-center align-items-center">
                          Carreira
                      </div>
                      <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          O-Gol
                        </div>
                      </th>
                      <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          Soccerway
                        </div>
                      </th>
                      <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          Transfermarkt
                        </div>
                      </th>
                      <th  pSortableColumn="start_contract" style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          <p-sortIcon field="start_contract"></p-sortIcon>Início
                        </div>
                      </th>
                      <th  pSortableColumn="end_contract" style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          <p-sortIcon field="end_contract"></p-sortIcon>Término
                        </div>
                      </th>
                      <th style="min-width:10rem">
                        <div class="flex justify-content-center align-items-center">
                          Situacão
                        </div>
                      </th>

                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-elenco>
                  <tr [pSelectableRow]="elenco" [pSelectableRowIndex]="rowIndex">
                      <td>
                        <p-tableRadioButton  name="uniqueName" [value]="elenco" [binary]="true"></p-tableRadioButton >
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <p-avatar image="{{ elenco.image }}"></p-avatar>
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{elenco.surname}}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{elenco.position_name}}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{elenco.number}}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;" step=".01">
                        <div class="circulo"></div>{{decimal(elenco.height)}}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{ getSideString(elenco.side) }}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{ elenco.origin }}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{ elenco.cbf }}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{ elenco.history }}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        {{ elenco.id_ogol }}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{ elenco.id_soccerway }}
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{ elenco.id_transfermarkt }}
                      </td>
                      <td style="padding: 0px; font-size: x-small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <div class="circulo"></div>{{ elenco.start_contract | date: 'dd/MM/yyyy' }}
                      </td>
                      <td style="padding: 0px; font-size: x-small; text-align: center;border-right: 1px solid #f8f8f8;">
                        <abbr title="{{ getDescripition(elenco.end_contract) }}">
                          <div class="{{ calcContract(elenco.end_contract) }}">
                          </div>
                        </abbr>
                            {{ elenco.end_contract | date: 'dd/MM/yyyy' }}  
                      </td>
                      <td style="padding: 0px; font-size: small; text-align: center;border-right: 1px solid #f8f8f8;">
                        {{ elenco.status }}
                      </td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td colspan="7">No customers found.</td>
                  </tr>
              </ng-template>
            </p-table>
      </div>      
    </p-tabPanel>
  </p-tabView>
</div>

 



<p-dialog
  [(visible)]="profileDialog"
  [style]="{ width: '60%', minHeight: '100%' }"
  header="Perfil"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-player-crud
      profileId="{{ profileId }}"
      contractId="{{ contractId }}"
    ></app-player-crud>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="transferLoanDialog"
  [style]="{ width: '60%', minHeight: '100%' }"
  header="Transferencia por empréstimo"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-transfer-by-loan
      profileId="{{ profileId }}"
      contractId="{{ contractId }}"
    ></app-transfer-by-loan>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="transferDialog"
  [style]="{ width: '60%', minHeight: '100%' }"
  header="Transferencia"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-transfer
      profileId="{{ profileId }}"
      contractId="{{ contractId }}"
    ></app-transfer>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="addSoccerPlayerDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Procurar Jogador"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-add-soccer-player></app-add-soccer-player>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="newSoccerPlayerDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Inserir novo Jogador"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-new-soccer-player [teamId]="teamId"></app-new-soccer-player>
  </ng-template>
</p-dialog>


<p-dialog
  [(visible)]="renewDialog"
  [style]="{ width: '60%', minHeight: '100%' }"
  header="Renovação de contrato"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-contract-renewal
      profileId="{{ profileId }}"
      contractId="{{ contractId }}"
    ></app-contract-renewal>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="pdfCastDialog"
  [style]="{ width: '70%', minHeight: '100%' }"
  header="Elenco"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-pdf-cast></app-pdf-cast>
  </ng-template>
</p-dialog>
<p-dialog
  [(visible)]="pdfSimpleCastDialog"
  [style]="{ width: '95%', minHeight: '100%' }"
  header="Elenco"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-cast-report-simple></app-cast-report-simple>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>