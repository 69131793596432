import { Component, OnInit } from '@angular/core';
import { ExplorerService } from './explorer.service';

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerComponent implements OnInit {
  inProgress: boolean;
  response: any[];
  constructor(private explorerService: ExplorerService) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listCast();
  }

  public listCast() {
    this.explorerService.getCast().subscribe(
      (response) => {
        console.log(response)
        this.inProgress = false;
        this.response = response;
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
