import { Component, OnInit} from '@angular/core';
import { SetCastService } from './set-cast.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Profile } from './profile';
import { Contract } from './contract';
import {Location} from '@angular/common'; 


import '@angular/compiler';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-set-cast',  
  templateUrl:  './set-cast.component.html', 
  styleUrls: ['./set-cast.component.scss'],
  providers: [SetCastService, MessageService, ConfirmationService],
})
export class SetCastComponent implements OnInit {
  players: any[];
  positions: any[];
  response: any[];
  teamId: any;
  teams: any[];
  cast: any[];
  selectedTeam: any;
  profileId: any;
  contractId: any;
  contract: Contract;
  modalityName: string;
  inProgress: boolean;
  profileDialog: boolean;
  transferDialog: boolean;
  transferLoanDialog: boolean;
  renewDialog: boolean;
  addSoccerPlayerDialog: boolean;
  newSoccerPlayerDialog: boolean;
  pdfCastDialog:boolean;
  cols: { field: string; header: string; width: string; fontweight:string, borderright:string }[];
  profile: Profile;
  history: Document;
  html: any;
  pdfSimpleCastDialog: boolean;
  total_players: any;
  elenco: any;
  selectedAtleta: any;
  categoryLegend: any;
  modalityLegend: any;
  username: any;

  constructor(
    private setCastService: SetCastService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router,

  ) {}

  ngOnInit(): void {
    // this.username = JSON.parse(localStorage.user).first_name;
    this.modalityLegend = localStorage.modality_legend;
    this.categoryLegend = localStorage.category_legend;
    this.teamId = this.activatedRoute.snapshot.paramMap.get('id');
    this.modalityName = localStorage.modality_name;
    // alert(this.teamId);
    
    if (this.teamId === "null") {
      this.listTeams();
    }else{
      this.inProgress = true;
      this.listTeams();
      this.listCast();
      this.listCast2()
    }

    this.cols = [
      {field: 'first', header: 'Camisa', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'second', header: 'Foto', width: '1%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'third', header: 'Nome', width: '3%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'fourth', header: 'Altura', width: '1%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'fifth', header: 'Predomi- nância', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'sixth', header: 'País de Origem', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'seventh', header: 'CBF', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'eighth', header: 'Carreira', width: '8%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'ninth', header: 'Atualizar carreira', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'tenth', header: 'O-Gol', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'eleventh', header: 'Soccer- way', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'twelfth', header: 'Transfer- mark', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'thirteenth', header: 'Início', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'fourteenth', header: 'Término', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'fifteenth', header: 'Situação', width: '1.2%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      

    ]

  }

  onRowSelect(event) {
    this.selectedAtleta = event.data
    console.log(this.selectedAtleta)
    this.messageService.add({ severity: 'info', summary: 'Jogador Selecionado', detail: event.data.name });
  }



  public decimal(n) {
    return (Math.round(n * 100) / 100).toFixed(2);
  }
   
  public listCast() {
    
    this.setCastService.getCast(this.teamId).subscribe(
      (response) => {
       
        this.total_players = 0
        let totalPlayers = 0 
        for(let n in response){
          totalPlayers = totalPlayers + response[n].players.length
        }
        this.total_players = totalPlayers
        this.inProgress = false;
        this.response = response;
        this.cast = response;
        console.log(response)

      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }


  public listCast2() {
    
    this.setCastService.getCast2(this.teamId).subscribe(
      (response) => {
        console.log(this.selectedTeam)
        this.inProgress = false;
        this.elenco = response;

      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }
  
  public searchCast() {
    this.total_players = 0
    this.inProgress = true;
    this.teamId = this.selectedTeam.id
    this.setCastService.getCast2(this.teamId).subscribe(
      (response) => {
        console.log(response)
        this.inProgress = false;
        this.elenco = response;

      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
    this.setCastService.getCast(this.selectedTeam.id).subscribe(
      (response) => {       
        this.total_players = 0
        let totalPlayers = 0 
        for(let n in response){
          totalPlayers = totalPlayers + response[n].players.length
          
        }
        
        this.total_players = totalPlayers
        this.inProgress = false;
        this.response = response;
        this.location.replaceState("/set-cast/" + this.selectedTeam.id);
        localStorage.setItem('activeTeam', this.selectedTeam.id)
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    )
  }

  public listTeams() {
    this.setCastService.getTeams().subscribe(
      (response) => {
        this.teams = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipes.',
          life: 3000,
        });
      }
    );
  }


exportExcel() {
  import("xlsx").then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(this.cast);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });
    this.saveAsExcelFile(excelBuffer, "cast");
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  import("file-saver").then(FileSaver => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  });
}



  public updateHistory(){
    let id = this.selectedAtleta.id_ogol;
    let profileid = this.selectedAtleta.id
    console.log(id)
    this.setCastService.getProfile(profileid).subscribe(
      (response) => {
        this.profile = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar perfil.',
          life: 3000,
        });
      }
    );

    this.confirmationService.confirm({
      message: 'Tem certeza que deseja executar essa ação?',
      acceptLabel: "Sim",
      rejectLabel: "Não",

      accept: () => {
        this.setCastService.setUpdateHistory(id).subscribe(
          (rtn) => {
                  console.log(rtn)

            var coachCareer, teams, rows, cols, i, row_data, span, raw_data = [],
            result = [], response = '', output, start, end, country, team;
            
            
            this.history = (new DOMParser).parseFromString(rtn, 'text/html')

            coachCareer = this.history.getElementById('coach_career');
            teams = coachCareer.getElementsByTagName('table')[0];
            rows = teams.querySelectorAll('tbody > tr');

            for (i = 0; i < rows.length; i += 1) {
                cols = rows[i].getElementsByTagName('td');
                row_data = {
                    year:  cols[1].textContent,
                    country: cols[2].querySelector('.image img').alt,
                    team: cols[2].querySelector('.text').textContent
                };
                span = cols[2].querySelector('span');
                if (span) {
                    row_data.team += span.textContent;
                }
                raw_data.push(row_data);
            }

            for (i = 0; i < raw_data.length; i += 1) {
                if (raw_data[i].year.indexOf('/') > 0) {
                    raw_data[i].year = raw_data[i].year.substr(0,raw_data[i].year.indexOf('/'));
                }
                raw_data[i].year = Number(raw_data[i].year);
                if (!start && !end && !country && !team) {
                    start = raw_data[i].year;
                    end = raw_data[i].year;
                    country = raw_data[i].country;
                    team = raw_data[i].team;
                } else {
                    if (raw_data[i].team != team) {
                        result.push({
                            start: start,
                            end: end,
                            country: country,
                            team: team
                        });
                        if (raw_data[i].year > 0) {
                            start = raw_data[i].year;
                            end = raw_data[i].year;
                        } else {
                            end = start
                        }
                        country = raw_data[i].country;
                        team = raw_data[i].team;
                    } else {
                        if (raw_data[i].year > 0 && (raw_data[i].year > end || end == 0)) {
                            end = raw_data[i].year;
                        } else if (raw_data[i].year > 0 && (raw_data[i].year < start || start == 0)) {
                            start = raw_data[i].year;
                        }
                    }
                }
              }

              result.push({
                start: start,
                end: end,
                country: country,
                team: team
              });

              for (i in result) {
                  if (result[i].country == 'Brasil') {
                      response += result[i].start + '-' + result[i].end + ' ' + result[i].team + '; ';
                  } else {
                      response += result[i].start + '-' + result[i].end + ' ' + result[i].team + '(' + result[i].country + '); ';
                  }

              }

              this.profile.clubes_anteriores= response
              this.setCastService.editProfile(this.profile, profileid).subscribe(
                (response) => {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Salvo',
                    detail: 'O perfil foi editado com sucesso.',
                    life: 3000,
                  });

                  window.location.reload()
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Houve algum erro ao editar perfil.',
                    life: 3000,
                  });
                }
              );
          },
          (error) => {
            console.log(error)

            return this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Houve algum erro ao recuperar equipes.',
              life: 3000,
            });
          }
        );      
      }
    });
    
  }

  
  
  public getSideString(side: string): string {
   
    if (side === 'C' || side === 'E') {
      return 'Canhoto';
    }
    if (side === 'D') {
      return 'Destro';
    }
    if (side === 'A') {
      return 'Ambidestro';
    }
    return null;
  }

  editProfile() {
    console.log(this.selectedAtleta.contract_id)
    this.contractId = this.selectedAtleta.contract_id;
    this.profileId = this.selectedAtleta.id;
    this.profileDialog = true;
  }

  renewContract(player) {
    this.contractId = this.selectedAtleta.contract_id;
    this.profileId = this.selectedAtleta.id;
    this.renewDialog = true;
  }

  transfer(player) {
    this.contractId = this.selectedAtleta.contract_id;
    this.profileId = this.selectedAtleta.id;
    this.transferDialog = true;
  }

  transferByLoan(player) {
    this.contractId = this.selectedAtleta.contract_id;
    this.profileId = this.selectedAtleta.id;
    this.transferLoanDialog = true;
  }

  addSoccerPlayer() {
    this.addSoccerPlayerDialog = true;
  }

  newSoccerPlayer() {
    this.newSoccerPlayerDialog = true;
  }

  pdfCast() {
    this.pdfCastDialog = true;
  }
  pdfSimpleCast() {
    this.pdfSimpleCastDialog = true;
  }
  getForDeactivateContractContract() {
    console.log(this.selectedAtleta)
    this.setCastService.getContract(this.selectedAtleta.contract_id).subscribe(
      (response) => {
        this.contract = response;
        this.deactivateContract(this.selectedAtleta);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail:
            'Houve algum erro ao recuperar jogador de id ' +
            this.selectedAtleta.contract_id +
            '.',
          life: 3000,
        });
      }
    );
  }

  deactivateContract(player) {
    this.contractId = player.contract_id;
    this.contract.ativo = 0;
    this.contract.situacao = null;
    this.confirmationService.confirm({
      message:
        'Tem certeza que deseja desativar o contrato de ' +
        player.surname +
        '?',
      header: 'Desativar?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      icon: 'pi pi-exclamation-triangle',
      accept: () => { console.log('aqui')
        this.setCastService
          .setContract(this.contract, this.contractId)
          .subscribe(
            (response) => {
              this.messageService.add({
                severity: 'info',
                summary: 'Desativado',
                detail:
                  'O contrato de ' +
                  player.surname +
                  ' foi desativado com sucesso.',
                life: 3000,
              });
              this.contract = {};
              this.listCast();
              window.location.reload()
            },
            (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erro',
                detail:
                  'Houve algum erro ao desativar o contrato de ' +
                  player.surname +
                  '.',
                life: 3000,
              });
            }
          );
      },
    });
  }

  calcContract(data){
    const dateC = new Date(data);
    const anoC = dateC.getFullYear();
    const today = new Date();
    const todayAno = today.getFullYear()
    var teste = anoC - todayAno

    if(teste < 1 && teste > -1){
      return 'contract1'
    }else if(teste >= 1 && teste < 3){
      return 'contract2'
    }else if(teste >= 3){
      return 'contract3'
    }else{
      return 'contract1'
    }

  }

  getDescripition(data){
    const dateC = new Date(data);
    const anoC = dateC.getFullYear();
    const today = new Date();
    const todayAno = today.getFullYear()
    var teste = anoC - todayAno

    if(teste < 1 && teste > -1){
      return 'Contrato vence em menos de 1 ano !'
    }else if(teste >= 1 && teste < 3){
      return 'Contrato maior igual a 1 anos e vence em menos de 3 anos !'
    }else if(teste >= 3){
      return 'Contrato maior igual que 3 ano !'
    }else{
      return 'Contrato Vencido !'
    }
  }

  public sairIAS() {
   
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja sair do CSM?',
      acceptLabel: "Sim",
      rejectLabel: "Não",

      accept: () => {
        localStorage.clear();
        this.router.navigate(['login/']);}
    })
  }

}
