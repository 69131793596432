import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LatestLinupsReportService {
  teamsUrl = localStorage.host + '/teams/';
  castUrl = localStorage.host + '/full-cast-last-games/';

  constructor(private http: HttpClient) { }

  public getTeam(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl + teamId + '/', { headers: header });
  }

  public getTeams(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl, { headers: header });
  }

  public getCast(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.castUrl +
      '?team_id=' +
      teamId +
      '&modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id,
      {
        headers: header,
      }
    );
  }
}
