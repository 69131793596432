import { Component, OnInit } from '@angular/core';
import { CastTableService } from './cast-table.service';

@Component({
  selector: 'app-cast-table',
  templateUrl: './cast-table.component.html',
  styleUrls: ['./cast-table.component.scss'],
})
export class CastTableComponent implements OnInit {
  players: any[];
  positions: any[];
  response: any[];
  modalityName: string;
  inProgress: boolean;

  constructor(private castTableService: CastTableService) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.modalityName = localStorage.modality_name;
    this.listCast();
    
  }

  public listCast() {
    this.castTableService.getCast().subscribe(
      (response) => {
       
        this.inProgress = false;
        this.response = response;
      },
      (error) => {
       alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
