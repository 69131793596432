<p-table [value]="indexList">
  <ng-template pTemplate="header">
    <tr>
      <th>Mais acessados</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-index>
    <tr>
      <td>
        <a href="#" (click)="addHit(index)">{{ index.name }}</a>
      </td>
    </tr>
  </ng-template>
</p-table>
