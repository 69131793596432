import { Component, OnInit } from '@angular/core';
import { MainPostsService } from './main-posts.service';

@Component({
  selector: 'app-main-posts',
  templateUrl: './main-posts.component.html',
  styleUrls: ['./main-posts.component.scss'],
})
export class MainPostsComponent implements OnInit {
  posts: any[];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  // constructor(private photoService: PhotoService) {}
  constructor(private mainPostsService: MainPostsService) {}

  ngOnInit(): void {
    this.listPosts();
  }

  public listPosts() {
    this.mainPostsService.getPosts().subscribe(
      (response) => {
        this.posts = response;
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
