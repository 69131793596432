import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from './profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  idPeople: any;
  people: any;
  inProgress: boolean;
  display: boolean;
  items: MenuItem[];
  option: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.idPeople = this.activatedRoute.snapshot.paramMap.get('id');
    this.getPeople();
    this.option = 'timeline';
    this.inProgress = true;
    this.display = false;
    this.items = [
      {
        label: 'Linha do tempo',
        command: () => this.setOption('timeline'),
      },
      {
        label: 'Futebol',
        items: [
          { label: 'Resumo', command: () => this.setOption('--') },
          { label: 'Contratos', command: () => this.setOption('--') },
          { label: 'Observações', command: () => this.setOption('--') },
        ],
      },
      {
        label: 'Saúde',
        items: [
          { label: 'Ocorrências', command: () => this.setOption('--') },
          { label: 'Avaliações', command: () => this.setOption('--') },
        ],
      },
      {
        label: 'Físico',
        items: [{ label: 'Resumo', command: () => this.setOption('--') }],
      },
      {
        label: 'Perfis',
        items: [
          { label: 'Básico', command: () => this.setOption('--') },
          { label: 'Pessoal', command: () => this.setOption('--') },
          { label: 'Jogador', command: () => this.setOption('--') },
          { label: 'Médico', command: () => this.setOption('--') },
          { label: 'Árbitro', command: () => this.setOption('--') },
          { label: 'Comissão', command: () => this.setOption('--') },
          { label: 'Funcionário', command: () => this.setOption('--') },
        ],
      },
    ];
  }

  public getPeople() {
    this.profileService.getPeople(this.idPeople).subscribe(
      (response) => {
        this.people = response;
        console.log(this.people);
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public setOption(optionName) {
    this.option = optionName;
    this.display = false;
  }
}
