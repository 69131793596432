import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parametro-jogador',
  templateUrl: './parametro-jogador.component.html',
  styleUrls: ['./parametro-jogador.component.scss']
})
export class ParametroJogadorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
