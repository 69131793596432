import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CaracteristicaService {
  url = localStorage.host + '/full_cast/';
  updateUrl = localStorage.host + '/perfil/';
  urlElenco = localStorage.host + '/elenco_completo/'
  teamsUrl = localStorage.host + '/teams/'

  constructor(private http: HttpClient) { }

  public getCast(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id +
      '&team_id=' +
      teamId,
      { headers: header }
    );
  }

  public getElenco(teamId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.urlElenco +
      '?modality_id=' +
      localStorage.modality_id +
      '&category_id=' +
      localStorage.category_id +
      '&team_id=' +
      teamId,
      { headers: header }
    );
  }

  public updateCaracteristica(response: any) {
    for (let item in response) {

      for (let player in response[item]['players']) {
        const header = new HttpHeaders().set(
          'Authorization',
          'x-csrftoken' + localStorage.token
        );
        let send = {
          id: response[item]['players'][player]['id'],
          caracteristicas: response[item]['players'][player]['caracteristicas'],
          pontos_fortes: response[item]['players'][player]['positive_points'],
          pontos_fracos: response[item]['players'][player]['negative_points'],
        };

        let id = response[item]['players'][player]['id'];
        this.http.put<any>(this.updateUrl + id + '/', send, {
          headers: header,
        }).subscribe(data => console.log(data));

      }
    }
  }

  getTeam(id) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.teamsUrl + id + '/', { headers: header });
  }
}


