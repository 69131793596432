<!--
<app-menubar></app-menubar>
-->
<p-fieldset legend="Gerenciar pessoas">
  <!--
  <p-accordion [multiple]="true">
    <p-accordionTab header="Dados principais" [selected]="true">
-->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6">
      <label for="apelido">Apelido</label>
      <input id="apelido" [(ngModel)]="people.apelido" type="text" pInputText />
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="nome">Nome</label>
      <input id="nome" [(ngModel)]="people.nome" type="text" pInputText />
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="sexo">Sexo</label>
      <p-selectButton
        id="sexo"
        [options]="sexOptions"
        [(ngModel)]="people.sexo"
        optionLabel="label"
        optionValue="value"
      ></p-selectButton>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <label for="nascimento">Data de Nascimento</label>
      <p-calendar
        inputId="nascimento"
        [(ngModel)]="people.nascimento"
        yearRange="1970:3030"
        [yearNavigator]="true"
        appendTo="body"
      ></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label for="foto">Imagem</label>
      <input style="margin-left: 1%;" type="file" accept="image/*" (change)="selectFile($event)" class="form-control">
    </div>
    <div class="p-field">
      <label for="bio">Bio</label>
      <textarea
        id="bio"
        pInputTextarea
        [(ngModel)]="people.bio"
        required
        rows="3"
        cols="20"
        [disabled]="verb === 'GET'"
      ></textarea>
    </div>
  </div>
  <div style="margin-top: 1%;">
    <p-accordion (onClose)="onTabClose($event)" (onOpen)="onTabOpen($event)">
      <p-accordionTab header="Dados de Atleta" >
        <form action="/pagina-processa-dados-do-form" [formGroup]="perfil_player" method="post">
          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 2%; display: inline-block;">
              <p-inputNumber inputId="integeronly" [min]="0" formControlName="federacao"></p-inputNumber>
              <label for="federacao" required>Nº Federação</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
              <p-inputNumber inputId="integeronly" [min]="0" formControlName="federacao_reg"  ></p-inputNumber>
              <label for="federacao_regional">Nº Fed. Regional</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%; display: inline-block;">
            <p-dropdown inputId="selecao" [options]="selecao" formControlName="selecao" [autoDisplayFirst]="false" optionLabel="name" >
            </p-dropdown>
              <label for="jogador_selecao">Jogador de Seleção</label>
            </span>
          </div>

          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 5%; display: inline-block;">
              <p-inputNumber formControlName="id_ogol" inputId="integeronly" [min]="0"   ></p-inputNumber>
              <label for="federacao"  required>ID no OGol</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
              <p-inputNumber inputId="integeronly" [min]="0" formControlName="soccerway"   ></p-inputNumber>
              <label for="federacao_regional">ID no Soccerway</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
            <p-inputNumber inputId="integeronly" [min]="0"  formControlName="transfermarket"></p-inputNumber>
            <label for="jogador_selecao">ID no Transfermarkt</label>
            </span>
          </div>
        
          <div id="federacao_regional" class="p-field p-col-2 p-md-4">
            <span class="p-float-label p-input-icon-left" style="width: 30%; margin-top: 5%; display: inline-block;">
              <input type="number" formControlName="altura"  pInputText />
              <label for="altura">Altura</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%; display: inline-block;">
              <p-dropdown  [options]="perna" [autoDisplayFirst]="false" formControlName="predominancia" optionLabel="nome" required>
              </p-dropdown>
                <label >Predominancia</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%; display: inline-block;">
              <p-dropdown inputId="selecao" [options]="pais" formControlName="origem"  [autoDisplayFirst]="false"  aria-placeholder="Pais" optionLabel="nome" required>
              </p-dropdown>
                <label >Pais de Origem</label>
            </span>
          </div>
          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 5%; display: inline-block;">
              <p-dropdown inputId="selecao" formControlName="posicao"  [options]="posicao"  [autoDisplayFirst]="false" optionLabel="nome" required>
              </p-dropdown>
                <label >Posição</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%; display: inline-block;">
              <p-dropdown  [options]="team" formControlName="equipe" [autoDisplayFirst]="false" optionLabel="apelido" required>
              </p-dropdown>
                <label for="jogador_selecao">Equipe</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%; display: inline-block;">
              <p-dropdown [options]="categoria" formControlName="categoria"  [autoDisplayFirst]="false" optionLabel="nome" required>
              </p-dropdown>
                <label for="jogador_selecao">Categoria</label>
            </span>          
          </div>
          
          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 5%;  display: inline-block;">
              <label>Início do contrato</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 5%;  display: inline-block;">
              <label>Término do contrato</label>
            </span>
          </div>
          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 2%; display: inline-block;">
              <p-calendar yearRange="1980:3030" formControlName="contrato_inicio" [yearNavigator]="true"appendTo="body"></p-calendar>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;display: inline-block; ">
              <p-calendar yearRange="1980:3030" formControlName="contrato_termino" [yearNavigator]="true"appendTo="body"></p-calendar>
            </span>
          </div>
          
          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-top: 5%; display: inline-block;">
              <label for="bio">Anotações da Carreira</label>
              <textarea id="bio" pInputTextarea required rows="3" cols="20" formControlName="carreira"></textarea>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-left: 2%; display: inline-block;">
              <label for="bio">Anotações de Participação</label>
              <textarea id="bio" pInputTextarea required rows="3" cols="20" formControlName="participacao"></textarea>
            </span>
          </div>
          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-top: 5%; display: inline-block;">
              <label for="bio">Anotações de Saúde</label>
              <textarea id="bio" pInputTextarea required rows="3" cols="20" formControlName="saude"></textarea>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-left: 2%; display: inline-block;">
              <label for="bio">Caracteristicas</label>
              <textarea id="bio" pInputTextarea required rows="3" cols="20" formControlName="caracteristica"></textarea>
            </span>
          </div>
          <div id="federacao" class="p-field p-col-1 p-md-1">
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-top: 5%; display: inline-block;">
              <label for="bio">Pontos Fortes</label>
              <textarea id="bio" pInputTextarea required rows="3" cols="20" formControlName="ponto_forte"></textarea>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-left: 2%; display: inline-block;">
              <label for="bio">Pontos Fracoso</label>
              <textarea id="bio" pInputTextarea required rows="3" cols="20" formControlName="ponto_fraco"></textarea>
            </span>
          </div>
        </form>
      </p-accordionTab>
     
      <p-accordionTab header="Dados de Comissão Técnica">
        <div id="federacao" class="p-field p-col-1 p-md-1">
          <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="perfil_comicao" method="post">
          <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-top: 5%; display: inline-block;">
            
              <p-dropdown [options]="func_fut" formControlName="funcao" [autoDisplayFirst]="false" optionLabel="nome" required>
              </p-dropdown>
            
              <label for="jogador_selecao">Função Principal</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-left: 2%; display: inline-block;">
            
            <p-dropdown [options]="team" formControlName="equipe" [autoDisplayFirst]="false" optionLabel="apelido" required>
            </p-dropdown>
              <label >Equipe</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-top: 5%; display: inline-block;">
            <p-dropdown  [options]="categoria" formControlName="categoria" [autoDisplayFirst]="false" optionLabel="nome" required>
            </p-dropdown>
              <label >Categoria</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="margin-top: 2%;margin-left: 2%; display: inline-block;">
            <label>Ativo?</label>
            <p-toggleButton  formControlName="ativo"  [style]="{'width': '10%', 'margin-left': '10%'}" [(ngModel)]="checked1" onLabel="Sim" offLabel="Não"></p-toggleButton>
          </span> 
          <span class="p-float-label p-input-icon-right" style="margin-top: 2%; margin-left: 2%; display: inline-block;"> 
            <label >PJ?</label>
            <p-toggleButton   formControlName="pj" [style]="{'width': '10%', 'margin-left': '10%'}" [(ngModel)]="checked2" onLabel="Sim" offLabel="Não"></p-toggleButton>
          </span>
        </form>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Dados Médicos">
        <div class="field-checkbox">
          <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="dados_medico" method="post">
            <p-checkbox name="group1" formContronName="corinthians" [checked]="1" ></p-checkbox>
            <label for="ny">Corinthians<br>
              <h5>Essa pessoa é um atleta do Corinthians.</h5>
            </label>
        
            <p-checkbox name="group1" formControlName="ceproo" [checked]="1"></p-checkbox>
            <label for="ny">CePROO<br>
              <h5>Essa pessoa foi ou será tratada no Centro de Preparação e Reabilitação Osmar de Oliveira.</h5>
            </label>
        
            <p-checkbox name="group1" formControlName="labr9" [checked]="1" ></p-checkbox>
            <label for="ny">Lab R9<br>
              <h5>Essa pessoa foi ou será avaliada no laboratório R9.</h5>
            </label>
            
            <span class="p-float-label p-input-icon-right" style="width: 22%; margin-left: 1%; margin-top: 5% ">
              <p-dropdown  [options]="tipo_sangue" value="tipo_sangue" formControlName="tipo_sangue" [autoDisplayFirst]="false" optionLabel="tipo">
              </p-dropdown>
                <label >Tipo Sanguíneo</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin: 1%; display: inline-block;">
              <label for="bio">Contato de Emergência</label>
              <textarea id="bio" formControlName="contato_emergencia" pInputTextarea required rows="3" cols="20"></textarea>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin: 1%; display: inline-block;">
              <label for="bio">Problemas crônicos</label>
              <textarea id="bio" formControlName="pronleb_cronicao" pInputTextarea required rows="3" cols="20"></textarea>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin: 1%; display: inline-block;">
              <label for="bio">Alergias</label>
              <textarea id="bio" formControlName="alergias" pInputTextarea required rows="3" cols="20"></textarea>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin: 1%; display: inline-block;">
              <label for="bio">Medicamentos</label>
              <textarea id="bio" formControlName="medicamentos" pInputTextarea required rows="3" cols="20"></textarea>
            </span>
          </form>
          </div>
        </p-accordionTab>
      <p-accordionTab header="Dados Pessoais">
        <div id="federacao" class="p-field p-col-1 p-md-1">
          <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="dados_pessoais" method="post">
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 2%; display: inline-block;">
              <input pInputText formControlName="email"  type="text" inputId="integeronly"/>
              <label for="federacao" required>E-mail principal</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
              <input type="text" formControlName="descricao" inputId="integeronly" pInputText/>
              <label for="federacao_regional">Descrição</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
            <input type="text" formControlName="rg" inputId="integeronly" pInputText />
            <label for="federacao_regional">RG</label>
            </span>
        
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 2%; display: inline-block;">
              <input pInputText  type="text" formControlName="cpf" inputId="integeronly"/>
              <label for="federacao" required>CPF</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
              <input type="text" formControlName="mae" inputId="integeronly" pInputText/>
              <label for="federacao_regional">Nome da Mãe</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
              <input type="text" formControlName="pai" inputId="integeronly" pInputText />
              <label for="federacao_regional">Nome do Pai</label>
            </span>
        
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 2%; display: inline-block;">
              <input pInputText formControlName="passaporte" type="text"/>
              <label for="federacao" required>Número Passaporte</label>
            </span>
            <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
              <p-calendar yearRange="1980:3030" formControlName="emissao_passaporte" [yearNavigator]="true"appendTo="body"></p-calendar>
              <label for="federacao_regional">Emissão do Passaporte</label>
          </span>
          <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
              <p-calendar yearRange="1980:3030" formControlName="validade_passaporte" [yearNavigator]="true"appendTo="body"></p-calendar>
              <label for="federacao_regional">Validade do Passaporte</label>
            </span>
        </form>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Dados de Árbitro" >
      <div class="p-field p-col-1 p-md-1">
        <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="perfil_arbitro" method="post">
        <span class="p-float-label p-input-icon-right" style="margin-top: 5%;">
          <label>Ativo?</label>
          <p-toggleButton  [style]="{'width': '10%', 'margin-left': '10%'}" formControlName="ativo" [(ngModel)]="checked1" onLabel="Sim" offLabel="Não"></p-toggleButton>
        </span>
        <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-top: 5%; display: inline-block;">
          <p-dropdown   [options]="func_fut" formControlName="funcao" [autoDisplayFirst]="false" optionLabel="nome" required>
          </p-dropdown>
            <label for="jogador_selecao">Função Principal</label>
        </span>
        <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-left: 2%; display: inline-block;">
          <input type="text" pInputText formControlName="classificacao" />
            <label >Classificação  (Ex.: CBF1)</label>
        </span>
      </form>
      </div>
      <div id="federacao" class="p-field p-col-1 p-md-1">
        <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-top: 5%; display: inline-block;">
          <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="opt_pais" method="post">
          <p-dropdown [options]="pais" formControlName="pais" [autoDisplayFirst]="false" optionLabel="nome" required>
          </p-dropdown>
          </form>
            <label for="opt_pais">Pais de atuação</label>
        </span>
        <span class="p-float-label p-input-icon-right" style="width: 47.5%; margin-left: 2%; display: inline-block;">
          <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="opt_estado" method="post">
            <p-dropdown [options]="estado" formControlName="estado" [autoDisplayFirst]="false"  optionLabel="nome" required>
            </p-dropdown>
          </form>
            <label  for="opt_estado">Estado de atuação</label>
        </span>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Dados de Funcionário">
      <div id="federacao" class="p-field p-col-1 p-md-1">
        <form action="/pagina-processa-dados-do-form" appendTo="body" [formGroup]="dados_funcionario" method="post">
        <span class="p-float-label p-input-icon-right" style="width: 30%; margin-top: 2%; display: inline-block;">
          <p-dropdown [options]="tipo_funcionario" formControlName="tipo"  [autoDisplayFirst]="false" optionLabel="name" required>
          </p-dropdown>
          <label for="federacao" required>Tipo de Funcionário</label>
        </span>
        <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%; display: inline-block;">
          <p-dropdown [options]="categoria" formControlName="categoria"  [autoDisplayFirst]="false" optionLabel="nome" required>
          </p-dropdown>
            <label for="jogador_selecao">Categoria</label>
       </span>
       <span class="p-float-label p-input-icon-right"  style="width: 30%; margin-left: 2%;  display: inline-block;">
          <input type="text" inputId="integeronly" formControlName="crm" pInputText />
          <label for="federacao_regional">CRM</label>
        </span>
        <span class="p-float-label p-input-icon-right"  style="width: 30%; margin-top: 2%; display: inline-block;">
          <input pInputText  type="text" formControlName="cref" inputId="integeronly"/>
          <label for="federacao" required>CREF</label>
        </span>
        <span class="p-float-label p-input-icon-right"  style="width: 30%; margin-left: 2%;  display: inline-block;">
          <input type="text" inputId="integeronly" formControlName="cna_oab" pInputText/>
          <label for="federacao_regional">CNA(OAB)</label>
         </span>
        <span class="p-float-label p-input-icon-right" style="width: 30%; margin-left: 2%;  display: inline-block;">
          <input type="text" formControlName="pj" inputId="integeronly" pInputText />
          <label for="federacao_regional">CNPJ</label>
        </span>
        <span class="p-float-label p-input-icon-right" style="margin-top: 2%; margin-left: 2%; display: inline-block;"> 
        <label>Ativo?</label>
        <p-toggleButton  formControlName="ativo" [style]="{'width': '10%', 'margin-left': '10%'}" [(ngModel)]="checked1" onLabel="Sim" offLabel="Não"></p-toggleButton>
      </span> 
      <span class="p-float-label p-input-icon-right" style="margin-top: 2%; margin-left: 2%; display: inline-block;"> 
        <label >PJ?</label>
        <p-toggleButton  formControlName="pj" [style]="{'width': '10%', 'margin-left': '10%'}" [(ngModel)]="checked2" onLabel="Sim" offLabel="Não"></p-toggleButton>
      </span>
      </form>
      </div>
    </p-accordionTab>
  </p-accordion>
  </div>
  <div
    style="
      width: 100%;
      margin-top: 1%;
      display: flex;
      float: left;
      justify-content: space-between;
    "
  >
    <button
      pButton
      pRipple
      style="width: 40%"
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      style="width: 40%"
      label="Salvar"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </div>
  <ng-template pTemplate="footer"> </ng-template>
</p-fieldset>

