import { Component, OnInit } from '@angular/core';
import { SearchResultListService } from './search-result-list.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.scss'],
})
export class SearchResultListComponent implements OnInit {
  searchResult: any[];

  constructor(
    private searchResultListService: SearchResultListService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.listSearchResult();
  }

  public listSearchResult() {
    let keywords = this.activatedRoute.snapshot.paramMap.get('search-keys');
    this.searchResultListService.getSearchResult(keywords).subscribe(
      (response) => {
        this.searchResult = response;
        
        
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  addHit(index) {
    this.searchResultListService.addHit(index).subscribe(
      (response) => {
        this.listSearchResult();
        if (index.url.includes('#')) {
          this.redirectTo(index.url.replace('#', ''));
        } else {
          window.open(index.url);
        }
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
