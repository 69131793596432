<app-menubar></app-menubar>
<h1 style="margin-top: 5%; text-align: center;">Relatórios</h1>
<div
    style="width: 100%; margin: auto; box-shadow: 2px 2px 2px #b3b3b3"
  >
    <div style="width: 90%; margin-left: auto; margin-right: auto; margin-top: 1%;">
        <p-table
          responsiveLayout="scroll"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="font-size: smaller; padding: 1px; font-weight: 800">
                Título
              </th>
              <th style="font-size: smaller; padding: 1px; font-weight: 800">
                Descrição
              </th>
              <th style="font-size: smaller; padding: 1px; font-weight: 800">
                Categoria
              </th>
              <th style="font-size: smaller; padding: 1px; font-weight: 800">
                Grupo
              </th>
              <th style="font-size: smaller; padding: 1px; font-weight: 800; width: 5%;">
              </th>
              <th style="font-size: smaller; padding: 1px; font-weight: 800; width: 5%;">
              </th>
            </tr>
            <tr>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Últimas Escalações
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Mostra as últimas 6 escalações da equipe
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Equipes
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Escalação
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                  <button
                  pButton
                  pRipple
                  (click)="pdfLineUp()"
                  icon="pi pi-eye"
                  class="p-button-rounded crud-button"
                  style="
                    color: white !important;
                    background-color: gray !important;
                    "
                ></button>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Desempenho da Equipe
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Mostra o desempenho da equipe em jogos 
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Equipes
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Desempenho
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                  <button
                  pButton
                  pRipple
                  (click)="pdfDesempenhoEquipe()"
                  icon="pi pi-eye"
                  class="p-button-rounded crud-button"
                  style="
                    color: white !important;
                    background-color: gray !important;
                    "
                ></button>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Elenco Completo
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Lista de jogadores ativos no elenco, com detalhes de clubes anteriores e foto
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Equipes
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Elenco
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                  <button
                  pButton
                  pRipple
                  (click)="pdfElencoCompleto()"
                  icon="pi pi-eye"
                  class="p-button-rounded crud-button"
                  style="
                    color: white !important;
                    background-color: gray !important;
                    "
                ></button>
              </td>
            </tr>
            <tr>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Elenco Simples
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Eleco Simples
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Equipes
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Elenco
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                  <button
                  pButton
                  pRipple
                  (click)="elencoSimples()"
                  icon="pi pi-eye"
                  class="p-button-rounded crud-button"
                  style="
                    color: white !important;
                    background-color: gray !important;
                    "
                ></button>
            </td>
          </tr>
            <tr>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Provavel Escalação
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Campo para editar provavel escalação
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Equipes
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                Elenco
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: center;">
                  <button
                  pButton
                  pRipple
                  (click)="provavelEscalacao()"
                  icon="pi pi-eye"
                  class="p-button-rounded crud-button"
                  style="
                    color: white !important;
                    background-color: gray !important;
                    "
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
  </div>

<p-dialog
  [(visible)]="pdfLineUpDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Ultimas Escalações"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-latest-lineups-report></app-latest-lineups-report>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="desempenhoEquipeDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Desempenho Equipe"
  [modal]="true"
  styleClass="p-fluid"
  >
  <ng-template pTemplate="content">
    <app-performance-report></app-performance-report>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="elencoCompletoDialog"
  [style]="{ width: '70%', minHeight: '100%' }"
  header="Elenco"
  [modal]="true"
  styleClass="p-fluid"
  >
  <ng-template pTemplate="content">
    <app-cast-report></app-cast-report>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="provavelEscalacaoDialog"
  [style]="{ width: '70%', minHeight: '100%' }"
  header="Provavel Escalação"
  [modal]="true"
  styleClass="p-fluid"
  >
  <ng-template pTemplate="content">
    <provavel-escalacao></provavel-escalacao>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="elencoSimplesDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Elenco Simples"
  [modal]="true"
  styleClass="p-fluid"
  >
  <ng-template pTemplate="content">
    <app-cast-report-simple></app-cast-report-simple>
  </ng-template>
</p-dialog>