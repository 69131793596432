import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Home } from './home';
import { HomeService } from './home.service';
import { MenuItem } from 'primeng/api';
import { MegaMenuItem } from 'primeng/api'; //required when using MegaMenu

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  items: MenuItem[];

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private fb: FormBuilder,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    if (!localStorage.token) {
      this.router.navigate(['login/']);
    }
  }
}
