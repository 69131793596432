<app-menubar></app-menubar>

<h1>Explore o CIFUT</h1>
<div style="max-width: 100%" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{
      width: '100%',
      height: '70px',
      marginTop: '20%',
      marginBottom: '20%'
    }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div class="cast-container" *ngIf="!inProgress">
  <div class="card-square" *ngFor="let player of response">
    <a href="#/profile/{{ player.id_people }}/">
      <img class="img-card" src="{{ player.image }}" />
      <div class="name-player" style="margin-left: 25%;">
        <p>{{ player.surname }}</p>
      </div>
    </a>
  </div>
</div>
