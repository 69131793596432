import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LatestLinupsReportService } from './latest-lineups-report.service';
import { MessageService } from 'primeng/api';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-latest-lineups-report',
  templateUrl: './latest-lineups-report.component.html',
  styleUrls: ['./latest-lineups-report.component.scss'],
  providers: [LatestLinupsReportService, MessageService],
})
export class LatestLineupsReportComponent implements OnInit {
  inProgress: boolean;
  response: any;
  team: any;
  teams: any[];
  selectedTeam: any;
  @ViewChild('dt',{static: false}) el: ElementRef

  constructor(
    private latestLinupsReportService: LatestLinupsReportService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listTeams();
  }

  public listTeams() {
    this.latestLinupsReportService.getTeams().subscribe(
      (response) => {
        this.inProgress = false;
        this.teams = response;
    
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipes.',
          life: 3000,
        });
      }
    );
  }

  public listCast() {
    this.latestLinupsReportService.getCast(this.selectedTeam.id).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
      
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  public getTeam() {
    this.latestLinupsReportService.getTeam(this.selectedTeam.id).subscribe(
      (response) => {
        this.team = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipe.',
          life: 3000,
        });
      }
    );
  }

  public printPdf(){
    const doc= new jsPDF('l', 'pt',[1200,920], true)
    
    doc.html(this.el.nativeElement, {callback:(doc) =>
    doc.save("Escalação-"+ this.selectedTeam.apelido +".pdf")});
  }

}


