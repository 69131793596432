<app-menubar></app-menubar>
<div class="filters-container">
	<div style="display: flex; float: left; width: 100%; margin-top: 5%;">
		<div>
			<h5>Temporada</h5>
			<p-dropdown [options]="sessions" [(ngModel)]="selectedSession"
				[showClear]="true" placeholder="Selecione a temporada"  (click)="sessionChange()">
				<ng-template pTemplate="selectedItem">
					<div class="session-item session-item-value" *ngIf="selectedSession">
						<div>{{selectedSession}}</div>
					</div>
				</ng-template>
				<ng-template let-session pTemplate="item">
					<div class="session-item">
						<div>{{session}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div>
			<h5>Campeonato</h5>
			<p-dropdown [options]="competitions" [(ngModel)]="selectedCompetition" optionLabel="descricao" [filter]="true" filterBy="descricao"
				[showClear]="true" placeholder="Selecione Campeonato"  (click)="competitionChange()">
				<ng-template pTemplate="selectedItem">
					<div class="competition-item competition-item-value" *ngIf="selectedCompetition">
						<div>{{selectedCompetition.campeonato}}</div>
					</div>
				</ng-template>
				<ng-template let-competition pTemplate="item">
					<div class="competition-item">
						<div>{{competition.campeonato}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div>
			<h5>Fase</h5>
			<p-dropdown [options]="phases" [(ngModel)]="selectedPhase" optionLabel="nome" [filter]="true" filterBy="nome"
				[showClear]="true" placeholder="Selecionar Fase"   (click)="phaseChange()">
				<ng-template pTemplate="selectedItem">
					<div class="phase-item phase-item-value" *ngIf="selectedPhase">
						<div>{{selectedPhase.nome}}</div>
					</div>
				</ng-template>
				<ng-template let-phase pTemplate="item">
					<div class="phase-item">
						<div>{{phase.nome}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div>
			<h5>Rodada</h5>
			<p-dropdown [options]="turns" [(ngModel)]="selectedTurn"
				[showClear]="true" placeholder="Selecione a rodada"  (click)="turnChange()">
				<ng-template pTemplate="selectedItem">
					<div class="turn-item turn-item-value" *ngIf="selectedTurn">
						<div>{{selectedTurn}}</div>
					</div>
				</ng-template>
				<ng-template let-turn pTemplate="item">
					<div class="turn-item">
						<div>{{turn}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div>
			<h5>Jogo</h5>
			<p-dropdown [options]="games" [(ngModel)]="selectedGame" optionLabel="game_name" [filter]="true" filterBy="game_name"
				[showClear]="true" placeholder="Selecione o Jogo">
				<ng-template pTemplate="selectedItem">
					<div class="game-item game-item-value" *ngIf="selectedGame">
						<div>{{selectedGame.game_name}}</div>
					</div>
				</ng-template>
				<ng-template let-game pTemplate="item">
					<div class="game-item">
						<div>{{game.game_name}}</div>
					</div>
				</ng-template>
			</p-dropdown>	
		</div>
	</div>
	<hr />
<div>
	<button
	pButton
	pRipple
	icon="pi pi-file-o"
	class="p-button-rounded crud-button"
	(click)="pdfLineUp()"
  ></button>
</div>
<div  *ngIf='selectedGame'>
	<button pButton label="Gerenciar Atividades" class="p-button-rounded" style="margin-left: 10%; width: 75%;margin-top: 1%;" (click)="openActivities()"></button>
</div>
<div style="display: flex; float: left; width: 100%" *ngIf='selectedGame'>
	
	<app-clipboard teamId={{selectedGame.commanding_team_id}} gameId={{selectedGame.id}}></app-clipboard>
	<app-clipboard teamId={{selectedGame.visiting_team_id}}  gameId={{selectedGame.id}}></app-clipboard>
</div> 

<div style="display: flex; float: left; width: 100%" *ngIf='!selectedGame'>
	<h1>Nenhum jogo selecionado!</h1>
</div> 

<p-dialog
  [(visible)]="activitiesDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Atividades"
  [modal]="true"
  styleClass="p-fluid"
>
<ng-template pTemplate="content">
    <app-activities-crud
      gameId="{{ selectedGame.id }}"
      visitingTeamId="{{ selectedGame.visiting_team_id }}"
      commandingTeamId="{{ selectedGame.commanding_team_id }}"
    ></app-activities-crud>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="pdfLineUpDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Ultimas Escalações"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-latest-lineups-report></app-latest-lineups-report>
  </ng-template>
</p-dialog>
