import { Component, OnInit } from '@angular/core';
import { ReportAtividadesService } from './report-atividades.service'
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-report-atividades',
  templateUrl: './report-atividades.component.html',
  styleUrls: ['./report-atividades.component.scss']
})
export class ReportAtividadesComponent implements OnInit {
  departamento: any;
  atividades: any;
  selectedDepartamento: any;
  cast: any[] = [];
  form: FormGroup;
  selectedTypeRport: any;
  formato: any;
  file: any;

  constructor(private reportAtividadeService: ReportAtividadesService,private fb: FormBuilder, private  router: Router) {
    this.form = this.fb.group({inicio:[null], final:[null], departamento:[null], atividade:[null], atleta:[null], formato:[null]})
   }

  ngOnInit(): void {
    this.getDp();
    this.listCast();
  }

  
  listCast(){
    this.reportAtividadeService.getCast().subscribe(
      data => { 
        for(let n in data){
          for(let i in data[n].players){
              this.cast.push(data[n].players[i])
          };
           }
           console.log(this.cast)
          }
    )
  }
  

  getAtividadeDp(){
    if(this.selectedDepartamento){
      this.reportAtividadeService.getDpAtividade(this.selectedDepartamento.id).subscribe(
      data=> {this.atividades = data; console.log(this.atividades)}
    )}
    
  }

  getDp(){
   
    this.reportAtividadeService.getDpFem().subscribe(
      data=> { this.departamento = data; this.getAtividadeDp()})
      
        
  }

  public departamentoChange() {
    this.getAtividadeDp();
  }
  
  public acessarProcesso(linkAcesso) {
    window.open(linkAcesso.toString(), '_blank');
  }
  
  public getReport(){
    this.selectedTypeRport = document.querySelector('input[name="formato"]:checked')
    this.form.value.formato = this.selectedTypeRport.value
    console.log(this.form.value)
    this.reportAtividadeService.getRport(this.form.value).subscribe(
      data => {this.acessarProcesso(data);}
    )
  }



}
