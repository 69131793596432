import { Component, OnInit, Input} from '@angular/core';
import { CompetitionTableService } from './competition-table.service';

@Component({
  selector: 'app-competition-table',
  templateUrl: './competition-table.component.html',
  styleUrls: ['./competition-table.component.scss'],
})
export class CompetitionTableComponent implements OnInit {
  @Input() fase: any;
  
  response: any[];
  columns: any[];
  inProgress: boolean;


  constructor(private competitionTableService: CompetitionTableService) {}

  ngOnInit(): void {
    this.inProgress = true;
    
    this.listClassification(this.fase)
    this.columns = [
      '#',
      'Equipe',
      'P',
      'PJ',
      'V',
      'E',
      'D',
      'GP',
      'GC',
      'SG',
      '%',
      'Jogos Anteriores',
    ];
  }

  public listClassification(fase) {
    this.competitionTableService.getClassification(fase).subscribe(
      (response) => {
        this.inProgress = false;
        
        
        this.response = response;

      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public score(PJ, P) {
    return (parseInt(PJ) / (parseInt(P) * 3)) * 100;
  }
}
