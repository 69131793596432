import { Injectable } from '@angular/core';
import { novoGrupoUsuario } from './grupousuario';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  url = localStorage.host + '/usuario/';
  url2 = localStorage.host + '/usergroups/';
  url4 = localStorage.host + '/authusergroups/';
  url5 = localStorage.host + '/regras_usuario/';
  urlModalidade = localStorage.host + '/modalidade/';
  urlCategoria = localStorage.host + '/categoria/?modality_id=';
  urlGrupo = localStorage.host + '/grupo/';

  constructor(private http: HttpClient) { }

  public delete(id: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.delete(this.url + id + '/', { headers: header });
  }

  public atualizaRolaps(id: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + id + '/', { headers: header });
  }

  public uprolaps(id: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + id + '/', { headers: header });
  }
  public getRegras(id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url5 + '?id=' + id, { headers: header });
  }

  public getModalidade(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlModalidade, { headers: header });
  }

  public getCategoria(selectedModalidade): Observable<any> {

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlCategoria + selectedModalidade.id, { headers: header });
  }


  getRolAps(): Observable<any[]> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get<any[]>(this.url, { headers: header });
  }

  getUserGroups(): Observable<any[]> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get<any[]>(this.url2, { headers: header });
  }

  public saveUserGroup(novogrupousuario: novoGrupoUsuario): Observable<novoGrupoUsuario> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<novoGrupoUsuario>(this.url4, novogrupousuario, { headers: header });
  }

  public updateUserGroup(id: any, novogrupousuario: novoGrupoUsuario): Observable<novoGrupoUsuario> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.put<novoGrupoUsuario>(this.url4 + id + '/', novogrupousuario, { headers: header });
  }


  uprolapss(id: string): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + id + '/', { headers: header });
  }

  saveUsreRule(form) {
    console.log(form[0])
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post('https://api.csm.corinthians.com.br/user_role/', form[0], { headers: header });
  }

  saveAllRulesCategoria(form) {
    console.log(form[0])
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post('https://api.csm.corinthians.com.br/user_role/', form[0], { headers: header });
  }

}




