import { Component, OnInit } from '@angular/core';
import { DashCalendarService } from './dash-calendar.service';
import { CalendarOptions } from '@fullcalendar/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dash-calendar',
  templateUrl: './dash-calendar.component.html',
  styleUrls: ['./dash-calendar.component.scss'],
})
export class DashCalendarComponent implements OnInit {
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    events: [],
  };

  events: any[];
  options: any;
  show: boolean;
  inProgress: boolean;

  constructor(
    private dashCalendarService: DashCalendarService,
    private router: Router
  ) {}

  ngOnInit() {
    this.show = false;
    this.inProgress = true;
    this.listEvents();
  }

  public listEvents() {
    this.dashCalendarService.getEvents().subscribe(
      (response) => {
        this.inProgress = false;
        this.events = response;
        this.calendarOptions.events = response;
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  openCalendar() {
    this.show = true;
  }

  closeCalendar() {
    this.show = false;
  }

  goToExplore() {
    this.router.navigate(['explorer/']);
  }

  handleDateClick(arg) {
    alert('date click! ' + arg.dateStr);
  }
}
