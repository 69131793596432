import { ConfiguracoesComponent } from './configuracoes.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { DespublicadorComponent } from './despublicador/despublicador.component';
import { EstuturasCorpoComponent } from './estruturas-corpo/estruturas-corpo.component';
import { FuncaoFutebolComponent } from './funcao-futebol/funcao-futebol.component';
import { GruposComponent } from './grupos/grupos.component';
import { IndicesAvaliacaoComponent } from './indices-avaliacao/indices-avaliacao.component';
import { LocaisComponent } from './locais/locais.component';
import { LocaisCorpoComponent } from './locais-corpo/locais-corpo.component';
import { MecanismosComponent } from './mecanismos/mecanismos.component';
import { MoedasComponent } from './moedas/moedas.component';
import { MotivoCartaoComponent } from './motivo-cartao/motivo-cartao.component';
import { MultasComponent } from './multas/multas.component';
import { ParametroJogadorComponent } from './parametro-jogador/parametro-jogador.component';
import { PosicoesComponent } from './posicoes/posicoes.component';
import { RateioPremiacaoComponent } from './rateio-premiacao/rateio-premiacao.component';
import { ReferenciaCargaComponent } from './referencia-carga/referencia-carga.component';
import { SeveridadesComponent } from './severidades/severidades.component';
import { TipoEventoComponent } from './tipo-evento/tipo-evento.component';

import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';


export const routes: Routes = [
  {
    path: 'configuracoes',
    component: ConfiguracoesComponent,
  },
  {
    path: 'categoria',
    component: CategoriaComponent,
  },
  {
    path: 'despublicador',
    component: DespublicadorComponent,
  },
  {
    path: 'estruturas-corpo',
    component: EstuturasCorpoComponent,
  },
  {
    path: 'funcao-futebol',
    component: FuncaoFutebolComponent,
  },
  {
    path: 'grupo',
    component: GruposComponent,
  },
  {
    path: 'indices-avaliacao',
    component: IndicesAvaliacaoComponent,
  },
  {
    path: 'locais',
    component: LocaisComponent,
  },
  {
    path: 'locais-corpo',
    component: LocaisCorpoComponent,
  },
  {
    path: 'mecanismo',
    component: MecanismosComponent,
  },
  {
    path: 'moedas',
    component: MoedasComponent,
  },
  {
    path: 'motivo-cartao',
    component: MotivoCartaoComponent,
  },
  {
    path: 'multas',
    component: MultasComponent,
  },
  {
    path: 'parametro-jogador',
    component: ParametroJogadorComponent,
  },
  {
    path: 'posicoes',
    component: PosicoesComponent,
  },
  {
    path: 'rateio-premiacao',
    component: RateioPremiacaoComponent,
  },
  {
    path: 'referencia-carga',
    component: ReferenciaCargaComponent,
  },
  {
    path: 'severidades',
    component: SeveridadesComponent,
  },
  {
    path: 'tipo-evento',
    component: TipoEventoComponent,
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfiguracoesRoutingModule { }
