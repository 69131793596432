<app-menubar></app-menubar>
<p-toast></p-toast>

<div class="card crud-container">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
      <button
        pButton
        pRipple
        [label]="Remover"
        icon="pi pi-trash"
        class="p-button-danger"
        (click)="deleteSelectedDepartments()"
        [disabled]="!selectedDepartments || !selectedDepartments.length"
      ></button>
    </ng-template>

    <!--
    <ng-template pTemplate="right">
      <p-fileUpload
        mode="basic"
        accept="image/*"
        [maxFileSize]="1000000"
        label="Importar"
        chooseLabel="Import"
        class="p-mr-2 p-d-inline-block"
      ></p-fileUpload>
      <button
        pButton
        pRipple
        label="Exportar"
        icon="pi pi-upload"
        class="p-button-help"
      ></button>
    </ng-template>
    -->
  </p-toolbar>

  <p-table
    #dt
    [value]="departments"
    [rows]="10"
    [paginator]="true"
    [globalFilterFields]="['name', 'created_by', 'updated_at']"
    [(selection)]="selectedDepartments"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <h5 class="p-m-0">Departamentos</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="name">
          Nome <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="created_by">
          Criador por <p-sortIcon field="created_by"></p-sortIcon>
        </th>
        <th pSortableColumn="updated_at">
          Atualizado em <p-sortIcon field="updated_at"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-department>
      <tr>
        <td>
          <p-tableCheckbox [value]="department"></p-tableCheckbox>
        </td>
        <td>{{ department.name }}</td>
        <td>
          {{ department.created_by }}
        </td>
        <td>{{ department.updated_at }}</td>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-eye"
            class="p-button-rounded crud-button"
            (click)="showDepartment(department)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded crud-button"
            (click)="editDepartment(department)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded crud-button"
            (click)="deleteDepartment(department)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are
        {{ departments ? departments.length : 0 }} departments.
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="departmentDialog"
  [style]="{ width: '50%' }"
  header="Department Details"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-field">
      <label for="name">Nome do departamento</label>
      <input
        type="text"
        pInputText
        id="name"
        [(ngModel)]="department.name"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !department.name"
        >Name is required.</small
      >
    </div>
    <div class="p-field">
      <label for="department_head_name">Nome do responsável</label>
      <input
        type="text"
        pInputText
        id="department_head_name"
        [(ngModel)]="department.department_head_name"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small
        class="p-invalid"
        *ngIf="submitted && !department.department_head_name"
        >Head Name is required.</small
      >
    </div>
    <div class="p-field">
      <label for="department_head_email">Email do responsável</label>
      <input
        type="text"
        pInputText
        id="department_head_email"
        [(ngModel)]="department.department_head_email"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small
        class="p-invalid"
        *ngIf="submitted && !department.department_head_email"
        >Head Email is required.</small
      >
    </div>
    <div class="p-field">
      <label for="department_head_phone">Telefone do responsável</label>
      <input
        type="text"
        pInputText
        id="department_head_phone"
        [(ngModel)]="department.department_head_phone"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small
        class="p-invalid"
        *ngIf="submitted && !department.department_head_phone"
        >Head Phone is required.</small
      >
    </div>
    <div class="p-field">
      <label for="department_head_extension">Ramal do responsável</label>
      <input
        type="text"
        pInputText
        id="department_head_extension"
        [(ngModel)]="department.department_head_extension"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small
        class="p-invalid"
        *ngIf="submitted && !department.department_head_extension"
        >Head extension is required.</small
      >
    </div>

    <div class="p-field">
      <label for="description">Description</label>
      <textarea
        id="description"
        pInputTextarea
        [(ngModel)]="department.description"
        required
        rows="3"
        cols="20"
        [disabled]="verb === 'GET'"
      ></textarea>
      <small class="p-invalid" *ngIf="submitted && !department.description"
        >Description is required.</small
      >
    </div>
    <div class="p-field">
      <label for="type"
        >Deseja possuir uma página restrita para seu departamento?</label
      >
      <div class="input-radio">
        <p-radioButton
          name="groupname"
          value="true"
          [(ngModel)]="department.is_restricted"
          [disabled]="verb === 'GET'"
        ></p-radioButton>
        <label>Sim</label>
      </div>
      <div class="input-radio">
        <p-radioButton
          name="groupname"
          value="false"
          [(ngModel)]="department.is_restricted"
          [disabled]="verb === 'GET'"
        ></p-radioButton>
        <label>Não</label>
      </div>
      <small class="p-invalid" *ngIf="submitted && !department.is_restricted"
        >Restrict is required.</small
      >
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
