import { Component, OnInit, Input } from '@angular/core';
import { SelectModalityService } from './select-modality.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-select-modality',
  templateUrl: './select-modality.component.html',
  styleUrls: ['./select-modality.component.scss'],
  providers: [MessageService],
})
export class SelectModalityComponent implements OnInit {
  modalitys: any[];
  roles: any[];
  user: any;
  inProgress: boolean;

  constructor(
    private route: ActivatedRoute,
    private selectModalityService: SelectModalityService,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
   
    localStorage.host = 'https://api.csm.corinthians.com.br';
    this.route.queryParams.subscribe(params => {
      this.user = params.user;
      console.log(params)
      localStorage.token = params.token
    }
  )
  
    this.inProgress = true;
    localStorage.modality_id = '';
    localStorage.modality_name = '';
    localStorage.modality_legend = '';
    localStorage.category_id = '';
    localStorage.category_name = '';
    localStorage.category_legend = '';
    this.listModalitys();
  }

  public goTo(modality, category) {
    localStorage.modality_id = modality.id;
    localStorage.modality_name = modality.name;
    localStorage.modality_legend = modality.legend;
    localStorage.category_id = category.id;
    localStorage.category_name = category.name;
    localStorage.category_legend = category.legend;
    this.router.navigate(['dashboard/' + category.legend]);
  }

  public listModalitys() {
    // let department = this.activatedRoute.snapshot.paramMap.get('id');

    this.selectModalityService.getUserRoles(this.user).subscribe(
      (response) => {
        this.inProgress = false;
        this.roles = response;
        console.log(this.roles);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar modalidades.',
          life: 3000,
        });
      }
    );
  }
}