import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class TeamService {
  url = localStorage.host + '/teams/';
  constructor(private http: HttpClient) { }

  public getTeam(id): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + id + '/', {
      headers: header,
    });
  }
}
