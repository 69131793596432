import { Component, OnInit, Input } from '@angular/core';
import { CastReportSimpleService } from './cast-report-simple.service';
import { MessageService } from 'primeng/api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-cast-report-simple',
  templateUrl: './cast-report-simple.component.html',
  styleUrls: ['./cast-report-simple.component.scss'],
  providers: [CastReportSimpleService, MessageService],
})
export class CastReportSimpleComponent implements OnInit {
  inProgress: boolean;
  response: any;
  team: any;
  teams: any[];
  selectedTeam: any;
  categoria: any;
  modalidade: any;

  constructor(
    private castReportService: CastReportSimpleService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.modalidade = localStorage.modality_legend;
    this.categoria = localStorage.category_legend;
    this.inProgress = true;
    this.listTeams();
  }

  public listCast() {
    this.getTeam();
    this.castReportService.getCast(this.selectedTeam.id).subscribe(
      (response) => {
        
        this.inProgress = false;
        this.response = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  public listTeams() {
    this.castReportService.getTeams().subscribe(
      (response) => {
        this.inProgress = false;
       
        this.teams = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipes.',
          life: 3000,
        });
      }
    );
  }

  public getTeam() {
    this.castReportService.getTeam(this.selectedTeam.id).subscribe(
      (response) => {
        console.log()
        this.team = response; console.log(this.modalidade, this.categoria)
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipe.',
          life: 3000,
        });
      }
    );
  }

  cutUrl(brasao){
  
    let cutUrl = brasao.split("/")
    console.log(cutUrl)
      if(cutUrl[3] == 'media'){
        if(cutUrl[5] == 's3.sa-east-1.amazonaws.com'){
        return 'https://' + cutUrl[5] + '/' +cutUrl[6]+'/'+cutUrl[7]+'/'+cutUrl[8]
    
      }return 'https://' + cutUrl[5] + '/' +cutUrl[6]+'/'+cutUrl[7]
    }
    return brasao
  }

  public getSideString(side: string): string {
    if (side === 'E') {
      return 'Canhoto';
    }
    if (side === 'D') {
      return 'Destro';
    }
    return null;
  }

  cut(surname){
    let name = surname.split(" ", 2)
    return name[0]
  }

  cut2(surname){
    let name = surname.split(" ", 2)
    return name[1]
  }

  exportPdf() {
    let pdf = new jsPDF('l', 'px',[3508, 2480]);
    let element = document.getElementById('pdf');
    pdf.addFont('https://fonts.googleapis.com/css?family=Russo+One', 'Russo One', 'Normal')
    pdf.setFont('Russo One')
    pdf.html(element, {
      callback: (pdf) => {
        pdf.save('CastReport.pdf');
      },
    },);
  }
}
