import { Component, OnInit } from '@angular/core';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import { NextPreviousGameService } from './next-previous-game.service';

@Component({
  selector: 'app-next-previous-game',
  templateUrl: './next-previous-game.component.html',
  styleUrls: ['./next-previous-game.component.scss'],
})
export class NextPreviousGameComponent implements OnInit {
  next: any;
  previous: any;
  inProgress: boolean;

  constructor(private nextPreviousGameService: NextPreviousGameService) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listGames();
  }

  public listGames() {
    this.nextPreviousGameService.getGames().subscribe(
      (response) => {
        
        if (response.length <=1){
          this.inProgress = false;
          this.next = response[0];
          this.previous = false
        }else{
          this.inProgress = false;
          this.previous = response[0];
          this.next = response[1];
        }
      },
      (error) => {
        //alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
