<div class="card">
  <div class="p-mb-3"></div>
  <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
    <p-progressSpinner
      [style]="{
        width: '100%',
        height: '70px',
        marginTop: '20%',
        marginBottom: '20%'
      }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <p-table
    [value]="games[first]"
    [(first)]="first"
    responsiveLayout="scroll"
    *ngIf="!inProgress"
  >
    <ng-template pTemplate="header">
      <div class="manager-container">
        <p-button
          type="button"
          icon="pi pi-chevron-left"
          (click)="prev()"
          [disabled]="isFirstPage()"
          styleClass="p-button-text"
        ></p-button>
        <div style="margin: 10%">Jogos da rodada {{ first }}</div>
        <p-button
          type="button"
          icon="pi pi-chevron-right"
          (click)="next()"
          [disabled]="isLastPage()"
          styleClass="p-button-text"
        ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-game>
      <tr>
        <td>
          {{ game.commanding_team }}
        </td>
        <td>
          {{ game.commanding_score }}
        </td>
        <td>X</td>
        <td>
          {{ game.visiting_score }}
        </td>
        <td>
          {{ game.visiting_team }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
