import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class ArenaService {
  url = localStorage.host + '/cast-game/';
  constructor(private http: HttpClient) { }

  public getJNJCast(teamId, gameId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url + '?team_id=' + teamId + '&game_id=' + gameId,
      {
        headers: header,
      }
    );
  }
}
