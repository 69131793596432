import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class PlayerCrudService {
  url = localStorage.host + '/perfil/';
  contractUrl = localStorage.host + '/contrato/';
  posicaoUrl = localStorage.host + '/posicao/?modalidade_id=1';

  constructor(private http: HttpClient) { }

  public getProfile(profileId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + profileId + '/', {
      headers: header,
    });
  }
  public getPosicoes(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.posicaoUrl, {
      headers: header,
    });
  }

  public getContract(contractId): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.contractUrl + contractId + '/', {
      headers: header,
    });
  }

  public getPessoa(pessoa_id) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(localStorage.host + '/pessoa/' + pessoa_id + '/', {
      headers: header,
    });

  }

  public editProfile(profile: any, id: any): Observable<any> {
    console.log(profile)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.url + id + '/', profile, {
      headers: header,
    });
  }



  public editContract(contract: any, id: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.contractUrl + id + '/', contract, {
      headers: header,
    });
  }
}
