import { Component, OnInit, Input } from '@angular/core';
import { UsageChartsService } from './usage-charts.service';
@Component({
  selector: 'app-usage-charts',
  templateUrl: './usage-charts.component.html',
  styleUrls: ['./usage-charts.component.scss'],
})
export class UsageChartsComponent implements OnInit {
  @Input() competition_id: any;
  response: any;
  atHomeData: any;
  outsideData: any;
  totalData: any;
  inProgress: boolean;

  constructor(private usageChartsService: UsageChartsService) {}

  ngOnInit(): void {
    this.inProgress = true;
    console.log(this.competition_id)
    this.listScore();
  }

  public listScore() {
    this.usageChartsService.getScore(this.competition_id).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
        
        this.atHomeData = {
          labels: ['Virtorias', 'Derrotas', 'Empates'],
          datasets: [
            {
              data: [
                this.response.at_home.victories,
                this.response.at_home.defeats,
                this.response.at_home.draws,
              ],
              backgroundColor: ['#3f51b5', '#9d261d', 'gray'],
              hoverBackgroundColor: ['#3f31a2', '#9d061d', '#4a4e52'],
            },
          ],
        };
        this.outsideData = {
          labels: ['Virtorias', 'Derrotas', 'Empates'],
          datasets: [
            {
              data: [
                this.response.outside.victories,
                this.response.outside.defeats,
                this.response.outside.draws,
              ],
              backgroundColor: ['#3f51b5', '#9d261d', 'gray'],
              hoverBackgroundColor: ['#3f31a2', '#9d061d', '#4a4e52'],
            },
          ],
        };
        this.totalData = {
          labels: ['Virtorias', 'Derrotas', 'Empates'],
          datasets: [
            {
              data: [
                this.response.total.victories,
                this.response.total.defeats,
                this.response.total.draws,
              ],
              backgroundColor: ['#3f51b5', '#9d261d', 'gray'],
              hoverBackgroundColor: ['#3f31a2', '#9d061d', '#4a4e52'],
            },
          ],
        };
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
