<div>
  <p-fieldset legend="Próximo jogo">
    <div class="spin-container" style="max-width: 100%" *ngIf="inProgress"; else noProgress>
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>
    <div *ngIf="!next" >
      <h3>Próximo jogo ainda não cadastrado.</h3>
    </div>
    <br>
    <h1 *ngIf="!inProgress">
      {{ next.commanding_team }} × {{ next.visiting_team }}
    </h1>
    <p *ngIf="!inProgress">
      {{ next.round }}ª rodada do {{ next.turn }}º Turno 
      {{ next.description }} - {{ next.start | date}}
    </p>
  </p-fieldset>
  <br>
  <p-fieldset legend="Jogo anterior">
    <div class="spin-container" style="max-width: 100%" *ngIf="inProgress"; else noProgressAnterior>
      <p-progressSpinner
        [style]="{
          width: '100%',
          height: '70px'
        }"
        styleClass="custom-spinner"
      ></p-progressSpinner>
    </div>
    <div *ngIf="!previous">
      <h3>Problema ao encontrar o jogo anterior.</h3>
    </div>
    <br>
    <h1 *ngIf="!inProgress">
      {{ previous.commanding_team }} {{ previous.commanding_score }} ×
      {{ previous.visiting_score }} {{ previous.visiting_team }}
    </h1>
    <p *ngIf="!inProgress">
      {{ previous.round }}ª rodada do {{ previous.turn }}º Turno 
      {{ previous.description }} {{ previous.start | date }}
    </p>
  </p-fieldset>
  <br>
</div>
