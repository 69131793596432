import { Component, OnInit } from '@angular/core';
import { CaracteristicaService } from './caracteristica.service'; 
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-caracteristica',
  templateUrl: './caracteristica.component.html',
  styleUrls: ['./caracteristica.component.scss']
})
export class CaracteristicaComponent implements OnInit {
  messageService: any;
  response: any;
  teamId: any;
  inProgress: boolean;
  cols: { field: string; header: string; width: string; fontweight:string, borderright:string }[];
  caracteristica: any;
  reportDialog: boolean;
  elenco: any;
  brasao: any;

  constructor( 
    private caracteristicaService: CaracteristicaService, 
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.listCast();
    this.inProgress = true;

    this.cols = [
      {field: 'first', header: 'Jogador', width:'10%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'second', header: 'Características Ofensivas', width: '25%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      {field: 'third', header: 'Características Defencivas', width: '25%', fontweight:'bold', borderright:'1px solid #e5e5e5'},
      
    ]

  }

  public listCast() {
    this.teamId =  this.activatedRoute.snapshot.paramMap.get('id');
    this.caracteristicaService.getCast(this.teamId).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
       
      

      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  
  public getSideString(side: string): string {
   
    if (side === 'C' || side === 'E') {
      return 'Canhoto';
    }
    if (side === 'D') {
      return 'Destro';
    }
    if (side === 'A') {
      return 'Ambidestro';
    }
    return null;
  }

  public addCaracteristica(){
   
    this.caracteristicaService.updateCaracteristica(this.response)
  }

  reportAble(){
    this.reportDialog = true
    this.caracteristicaService.getElenco(this.teamId).subscribe(
      (data) => {this.elenco = data; console.log(data)}
    ); this.getBrasao(this.teamId)
  }

  getBrasao(team){
    this.caracteristicaService.getTeam(team).subscribe(
      data => {
        this.brasao = data['brasao']; console.log(this.brasao)
      }
    )
  }

  cut(history){
    console.log(history)
    if(!history){
      return null
    }else{
    let cut = history.split(";", 4)
    
    return cut[1]
    }
  }
  cut2(history){
    if(!history){
      return null
    }else{
    let cut = history.split(";", 4)
    
    return cut[2]
    }
  }
  cut3(history){
    if(!history){
      return null
    }else{
    let cut = history.split(";", 4)
    
    return cut[3]
    }
  }

  selctPosition(position){
    if(position == 'GOL'){
      return 'circulo_gol'
    }else if(position == 'LATD'){
      return 'circulo_latd'
    }else if(position == 'ZAG'){
      return 'circulo_zag'
    }else if(position == 'LATE'){
      return 'circulo_late'
    }else if(position == 'VOL'){
      return 'circulo_vol'
    }else if(position == 'SVOL'){
      return 'circulo_svol'
    }else if(position == 'EXT'){
      return 'circulo_ext'
    }else if(position == 'MEI'){
      return 'circulo_mei'
    }else if(position == 'ATA'){
      return 'circulo_ata'
    }
  }

  
  exportPdf() {
    
    let pdf = new jsPDF('l', 'px',[3508, 2480]);
    let element = document.getElementById('pdf');
  
    pdf.html(element, {
      callback: (pdf) => {
        
        pdf.save('CastReport.pdf');
      },
    });
  }

}
