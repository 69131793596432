import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-posicoes',
  templateUrl: './posicoes.component.html',
  styleUrls: ['./posicoes.component.scss']
})
export class PosicoesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
