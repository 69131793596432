<p>Você está prestes a renovar o contrato desse jogador com a equipe. O status do contrato atual será desativado.</p>

<p-toast></p-toast>

<div style="height: 400px; display: flex; float: left" class="p-field p-col-12 p-md-4">
    <div style="width: 40%; margin-right:50%" class="p-calendar p-datepicker">
        <label for="inicio">Novo Inicio</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="date1" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" inputId="inicio"></p-calendar>
        <small class="p-invalid" *ngIf="submitted && !date1"
          >Campo obrigatório.</small
        >
    </div>
    <div style="width: 40%; margin-left:50%"  class="p-calendar p-datepicker">
        <label for="termino">Novo Termino</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="date2" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" inputId="termino"></p-calendar>
        <small class="p-invalid" *ngIf="submitted && !date2"
          >Campo obrigatório.</small
        >
    </div>
</div>
<button
  pButton
  pRipple
  label="Salvar"
  icon="pi pi-check"
  class="p-button-text"
  style="margin-top: 40%"
  (click)="submitForm()"
></button>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
