import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PessoasService {

  urlTeams = localStorage.host + '/teams/';
  urlPais = localStorage.host + '/pais/';
  urlEstado = localStorage.host + '/estado/';
  urlPosicao = localStorage.host + '/posicao/';
  urlCategoria = localStorage.host + '/categoria/';
  urlFuncao_futebol = localStorage.host + '/funcao_futebol/'
  urlPerfil = localStorage.host + '/perfil/'
  constructor(private http: HttpClient) { }

  getPais() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlPais, { headers: header });
  }

  getEstado() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlEstado, { headers: header });
  }

  getFuncaoFutebol(perfil) {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlFuncao_futebol + '?tipo=' + perfil, { headers: header });
  }


  getPosicao() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlPosicao +
      '?modalidade_id=' + localStorage.modality_id +
      '&categoria_id=' + localStorage.categoty_id, { headers: header });
  }

  getTeam() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlTeams, { headers: header });
  }

  getCategoria() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.urlCategoria +
      '?modality_id=' + localStorage.modality_id,
      { headers: header });
  }

  postPerfil(perfil) {
    console.log(perfil)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post(
      this.urlPerfil, perfil, { headers: header });
  }

}



