<div style="margin: 2% 2% 2% 5%">
  <button
    pButton
    type="button"
    icon="pi pi-calendar"
    iconPos="left"
    (click)="openCalendar()"
    *ngIf="!show"
  ></button>
  <button
    pButton
    type="button"
    icon="pi pi-times-circle"
    iconPos="left"
    (click)="closeCalendar()"
    *ngIf="show"
  ></button>
  <button pButton type="button" label="Explorar" style="margin-left: 1%; margin-top: 5%;" (click)="goToExplore()" ></button>

  <div
    class="spin-container"
    style="max-width: 100%"
    *ngIf="inProgress && show"
  >
    <p-progressSpinner
      [style]="{
        width: '100%',
        height: '70px',
        marginTop: '20%',
        marginBottom: '20%'
      }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <full-calendar
    style="background-color: white"
    [options]="calendarOptions"
    *ngIf="show && !inProgress"
  ></full-calendar>
</div>
