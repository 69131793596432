import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PostsService } from './posts.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Post } from './post';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
  providers: [PostsService, MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None,
})
export class PostsComponent implements OnInit {
  id: String;

  postDialog: boolean;

  posts: Post[];

  post: Post;

  submitted: boolean;

  verb: string;

  isEmpt: boolean;

  constructor(
    private postsService: PostsService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.isEmpt = false;
    this.listPosts();
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  public listPosts() {
    let department = this.activatedRoute.snapshot.paramMap.get('id');
    this.postsService.getPosts(department).subscribe(
      (response) => {
        this.posts = response;
        if (this.posts.length === 0) {
          this.isEmpt = true;
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar departamentos.',
          life: 3000,
        });
      }
    );
  }

  editPost(post: Post) {
    this.post = { ...post };
    this.postDialog = true;
    this.verb = 'PUT';
  }

  hideDialog() {
    this.postDialog = false;
    this.submitted = false;
  }

  submitForm() {
    this.post.departments = [{ id: this.id }];
    this.post.type = 'department';
    this.post.content = this.post.content
      .split('<img src=')
      .join('<img class="image-post" src=');
    this.postsService.putPost(this.post).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Seu Departamento editado com sucesso.',
          life: 3000,
        });
        this.posts = [...this.posts];
        this.postDialog = false;
        this.post = {};
        this.listPosts();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar departamento.',
          life: 3000,
        });
      }
    );
  }

  deletePost(post) {
    this.postsService.deletePost(post).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Excluído',
          detail: 'O departamento ' + post.name + ' foi excluído com sucesso.',
          life: 3000,
        });
        this.post = {};
        this.listPosts();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail:
            'Houve algum erro ao excluir o departamento ' + post.name + '.',
          life: 3000,
        });
      }
    );
  }
}
