import { Injectable } from '@angular/core';
import { Department } from './department';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DepartmentCRUDService {
  url = localStorage.host + '/departments/';

  constructor(private http: HttpClient) { }

  public getDepartments(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url, { headers: header });
  }

  public getDepartmentsSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <Department[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public getDepartmentsWithOrdersSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <Department[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public postDepartment(department: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url, department, { headers: header });
  }

  public putDepartment(department: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.put<any>(this.url + department.id + '/', department, {
      headers: header,
    });
  }

  public deleteDepartment(department: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.delete<any>(this.url + department.id + '/', {
      headers: header,
    });
  }
}
