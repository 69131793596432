import { Component, OnInit } from '@angular/core';
import { CompetitionsService } from './competitions.service';

@Component({
  selector: 'app-competitions',
  templateUrl: './competitions.component.html',
  styleUrls: ['./competitions.component.scss'],
})
export class CompetitionsComponent implements OnInit {
  activeIndex: number = 0;
  response: any[];
  inProgress: boolean;

  constructor(private competitionsService: CompetitionsService) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.listCompetitions();
  }

  public listCompetitions() {
    this.competitionsService.getCompetitions().subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
        
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }


}
