import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class TeamTimelineService {
  url = localStorage.host + '/team-timeline/';
  game: any;

  constructor(private http: HttpClient, private router: Router) { }

  public getTimeline(idTeam): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + '?team_id=' + idTeam, {
      headers: header,
    });
  }

  public appendToTimeline(idTeam, lastRegistry): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    console.log(idTeam)
    return this.http.get(
      this.url + '?team_id=' + idTeam + '&last_registry=' + lastRegistry,
      {
        headers: header,
      }
    );
  }
  public goToService(game) {
    let gameId = game['id']
    console.log(gameId)
    this.setGame(game)
    this.router.navigateByUrl('/games', {
      state: { selectedGame: gameId }
    })
  }

  setGame(game) {
    this.game = game;
  }
  getGames() {
    return this.game;
  }
}