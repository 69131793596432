<p-toast></p-toast>
<div class="filters-container" style="margin: 5%">
  <h2>Relatório de Elenco</h2>
  <div style="display: flex; float: left; width: 100%">
    <div>
      <h5>Equipe</h5>
      <p-dropdown
        [options]="teams"
        [(ngModel)]="selectedTeam"
        optionLabel="apelido"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o equipe"
      >
        <ng-template pTemplate="selectedItem">
          <div class="team-item team-item-value" *ngIf="selectedTeam">
            <div>{{ selectedTeam.apelido }}</div>
          </div>
        </ng-template>
        <ng-template let-team pTemplate="item">
          <div class="team-item">
            <div>{{ team.apelido }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <button
        pButton
        pRipple
        icon="pi pi-search"
        class="p-button-text"
        (click)="listCast()"
      ></button>
    </div>
  </div>
  <hr />
</div>

<div style="margin: 5%">
  <h1 *ngIf="!response">Nenhuma equipe selecionada</h1>
  <div
    style="width: 100%; margin: auto; box-shadow: 2px 2px 2px #b3b3b3"
    *ngIf="response"
  >
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file-pdf"
      (click)="exportPdf()"
      pTooltip="PDF"
      tooltipPosition="bottom"
      style="
            color: white !important;
            background-color: #000000 !important;
            margin: 2px 10px 0 0;
            width: 37px;
            height: auto;
          "
    ></button>
    <div id="pdf">
      <div
        style="
          width: 100%;
          background-color: black;
          color: white;
          display: flex;
          float: left;
          justify-content: space-between;
          border-bottom: solid 1px white;
          align-items: center;
        "
      >
        <div><img style="width: 80px" src="{{ team.brasao }}" /></div>
        <div style="text-align: center">
          <div style="font-weight: bold">{{ team.apelido }}</div>
          <div>{{ team.nome }}</div>
        </div>
        <div class="font-logo"></div>
      </div>
      <div class="spin-container" style="max-width: 100%" *ngIf="inProgress">
        <p-progressSpinner
          [style]="{
            width: '100%',
            height: '70px',
            marginTop: '20%',
            marginBottom: '20%'
          }"
          styleClass="custom-spinner"
        ></p-progressSpinner>
      </div>
      <div *ngIf="!inProgress" style="width: 100%">
        <div *ngFor="let item of response">
          <div style="background-color: black; color: white">
            {{ item.position }}
          </div>
          <p-table
          [columns]="[item.position]"
          [value]="item.players"
          responsiveLayout="scroll"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 10%;">
                Foto
              </th>
              <th style="width: 10%;">
                Nome
              </th>
              <th style="width: 50%;">
                Hostórico
              </th>
              <th style="width: 20%;">
                Função
              <th >  
              </th>    
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td style="padding: 0px; font-size: x-small; text-align: center; border-right: 1px solid #f8f8f8;">
                <p-avatar image="{{ item.image }}"></p-avatar>  
                {{ item.number }}
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: left; border-right: 1px solid #f8f8f8;">
                <p>{{ item.name }}</p> 
                <p>{{ item.surname }} {{item.year}} {{item.age}}</p>
              </td>
              <td style="padding: 0px;  font-size: x-small; text-align: left; border-right: 1px solid #f8f8f8;">
                {{ item.history }}
            </td>
            <td style="padding: 0px; font-size: x-small; text-align: left; border-right: 1px solid #f8f8f8;">
              <p style="color: blue;">{{ item.positive_points }}</p> 
              <p style="color: red;">{{ item.negative_points }}</p>
          </td>
            </tr>
          </ng-template>
        </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
