<div class="p-col-12 p-md-4 search-container">
  <form [formGroup]="dataForm">
    <div class="p-inputgroup">
      <input
        type="search"
        [(ngModel)]="searchValue"
        pInputText
        placeholder="Como posso ajudar?"
        formControlName="searchValue"
      />
      <button
        type="submit"
        pButton
        pRipple
        icon="pi pi-fw pi-search"
        (click)="search()"
      ></button>
    </div>
  </form>
  <div class="app-options">
    <div class="app-item" *ngFor="let app of apps | searchField: searchValue">
      <a href="#" (click)="addHit(app)">
        <div>
          {{ app.name }}
        </div>
      </a>
    </div>
  </div>
</div>
