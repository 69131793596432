<div id="login-area-content">
  <div class="spin-container" *ngIf="inProgress">
    <p-progressSpinner
      [style]="{ width: '100%', height: '70px', marginTop: '60%' }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" *ngIf="!inProgress">
    <div>
      <p-toast key="error" class="toast-style"></p-toast>
      <p-messages [(value)]="msgs"></p-messages>
      <div class="p-text-center font-logo">
      <img style="width: 75%; margin-top: -25%;" src="/assets/imgs/CSM_preto_h.png" /></div>
      <!-- <div class="p-inputgroup login-size" style="margin-top: -25%; width: 60%;">
        <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
        <input
          type="text"
          [(ngModel)]="loginform.username"
          formControlName="username"
          pInputText
          placeholder="Usuário"
          class="p-inputtext-lg"
          
        />
      </div>
      <div class="ui-grid-col-4">
        <div
          [hidden]="
            loginForm.controls['username'].valid ||
            !loginForm.controls['username'].dirty
          "
          class="alert alert-danger"
        >
          Login is required
        </div>
      </div>
      <br />
      <div class="p-inputgroup login-size" style="width: 60%;">
        <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
        <p-password
          [feedback]="false"
          placeholder="Senha"
          class="p-inputtext-lg passdw"
          [(ngModel)]="loginform.password"
          formControlName="password"
        >
        </p-password>
      </div>
      <div class="ui-grid-col-4">
        <div
          [hidden]="
            loginForm.controls['password'].valid ||
            !loginForm.controls['password'].dirty
          "
          class="alert alert-danger"
        >
          Password is required
        </div>
      </div> -->
      <a
        id="btn-login"
        pButton
        pRipple
        type="submit"
        label="Acessar"
        class="p-button-warning"
       
        href="https://api.csm.corinthians.com.br/auth/sign_in"
        style="width: 40%; margin-top: 5%;"
      ></a>
      <div class="p-inputgroup login-size" style="margin-top: -7px; font-size: 5px; margin-left: 55px;">
        <div id="recuperar-senha"   >
          <i class="pi pi-lock lock-mobile">
          <a class="link-login" (click)="esqueciSenha()" style="cursor: pointer;font-size: 12px; margin-top: 200px;">Esqueceu a senha?</a></i>
          <img  style="width: 100%; margin-top: 70px;" src="/assets/imgs/IPTEC HPreto.png">
        </div>
      </div>
    </div>
  </form>
</div>
<div class="global-login-area"></div>
<div id="login-area"></div>

