import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-despublicador',
  templateUrl: './despublicador.component.html',
  styleUrls: ['./despublicador.component.scss']
})
export class DespublicadorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
