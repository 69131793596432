<app-menubar></app-menubar>
<div class="modality-container">
  <h1 class="par">
    Para iniciar, selecione a Modalidade e a Categoria
  </h1>
</div>
<div class="spin-container" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{ width: '100%', marginTop: '5%' }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>
<div class="modality-container" *ngIf="!inProgress">
  <div *ngIf="roles.length == 0">
    <p-card >
      Entre em contato com o T.I para liberar regras pra uso do sistema 
    </p-card>
  </div>
  <p-card class="modality-item" *ngFor="let role of roles">
    <ng-template pTemplate="header">
      <div class="font-modality">{{ role.modality.name }}</div>
    </ng-template>
    <div class="modality-item" *ngFor="let category of role.category">
      <button
        pButton
        pRipple
        type="button"
        (click)="goTo(role.modality, category)"
        class="p-button-secondary p-button-text btn-category"
      >
        <span class="cat-name">{{ category.name }}</span>
        <div class="category-item font-category">
          <span class="font-modality-legend">
            {{ role.modality.legend }}
          </span>
          <span class="font-category-legend">
            {{ category.legend }}
          </span>
        </div>
      </button>
      <hr />
    </div>
  </p-card>
</div>