
<div style="max-width: 100%" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{
      width: '100%',
      height: '70px',
      marginTop: '20%',
      marginBottom: '20%'
    }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>

<div class="card crud-container" *ngIf="!inProgress">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        icon="pi pi-plus"
        class="p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
    </ng-template>
  </p-toolbar>

  <p-table
    #dt
    [value]="activities"
    [rows]="50"
    [paginator]="true"
    [globalFilterFields]="['time', 'type', 'player', 'team', 'description']"
    [(selection)]="selectedActivities"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <h5 class="p-m-0">Atividades</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem" pSortableColumn="team"></th>
        <th style="width: 3rem" pSortableColumn="time">
          <p-sortIcon
            style="margin-left: none; text-align: none"
            field="time"
          ></p-sortIcon>
        </th>
        <th style="width: 8rem" pSortableColumn="type">Tipo</th>
        <th pSortableColumn="player">Sujeito</th>
        <th pSortableColumn="description">Descrição</th>
        <th style="width: 8rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-activity>
      <tr>
        <td>
          <img
            src="{{ activity.team_logo }}"
            alt="{{ activity.team }}"
            style="width: 30px; height: 30px"
          />
        </td>
        <td>{{ activity.time }}`</td>
        <td>
          <div *ngIf="activity.identity === 'atividade_gol'">Gol</div>
          <div *ngIf="activity.identity === 'atividade_cartao'">
            Cartão
            <span *ngIf="activity.color === 'A'">Amarelo</span>
            <span *ngIf="activity.color === 'V'">Vermelho</span>
            <span *ngIf="activity.color === 'D'">Vermelho</span>
          </div>
          <div *ngIf="activity.identity === 'atividade_substituicao'">
            Substituição
          </div>
          <div *ngIf="activity.identity === 'atividade_penalti'">Penalti</div>
          <div *ngIf="activity.identity === 'atividade_cartao_comissao'">
            Cartão Comissão
          </div>
          <div *ngIf="activity.identity === 'atividade_arbitro'">
            Ocorrência de Árbitro
          </div>
        </td>
        <td>{{ activity.player_number }} - {{ activity.player }}</td>
        <td>
          <div *ngIf="activity.identity === 'atividade_substituicao'">
            <p-sortIcon
              style="margin-left: none; text-align: none"
              field="sub"
            ></p-sortIcon>
            {{ activity.output_player_number }} - {{ activity.output_player }}
          </div>
          <div *ngIf="activity.identity !== 'atividade_substituicao'">
            {{ activity.description }}
            <p-tag
              styleClass="p-mr-2"
              value="{{ activity.move }}"
              *ngIf="activity.move"
            ></p-tag>
          </div>
        </td>
        <td>
          <!-- 
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded crud-button"
            (click)="editActivity(activity)"
          ></button>
          -->
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded crud-button"
            (click)="deleteActivity(activity)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        No total, existem
        {{ activities ? activities.length : 0 }} atividades.
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="activityDialog"
  [style]="{ width: '80%' }"
  header="Atividade {{ selectedActivityType?.name }} - {{
    selectedTeam?.apelido
  }}"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div *ngIf="!selectedActivityType">
      <h1>Selecione o tipo de Atividade:</h1>
      <p-selectButton
        [options]="activityTypes"
        [(ngModel)]="selectedActivityType"
        optionLabel="code"
      >
        <ng-template let-item>
          <img
            src="{{ item.img }}"
            alt="{{ item.name }}"
            style="width: 25px; height: 25px"
          />
        </ng-template>
      </p-selectButton>
    </div>
    <!-- gol -->
    <div *ngIf="selectedActivityType?.code === 'atividade_gol'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field-checkbox" *ngIf="selectedTeam">
        <label for="contra">Gol Contra?</label>
        <p-checkbox
          [(ngModel)]="activity.contra"
          binary="true"
          inputId="binary"
        ></p-checkbox>
      </div>
      <div class="p-field-checkbox" *ngIf="selectedTeam && !activity.contra">
        <label for="pass">Assistencia?</label>
        <p-checkbox
          [(ngModel)]="pass"
          binary="true"
          inputId="binary"
        ></p-checkbox>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId && pass">
        <label for="jogador_assistencia_id">Assistente</label>
        <p-dropdown
          id="jogador_assistencia_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador que fez a assistencia"
          [(ngModel)]="activity.jogador_assistencia_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId && pass">
        <label for="jogador_assistencia_id">Assistente</label>
        <p-dropdown
          id="jogador_assistencia_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador que fez a assistencia"
          [(ngModel)]="activity.jogador_assistencia_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="origem">Origem</label>
        <p-dropdown
          id="origem"
          [options]="origins"
          placeholder="Selecione a origem da jogada"
          [(ngModel)]="activity.origem"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="parte_do_corpo">Parte Corpo</label>
        <p-dropdown
          id="parte_do_corpo"
          [options]="bodyParts"
          placeholder="Selecione a parte do corpo"
          [(ngModel)]="activity.parte_do_corpo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="jogada">Jogada</label>
        <p-dropdown
          id="jogada"
          [options]="moves"
          placeholder="Selecione a jogada"
          [(ngModel)]="activity.jogada"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tipo">Tipo</label>
        <p-dropdown
          id="tipo"
          [options]="types"
          placeholder="Selecione o tipo"
          [(ngModel)]="activity.tipo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
    </div>

    <!-- Substituição -->
    <div *ngIf="selectedActivityType?.code === 'atividade_substituicao'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_substituido_id">Jogador Substituido</label>
        <p-dropdown
          id="jogador_substituido_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador substituido"
          [(ngModel)]="activity.jogador_substituido_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_substituido_id">Jogador Substituido</label>
        <p-dropdown
          id="jogador_substituido_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador Substituido"
          [(ngModel)]="activity.jogador_substituido_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
    </div>

    <!-- cartão -->
    <div *ngIf="selectedActivityType?.code === 'atividade_cartao'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field-checkbox" *ngIf="selectedTeam">
        <label for="justo"
          >Desmarque se o cartão foi aplicado injustamente</label
        >
        <p-checkbox
          [(ngModel)]="activity.justo"
          binary="true"
          inputId="binary"
        ></p-checkbox>
      </div>
      <div *ngIf="selectedTeam">
        <label for="cor">Cor</label>
        <p-selectButton
          [options]="cardTypes"
          [(ngModel)]="activity.cor"
          optionLabel="code"
        >
          <ng-template let-item>
            <img
              src="{{ item.img }}"
              alt="{{ item.name }}"
              style="width: 25px; height: 25px"
            />
            {{ item.name }}
          </ng-template>
        </p-selectButton>
      </div>
    </div>

    <!-- cartão comissão -->
    <div *ngIf="selectedActivityType?.code === 'atividade_cartao_comissao'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="comissao_id">Membro da Comissão</label>
        <p-dropdown
          id="comissao_id"
          [options]="commandingCommission"
          placeholder="Selecione o membro da comissão"
          [(ngModel)]="activity.comissao_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="comissao_id">Membro da Comissão</label>
        <p-dropdown
          id="comissao_id"
          [options]="visitingCommission"
          placeholder="Selecione o membro da comissão"
          [(ngModel)]="activity.comissao_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field-checkbox" *ngIf="selectedTeam">
        <label for="justo"
          >Desmarque se o cartão foi aplicado injustamente</label
        >
        <p-checkbox
          [(ngModel)]="activity.justo"
          binary="true"
          inputId="binary"
        ></p-checkbox>
      </div>
      <div *ngIf="selectedTeam">
        <label for="cor">Cor</label>
        <p-selectButton
          [options]="cardTypes"
          [(ngModel)]="activity.cor"
          optionLabel="code"
        >
          <ng-template let-item>
            <img
              src="{{ item.img }}"
              alt="{{ item.name }}"
              style="width: 25px; height: 25px"
            />
            {{ item.name }}
          </ng-template>
        </p-selectButton>
      </div>
    </div>

    <!-- penalti -->
    <div *ngIf="selectedActivityType?.code === 'atividade_penalti'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
      <div
        class="p-field"
        *ngIf="selectedTeam && selectedTeam?.id != commandingTeamId"
      >
        <label for="goleiro_id">Goleiro</label>
        <p-dropdown
          id="goleiro_id"
          [options]="commandingCast"
          placeholder="Selecione o goleiro"
          [(ngModel)]="activity.goleiro_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div
        class="p-field"
        *ngIf="selectedTeam && selectedTeam?.id != visitingTeamId"
      >
        <label for="goleiro_id">Goleiro</label>
        <p-dropdown
          id="goleiro_id"
          [options]="visitingCast"
          placeholder="Selecione o goleiro"
          [(ngModel)]="activity.goleiro_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="chute">Chute</label>
        <p-dropdown
          id="chute"
          [options]="kickOptions"
          placeholder="Selecione o tipo de chute"
          [(ngModel)]="activity.chute"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="pe">Pé</label>
        <p-dropdown
          id="pe"
          [options]="footOptions"
          placeholder="Selecione o pé"
          [(ngModel)]="activity.pe"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="resultado">Resultado</label>
        <p-dropdown
          id="resultado"
          [options]="resultOptions"
          placeholder="Selecione o resultado"
          [(ngModel)]="activity.resultado"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
    </div>

    <!-- procedimento -->
    <div *ngIf="selectedActivityType?.code === 'atividade_procedimento'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
    </div>

    <!-- passe -->
    <div *ngIf="selectedActivityType?.code === 'atividade_passe'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_id">Jogador</label>
        <p-dropdown
          id="jogador_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == commandingTeamId">
        <label for="jogador_id">Passe De</label>
        <p-dropdown
          id="jogador_id"
          [options]="commandingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam?.id == visitingTeamId">
        <label for="jogador_id">Passe De</label>
        <p-dropdown
          id="jogador_id"
          [options]="visitingCast"
          placeholder="Selecione o jogador"
          [(ngModel)]="activity.jogador_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
    </div>

    <!-- Arbitro -->
    <div *ngIf="selectedActivityType?.code === 'atividade_arbitro'">
      <div class="p-field" *ngIf="!selectedTeam">
        <h1>Selecione a equipe:</h1>
        <p-selectButton
          id="team"
          [options]="teams"
          [(ngModel)]="selectedTeam"
          optionLabel="apelido"
        >
          <ng-template let-item>
            <img
              src="{{ item.brasao }}"
              alt="{{ item.apelido }}"
              style="width: 30px; height: 30px"
            />
            {{ item.apelido }}
          </ng-template>
        </p-selectButton>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="periodo">Periodo</label>
        <p-dropdown
          id="periodo"
          [options]="periods"
          placeholder="Selecione o periodo"
          [(ngModel)]="activity.periodo"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="tempo">Minutos</label>
        <input
          type="number"
          pInputText
          id="tempo"
          placeholder="Selecione o minuto"
          [(ngModel)]="activity.tempo"
          required
          min="0"
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !activity.tempo"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="duracao">Duração</label>
        <p-dropdown
          id="duracao"
          [options]="durations"
          placeholder="Selecione a duração"
          [(ngModel)]="activity.duracao"
          required
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="arbitro_id">Arbitro</label>
        <p-dropdown
          id="arbitro_id"
          [options]="arbitrationCast"
          placeholder="Selecione o Arbitro"
          [(ngModel)]="activity.arbitro_id"
          required
          optionLabel="surname"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="p-field" *ngIf="selectedTeam">
        <label for="descricao">Descrição</label>
        <textarea
          id="descricao"
          pInputTextarea
          [(ngModel)]="activity.descricao"
          required
          rows="3"
          cols="20"
        ></textarea>
        <small class="p-invalid" *ngIf="submitted && !activity.descricao"
          >Campo obrigatório.</small
        >
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
