<div>
  <p-table
    #dt
    [value]="searchResult"
    [rows]="10"
    [paginator]="true"
    [globalFilterFields]="['name']"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Resultados</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-app>
      <tr class="item-result-container">
        <div class="item-result">
          <div>
            <a href="#" (click)="addHit(app)">
              <h5 class="p-mb-2">{{ app.name }}</h5>
            </a>

            <span class="post-category">{{ app.description }}</span>
          </div>
        </div>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are
        {{ searchResult ? searchResult.length : 0 }} extensions.
      </div>
    </ng-template>
  </p-table>
</div>
