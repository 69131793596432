import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'menu',
    redirectTo: '/menu',
    pathMatch: 'full',
  },
  {
    path: 'department/:id',
    redirectTo: '/department/:id',
    pathMatch: 'prefix',
  },
  {
    path: 'search-result/:search-keys',
    redirectTo: '/search-result/:search-keys',
    pathMatch: 'prefix',
  },
  {
    path: 'generic-crud',
    redirectTo: '/generic-crud',
    pathMatch: 'full',
  },
  {
    path: 'department-crud',
    redirectTo: '/department-crud',
    pathMatch: 'full',
  },
  {
    path: 'post-crud',
    redirectTo: '/post-crud',
    pathMatch: 'full',
  },
  {
    path: 'select-modality',
    redirectTo: '/select-modality/:user',
    pathMatch: 'prefix',
  },
  {
    path: 'dashboard/:modality',
    redirectTo: '/dashboard/:modality',
    pathMatch: 'prefix',
  },
  {
    path: 'configuracoes',
    redirectTo: '/configuracoes',
    pathMatch: 'prefix',
  },
  {
    path: 'profile/:id',
    redirectTo: '/profile/:id',
    pathMatch: 'prefix',
  },
  {
  path: 'team/:id',
  redirectTo: '/team/:id',
  pathMatch: 'prefix',
  },
  {
    path: 'explorer',
    redirectTo: '/explorer',
    pathMatch: 'full',
  },
  {
    path: 'games',
    redirectTo: '/games',
    pathMatch: 'full',
  },
  {
    path: 'games-crud',
    redirectTo: '/games-crud',
    pathMatch: 'full',
  },
  {
    path: 'set-cast/:id',
    redirectTo: '/set-cast/:id',
    pathMatch: 'prefix',
  },
  {
    path: 'elenco',
    redirectTo: '/elenco',
    pathMatch: 'full',
  },
  {
    path: 'reports',
    redirectTo: '/reports',
    pathMatch: 'full',
  },
  {
    path: 'people-crud',
    redirectTo: '/people-crud',
    pathMatch: 'full',
  },
  {
    path: 'teams-crud',
    redirectTo: '/teams-crud',
    pathMatch: 'full',
  },
  {  
    path: 'campeonatos',
    redirectTo: '/campeonatos',
    pathMatch: 'full',
  },  
  {
    path: 'configuracoes.regras',
    redirectTo: 'configuracoes/regras',
    pathMatch: 'prefix',
  },
  {
    path: 'feminino',
    redirectTo: '/atividade',
    pathMatch: 'full',
  },
  {
    path: 'dpmedico',
    redirectTo: '/dp-medico',
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
