import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  eventUrl = localStorage.host + '/np-games/' + '?category_id=' + localStorage.category_id + '&modality_id=' + localStorage.modality_id;
  nextUrl = localStorage.host + '/next-games/?modality_id=1&category_id=1&competition_id=13672'
  nextTeamsUrl = localStorage.host + '/next-teams/?modality_id=1&category_id=1&competition_id=13672'
  game: any

  constructor(private http: HttpClient, private router: Router) { }

  public getGame(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.eventUrl, {
      headers: header,
    });
  }
  public getNextGame(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.nextUrl, {
      headers: header,
    });
  }

  public getNextTems(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.nextTeamsUrl, {
      headers: header,
    });
  }
  public goToService(game) {
    let gameId = game['item']['id']
    this.setGame(game)
    this.router.navigateByUrl('/games', {
      state: { selectedGame: gameId }
    })
  }

  setGame(game) {
    this.game = game;
  }
  getGames() {
    return this.game;
  }
}

