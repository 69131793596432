import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PerformanceReportService } from './performance-report.service';
import { MessageService } from 'primeng/api';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.scss'],
  providers: [PerformanceReportService, MessageService],
})
export class PerformanceReportComponent implements OnInit {
  inProgress: boolean;
  response: any;
  team: any;
  teams: any[];
  selectedTeam: any;
  sessions: any;
  selectedSession: any;
  competitions: any;
  selectedCompetition: any;

  @ViewChild('dt',{static: false}) el: ElementRef

  constructor(
    private performanceReportService: PerformanceReportService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    //this.inProgress = true;
    this. listSessions();
    

  }

  public listTeams() {
    this.performanceReportService.getTeams().subscribe(
      (response) => {
        this.inProgress = false;
        this.teams = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipes.',
          life: 3000,
        });
      }
    );
  }

  public getTeam() {
    this.performanceReportService.getTeam(this.selectedTeam.id).subscribe(
      (response) => {
        this.team = response;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar equipe.',
          life: 3000,
        });
      }
    );
  }

  public listSessions() {
    this.performanceReportService.getSessions().subscribe(
      (response) => {
        this.sessions = response;
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public listCompetitions() {
    if (this.selectedSession) {
      this.performanceReportService.getCompetitions(this.selectedSession).subscribe(
        (response) => {
          this.competitions = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }

  public sessionChange() {
    this.listCompetitions()
  }

  public competitionChange() {
    this.listTeams();
  }

  public getData() {
    this.performanceReportService
      .getData(this.selectedTeam.id, this.selectedCompetition.id)
      .subscribe(
        (response) => {
          this.inProgress = false;
          this.response = response;
          
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve algum erro ao recuperar relatorio.',
            life: 3000,
          });
        }
      );
  }
  public printPdf(){
    const doc= new jsPDF('l', 'pt' )
    doc.html(this.el.nativeElement, {callback:(doc) =>
    doc.save("Desempenho.pdf")});
  }
}