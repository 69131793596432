<app-menubar></app-menubar>
<p-toast></p-toast>
<div style="max-width: 100%;" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{
      width: '100%',
      height: '70px',
      marginTop: '20%',
      marginBottom: '20%'
    }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>

<div class="card crud-container" style="margin-top: 5%;">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
      <button
        pButton
        pRipple
        [label]="Remover"
        icon="pi pi-trash"
        class="p-button-danger"
        (click)="deleteSelectedPeoples()"
        [disabled]="!selectedPeoples || !selectedPeoples.length"
      ></button>
    </ng-template>
  </p-toolbar>

  <p-table
    #dt
    [value]="peoples"
    [rows]="10"
    [paginator]="true"
    [globalFilterFields]="['nome', 'apelido', 'nascimento', 'updated_at']"
    [(selection)]="selectedPeoples"
    [rowHover]="true"
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <h5 class="p-m-0">Pessoas</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Foto</th>
        <th pSortableColumn="apelido">
          Apelido <p-sortIcon field="apelido"></p-sortIcon>
        </th>
        <th pSortableColumn="nome">
          Nome Completo <p-sortIcon field="nome"></p-sortIcon>
        </th>
        <th pSortableColumn="nascimento">
          Data de Nasc. <p-sortIcon field="nascimento"></p-sortIcon>
        </th>
        <th pSortableColumn="update_time">
          Atualizado em <p-sortIcon field="update_time"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-people>
      <tr>
        <td>
          <p-tableCheckbox [value]="people"></p-tableCheckbox>
        </td>
        <td>
          <p-avatar image="{{ cutUrl(people.foto) }}"></p-avatar>
        </td>
        <td>{{ people.apelido }}</td>
        <td>
          {{ people.nome }}
        </td>
        <td>{{ people.nascimento |  date: 'dd/MM/yyyy' }}</td>
        <td>{{ people.update_time |  date: 'dd/MM/yyyy' }}</td>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-eye"
            class="p-button-rounded crud-button"
            (click)="showPeople(people)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded crud-button"
            (click)="editPeople(people)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded crud-button"
            (click)="deletePeople(people)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are
        {{ peoples ? peoples.length : 0 }} peoples.
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="peopleDialog"
  [style]="{ width: '70%' }"
  header="Detalhes de Pessoa"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-pessoas
      [people]="people"
      [peoples]="peoples"
      [peopleCRUDService]="peopleCRUDService"
      [listPeople]="listPeople"
      [verb]="verb"
      [peopleDialog]="peopleDialog"
      [hideDialog]="hideDialog"
    ></app-pessoas>
  </ng-template>
</p-dialog>


