import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeIcons } from 'primeng/api';
import { ResumoService } from './resumo.service';

@Component({
  selector: 'app-resumo',
  templateUrl: './resumo.component.html',
  styleUrls: ['./resumo.component.scss'],
})
export class ResumoComponent implements OnInit {
  response: any;
  atHomeData: any;
  outsideData: any;
  totalData: any;
  inProgress: boolean;
  selectedCompetition: any;
  competitions: any[];
  selectedSession: any;
  sessions:any[];
  idTeam: any;
  team: any;

  constructor(private resumoService: ResumoService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.idTeam = this.activatedRoute.snapshot.paramMap.get('id');
    this.listScore();
    this. listSessions();

    
  }

  public listScore() {
    this.resumoService.getScore(this.idTeam).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
        console.log(response)
        
        this.team = response.equipe
        console.log(this.team)
        this.totalData = {
          labels: ['Virtorias', 'Derrotas', 'Empates'],
          datasets: [
            {
              data: [
                this.response.total.victories,
                this.response.total.defeats,
                this.response.total.draws,
              ],
              backgroundColor: ['blue', 'red', 'gray'],
              hoverBackgroundColor: ['#3f31a2', '#9d061d', '#4a4e52'],
            },
          ],
        };
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
 
  public listSessions() {
    this.resumoService.getSessions().subscribe(
      (response) => {
        this.sessions = response;
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }

  public listCompetitions() {
    if (this.selectedSession) {
      this.resumoService.getCompetitions(this.selectedSession).subscribe(
        (response) => {
          this.competitions = response;
        },
        (error) => {
          alert('Houve algum erro ao carregar a lista.');
        }
      );
    }
  }
  public sessionChange() {
    this.listCompetitions()
  }
  public competitionChange() {
    this.getCompetitionScore();
  }
  public getCompetitionScore() {
    this.resumoService.mudaScore(this.selectedCompetition.id, this.idTeam).subscribe(
      (response) => {
        this.inProgress = false;
        this.response = response;
        console.log(response)
        
        
        this.totalData = {
          labels: ['Virtorias', 'Derrotas', 'Empates'],
          datasets: [
            {
              data: [
                this.response.total.victories,
                this.response.total.defeats,
                this.response.total.draws,
              ],
              backgroundColor: ['blue', 'red', 'gray'],
              hoverBackgroundColor: ['#3f31a2', '#9d061d', '#4a4e52'],
            },
          ],
        };
      },
      (error) => {
        alert('Houve algum erro ao carregar a lista.');
      }
    );
  }
}
