<div class="card">
  <h2>{{ pessoa.nome }}</h2>
  <br><br>
  <label >Foto</label>
  <input style="margin-left: 1%;" type="file" accept="image/*" (change)="selectFile($event)" class="form-control">
</div>

<div>
    <label for="altura">Altura</label>
    <input
      type="number"
      pInputText
      id="altura"
      [(ngModel)]="profile.altura"
      required
      autofocus
    />
    <small class="p-invalid" *ngIf="submitted && !profile.altura"
      >Campo obrigatório.</small
    >
</div>
<div>
    <label for="predominancia">Predominancia</label>
    <p-dropdown [options]="perna" value="id" [(ngModel)]="selectPerna" placeholder="{{getSideString(profile.predominancia)}}" optionLabel="nome"></p-dropdown>
</div>

  <label for="numerocamisa" style="text-align: left;" >Número da camisa</label>
  <div>
  <input
    type="number"
    pInputText
    id="numerocamisa"
    [(ngModel)]="contract.camisa"
    autofocus
  />
  <!-- <small class="p-invalid" *ngIf="submitted && !contract.camisa">Campo obrigatório.</small> -->
</div>
<div>
  <label for="posicao">Posição</label>
  <p-dropdown [options]="posicao" value="id" [(ngModel)]="selectPosicao" placeholder="{{selectPosition(profile.posicao_id)}}" optionLabel="nome"></p-dropdown>
 
    <!-- <small class="p-invalid" *ngIf="submitted && !contract.camisa">Campo obrigatório.</small> -->
</div>

    <label for="nacionalidade" style="text-align: left;">País de origem</label>
  <div>
    <input
      type="text"
      pInputText
      id="nacionalidade"
      [(ngModel)]="profile.nacionalidade"
      required
      autofocus
    />
    <small class="p-invalid" *ngIf="submitted && !profile.nacionalidade"
      >Campo obrigatório.</small
    >
</div>
<div>
  <label for="predominancia">CBF</label>
  <input
    type="number"
    pInputText
    id="cbf"
    [(ngModel)]="profile.cbf"
    autofocus
  />
  <!-- <small class="p-invalid" *ngIf="submitted && !contract.camisa">Campo obrigatório.</small> -->
</div>
<div>
    <label for="clubes_anteriores">Carreira</label>
    <input
      type="text"
      pInputText
      id="clubes_anteriores"
      [(ngModel)]="profile.clubes_anteriores"
      required
      autofocus
    />
    <small class="p-invalid" *ngIf="submitted && !profile.clubes_anteriores"
      >Campo obrigatório.</small
    >
</div>
<div>
    <label for="id_ogol">Ogol</label>
    <input
      type="text"
      pInputText
      id="id_ogol"
      [(ngModel)]="profile.id_ogol"
      required
      autofocus
    />
    <small class="p-invalid" *ngIf="submitted && !profile.id_ogol"
      >Campo obrigatório.</small
    >
</div>
<div>
    <label for="id_soccerway">Soccerway</label>
    <input
      type="text"
      pInputText
      id="id_soccerway"
      [(ngModel)]="profile.id_soccerway"
      required
      autofocus
    />
    <small class="p-invalid" *ngIf="submitted && !profile.id_soccerway"
      >Campo obrigatório.</small
    >
</div>
<div>
    <label for="id_transfermarkt">Transfermarkt</label>
    <input
      type="text"
      pInputText
      id="id_transfermarkt"
      [(ngModel)]="profile.id_transfermarkt"
      required
      autofocus
    />
    <small class="p-invalid" *ngIf="submitted && !profile.id_transfermarkt"
      >Campo obrigatório.</small
    >
</div>

<div style="height: 400px; display: flex; float: left" class="p-field p-col-12 p-md-4">
    <div style="width: 40%; margin-right:50%" class="p-calendar p-datepicker">
        <label for="inicio">Novo Inicio</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="date1" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" inputId="inicio"></p-calendar>
        <small class="p-invalid" *ngIf="submitted && !contract.inicio"
          >Campo obrigatório.</small
        >
    </div>
    <div style="width: 40%; margin-left:50%"  class="p-calendar p-datepicker">
        <label for="termino">Novo Termino</label>
        <p-calendar style="top: 0 !important;" [(ngModel)]="date2" view="date" dateFormat="dd/mm/yy" [showIcon]="true" [yearNavigator]="true" yearRange="2000:3030" [readonlyInput]="true" inputId="termino"></p-calendar>
        <small class="p-invalid" *ngIf="submitted && !contract.termino"
          >Campo obrigatório.</small
        >
    </div>
</div>

<button
  pButton
  pRipple
  label="Salvar"
  icon="pi pi-check"
  class="p-button-text"
  (click)="submitForm()"
  style="margin-top: 10%"
></button>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
