import { Component, OnInit } from '@angular/core';
import { FemininoService } from './feminino.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-feminino',
  templateUrl: './feminino.component.html',
  styleUrls: ['./feminino.component.scss'],
})
export class FemininoComponent implements OnInit {
  inProgress: boolean;
  response: any[];
  atividades: any[];
  atividades_atletas: any;
  newAtividadeDialog: boolean;
  updateAtividadeDialog: boolean;
  atividade: any;
  departamento:any;
  selectedDepartamento: any;
  cast: any[] = [];
  form : FormGroup;
  toDay: any;
  todayAsStr: any;
  reportDialog: boolean;

  constructor(private femininoService: FemininoService, private fb: FormBuilder, private confirmationService: ConfirmationService,) {}

  
  ngOnInit(){
    this.toDay = new Date()
    this.form = this.fb.group({
      id:[null], dp: [null,], atividade: [null,], atleta: [null,], data_atividade: [null], tempo: [,null], observacao: [null], local:[null]})
    this.getDp()
    this.listCast()
    this.getAtividade()
  }

  reportDialogOpen(){
    this.reportDialog = true
  }

  getAtividade(){
    this.femininoService.getAtividade().subscribe(
      response => {this.atividades_atletas =  response; 
        this.atividades_atletas.forEach(atividade => atividade.data_atividade = new Date(atividade.data_atividade));console.log(this.atividades_atletas)}
        
      )
        
  }
  updateAtividade(atividade){
    this.updateAtividadeDialog = true
    this.atividade = atividade
    console.log(this.atividade)
  }
  newAtividade(){
    this.newAtividadeDialog = true
    
  }
  getDp(){
   
    this.femininoService.getDpFem().subscribe(
      data=> { this.departamento = data; this.getAtividadeDp()})
      
        
  }

  public departamentoChange() {
    this.getAtividadeDp();
  }

  deleteAtividade(atividade){
     
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja deletar a atividade: ' + atividade.atividade +' referente a atleta ' + atividade.atleta +' ?',
      acceptLabel: "Sim",
      rejectLabel: "Não",

      accept: () => {
        this.femininoService.deleteAtividade(atividade.id).subscribe( data => {console.log(data)} ) 
        window.location.reload()}
    })
    
   
  }

  getAtividadeDp(){
    if(this.selectedDepartamento){
      this.femininoService.getDpAtividade(this.selectedDepartamento.id).subscribe(
      data=> {this.atividades = data; console.log(this.atividades)}
    )}
    
  }

  listCast(){
    this.femininoService.getCast().subscribe(
      data => { console.log()
        for(let n in data){
          for(let i in data[n].players){
              this.cast.push(data[n].players[i])
          };
           }
          }
    )
  }

  checkAtividade(){
    
    if(!this.form.value.data_atividade){
      this.form.value.data_atividade = this.toDay
    }
    
    this.femininoService.checkAtividade(this.form.value).subscribe(
      response => {
        if(response == 200){
          this.confirmationService.confirm({
            message: 'Atividade consta em nosso banco de dados. Deseja cadastar a atividade? ',
            acceptLabel: "Sim",
            rejectLabel: "Não",
      
            accept: () => {
            this.saveAtividade() 
            }
          })

        }else{
          this.saveAtividade()
        }
      } )
   
  }

  saveAtividade(){
    console.log(this.form.value.data_atividade)
    this.femininoService.saveAtividade(this.form.value).subscribe(
      response => {console.log(response)} )
      window.location.reload()
  }
  changeAtividade(atividade){
    this.femininoService.updateAtividade(atividade).subscribe( data => {console.log(data)} )
       window.location.reload()
  }
}
