<p-toast></p-toast>

<div class="cast-container" style="background-color: {{ teamData.cor }}">
  <button
    pButton
    pRipple
    icon="pi pi-user-plus"
    class="p-button-rounded crud-button"
    (click)="openNew()"
    
  ></button>
  <button
    pButton
    pRipple
    icon="pi pi-sort-alt"
    class="p-button-rounded crud-button"
    (click)="openNewDrag()"
    
  ></button>

  <div class="opc"><img class="four-lines" src="{{ teamData.brasao }}" /></div>
  <div class="team-label"><a href="#/team/{{ teamId }}">{{ teamData.apelido }}</a></div>
  <div class="spin-container" *ngIf="inProgress">
    <p-progressSpinner
      [style]="{ width: '100%', height: '70px', marginTop: '5%' }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <div id="teste" class="centralized"  style="opacity: 0.8">
    <app-arena teamId="{{ teamId }}" gameId="{{ gameId }}"></app-arena>
  </div>
  <div style="opacity: 0.8">
    <p-table [value]="players" responsiveLayout="scroll" *ngIf="toDo">
      <ng-template pTemplate="body" let-player>
        <tr *ngIf="player.status == 'TIT'">
          <td style="width: 10%">{{ player.number }}</td>
          <td style="width: 18%">
            <div class="pos-{{ player.position }}">{{ player.position }}</div>
          </td>
          <td style="width: 30%">{{ player.surname }}</td>
          <td ></td>
          <td style="display: flex; float: left" >
            <button
              pButton
              pRipple
              icon="pi pi-sort-alt"
              class="p-button-rounded crud-button"
              style="color: white !important; background-color: red !important"
              (click)="removeToMainCast(player)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="cast-container" style="opacity: 0.8">
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon">Formação Tática</span>
    <input type="text" pInputText placeholder="Digite seu esquema tático"/>
    <button type="button" pButton pRipple icon="pi pi-save"></button>
  </div>
</div>
<div class="cast-container" style="background-color: {{ teamData.cor }}">
  <div class="spin-container" *ngIf="inProgress">
    <p-progressSpinner
      [style]="{ width: '100%', height: '70px', marginTop: '5%' }"
      styleClass="custom-spinner"
    ></p-progressSpinner>
  </div>
  <div style="opacity: 0.8">
    <p-table [value]="players" responsiveLayout="scroll">
      <ng-template pTemplate="body" let-player>
        <tr *ngIf="player.status != 'TIT'">
          <td style="width: 10%">{{ player.number }}</td>
          <td style="width: 18%">
            <div class="pos-{{ player.position }}">{{ player.position }}</div>
          </td>
          <td style="width: 30%">{{ player.surname }}</td>
          <td >Dados do jogador no jogo</td>
          <td style="display: flex; float: left">
            <button
              pButton
              pRipple
              icon="pi pi-sort-alt"
              class="p-button-rounded crud-button"
              style="color: white !important; background-color: blue !important"
              (click)="setPosition(player)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog
  [(visible)]="playerDialog"
  [style]="{ width: '60%', minHeight: '100%' }"
  header="Elenco total"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-players-select
      teamId="{{ teamId }}"
      gameId="{{ gameId }}"
      inHome="{{ inHome }}"
    ></app-players-select>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="dragPlayerDialog"
  [style]="{ width: '60%', minHeight: '100%' }"
  header="Criar substituição"
  [modal]="true"
  (onHide)="listCast()"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-drag-and-drop-main-cast></app-drag-and-drop-main-cast>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="changeDialog"
  [style]="{ width: '600px' }"
  header="Selecione a posição em que {{ player?.surname }} será escalado"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="header">
    <h5>Posição</h5>
    <p-dropdown
      [options]="positions"
      [(ngModel)]="selectedPosition"
      optionLabel="nome"
      [filter]="true"
      filterBy="nome"
      [showClear]="true"
      placeholder="Selecione a posição"
    >
      <ng-template pTemplate="selectedItem">
        <div class="position-item position-item-value" *ngIf="selectedPosition">
          <div>{{ selectedPosition.nome }}</div>
        </div>
      </ng-template>
      <ng-template let-position pTemplate="item">
        <div class="position-item">
          <div>{{ position.nome }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideChangeDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Escalar"
      icon="pi pi-check"
      class="p-button-text"
      [disabled]="!selectedPosition"
      (click)="addInMainCast()"
      [modal]="false"
    ></button>
  </ng-template>
</p-dialog>




