<button
pButton
pRipple
icon="pi pi-download"
(click)="  printPdf()"
class="p-button-rounded crud-button"
style="
  color: white !important;
  background-color: #000000 !important;
  margin: 2px 10px 0 0;
  width: 37px;
  height: auto;
"
></button>

  <div id="dt"
    style="width: 100%; margin: auto; box-shadow: 2px 2px 2px #b3b3b3; margin-top: 1%;"
    *ngIf="response"
    #dt
  >
  <div
  style="
    width: 100%;
    height: 20%;
    background-color: black;
    color: white;
    border-bottom: solid 1px white;
    
  "
  >
  <img style="width: 10%;" src="{{ teamId.brasao }}">  
</div>  
<div style="margin-left: 12%; margin-top: -10%; color: white;"> {{ teamId.nome }} </div>
<div style="margin-left: 12%; color: white;"> {{ teamId.apelido }} </div>
<div style="margin-left: 12%; color: white;"> Elenco {{ modalityName }} </div>

  <div 
    class="p-d-flex"
    style="
      float: right;
    "
    >
    <div *ngFor="let item of response">
      <div style="background-color: black; color: white; margin-top: 5%; text-align: left;">
        {{ item.position }}
      </div>
        <p-table
          [columns]="[item.position]"
          [value]="item.players"
          responsiveLayout="scroll"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 10%;">
                Foto
              </th>
              <th style="width: 10%;">
                Nome
              </th>
              <th style="width: 50%;">
                Hostórico
              </th>
              <th style="width: 20%;">
                Função
              <th >  
              </th>    
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td style="padding: 0px; font-size: x-small; text-align: center; border-right: 1px solid #f8f8f8;">
                <p-avatar image="{{ item.image }}"></p-avatar>  
                {{ item.number }}
              </td>
              <td style="padding: 0px; font-size: x-small; text-align: left; border-right: 1px solid #f8f8f8;">
                <p>{{ item.name }}</p> 
                <p>{{ item.surname }} {{item.year}} {{item.age}}</p>
              </td>
              <td style="padding: 0px;  font-size: x-small; text-align: left; border-right: 1px solid #f8f8f8;">
                {{ item.history }}
            </td>
            <td style="padding: 0px; font-size: x-small; text-align: left; border-right: 1px solid #f8f8f8;">
              <p style="color: blue;">{{ item.positive_points }}</p> 
              <p style="color: red;">{{ item.negative_points }}</p>
          </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
  </div>
</div>
      
 