import { Component, OnInit, Input } from '@angular/core';
import { PlayersSelectService } from './players-select.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { PlayerInGame } from './player_in_game';
import '@angular/compiler';

@Component({
  selector: 'app-players-select',
  templateUrl: './players-select.component.html',
  styleUrls: ['./players-select.component.scss'],
  providers: [PlayersSelectService, MessageService, ConfirmationService],
})
export class PlayersSelectComponent implements OnInit {
  inProgress: boolean;
  buttonInProgress: boolean;
  players: any[];
  playerInGame: PlayerInGame;
  @Input() teamId: any;
  @Input() gameId: any;
  @Input() inHome: any;

  constructor(
    private playersSelectService: PlayersSelectService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.inProgress = true;
    this.buttonInProgress = false;
    this.listFullCast();
  }

  public listFullCast() {
    this.playersSelectService.getFullCast(this.teamId, this.gameId).subscribe(
      (response) => {
        this.buttonInProgress = false;
        this.inProgress = false;
        this.players = response;
       
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar elenco.',
          life: 3000,
        });
      }
    );
  }

  addInMainCast(player) {
    this.buttonInProgress = true;
    this.playerInGame = {};
    this.playerInGame.editor = localStorage.iduser;
    this.playerInGame.situacao = 'BAN';

    this.playersSelectService
      .addOrRemoveToMainCast(this.playerInGame, player.id)
      .subscribe(
        (response) => {
          this.playerInGame = {};
          this.listFullCast();
          this.messageService.add({
            severity: 'info',
            summary: 'Relacionado',
            detail:
              'O jogador ' + player.surname + ' foi relacionado com sucesso.',
            life: 3000,
         });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve algum erro ao relacionar jogador.',
            life: 3000,
          });
        }
      );
  }

  removeToMainCast(player) {
    this.buttonInProgress = true;
    this.playerInGame = {};
    this.playerInGame.editor = localStorage.iduser;
    this.playerInGame.situacao = 'NR';

    this.playersSelectService
      .addOrRemoveToMainCast(this.playerInGame, player.id)
      .subscribe(
        (response) => {
          this.playerInGame = {};
          this.listFullCast();
          // this.messageService.add({
          //   severity: 'info',
          //   summary: 'Relacionado',
          //   detail:
          //     'O jogador ' + player.surname + ' foi relacionado com sucesso.',
          //   life: 3000,
          // });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve algum erro ao relacionar jogador.',
            life: 3000,
          });
        }
      );
  }

  deleteJNJ(player) {
    this.buttonInProgress = true;
    //this.confirmationService.confirm({
    //  message: 'Tem certeza que deseja excluir este jogador do jogo?',
    //  header: 'Excluir?',
    //  acceptLabel: 'Sim',
    //  rejectLabel: 'Não',
    //  icon: 'pi pi-exclamation-triangle',
    //  accept: () => {
    this.playersSelectService.deleteJNJ(player.id_jnj).subscribe(
      (response) => {
        // this.messageService.add({
        //   severity: 'info',
        //   summary: 'Excluído',
        //   detail:
        //     'O jogador ' +
        //     player.surname +
        //     ' foi removido dos relacionados.',
        //   life: 3000,
        // });
        this.playerInGame = {};
        this.listFullCast();
      },
      (error) => {
        //this.messageService.add({
        //  severity: 'error',
        //  summary: 'Erro',
        //  detail:
        //    'Houve algum erro ao remover o jogador ' +
        //    player.surname +
        //    ' dos relacionados.',
        //  life: 3000,
        //});
      }
    );
    //   },
    // });
  }
}
