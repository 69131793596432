import { Component, OnInit } from '@angular/core';
import { CampeonatosService } from './campeonatos.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Campeonatos } from './campeonatos';
import '@angular/compiler';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-campeonatos',
  templateUrl: './campeonatos.component.html',
  styleUrls: ['./campeonatos.component.scss'],
  providers: [CampeonatosService, MessageService, ConfirmationService],
})
export class CampeonatosComponent implements OnInit {
  campeonatoDialog: boolean;
  novoCampeonatoDialog: boolean;
  novoFaseDialog: boolean;
  participanteFaseDialog: boolean;
  campeonatos: Campeonatos[];
  campeonato: FormGroup;
  fase: FormGroup;
  selectedCampeonatos: Campeonatos[];
  selectedCampeonato: any;
  fases: any;
  jogos: any;
  submitted: boolean;
  verb: string;
  criterio: boolean;
  criterios: any;
  id_campeonato: any;
  selectedYear: number;
  years: number[] = [];
  temp: FormGroup;
  teams: FormGroup;
  equipes: any;
  selectedTeam: any;
  participantes: any;
  invalidDates: Array<Date>

  isRestrictedOptions: any[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];
  games: any;
  inProgress: boolean;
  editCampeonatoDialog: boolean;

  constructor(
    private campeonatosService: CampeonatosService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    
  ) {}

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear+1; year >= 2010; year--) {
      this.years.push(year);}
    console.log(this.years)
    this.criterio = false;
    this.criterios = 0
    this.listCampeonatos();
    
    this.campeonato = this.fb.group({
      nome: [null,], temporada: [null,], abrangencia: [null,], inicio: [null,], termino:[null,],
      observacao: [null,], oficial: [null,],
    })
    this.temp = this.fb.group({ano: [null,],})
    this.fase = this.fb.group({
      nome: [null,], apelido: [null,], inicio: [null,], sistema:[null,],
      classifica: [null,], desclassifica: [null,], zera_cartao: [null,], vitoria: [null,], 
      empate: [null,], derrota: [null,], dv_visitante: [null,], de_visitante: [null,],
      dd_visitante: [null,], iamaralo: [null,], iiamarelo: [null,], vermelho: [null,],
      criterio1: [null,], criterio2: [null,], criterio3: [null,],
    })
    this.teams = this.fb.group({equipes: [null,], })

  }
  
  public getCampeonatos() {
    
    this.campeonatosService.getCampeonatosTemporada(this.temp).subscribe(
      (response) => {
        console.log(response)
        this.campeonatos = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar departamentos.',
          life: 3000,
        });
      }
    );
  }

  public listCampeonatos() {
    
    this.campeonatosService.getCampeonatos().subscribe(
      (response) => {
        console.log(response)
        this.campeonatos = response;
        
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar departamentos.',
          life: 3000,
        });
      }
    );
  }

  openNew() {
    
    this.submitted = false;
    this.novoCampeonatoDialog = true;
    this.verb = 'POST';
  }
  novaFase(){
    this.novoFaseDialog = true;
    this.novoCampeonatoDialog = false;

  }
  public getPhase(id, campeonato) {
    console.log(id)
    console.log(campeonato)
    this.selectedCampeonato = campeonato;
    this.id_campeonato = id
    this.campeonatosService.getPhase(id).subscribe(
      (response) => {
        this.fases = response;
        console.log(response)
        this.campeonatoDialog = true;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar jogos.',
          life: 3000,
        });
      }
    );
  }

  editCampeonato(campeonato){
    this.editCampeonatoDialog = true
    
    this.campeonatosService.getCampeonato(campeonato.id_campeonato).subscribe(
      data=>{ this.selectedCampeonato = data; console.log(data)}
    )
  }

  getParticipantes(fase_id){
    console.log(fase_id)
    this.campeonatosService.getParticipante(fase_id).subscribe(
      response => { this.participantes = response; console.log(response)}
    )
  }

  public getGame(campeonato, phase_id) {
  
  
    this.campeonatosService.getGames(campeonato, phase_id).subscribe(
      (response) => {
        this.jogos = response;
        console.log(this.jogos)
    
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar jogos.',
          life: 3000,
        });
      }
    );
  }
  goTo(game) {    
    
    this.campeonatosService.goToService(game)
  }
  
  addCriterio() {    
    this.criterio = true;
    this.criterios = +1 
    console.log(this.criterios)
  }

  public addCampeonato(){
    console.log(this.campeonato)
    this.campeonatosService.postCampeonato(this.campeonato).subscribe(
      (response) => {
        alert('Operacao realizada com sucesso')
        window.location.reload();
        this.inProgress = false;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao recuperar elenco.',
          life: 3000,
        });
      }
    );
  }

  public addFase(){
    console.log(this.id_campeonato)
    this.campeonatosService.postFase(this.id_campeonato, this.fase).subscribe(
      (response) => {
        this.fases = response;
        window.location.reload();
        console.log(response)
        this.campeonatoDialog = true;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao listar jogos.',
          life: 3000,
        });
      }
    );
  }

  openParticipante(fase_campeonato, fase_id ){
    localStorage.fase = fase_id
    this.participanteFaseDialog = true
    this.campeonatosService.getEquipes().subscribe(
      response => {
        console.log(response)
        this.equipes = response
      }
    )
    this.getParticipantes(fase_id)

  }
  addParticipantes(){
    
    this.campeonatosService.addParticipantes(this.teams.value.equipes, localStorage.fase).subscribe(
      data => {console.log(data)}
    )
  }

  updateCampeonato(campeonato){
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja alterar o campeonato: ' + campeonato.campeonato + '?',
      acceptLabel: "Sim",
      rejectLabel: "Não",

      accept: () => {
        this.campeonatosService.updateCampeonato(campeonato).subscribe(
          data => {console.log(data)}
         ) 
        window.location.reload()}
    })
  }

  deleteCampeonato(campeonato){
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja deletar o campeonato: ' + campeonato.campeonato + '?',
      acceptLabel: "Sim",
      rejectLabel: "Não",

      accept: () => {
        this.campeonatosService.deleteCampeonato(campeonato).subscribe(
          data => {console.log(data)}
         ) 
        window.location.reload()}
    })
  }
}

