import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rateio-premiacao',
  templateUrl: './rateio-premiacao.component.html',
  styleUrls: ['./rateio-premiacao.component.scss']
})
export class RateioPremiacaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
