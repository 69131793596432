import { Injectable } from '@angular/core';

import { observable, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root',
})
export class ElencoService {

  url = localStorage.host + '/full_cast/';

  constructor(private http: HttpClient) { }

  public getCast(team_id): Observable<any> {
    if (team_id == null) {
      return this.http.get(this.url +
        '?modality_id=' + localStorage.modality_id +
        '&category_id=' + localStorage.category_id +
        '&team_id=' + 1,);
    } else {
      return this.http.get(this.url +
        '?modality_id=' + localStorage.modality_id +
        '&category_id=' + localStorage.category_id +
        '&team_id=' + team_id,);
    }
  }
}

