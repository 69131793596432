import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class CartoesService {
  url = localStorage.host + '/atividade-cartao/';
  competitionsUrl = localStorage.host + '/competitions/';
  sessionUrl = localStorage.host + '/sessions/';


  constructor(private http: HttpClient) { }

  public getSessions(): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.sessionUrl, { headers: header });
  }

  public getCompetitions(session): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.competitionsUrl + '?session=' + session, {
      headers: header,
    });
  }

  public getAtividades(game, team) {
    console.log(team)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(this.url + '?game_id=' + game.id + '&team_id=' + team, {
      headers: header,
    });
  }
}
