import { Component, OnInit, Input } from '@angular/core';
import { People } from '../../people-crud/people';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { PessoasService } from './pessoas.service'; 
import { FormBuilder, FormGroup } from '@angular/forms';
import '@angular/compiler';

@Component({
  selector: 'app-pessoas',
  templateUrl: './pessoas.component.html',
  styleUrls: ['./pessoas.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class PessoasComponent implements OnInit {
  @Input() people: People;
  @Input() peoples: People[];
  @Input() peopleCRUDService: any;
  @Input() listPeople: any;
  @Input() hideDialog: any;
  @Input() verb: string;
  @Input() peopleDialog: boolean;

  sexOptions: any[] = [
    { label: 'Masculino', value: 'M' },
    { label: 'Feminino', value: 'F' },
  ];

  selectedValue: string;
  stateOptions: any[];
  activeState: boolean[] = [true, false, false];
  perfil_tipo: any;
  perfil_player: FormGroup;
  perfil_arbitro: FormGroup;
  opt_pais: FormGroup;
  opt_estado: FormGroup;
  perfil_comicao: FormGroup;
  dados_medico: FormGroup;
  dados_pessoais: FormGroup;
  dados_funcionario: FormGroup;
  otp_func: FormGroup;
  pais: any;
  estado: any;
  posicao: any;
  team: any;
  categoria: any;
  checked1: boolean;
  checked2: boolean;
  selecao: any;
  perna: any;
  func_fut: any;
  var: any;
  funcao: any;
  ativo: any;
  classificacao: string;
  pais_atuacao: any;
  estado_atuacao: any;
  tipo_sangue: any;
  tipo_funcionario: any;
  people_id: any;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private http: HttpClient,
    private pessoasService: PessoasService,
    private fb: FormBuilder,
  ) {
    this.stateOptions = [{label: 'Ativo', value: 1}];
    this.tipo_funcionario = [{name: 'Grupo de Apoio '}, {name:' Diretoria'}]
    this.perfil_player = this.fb.group({
      federacao: [,null], federacao_reg: [,null], selecao: [,null], id_ogol: [,null],
      soccerway: [,null], transfermarket: [, null], altura: [,null], predominancia: [, null],
      origem: [, null], posicao: [,null], equipe: [,null], categoria: [,null], contrato_inicio: [,null], 
      contrato_termino: [, null], carreira: [,null], participacao: [,null], saude: [,null], caracteristica: [,null],
      ponto_forte: [,null], ponto_fraco: [, null],
    })

    this.perfil_arbitro = this.fb.group({
      ativo: [,null], funcao: [,null], classificacao: [, null], 
      pais_atuacao: [, null], estado_atuacao: [,null] 
    })

    this.opt_pais = this.fb.group({pais: [,null]})
    this.opt_estado = this.fb.group({estado: [,null]})

    this.perfil_comicao = this.fb.group({funcao: [,null], equipe: [, null], categoria: [,null], ativo: [,null], pj: [,null]})
    this.dados_medico = this.fb.group({
      corinthians:[null], ceproo: [null], labr9: [null], tipo_sangue: [null],
      contato_emergencia:[,null], pronleb_cronicao:[,null], alergias:[,null], medicamentos: [,null]
    })

    this.dados_pessoais = this.fb.group({
      email: [,null], descricao:[,null], rg:[,null], cpf:[,null], mae:[,null], pai:[,null],
      passaporte:[,null], emissao_passaporte:[,null], validade_passaporte:[,null]  
    })

    this.dados_funcionario = this.fb.group({
      tipo:[,null], categoria:[,null], ativo:[,null], pj: [,null], CRM:[,null],
      cref:[,null], cna_oab:[,null], cnpj:[,null]
    })

    this.otp_func = this.fb.group({funcao: [,null]})
    this.tipo_sangue = [{tipo: 'A-'}, {tipo: 'A+'}, {tipo: 'B-'}, {tipo: 'B+'}, {tipo: 'O-'}, {tipo: 'O+'}, , {tipo: 'AB-'}, , {tipo: 'AB+'}]

  }

  ngOnInit(): void {
    this.checked1 = true
    this.checked2 = true

    this.selecao =[ {name: "Sim Principal", id:'S'},{name: "Sim Base", id:'B' }, {name: "não", id: 'N'}]
    this.perna = [{id: 'D', nome:'Destro'}, 
                  {id: 'E',  nome: 'Canhoto'},
                  {id: 'A', nome: 'Ambidestro'}      ]
  }
 
  getPais(){
    this.pessoasService.getPais().subscribe(
      data => {this.pais = data; }
    )
  }

  getFuncaoFutebol(perfil){
    this.pessoasService.getFuncaoFutebol(perfil).subscribe(
      data => {this.func_fut = data; console.log(this.func_fut) }
    )
  }


  getEstado(){
    this.pessoasService.getEstado().subscribe(
      data => {this.estado = data; }
    )
  }

  getPosicao(){
    this.pessoasService.getPosicao().subscribe(
      data => {this.posicao = data; }
    )
  }

  getTeam(){
    this.pessoasService.getTeam().subscribe(
      data => {this.team = data;}
    )
  }

  getCategoria(){
    this.pessoasService.getCategoria().subscribe(
      data => {this.categoria = data; console.log(this.categoria)}
    )
  }


  onTabClose(event) {
    console.log(event)
   
  }
  
  onTabOpen(event) {
    this.var = '[disabled]="true"'
    if(event.index == 0){
      this.perfil_tipo = 'perfil_jogador'
      this.getPais()
      this.getPosicao()
      this.getTeam()
      this.getCategoria()
    }else if(event.index == 1){
      this.perfil_tipo = 'perfil_comissao'
      this.getTeam()
      this.getCategoria()
      this.getFuncaoFutebol('comissao')
    }else if(event.index == 4){
      this.perfil_tipo = 'perfil_comissao'
      this.getTeam()
      this.getCategoria()
      this.getFuncaoFutebol('comissao')
    }else if(event.index == 5){
      console.log('func')
      this.getCategoria()


    }
  }
  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
}

  submitForm() {
    console.log(this.people)

    if (this.verb === 'POST') {

      this.postForm();
    } else if (this.verb === 'PUT') {
      this.putForm();
    }
  }

  postForm() {
    console.log(this.perfil_tipo)
    
    this.people.field_is_deleted = 0;
    this.peopleCRUDService.postPeople(this.people).subscribe(
      (response) => {
        this.people_id = response
        console.log(this.people_id)
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Pessoa foi criado com sucesso.',
          life: 3000,
        });
        if(this.perfil_tipo == 'perfil_jogador'){
          let perfil = {
            pessoa_id: this.people_id,
            Identity: 'perfil_jogador',
            criador: localStorage.user.id,
            altura: this.perfil_player.value.altura,
            ativo: 1,
            catecogira_id: localStorage.categoria_id,
            cbf: this.perfil_player.value.federacao,
            descricao: this.perfil_player.value.descricao,
            equipe_id: this.perfil_player.value.equipe,
            estado_id: this.perfil_player.value.estado,
            fpf: this.perfil_player.value.federacao_reg,
            funcao_id: this.perfil_player.value.funcao,
            pais_id: this.perfil_player.value.pais,
            posicao_id: this.perfil_player.value.posicao.id,
            nacionalidade: this.perfil_player.value.nacionalidade,
            predominacia: this.perfil_player.value.predominancia.id,
            classificacao: this.perfil_player.value.classificacao,
            corinthians: this.dados_medico.value.corinthians, 
            ceproo: this.dados_medico.value.ceproo, 
            labr9: this.dados_medico.value.labr9, 
            sangue: this.dados_medico.value.tipo_sangue['tipo'],
            contato_emergencia:this.dados_medico.value.contato_emergencia, 
            pronleb_cronicao: this.dados_medico.value.pronleb_cronicao, 
            alergias: this.dados_medico.value.alergias, 
            medicamentos: this.dados_medico.value.medicamentos, 
            rg: this.dados_pessoais.value.rg, 
            cpf: this.dados_pessoais.value.cpf, 
            mae: this.dados_pessoais.value.mae, 
            pai: this.dados_pessoais.value.pai,
            passaporte: this.dados_pessoais.value.passaporte, 
            emissao_passaporte: this.dados_pessoais.value.emissao_passaporte, 
            validade_passaporte: this.dados_pessoais.value.validade_passaporte,
            tipo: this.dados_funcionario.value.tipo.name, 
            categoria: this.dados_funcionario.value.categoria,  
            pj: this.dados_funcionario.value.pj, 
            CRM: this.dados_funcionario.value.crm,
            cref: this.dados_funcionario.value.cref, 
            cna_oab: this.dados_funcionario.value.cna_oab, 
            cnpj: this.dados_funcionario.value.cnpj              
      
          }
          this.pessoasService.postPerfil(perfil).subscribe(
            response => {console.log(response)}
          )
        }else if(this.perfil_tipo == 'perfil_arbitro'){
          let perfil = {
            pessoa_id: this.people_id,
            Identity: 'perfil_arbitro',
            ativo: this.perfil_arbitro.value.ativo,
            funcao_id: this.perfil_arbitro.value.funcao,
            classificacao: this.perfil_arbitro.value.classificacao,
            pais_id: this.perfil_arbitro.value.pais_atuacao,
            estado_atuacao: this.perfil_arbitro.value.estado_atuacao

          }
          this.pessoasService.postPerfil(perfil).subscribe(
            response => {console.log(response)}
          )
        }else if(this.perfil_tipo == 'perfil_comissao'){
          let perfil =  {
            pessoa_id: this.people_id,
            identity: 'perfil_comissao',
            funcao_id: this.perfil_comicao.value.funcao['id'], 
            equipe_id: this.perfil_comicao.value.equipe['id'], 
            categoria: this.perfil_comicao.value.categoria['id'], 
            ativo: 1, 
            pj: 1,
            // corinthians: this.dados_medico.value.corinthians[0], 
            // ceproo: this.dados_medico.value.ceproo[0], 
            // lab: this.dados_medico.value.labr9[0], 
            //sangue: this.dados_medico.value.tipo_sangue['tipo'],
            contato_emergencia:this.dados_medico.value.contato_emergencia, 
            cronico: this.dados_medico.value.pronleb_cronicao, 
            alergias: this.dados_medico.value.alergias, 
            medicamentos: this.dados_medico.value.medicamentos,
            email_principal: this.dados_pessoais.value.email, 
            rg: this.dados_pessoais.value.rg, 
            cpf: this.dados_pessoais.value.cpf, 
            mae: this.dados_pessoais.value.mae, 
            pai: this.dados_pessoais.value.pai,
            passaporte: this.dados_pessoais.value.passaporte, 
            emissao_passaporte: this.dados_pessoais.value.emissao_passaporte, 
            validade_passaporte: this.dados_pessoais.value.validade_passaporte,
            tipo_funcionario: this.dados_funcionario.value.tipo,    
            CRM: this.dados_funcionario.value.crm,
            cref: this.dados_funcionario.value.cref, 
            cna_oab: this.dados_funcionario.value.cna_oab, 
            cnpj: this.dados_funcionario.value.cnpj              
          }
          console.log(perfil)
          this.pessoasService.postPerfil(perfil).subscribe(
            response => {console.log(response)}
          )
        }
        
        this.peoples = [...this.peoples];
        this.peopleDialog = false;
        this.people = {};
        this.listPeople();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao criar pessoa.',
          life: 3000,
        });
      }
    );
  }

  
  selectFile(event: any) {
     
    if (event.target.files && event.target.files[0]) {
      const foto = event.target.files[0];
      const formData = new FormData();
      formData.append('foto', foto);
      
      try {
        this.http.patch('https://api.csm.corinthians.com.br/pessoa-foto/'+this.people['id'] +'/', formData)
        .subscribe(resposta => console.log(resposta));

      } catch (error) {
        alert('Erro ao carregar arquivo');
      }
    }
  }

  putForm() {
    this.peopleCRUDService.putPeople(this.people).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Sucesso',
          detail: 'Pessoa editada com sucesso.',
          life: 3000,
        });
        this.peoples = [...this.peoples];
        this.peopleDialog = false;
        this.people = {};
        window.location.reload()
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Houve algum erro ao editar pessoa.',
          life: 3000,
        });
      }
    );
  }
}
