<app-menubar></app-menubar>
<p-toast></p-toast>
<div style="max-width: 100%; margin-top: 5%;" *ngIf="inProgress">
  <p-progressSpinner
    [style]="{
      width: '100%',
      height: '70px',
      marginTop: '20%',
      marginBottom: '20%'
    }"
    styleClass="custom-spinner"
  ></p-progressSpinner>
</div>

<div class="card crud-container" *ngIf="!inProgress">
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
      <button
        pButton
        pRipple
        [label]="Remover"
        icon="pi pi-trash"
        class="p-button-danger"
        (click)="deleteSelectedGames()"
        [disabled]="!selectedGames || !selectedGames.length"
      ></button>
    </ng-template>

    <!--
    <ng-template pTemplate="right">
      <p-fileUpload
        mode="basic"
        accept="image/*"
        [maxFileSize]="1000000"
        label="Importar"
        chooseLabel="Import"
        class="p-mr-2 p-d-inline-block"
      ></p-fileUpload>
      <button
        pButton
        pRipple
        label="Exportar"
        icon="pi pi-upload"
        class="p-button-help"
      ></button>
    </ng-template>
    -->
  </p-toolbar>

  <p-table
    #dt
    [value]="games"
    [rows]="50"
    [paginator]="true"
    [globalFilterFields]="['game_name']"
    [(selection)]="selectedGames"
    [paginator]="true" 
    dataKey="id"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-ai-center p-jc-between">
        <h5 class="p-m-0">Jogos</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..."
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="game_name">
          Jogo <p-sortIcon field="game_name"></p-sortIcon>
        </th>
        <th pSortableColumn="start">
          Data <p-sortIcon field="start"></p-sortIcon>
        </th>
        <th pSortableColumn="created_at">
          Data de criação <p-sortIcon field="created_at"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-game>
      <tr>
        <td>
          <p-tableCheckbox [value]="game"></p-tableCheckbox>
        </td>
        <td>{{ game.game_name }}</td>
        <td>
          {{ game.start }}
        </td>
        <td>
          {{ game.created_at }}
        </td>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-eye"
            class="p-button-rounded crud-button"
            (click)="showGame(game)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded crud-button"
            (click)="editGame(game)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-users"
            class="p-button-rounded crud-button"
            (click)="editCast(game)"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded crud-button"
            (click)="deleteGame(game)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        No total, existem
        {{ games ? games.length : 0 }} jogos.
      </div>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="gameDialog"
  [style]="{ width: '80%' }"
  header="Jogo"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div
      style="padding: 5px; margin: 5px; display: flex; float: left; width: 100%"
    >
      <div class="p-field" style="width: 50%; padding: 5px">
        <label>Mandante</label>
        <p-dropdown
          [options]="teams"
          [(ngModel)]="selectedMTeam"
          optionLabel="apelido"
          [filter]="true"
          filterBy="nome"
          [showClear]="true"
          placeholder="Selecione a equipe mandante"
        >
          <ng-template pTemplate="selectedItem">
            <div class="team-item team-item-value" *ngIf="selectedMTeam">
              <div>{{ selectedMTeam.apelido }}</div>
            </div>
          </ng-template>
          <ng-template let-team pTemplate="item">
            <div class="team-item">
              <div>{{ team.apelido }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-field" style="width: 50%; padding: 5px">
        <label>Visitante</label>
        <p-dropdown
          [options]="teams"
          [(ngModel)]="selectedVTeam"
          optionLabel="apelido"
          [filter]="true"
          filterBy="nome"
          [showClear]="true"
          placeholder="Selecione a equipe visitante"
        >
          <ng-template pTemplate="selectedItem">
            <div class="team-item team-item-value" *ngIf="selectedVTeam">
              <div>{{ selectedVTeam.apelido }}</div>
            </div>
          </ng-template>
          <ng-template let-team pTemplate="item">
            <div class="team-item">
              <div>{{ team.apelido }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <small class="p-invalid" *ngIf="submitted && !selectedVTeam"
          >Time visitante obrigatório.</small
        >
      </div>
    </div>
    <label>Gols comuns</label>
    <div
      style="
        background-color: black;
        padding: 5px;
        margin: 5px;
        display: flex;
        float: left;
        width: 100%;
      "
    >
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="placar_mandante">Mandante</label>
        <input
          type="number"
          pInputText
          id="placar_mandante"
          [(ngModel)]="game.placar_mandante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.placar_mandante"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="placar_visitante">Visitante</label>
        <input
          type="number"
          pInputText
          id="placar_visitante"
          [(ngModel)]="game.placar_visitante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.placar_visitante"
          >Campo obrigatório.</small
        >
      </div>
    </div>
    <br />
    <label>Gols em penaltis</label>
    <div
      style="
        background-color: black;
        padding: 5px;
        margin: 5px;
        display: flex;
        float: left;
        width: 100%;
      "
    >
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="placar_penalti_mandante"
          >Mandante</label
        >
        <input
          type="number"
          pInputText
          id="placar_penalti_mandante"
          [(ngModel)]="game.placar_penalti_mandante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small
          class="p-invalid"
          *ngIf="submitted && !game.placar_penalti_mandante"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="placar_penalti_visitante"
          >Visitante</label
        >
        <input
          type="number"
          pInputText
          id="placar_penalti_visitante"
          [(ngModel)]="game.placar_penalti_visitante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small
          class="p-invalid"
          *ngIf="submitted && !game.placar_penalti_visitante"
          >Campo obrigatório.</small
        >
      </div>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <label for="inicio">Inicio</label>
      <p-calendar
        [(ngModel)]="game.inicio"
        [showTime]="true"
        inputId="inicio"
        [disabled]="verb === 'GET'"
      ></p-calendar>
      <small class="p-invalid" *ngIf="submitted && !game.inicio"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="termino">Termino</label>
      <p-calendar
        [(ngModel)]="game.termino"
        [showTime]="true"
        inputId="termino"
        [disabled]="verb === 'GET'"
      ></p-calendar>
      <small class="p-invalid" *ngIf="submitted && !game.termino"
        >Campo obrigatório.</small
      >
    </div>
    <div>
      <h5>Temporada</h5>
      <p-dropdown
        [options]="sessions"
        [(ngModel)]="selectedSession"
        [showClear]="true"
        placeholder="Selecione a temporada"
        (click)="sessionChange()"
      >
        <ng-template pTemplate="selectedItem">
          <div class="session-item session-item-value" *ngIf="selectedSession">
            <div>{{ selectedSession }}</div>
          </div>
        </ng-template>
        <ng-template let-session pTemplate="item">
          <div class="session-item">
            <div>{{ session }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div>
      <h5>Campeonato</h5>
      <p-dropdown
        [options]="competitions"
        [(ngModel)]="selectedCompetition"
        optionLabel="descricao"
        [filter]="true"
        filterBy="descricao"
        [showClear]="true"
        placeholder="Selecione Campeonato"
        (click)="competitionChange()"
      >
        <ng-template pTemplate="selectedItem">
          <div
            class="competition-item competition-item-value"
            *ngIf="selectedCompetition"
          >
            <div>{{ selectedCompetition.campeonato }}</div>
          </div>
        </ng-template>
        <ng-template let-competition pTemplate="item">
          <div class="competition-item">
            <div>{{ competition.campeonato }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div>
      <h5>Fase</h5>
      <p-dropdown
        [options]="phases"
        [(ngModel)]="selectedPhase"
        optionLabel="nome"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecionar Fase"
        (click)="phaseChange()"
      >
        <ng-template pTemplate="selectedItem">
          <div class="phase-item phase-item-value" *ngIf="selectedPhase">
            <div>{{ selectedPhase.nome }}</div>
          </div>
        </ng-template>
        <ng-template let-phase pTemplate="item">
          <div class="phase-item">
            <div>{{ phase.nome }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div>
      <label for="turno">Turno</label>
      <input
        type="number"
        pInputText
        id="turno"
        [(ngModel)]="game.turno"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !game.turno"
        >Campo obrigatório.</small
      >
    </div>
    <div>
      <label for="turno">Rodada</label>
      <input
      type="number"
      pInputText
      id="rodada"
      [(ngModel)]="game.rodada"
      required
      autofocus
      [disabled]="verb === 'GET'"
    />
    </div>

    <label>Classificação</label>
    <div
      style="
        background-color: black;
        padding: 5px;
        margin: 5px;
        display: flex;
        float: left;
        width: 100%;
      "
    >
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="posicao_mandante">Mandante</label>
        <input
          type="number"
          pInputText
          id="posicao_mandante"
          [(ngModel)]="game.posicao_mandante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.posicao_mandante"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="posicao_visitante">Visitante</label>
        <input
          type="number"
          pInputText
          id="posicao_visitante"
          [(ngModel)]="game.posicao_visitante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.posicao_visitante"
          >Campo obrigatório.</small
        >
      </div>
    </div>

    <label>Pontuação</label>
    <div
      style="
        background-color: black;
        padding: 5px;
        margin: 5px;
        display: flex;
        float: left;
        width: 100%;
      "
    >
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="pontos_mandante">Mandante</label>
        <input
          type="number"
          pInputText
          id="pontos_mandante"
          [(ngModel)]="game.pontos_mandante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.pontos_mandante"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="pontos_visitante">Visitante</label>
        <input
          type="number"
          pInputText
          id="pontos_visitante"
          [(ngModel)]="game.pontos_visitante"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.pontos_visitante"
          >Campo obrigatório.</small
        >
      </div>
    </div>

    <div class="p-field">
      <label>Estádio</label>
      <p-dropdown
        [options]="places"
        [(ngModel)]="selectedPlace"
        optionLabel="apelido"
        [filter]="true"
        filterBy="nome"
        [showClear]="true"
        placeholder="Selecione o estádio"
      >
        <ng-template pTemplate="selectedItem">
          <div class="place-item place-item-value" *ngIf="selectedPlace">
            <div>{{ selectedPlace.apelido }}</div>
          </div>
        </ng-template>
        <ng-template let-place pTemplate="item">
          <div class="place-item">
            <div>{{ place.apelido }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small class="p-invalid" *ngIf="submitted && !selectedPlace"
        >Campo obrigatório.</small
      >
    </div>

    <label>Acréscimos</label>
    <div
      style="
        background-color: black;
        padding: 5px;
        margin: 5px;
        display: flex;
        float: left;
        width: 100%;
      "
    >
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="acrescimo1t">1° Tempo</label>
        <input
          type="number"
          pInputText
          id="acrescimo1t"
          [(ngModel)]="game.acrescimo1t"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.acrescimo1t"
          >Campo obrigatório.</small
        >
      </div>
      <div class="p-field" style="width: 50%; padding: 5px">
        <label style="color: white" for="acrescimo2t">2° Tempo</label>
        <input
          type="number"
          pInputText
          id="acrescimo2t"
          [(ngModel)]="game.acrescimo2t"
          required
          autofocus
          [disabled]="verb === 'GET'"
        />
        <small class="p-invalid" *ngIf="submitted && !game.acrescimo2t"
          >Campo obrigatório.</small
        >
      </div>
    </div>

    <div class="p-field">
      <label for="descricao">Descrição</label>
      <input
        type="text"
        pInputText
        id="descricao"
        [(ngModel)]="game.descricao"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !game.descricao"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="transmissao">Transmissão</label>
      <input
        type="text"
        pInputText
        id="transmissao"
        [(ngModel)]="game.transmissao"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !game.transmissao"
        >Campo obrigatório.</small
      >
    </div>

    <div class="p-field">
      <label for="clima">Clima</label>
      <input
        type="text"
        pInputText
        id="clima"
        [(ngModel)]="game.clima"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !game.clima"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="temperatura">Temperatura</label>
      <input
        type="number"
        pInputText
        id="temperatura"
        [(ngModel)]="game.temperatura"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !game.temperatura"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="fuso">Fuso</label>
      <input
        type="text"
        pInputText
        id="fuso"
        [(ngModel)]="game.fuso"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !game.fuso"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="abrangencia">Abrangencia</label>
      <input
        type="text"
        pInputText
        id="abrangencia"
        [(ngModel)]="game.abrangencia"
        required
        autofocus
        [disabled]="verb === 'GET'"
      />
      <small class="p-invalid" *ngIf="submitted && !game.abrangencia"
        >Campo obrigatório.</small
      >
    </div>
    <div class="p-field">
      <label for="televisionado">TV?</label>
      <div class="input-radio">
        <p-radioButton
          name="televisionado"
          value="1"
          [(ngModel)]="game.televisionado"
          [disabled]="verb === 'GET'"
        ></p-radioButton>
        <label>Sim</label>
      </div>
      <div class="input-radio">
        <p-radioButton
          name="televisionado"
          value="0"
          [(ngModel)]="game.televisionado"
          [disabled]="verb === 'GET'"
        ></p-radioButton>
        <label>Não</label>
      </div>
      <small class="p-invalid" *ngIf="submitted && !game.televisionado"
        >Campo obrigatório.</small
      >
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      (click)="submitForm()"
      *ngIf="!(verb === 'GET')"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="castDialog"
  [style]="{ width: '80%', minHeight: '100%' }"
  header="Elenco"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <button
      pButton
      pRipple
      label="Gerenciar Atividades"
      class="p-button-rounded"
      (click)="openActivities()"
    ></button>
    <div
      style="display: flex; float: left; width: 100%; min-height: 550px"
      *ngIf="selectedGame"
    >
      <app-clipboard
        teamId="{{ selectedGame.commanding_team_id }}"
        gameId="{{ selectedGame.id }}"
        isCRUD="{{ true }}"
        inHome="1"
      ></app-clipboard>
      <app-clipboard
        teamId="{{ selectedGame.visiting_team_id }}"
        gameId="{{ selectedGame.id }}"
        isCRUD="{{ true }}"
        inHome="0"
      ></app-clipboard>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="activitiesDialog"
  [style]="{ width: '90%', minHeight: '100%' }"
  header="Atividades"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <app-activities-crud
      gameId="{{ selectedGame.id }}"
      visitingTeamId="{{ selectedGame.visiting_team_id }}"
      commandingTeamId="{{ selectedGame.commanding_team_id }}"
    ></app-activities-crud>
  </ng-template>
</p-dialog>


