import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PeopleCRUDService {
  url = localStorage.host + '/pessoa/';
  url2 = localStorage.host + '/add-pessoa/';
  modality_id = localStorage.modality_id;
  category_id = localStorage.category_id;

  constructor(private http: HttpClient) { }

  public getPeoples(): Observable<any> {

    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.get(
      this.url +
      '?modality_id=' +
      this.modality_id +
      '&category_id=' +
      this.category_id,
      {
        headers: header,
      }
    );
  }

  public getPeopleSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <any[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public getPeopleWithOrdersSmall() {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http
      .get<any>(this.url, { headers: header })
      .toPromise()
      .then((res) => <any[]>res.data)
      .then((data) => {
        return data;
      });
  }

  public postPeople(people: any): Observable<any> {
    console.log(people)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.post<any>(this.url2, {
      "nome": people.nome,
      "apelido": people.apelido,
      "sexo": people.sexo,
      "nascimento": people.nascimento,
      "documento": people.documento,
      "bio": people.bio
    },
      {
        headers: header,
      });
  }

  public putPeople(people: any): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );
    return this.http.patch<any>(this.url + people.id + '/', {

      "nome": people.nome,
      "apelido": people.apelido,
      "sexo": people.sexo,
      "nascimento": people.nascimento,
      "documento": people.documento,
      "bio": people.bio
    },
      {
        headers: header,
      });
  }

  public deletePeople(people: any): Observable<any> {
    console.log(people)
    const header = new HttpHeaders().set(
      'Authorization',
      'x-csrftoken' + localStorage.token
    );

    return this.http.patch<any>(this.url + people.id + '/', { "field_is_deleted": 1 }, {
      headers: header,
    });
  }
}
